"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaternityTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.MaternityTags = {
    "": {
        title: constants_1.ProductInfo.maternity.description,
        canonicalUrl: "/maternity",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/maternity/articles",
    },
    "/articles/postpartum-mind-nutrition": {
        title: "הקשר בין תזונה לבריאות הנפשית לאחר לידה",
        description: "מדריך מקיף על הקשר בין תזונה ובריאות נפשית לאחר לידה: גלי כיצד תזונה נכונה יכולה לתמוך בבריאות הנפשית שלך, להפחית סיכון לדיכאון, ולשפר את מצב הרוח בתקופה המאתגרת שלאחר הלידה.",
        keywords: "תזונה לאחר לידה, בריאות נפשית, דיכאון לאחר לידה, תזונה ומצב רוח, הורמונים, אומגה 3, ויטמינים, תזונאית קלינית, עדי יאנה, תזונה מאוזנת, בריאות האם, תקופת לידה, חוסרים תזונתיים, אנרגיה, מצב רוח, התאוששות לאחר לידה",
        canonicalUrl: "/maternity/articles/postpartum-mind-nutrition",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3875225/pexels-photo-3875225.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונה ובריאות לאחר לידה",
            },
        },
    },
    "/articles/breastfeeding-nutrition": {
        title: "תזונה במהלך ההנקה: השפעות, יתרונות והמלצות תזונתיות",
        description: "מדריך מקיף לתזונה נכונה בתקופת ההנקה: אבות המזון החיוניים, השפעתם על איכות חלב האם, המלצות להידרציה נכונה, ומזונות שכדאי להימנע מהם. כל מה שאם מניקה צריכה לדעת על תזונה מאוזנת התומכת בהנקה מוצלחת ובבריאות האם והתינוק.",
        keywords: "הנקה, תזונה בהנקה, חלב אם, תזונת אם מניקה, המלצות תזונה להנקה, הידרציה בהנקה, חלבון בהנקה, סידן בהנקה, ברזל בהנקה, מזונות מומלצים להנקה, תזונאית הנקה, ייעוץ הנקה, בריאות אם ותינוק",
        canonicalUrl: "/maternity/articles/breastfeeding-nutrition",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3875276/pexels-photo-3875276.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "הנקה ותזונה",
            },
        },
    },
    "/articles/first-1000-days-nutrition": {
        title: "תזונת 1000 הימים הראשונים - יסודות להתפתחות מיטבית",
        description: "מדריך מקיף על חשיבות התזונה ב-1000 הימים הראשונים לחיים, מתקופת טרום ההיריון ועד גיל שנתיים. כולל המלצות תזונתיות, השפעות ארוכות טווח, והנחיות להתפתחות בריאה של תינוקך.",
        keywords: "תזונת תינוקות, תזונה בהריון, הנקה, התפתחות ילדים, בריאות, תזונה מאוזנת, מזון משלים, חומצה פולית, ברזל, אומגה 3, חלבון, ויטמינים, מינרלים, התפתחות מוחית, מערכת חיסון",
        canonicalUrl: "/maternity/articles/first-1000-days-nutrition",
        ogType: "article",
        ogImage: "https://images.unsplash.com/photo-1623227866882-c005c26dfe41?auto=format&fit=crop&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונת הריון ותינוקות",
            },
        },
    },
    "/articles/postpartum-weight-loss-nutrition": {
        title: "עקרונות תזונתיים בירידה במשקל לאחר לידה",
        description: "מדריך מקצועי לירידה במשקל בריאה ומאוזנת לאחר לידה. כולל עקרונות תזונתיים, טיפים לשמירה על ייצור חלב, ותמיכה בהתאוששות הגוף.",
        keywords: "ירידה במשקל לאחר לידה, תזונה לאחר לידה, הנקה ודיאטה, תזונה מאוזנת, התאוששות מלידה, תזונה בהנקה, חילוף חומרים, תזונאית קלינית, עדי יאנה",
        canonicalUrl: "/maternity/articles/postpartum-weight-loss-nutrition",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3270224/pexels-photo-3270224.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונת אימהות",
            },
        },
    },
    "/articles/postpartum-target-dress": {
        title: "בגד מטרה לאחר לידה: מנוע מוטיבציה או מעגל תסכול?",
        description: "מדריך מקיף על השימוש בבגד מטרה לאחר לידה - היתרונות, האתגרים הרגשיים והפיזיים, והדרך הנכונה להשתמש בו כמקור מוטיבציה חיובי. כולל טיפים מקצועיים להצבת יעדים ריאליים ושמירה על בריאות הגוף והנפש.",
        keywords: "בגד מטרה, לאחר לידה, ירידה במשקל, מוטיבציה, דימוי גוף, בריאות נשים, הריון ולידה, תזונה לאחר לידה, חזרה לכושר, הרזיה בריאה, תזונה מאוזנת, בריאות נפשית, העצמה נשית, יעדי משקל, תזונאית קלינית",
        canonicalUrl: "/maternity/articles/postpartum-target-dress",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/6567607/pexels-photo-6567607.jpeg?auto=compress&cs=tinysrgb&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "בריאות נשים",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/maternity/lets-talk",
    },
};
