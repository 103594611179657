import {
  faAppleWhole,
  faBrain,
  faChild,
  faHeartbeat,
  faMoon,
  faScaleBalanced,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(5),
  margin: theme.spacing(3),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const keyFactors = [
  {
    id: "hormones",
    title: "איזון הורמונלי",
    description:
      "שינה משפיעה על הורמוני הרעב והשובע (גרלין ולפטין), המווסתים את התיאבון.",
    icon: faHeartbeat,
    color: "#FF6B6B",
  },
  {
    id: "growth",
    title: "הורמון גדילה",
    description: "הורמון הגדילה מופרש בעיקר בשינה עמוקה, חיוני להתפתחות תקינה.",
    icon: faChild,
    color: "#4ECDC4",
  },
  {
    id: "brain",
    title: "תפקוד מוחי",
    description:
      "שינה טובה משפרת את קבלת ההחלטות התזונתיות ומפחיתה אכילה רגשית.",
    icon: faBrain,
    color: "#45B7D1",
  },
  {
    id: "balance",
    title: "איזון משקל",
    description: "שינה מספקת תורמת לשמירה על משקל תקין ומטבוליזם בריא.",
    icon: faScaleBalanced,
    color: "#96CEB4",
  },
];

const sleepRecommendations = [
  {
    age: "גיל הינקות (0-1)",
    hours: "12-16",
    importance: "חיוני להתפתחות המוח והגוף",
  },
  {
    age: "פעוטות (1-2)",
    hours: "11-14",
    importance: "תמיכה בגדילה מואצת",
  },
  {
    age: "גיל הגן (3-5)",
    hours: "10-13",
    importance: "פיתוח כישורים קוגניטיביים",
  },
  {
    age: "גיל בית ספר (6-12)",
    hours: "9-12",
    importance: "תמיכה בלמידה ובהתפתחות רגשית",
  },
  {
    age: "מתבגרים (13-18)",
    hours: "8-10",
    importance: "איזון הורמונלי ובריאות נפשית",
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(6),
  },
  "& > *:last-child": {
    marginBottom: 0,
  },
}));

const ParallaxSection = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(8),
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  borderRadius: 15,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StyledList = styled("ul")(({ theme }) => ({
  listStyle: "none",
  padding: 0,
  margin: theme.spacing(0, 0, 4),
  "& li": {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: "0 2px 8px rgba(0,0,0,0.05)",
    "& svg": {
      marginRight: theme.spacing(4),
      marginLeft: theme.spacing(2),
      marginTop: "4px",
      color: theme.palette.primary.main,
      fontSize: "1.5rem",
      minWidth: "24px",
      flexShrink: 0,
    },
    "& p": {
      margin: 0,
      flex: 1,
      fontSize: "1.1rem",
      lineHeight: 1.6,
    },
  },
  "& li:last-child": {
    marginBottom: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.primary.main,
  marginBottom: theme.spacing(4),
  fontSize: "1.5rem",
}));

const HERO_IMAGE =
  "https://images.pexels.com/photos/3771663/pexels-photo-3771663.jpeg?w=1260&auto=compress";
const SLEEP_IMAGE =
  "https://images.pexels.com/photos/3771662/pexels-photo-3771662.jpeg?w=1260&auto=compress";

export const SleepObesityLink = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  ילדים עייפים אוכלים יותר
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  הקשר בין שעות שינה והשמנה
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  הקדמה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  השמנה בילדים היא תופעה עולמית הולכת וגוברת, המושפעת מגורמים
                  רבים, כולל תזונה, פעילות גופנית, גנטיקה ואורח חיים. אחד
                  מהגורמים הנחקרים ביותר בהקשר זה הוא מחסור בשינה, אשר נמצא קשור
                  באופן ישיר להשמנה בילדים ובני נוער. מחקרים עדכניים מראים כי
                  מחסור בשעות שינה משפיע על מנגנוני הרעב והשובע, על חילוף
                  החומרים, על דפוסי האכילה לא בריאים, ובכך עלולות להגביר את
                  הסיכון לעלייה במשקל.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Key Factors Grid */}
            <Grid container spacing={3}>
              {keyFactors.map((factor) => (
                <Grid key={factor.id} size={{ xs: 12, sm: 6, md: 3 }}>
                  <InfoCard>
                    <FontAwesomeIcon
                      icon={factor.icon}
                      style={{
                        fontSize: "2.5rem",
                        color: factor.color,
                        marginBottom: "1rem",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {factor.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {factor.description}
                    </Typography>
                  </InfoCard>
                </Grid>
              ))}
            </Grid>

            {/* Sleep Research Section */}
            <ParallaxSection>
              <ParallaxBanner style={{ height: "100%" }}>
                <ParallaxBannerLayer
                  image={SLEEP_IMAGE}
                  speed={-15}
                  scale={[1.1, 1]}
                  opacity={[0.8, 1]}
                />
                <ParallaxBannerLayer speed={-10} opacity={[1, 0.9]}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                      padding: 4,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: "white",
                        textAlign: "center",
                        textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                        maxWidth: "800px",
                        fontWeight: 700,
                      }}
                    >
                      הקשר הביולוגי בין שינה לתיאבון ולגדילה
                    </Typography>
                  </Box>
                </ParallaxBannerLayer>
              </ParallaxBanner>
            </ParallaxSection>

            {/* Sleep Recommendations */}
            <StyledPaper elevation={3}>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, color: theme.palette.primary.main }}
              >
                המלצות שינה לפי גיל
              </Typography>
              <Grid container spacing={3}>
                {sleepRecommendations.map((rec, index) => (
                  <Grid key={index} size={{ xs: 12, sm: 6, md: 4 }}>
                    <Paper
                      sx={{
                        p: 2,
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faMoon}
                        style={{
                          fontSize: "2rem",
                          color: "#45B7D1",
                          marginBottom: "0.5rem",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        {rec.age}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        gutterBottom
                      >
                        {rec.hours} שעות
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {rec.importance}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>

            {/* Practical Tips */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  טיפים מעשיים לשיפור איכות השינה
                </Typography>
                <Grid container spacing={3}>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        שגרת שינה קבועה
                      </Typography>
                      <Typography variant="body1">
                        קביעת זמני שינה והתעוררות קבועים עוזרת לגוף להתרגל
                        למחזור שינה בריא.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        סביבת שינה מתאימה
                      </Typography>
                      <Typography variant="body1">
                        חדר חשוך, שקט וקריר מסייע לשינה איכותית יותר.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        הגבלת מסכים
                      </Typography>
                      <Typography variant="body1">
                        הימנעות ממסכים שעה לפני השינה משפרת את איכות השינה.
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        פעילות גופנית
                      </Typography>
                      <Typography variant="body1">
                        פעילות גופנית סדירה במהלך היום תורמת לשינה טובה יותר
                        בלילה.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Parallax>

            <StyledPaper elevation={3}>
              <StyledTitle variant="h5">מחקרים עדכניים</StyledTitle>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: "1.1rem",
                  lineHeight: 1.8,
                  mb: 0,
                  px: { xs: 1, md: 2 },
                }}
              >
                מחקר פרוספקטיבי גדול שפורסם ב-Pediatrics בשנת 2019, בחן את הרגלי
                השינה של כ-10,000 ילדים לאורך מספר שנים. נמצא כי ילדים שישנו
                פחות מ-9 שעות בלילה היו בסיכון גבוה יותר לפתח השמנה בהשוואה לאלו
                שישנו 10-11 שעות. תוצאות דומות נמצאו במחקר מטא-אנליזה שכלל 30
                מחקרים שונים והדגיש את הקשר החזק בין מחסור בשעות שינה לבין עלייה
                ב-BMI בילדים.
              </Typography>
            </StyledPaper>

            <StyledPaper elevation={3}>
              <StyledTitle variant="h5">
                השפעת חוסר שינה על הרגלי אכילה וגדילה
              </StyledTitle>
              <Box sx={{ px: { xs: 0, md: 0 } }}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: 1.8,
                    mb: 4,
                    px: theme.spacing(3),
                  }}
                >
                  מעבר לשינויים ההורמונליים, מחסור בשעות שינה משפיע גם על
                  ההתנהגות התזונתית של ילדים. ילדים עייפים נוטים:
                </Typography>
                <StyledList>
                  <li>
                    <FontAwesomeIcon icon={faAppleWhole} />
                    <Typography variant="body1">
                      לצרוך יותר חטיפים ומזון מעובד – מחסור בשינה מגביר את
                      הנטייה לבחירת מזונות עשירים בסוכר ושומן.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faMoon} />
                    <Typography variant="body1">
                      לאכול בשעות מאוחרות – מחקרים מצביעים על כך ששעות אכילה
                      מאוחרות קשורות לעלייה במשקל.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faScaleBalanced} />
                    <Typography variant="body1">
                      לדלג על ארוחות מסודרות – עייפות יכולה לגרום לילדים לדלג על
                      ארוחת הבוקר, מה שמוביל לפיצוי יתר במהלך היום.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChild} />
                    <Typography variant="body1">
                      להאט את קצב הגדילה – מחסור בשינה פוגע בהפרשת הורמון הגדילה
                      ומשפיע על התפתחות תקינה של מערכות הגוף.
                    </Typography>
                  </li>
                </StyledList>
              </Box>
            </StyledPaper>

            <StyledPaper elevation={3}>
              <StyledTitle variant="h5">
                תזונה תומכת גדילה ושינה איכותית
              </StyledTitle>
              <Box sx={{ px: { xs: 0, md: 0 } }}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    fontSize: "1.1rem",
                    lineHeight: 1.8,
                    mb: 4,
                    px: theme.spacing(3),
                  }}
                >
                  כדי לתמוך בתהליך הגדילה ולשפר את איכות השינה, מומלץ:
                </Typography>
                <StyledList>
                  <li>
                    <FontAwesomeIcon icon={faHeartbeat} />
                    <Typography variant="body1">
                      לשלב חלבונים בתפריט היומי – חלבון הוא מרכיב חיוני בבניית
                      רקמות השריר והתפתחות הגוף.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faBrain} />
                    <Typography variant="body1">
                      לוודא צריכת מגנזיום וסידן – מינרלים אלה חיוניים לבריאות
                      העצם ותומכים בשינה רגועה.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faAppleWhole} />
                    <Typography variant="body1">
                      להפחית צריכת סוכרים מעובדים בערב – סוכרים גורמים לעוררות
                      ועלולים לפגוע באיכות השינה.
                    </Typography>
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faChild} />
                    <Typography variant="body1">
                      לשתות מספיק מים – התייבשות עלולה לפגוע בגדילה ולהוביל
                      לעייפות כרונית.
                    </Typography>
                  </li>
                </StyledList>
              </Box>
            </StyledPaper>

            <StyledPaper elevation={3}>
              <StyledTitle variant="h5">סיכום</StyledTitle>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: "1.1rem",
                  lineHeight: 1.8,
                  mb: 0,
                  px: { xs: 2, md: 3 },
                }}
              >
                שינה מספקת היא מרכיב חיוני לשמירה על בריאות הילדים, למניעת השמנה
                ולקידום תהליך גדילה תקין. מחקרים מצביעים על כך שחסר שינה גורם
                לשינויים הורמונליים והתנהגותיים המובילים לצריכת יתר של מזון,
                האטת הגדילה ועלייה במשקל. על כן, חשוב להעלות את המודעות לחשיבות
                שינה טובה, איכותית ומספקת ולהקנות לילדים הרגלים נכונים לשמירה על
                אורח חיים בריא והתפתחות תקינה.
              </Typography>
            </StyledPaper>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
