import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faCarrot,
  faEgg,
  faFaceHandYawn,
  faFaceNauseated,
  faLeaf,
  faPersonBreastfeeding,
  faScaleBalanced,
  faSun,
  faUtensils,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, Container, Grid2 as Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { withWrapper } from "app/hooks";
import { motion } from "framer-motion";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import "./Home.scss";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  height: "100%",
  minHeight: "180px",
  background: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "blur(10px)",
  transition: "all 0.3s ease-in-out",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "4px",
    background: "linear-gradient(to right, #FF69B4, #FF1493)",
  },
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.08)",
  },
}));

interface FloatingIconProps {
  icon: IconDefinition;
  color: string;
  delay?: number;
}

const FloatingIcon: React.FC<FloatingIconProps> = ({
  icon,
  color,
  delay = 0,
}: FloatingIconProps) => (
  <motion.div
    initial={{ y: 0 }}
    animate={{ y: [-20, 300, -20] }}
    transition={{
      duration: 12,
      repeat: Infinity,
      ease: "easeInOut",
      delay,
    }}
    style={{ position: "absolute", zIndex: 2 }}
  >
    <FontAwesomeIcon
      icon={icon}
      style={{
        fontSize: 28,
        color,
        filter: "drop-shadow(0 2px 4px rgba(0,0,0,0.1))",
      }}
    />
  </motion.div>
);

const nutritionTips = [
  {
    icon: faScaleBalanced,
    title: "תזונה מאוזנת בהריון",
    description: "תוכנית תזונתית מותאמת אישית לבריאות האם ולהתפתחות העובר.",
    color: "#FF69B4",
    bgColor: "#FFF0F5",
  },
  {
    icon: faAppleWhole,
    title: "שמירה על אנרגיה וחיוניות",
    description: "התמודדות עם העייפות בהריון ובניית הרגלי אכילה תומכים.",
    color: "#4CAF50",
    bgColor: "#F1F8E9",
  },
  {
    icon: faWeightScale,
    title: "איזון משקל בהריון",
    description: "שמירה על משקל תקין תוך התאמה לצרכים המשתנים.",
    color: "#2196F3",
    bgColor: "#E3F2FD",
  },
  {
    icon: faFaceNauseated,
    title: "התמודדות עם בחילות ותיאבון משתנה",
    description:
      "טיפים וכלים לשיפור ההרגשה ולהתמודדות עם שינויים בתחילת ההריון.",
    color: "#9C27B0",
    bgColor: "#F3E5F5",
  },
];

const postpartumTips = [
  {
    icon: faPersonBreastfeeding,
    title: "תמיכה בהנקה",
    description: "התאמת התזונה לתקופת ההנקה ולצרכים המשתנים של האם הטרייה.",
    color: "#00BCD4",
    bgColor: "#E0F7FA",
  },
  {
    icon: faSun,
    title: "התאוששות וחיזוק הגוף",
    description:
      "תזונה מותאמת להחלמה מההריון והלידה, למילוי מאגרי הגוף ולתמיכה בחיוניות היומיומית.",
    color: "#FF9800",
    bgColor: "#FFF3E0",
  },
  {
    icon: faFaceHandYawn,
    title: "התמודדות עם עייפות התקופה",
    description: "שילוב תזונה תומכת להתמודדות עם העייפות והגברת החיוניות.",
    color: "#E91E63",
    bgColor: "#FCE4EC",
  },
  {
    icon: faUtensils,
    title: "ארוחות מזינות ומהירות",
    description: "רעיונות לארוחות קלות, מהירות ועשירות שמתאימות לשגרה החדשה.",
    color: "#8BC34A",
    bgColor: "#F1F8E9",
  },
];

export const Home = withWrapper(() => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden", bgcolor: "#FAFAFA" }}>
        {/* Hero Section with Split Design */}
        <Box
          sx={{
            position: "relative",
            height: { xs: "auto", md: "85vh" },
            overflow: "hidden",
          }}
        >
          <Grid container sx={{ height: "100%" }}>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                p: { xs: 4, md: 8 },
                zIndex: 2,
              }}
            >
              <motion.div
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: "2rem", md: "3.5rem" },
                    fontWeight: 800,
                    mb: 3,
                    background: "linear-gradient(45deg, #FF69B4, #FF1493)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                  }}
                >
                  תזונה בהריון ולאחר לידה
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "1.1rem", md: "1.5rem" },
                    color: "text.secondary",
                    mb: { xs: 4, md: 6 },
                    maxWidth: "600px",
                  }}
                >
                  שינויים בגוף ובתזונה – ליווי מותאם מההריון ועד אחרי הלידה
                </Typography>

                <ArticlesButton />
              </motion.div>
            </Grid>
            <Grid
              size={{ xs: 12, md: 6 }}
              sx={{
                position: "relative",
                minHeight: { xs: "300px", md: "100%" },
                mt: { xs: 4, md: 0 },
              }}
            >
              <Box
                sx={{
                  position: { xs: "relative", md: "absolute" },
                  top: { xs: 0, md: "50%" },
                  left: { xs: 0, md: "50%" },
                  transform: { xs: "none", md: "translate(-50%, -50%)" },
                  width: "100%",
                  height: { xs: "300px", md: "100%" },
                  backgroundImage:
                    "url(https://images.unsplash.com/photo-1515488042361-ee00e0ddd4e4?q=80&w=1200)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: { xs: "20px", md: "0 0 0 100px" },
                  mx: { xs: "auto", md: 0 },
                  maxWidth: { xs: "90%", md: "100%" },
                }}
              />
              {/* Floating Icons - Adjusted for mobile */}
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  display: { xs: "none", md: "block" },
                }}
              >
                <Box sx={{ position: "absolute", top: "15%", left: "30%" }}>
                  <FloatingIcon icon={faCarrot} color="#FF9800" delay={0} />
                </Box>
                <Box sx={{ position: "absolute", top: "10%", right: "35%" }}>
                  <FloatingIcon icon={faEgg} color="#4CAF50" delay={2.5} />
                </Box>
                <Box sx={{ position: "absolute", top: "5%", left: "45%" }}>
                  <FloatingIcon icon={faLeaf} color="#8BC34A" delay={1.2} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Container maxWidth="lg" sx={{ px: { xs: 2, md: 4 } }}>
          {/* Pregnancy Nutrition Section */}
          <Box sx={{ py: { xs: 6 } }}>
            <Parallax translateY={[20, -20]} opacity={[0.5, 1]}>
              <Typography
                variant="h2"
                className="section-title"
                sx={{
                  textAlign: "center",
                  mb: { xs: 6, md: 10 },
                  fontSize: { xs: "2rem", md: "2.5rem" },
                  fontWeight: 700,
                }}
              >
                תזונה בהריון
              </Typography>
            </Parallax>

            <Grid container spacing={{ xs: 3, md: 4 }}>
              {nutritionTips.map((tip, index) => (
                <Grid key={index} size={{ xs: 12, md: 6 }}>
                  <Parallax
                    translateY={[15, -15]}
                    opacity={[0.5, 1]}
                    scale={[0.95, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <StyledCard
                        sx={{
                          bgcolor: tip.bgColor,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "white",
                              p: 2,
                              borderRadius: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                              flexShrink: 0,
                              width: "56px",
                              height: "56px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={tip.icon}
                              style={{
                                fontSize: 32,
                                color: tip.color,
                              }}
                            />
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{
                                fontWeight: 700,
                                color: tip.color,
                                fontSize: { xs: "1.25rem", md: "1.4rem" },
                                mb: 1,
                              }}
                            >
                              {tip.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                color: "text.secondary",
                                lineHeight: 1.6,
                              }}
                            >
                              {tip.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Postpartum Section */}
          <Box
            sx={{
              py: { xs: 6 },
              position: "relative",
              "&::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: "-100%",
                right: "-100%",
                height: "100%",
                background:
                  "linear-gradient(180deg, #FFF5F8 0%, transparent 100%)",
                zIndex: -1,
              },
            }}
          >
            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                className="section-title"
                sx={{
                  textAlign: "center",
                  mb: { xs: 6, md: 10 },
                  fontSize: { xs: "2rem", md: "2.5rem" },
                  fontWeight: 700,
                }}
              >
                תזונה לאחר לידה
              </Typography>
            </Parallax>

            <Grid container spacing={{ xs: 3, md: 4 }}>
              {postpartumTips.map((tip, index) => (
                <Grid key={index} size={{ xs: 12, md: 6 }}>
                  <Parallax
                    translateY={[15, -15]}
                    opacity={[0.5, 1]}
                    scale={[0.95, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <StyledCard
                        sx={{
                          bgcolor: tip.bgColor,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            width: "100%",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              bgcolor: "white",
                              p: 2,
                              borderRadius: "16px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                              flexShrink: 0,
                              width: "56px",
                              height: "56px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={tip.icon}
                              style={{
                                fontSize: 32,
                                color: tip.color,
                              }}
                            />
                          </Box>
                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{
                                fontWeight: 700,
                                color: tip.color,
                                fontSize: { xs: "1.25rem", md: "1.4rem" },
                                mb: 1,
                              }}
                            >
                              {tip.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                color: "text.secondary",
                                lineHeight: 1.6,
                              }}
                            >
                              {tip.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
});
