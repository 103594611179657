import {
  faBrain,
  faHeartPulse,
  faMoon,
  faPlateUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  LinearProgress,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/3875225/pexels-photo-3875225.jpeg?auto=compress&w=1260",
  nutrition:
    "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&w=640",
  sleep:
    "https://images.pexels.com/photos/3807332/pexels-photo-3807332.jpeg?auto=compress&w=1260",
  mentalHealth:
    "https://images.pexels.com/photos/3094230/pexels-photo-3094230.jpeg?auto=compress&w=640",
  foodPrep:
    "https://images.pexels.com/photos/1640773/pexels-photo-1640773.jpeg?auto=compress&w=640",
};

const moodFactors = [
  {
    id: "hormones",
    title: "שינויים הורמונליים",
    description: "ירידה ברמות אסטרוגן ופרוגסטרון המשפיעה על מצב הרוח",
    icon: faBrain,
    color: "#FF6B6B",
    level: 85,
  },
  {
    id: "nutrition",
    title: "תזונה ומצב רוח",
    description: "השפעת רכיבי תזונה על ייצור נוירוטרנסמיטורים",
    icon: faPlateUtensils,
    color: "#4ECDC4",
    level: 75,
  },
  {
    id: "sleep",
    title: "שינה ומנוחה",
    description: "חשיבות השינה לוויסות רגשות והתמודדות עם לחץ",
    icon: faMoon,
    color: "#45B7D1",
    level: 90,
  },
  {
    id: "wellness",
    title: "בריאות כללית",
    description: "השפעת התזונה על הבריאות הפיזית והנפשית",
    icon: faHeartPulse,
    color: "#96CEB4",
    level: 80,
  },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const PostpartumMindNutrition = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    הקשר בין תזונה למצב הרוח לאחר לידה
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    כיצד תזונה נכונה תומכת בבריאות הנפשית בתקופה המאתגרת שלאחר
                    הלידה
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      מבוא
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      התקופה שלאחר הלידה מאופיינת בשינויים הורמונליים
                      ופיזיולוגיים המשפיעים על תפקוד המוח ומצב הרוח. ירידה
                      טבעית, לאחר הלידה, ברמות ההורמונים אסטרוגן ופרוגסטרון
                      עשויה לגרום לשינויים במצב הרוח, בעוד שהעלייה בצרכי
                      האנרגיה, העומס והלחץ הנפשיים מגבירים את הצורך בתזונה תומכת
                      ומאוזנת. תזונה נכונה ומאוזנת יכולה לשחק תפקיד מרכזי
                      בהתמודדות עם עייפות, שיפור מצב הרוח ואף במניעת דיכאון לאחר
                      לידה. מאמר זה יבחן כיצד תזונה משפיעה על בריאות האם הטרייה.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.nutrition}
                  alt="תזונה בריאה לאחר לידה"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Mood Factors Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                גורמים המשפיעים על מצב הרוח
              </Typography>
              <Grid container spacing={3}>
                {moodFactors.map((factor, index) => (
                  <Grid key={factor.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${factor.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={factor.icon}
                              style={{ fontSize: 40, color: factor.color }}
                            />
                          </Box>
                          <Box sx={{ flexGrow: 1 }}>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: factor.color, fontWeight: 600 }}
                            >
                              {factor.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem", mb: 2 }}
                            >
                              {factor.description}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={factor.level}
                              sx={{
                                height: 8,
                                borderRadius: 4,
                                backgroundColor: `${factor.color}20`,
                                "& .MuiLinearProgress-bar": {
                                  backgroundColor: factor.color,
                                },
                              }}
                            />
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Nutrition and Mood Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      הקשר בין תזונה למצב הרוח לאחר לידה
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      מחקרים מראים כי חוסר בחומרים מזינים מסוימים עלול להשפיע על
                      ייצור הנוירוטרנסמיטורים במוח, האחראים על וויסות מצב הרוח.
                      לדוגמה, חומצות שומן אומגה-3, המצויות באגוזים, זרעי צ'יה
                      ושמן זית, תומכות בפעילות המוח ומשפרות את התחושה הכללית.
                      בנוסף, ויטמין B12 וברזל חיוניים למניעת עייפות ודכדוך.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      צריכת פירות וירקות עשירים בנוגדי חמצון מסייעת בהפחתת סטרס
                      חמצוני - אשר גורם להגברת העייפות, להעלייה בתחושות מתח
                      וחרדה ולפגיעה במצב הרוח. מצבים אלו עלולים ליצור ״מעגל
                      דכדוך״ שבו צריכת ״מזון נחמה" מגבירה את תחושת הכבדות
                      והעייפות, ומובילה לאכילה לא מאוזנת שאינה תורמת לשיפור המצב
                      הכללי. לכן מומלץ להפחית צריכת מזונות עתירי סוכר ומעובדים,
                      אשר עלולים להגביר את הפרשת הקורטיזול - הורמון הלחץ שעלול
                      לפגוע במאזן הכימי של המוח ולהוביל לעצבנות ועייפות. מנגד,
                      שילוב מזונות כמו בננות ושיבולת שועל, המגבירים את ייצור
                      הסרוטונין, תורם לתחושת רוגע ושביעות רצון. חוסר שינה פוגע
                      ביכולת המוח לווסת רגשות ומגביר רגישות ללחץ, ולכן תזונה
                      מאוזנת היא מפתח לתמיכה במערכת העצבים ולשמירה על רמות
                      אנרגיה יציבות.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
            </Box>

            {/* Sleep and Fatigue Section */}
            <Box sx={{ my: 8 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.sleep}
                  alt="שינה ומנוחה"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
              <Grid container spacing={4} alignItems="center">
                <motion.div
                  initial={{ opacity: 0, x: 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      התמודדות עם עייפות באמצעות תזונה נכונה
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      עייפות היא אחד האתגרים המרכזיים שאימהות טריות חוות,
                      ולעיתים קרובות חוסר השינה מלווה בהעמסה מנטלית ופיזית. שינה
                      איכותית, גם אם קצרה במהלך היום, חיונית לשמירה על הבריאות
                      הנפשית והפיזית. מומלץ לנצל הזדמנויות למנוחה קצרה במקום
                      להתעסק במטלות השונות והבלתי נגמרות. לכן תכנון מראש של
                      ארוחות יכול לעזור במצבים אלו. הכנת מנות שניתן לאכול
                      במהירות או ביד אחת, כמו כריכי חביתה, יוגורט עם גרנולה
                      ופירות או ירקות חתוכים עם חומוס, מאפשרת לאם להקדיש יותר
                      זמן למנוחה כאשר מתאפשר לה.
                      <br />
                      שילוב של חלבונים ופחמימות מורכבות מסייע לשמירה על רמות
                      אנרגיה יציבות לאורך היום. לדוגמה, פרוסת לחם מחיטה מלאה עם
                      חמאת בוטנים מספקת איזון אופטימלי בין פחמימות המספקות
                      אנרגיה זמינה, שומן וחלבון המשפרים את תחושת השובע. ארוחות
                      ביניים בריאות כמו אגוזים, שקדים ופירות מיובשים מספקים
                      אנרגיה מהירה בין הארוחות. חשוב להקפיד על שתייה מספקת של
                      מים לשמירה על ריכוז ואנרגיה, תוך הימנעות מצריכת יתר של
                      קפאין שעלולה להוביל לתשישות נוספת. בנוסף, תכנון תפריט יומי
                      מראש והכנת מנות גדולות שניתן להקפיא לשימוש בעת הצורך
                      חוסכים זמן ומאפשרים לאם להקדיש יותר זמן לשינה ומנוחה במהלך
                      היום, מה שתורם לשיפור מצב הרוח ולהפחתת תחושת העייפות.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
            </Box>

            {/* Depression Prevention Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        מניעת דיכאון לאחר לידה בעזרת תזונה מאוזנת
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        דיכאון לאחר לידה קשור בין היתר לחוסרים תזונתיים. מחקרים
                        מצביעים על כך שצריכת אומגה-3, חומצה פולית וויטמין D
                        עשויה להפחית את הסיכון להתפתחות דיכאון. חומצות שומן
                        חיוניות המצויות באבוקדו, אגוזים ושמן זית משפרות את תפקוד
                        המוח ותורמות לאיזון רגשי. בנוסף, פחמימות מורכבות כגון
                        קינואה ודגנים מלאים מסייעות להגביר את ייצור הסרוטונין -
                        המשפר את מצב הרוח.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.mentalHealth}
                    alt="בריאות נפשית"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Planning Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                תכנון והערכות לאמא העמוסה
              </Typography>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.foodPrep}
                    alt="הכנת אוכל מראש"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      mb: { xs: 4, md: 0 },
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        בשל עומס היומיום, ארוחות גדולות עלולות להיות מכבידות
                        וקשות לעיכול. צריכת ארוחות קטנות לאורך היום מסייעת
                        לשמירה על רמות אנרגיה יציבות ומונעת נפילות סוכר הגורמות
                        לעייפות. שפע של כריכים מהירי הכנה, יוגורטים מגוונים
                        מסוגים שונים או פירות חתוכים הם דוגמאות למנות בריאות,
                        מהירות הכנה שניתן לאכול ביד אחת. בנוסף, הכנת מנות גדולות
                        והקפאתן לשימוש בעת הצורך, כמו מרקים עשירים בקטניות
                        וירקות מוקפאים, מאפשרת לאם ליהנות מארוחות מזינות גם
                        בימים העמוסים ביותר.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    לסיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    חודשי ההריון ותהליך הלידה וההחלמה מתקופה זו שואבות
                    נוטריינטים רבים ממאגרי האם – כל אלו לצד אתגרי ההורות לתינוק
                    קטן מדגישים את חשיבותה של תזונה נכונה, המחדשת וממלאת את
                    מאגרי הגוף, תורמת לשיפור מצב הרוח, מסייעת בהתמודדות עם
                    עייפות ותומכת במניעת דיכאון.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    תזונה מאוזנת מסייעת בטווח הקצר אך גם תומכת בבריאות ארוכת
                    טווח של האם. שילוב של חלבונים, פחמימות מורכבות, אומגה-3
                    וויטמינים חיוניים חשובים לבריאות הנפשית והפיזית של האם
                    הטרייה. ליווי של גורם מקצועי כמו דיאטנית יכול לעזור ולספק
                    התאמה אישית לצרכים הייחודיים של כל אם, תוך חיזוק היכולת
                    לדאוג לעצמה ולרווחתה באמצעות בחירות תזונתיות נכונות.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
