"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VeganTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.VeganTags = {
    "": {
        title: constants_1.ProductInfo.vegan.description,
        description: "גלו את היתרונות של תזונה טבעונית - בריאות מיטבית, אחריות סביבתית ותזונה עשירה. מידע מקצועי, מתכונים וטיפים לאורח חיים טבעוני מאת עדי יאנה, תזונאית קלינית.",
        keywords: "תזונה טבעונית, טבעונות, אורח חיים בריא, תזונה מהצומח, ייעוץ תזונתי, מתכונים טבעוניים, בריאות טבעונית, תזונאית טבעונית",
        canonicalUrl: "/vegan",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/vegan/articles",
    },
    "/articles/plant-based-weight-loss": {
        title: "אסטרטגיות וטיפים מעשיים לירידה במשקל בתזונה טבעונית וצמחונית",
        description: "מדריך מקיף לירידה במשקל בתזונה טבעונית וצמחונית, כולל אסטרטגיות מעשיות, טיפים והמלצות מבוססות מחקר.",
        keywords: "תזונה טבעונית, ירידה במשקל, תזונה צמחונית, חלבון צמחי, פחמימות, שומנים בריאים, סידן",
        canonicalUrl: "/vegan/articles/plant-based-weight-loss",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-21",
                dateModified: "2025-02-21",
                articleSection: "תזונה טבעונית",
            },
        },
    },
    "/articles/myths": {
        title: "מיתוסים על טבעונות - מה נכון ומה לא?",
        description: "מדריך מקיף על מיתוסים נפוצים בתזונה טבעונית: חלבון, סידן, ברזל, אנרגיה ועוד. מידע מבוסס מחקר והמלצות מעשיות.",
        keywords: "טבעונות, מיתוסים על טבעונות, תזונה טבעונית, חלבון טבעוני, סידן טבעוני, ברזל טבעוני, טופו, ספורטאים טבעונים",
        canonicalUrl: "/vegan/articles/myths",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1458694/pexels-photo-1458694.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונה טבעונית",
            },
        },
    },
    "/articles/plant-based-sports": {
        title: "צמחונות, טבעונות וספורט - מדריך מקיף להישגים ספורטיביים",
        description: "מדריך מקיף על הקשר בין תזונה טבעונית וצמחונית לבין הישגים ספורטיביים, כולל מחקרים עדכניים והמלצות מעשיות.",
        keywords: "תזונה טבעונית, ספורט, ביצועים ספורטיביים, בניית שריר, תזונת ספורטאים, צמחונות, טבעונות",
        canonicalUrl: "/vegan/articles/plant-based-sports",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4720236/pexels-photo-4720236.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונת ספורט",
            },
        },
    },
    "/articles/health-benefits": {
        title: "היתרונות הבריאותיים של תזונה טבעונית",
        description: "מדריך מקיף על היתרונות הבריאותיים של תזונה טבעונית: מניעת מחלות לב, איזון סוכרת, הפחתת סיכון לסרטן ועוד",
        keywords: "תזונה טבעונית, בריאות, מניעת מחלות, תזונה בריאה, טבעונות, סוכרת, מחלות לב, סרטן",
        canonicalUrl: "/vegan/articles/health-benefits",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונה טבעונית",
            },
        },
    },
    "/articles/life-stages": {
        title: "טבעונות בשלבי החיים השונים",
        description: "מדריך מקיף על תזונה טבעונית בשלבי החיים השונים: הריון והנקה, גיל ההתבגרות והגיל המבוגר. כולל המלצות תזונתיות מותאמות לכל שלב חיים והתייחסות ליתרונות הבריאותיים.",
        keywords: "טבעונות, תזונה טבעונית, הריון טבעוני, הנקה טבעונית, תזונת מתבגרים, תזונה בגיל השלישי, תזונה מאוזנת, בריאות",
        canonicalUrl: "/vegan/articles/life-stages",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/5529587/pexels-photo-5529587.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונה טבעונית",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/vegan/lets-talk",
    },
};
