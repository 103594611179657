import {
  faAppleWhole,
  faBacteria,
  faBed,
  faBrain,
  faFire,
  faPersonRunning,
  faWeight,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const microbiomeData = [
  { name: "לקטובצילוס", value: 35, color: "#FF6B6B" },
  { name: "ביפידובקטריה", value: 25, color: "#4ECDC4" },
  { name: "בקטרואידס", value: 20, color: "#45B7D1" },
  { name: "אחרים", value: 20, color: "#96CEB4" },
];

const benefitsData = [
  {
    id: "weight",
    title: "ניהול משקל",
    description: "השפעה ישירה על חילוף החומרים ואחסון שומן",
    icon: faWeight,
    color: "#FF6B6B",
  },
  {
    id: "metabolism",
    title: "חילוף חומרים",
    description: "שיפור יעילות עיבוד המזון והאנרגיה",
    icon: faFire,
    color: "#4ECDC4",
  },
  {
    id: "bacteria",
    title: "איזון חיידקים",
    description: "שמירה על מגוון חיידקים בריא במעיים",
    icon: faBacteria,
    color: "#45B7D1",
  },
  {
    id: "hormones",
    title: "איזון הורמונלי",
    description: "תמיכה במטבוליזם של אסטרוגן",
    icon: faBrain,
    color: "#96CEB4",
  },
];

const recommendations = [
  {
    icon: faAppleWhole,
    text: "תזונה עשירה בסיבים – צריכת ירקות, קטניות, אגוזים ודגנים מלאים מספקת מצע מזון לחיידקים הידידותיים.",
  },
  {
    icon: faBacteria,
    text: "פרוביוטיקה ופרה-ביוטיקה – שילוב של מזונות מותססים (כמו יוגורט, קפיר וכרוב כבוש) ותוספי פרוביוטיקה עשוי לשפר את הרכב החיידקים.",
  },
  {
    icon: faFire,
    text: "הימנעות מסוכרים מעובדים – צריכה עודפת של סוכר עלולה לשבש את איזון חיידקי המעיים ולעורר דלקתיות.",
  },
  {
    icon: faPersonRunning,
    text: "פעילות גופנית – מחקרים מראים כי פעילות סדירה משפרת את מגוון המיקרוביום ותורמת לאיזון משקל.",
  },
  {
    icon: faBed,
    text: "ניהול מתחים ושינה מספקת – סטרס כרוני עלול לפגוע במיקרוביום, ולכן מומלץ לשלב טכניקות הרפיה כמו יוגה ומדיטציה.",
  },
];

const HERO_IMAGE =
  "https://images.unsplash.com/photo-1576086213369-97a306d36557?q=80&w=1260";

export const Microbiome = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.8))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  המיקרוביום בגיל המעבר
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  המפתח לאיזון משקל, הורמונים ורעב
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  גיל המעבר מביא עמו שינויים פיזיולוגיים והורמונליים משמעותיים
                  שמשפיעים על הגוף, כולל שינויים במשקל, רמות האנרגיה ותחושת
                  הרעב. אחד הגורמים המרכזיים המשפיעים על התהליכים הללו הוא
                  המיקרוביום – קהילת החיידקים החיים במערכת העיכול שלנו. מחקרים
                  עדכניים מראים כי איזון נכון של חיידקי המעי יכול לתרום משמעותית
                  לניהול משקל, ויסות הורמונלי והפחתת חשקים למתוקים ולמאכלים
                  מעובדים.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Benefits Grid */}
            <Grid container spacing={3} sx={{ mt: 4 }}>
              {benefitsData.map((benefit) => (
                <Grid key={benefit.id} size={{ xs: 12, sm: 6, md: 3 }}>
                  <Box
                    data-aos="fade-up"
                    data-aos-delay={benefitsData.indexOf(benefit) * 100}
                  >
                    <StyledPaper
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={benefit.icon}
                        style={{
                          fontSize: "2.5rem",
                          color: benefit.color,
                          marginBottom: "1rem",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        {benefit.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {benefit.description}
                      </Typography>
                    </StyledPaper>
                  </Box>
                </Grid>
              ))}
            </Grid>

            {/* Microbiome Composition Chart */}
            <StyledPaper>
              <Box sx={{ textAlign: "center", mb: 4 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  align="center"
                  sx={{ mb: 3, fontWeight: 600 }}
                >
                  הרכב המיקרוביום הבריא
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}
                >
                  חיידקים ידידותיים במעי משחקים תפקיד מרכזי בבריאות הגוף. להלן
                  התפלגות החיידקים העיקריים במיקרוביום בריא
                </Typography>
              </Box>
              <Box sx={{ height: 400, direction: "ltr" }} data-aos="fade-left">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={microbiomeData}
                      cx="50%"
                      cy="50%"
                      outerRadius={160}
                      innerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={2}
                      label={({ name, percent }) =>
                        `${name} ${(percent * 100).toFixed(0)}%`
                      }
                      labelLine={false}
                    >
                      {microbiomeData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.color}
                          stroke="none"
                        />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => `${value}%`}
                      contentStyle={{
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "8px",
                        border: "none",
                        boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
                      }}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </StyledPaper>

            {/* What is Microbiome Section */}
            <StyledPaper>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                מהו המיקרוביום וכיצד הוא משתנה בגיל המעבר?
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                המיקרוביום מורכב ממיליארדי חיידקים ידידותיים השוכנים במעיים
                ומשפיעים על הבריאות הכללית שלנו. בגיל המעבר, ירידה ברמות
                האסטרוגן עלולה לגרום לשינוי בהרכב החיידקים, מה שמוביל להאטה בקצב
                חילוף החומרים, תהליכים דלקתיים מוגברים ושיבוש באיזון ההורמונלי.
                כתוצאה מכך, נשים רבות חוות עלייה במשקל וחשקים עזים יותר למזונות
                מסוימים.
              </Typography>
            </StyledPaper>

            {/* Metabolism Impact Section */}
            <StyledPaper>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                כיצד המיקרוביום משפיע על המשקל ועל חילוף החומרים?
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                הרכב המיקרוביום משפיע ישירות על יעילות חילוף החומרים והאופן שבו
                הגוף מעבד מזון. חיידקים מסוימים מסייעים בפירוק סיבים תזונתיים,
                הפקת חומצות שומן חיוניות והפחתת דלקתיות – גורמים שעשויים להשפיע
                לטובה על ירידה במשקל ושמירה עליו לאורך זמן.
              </Typography>
            </StyledPaper>

            {/* Changes in Microbiome Section */}
            <StyledPaper>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                שינויים בהרכב המיקרוביום בגיל המעבר
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                מחקרים הראו כי לאחר גיל המעבר חלה ירידה במגוון החיידקים במעי.
                בפרט:
              </Typography>
              <Box
                component="ul"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8, pl: 4 }}
              >
                <li>
                  ירידה בשפע של חיידקים מיטיבים – לדוגמה, חיידקים ממשפחת
                  Lactobacillaceae, הידועים בתרומתם לבריאות המעי.
                </li>
                <li>
                  עלייה בחיידקים מקדמי דלקת – כמו ממשפחת Enterobacteriaceae,
                  שקשורים לתהליכים דלקתיים שעלולים להשפיע על המשקל וחילוף
                  החומרים.
                </li>
                <li>
                  שינויים במטבוליטים – ירידה ברמות חומצות שומן קצרות שרשרת
                  (SCFA), המשפיעות על חילוף החומרים והמערכת החיסונית.
                </li>
              </Box>
            </StyledPaper>

            {/* Hormonal Regulation Section */}
            <StyledPaper>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                תפקיד המיקרוביום בוויסות הורמונלי
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                המיקרוביום לא רק משפיע על המשקל, אלא גם ממלא תפקיד חשוב בוויסות
                הורמונלי. חיידקי המעיים מעורבים במטבוליזם של אסטרוגן, ומשפיעים
                על רמות ההורמון בגוף. איזון נכון של חיידקי המעיים עשוי להפחית
                תסמינים כמו גלי חום, שינויים במצבי רוח ותחושת עייפות כרונית.
              </Typography>
            </StyledPaper>

            {/* Recommendations */}
            <StyledPaper>
              <Box sx={{ textAlign: "center", mb: 4 }}>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                  המלצות לטיפוח מיקרוביום בריא
                </Typography>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ maxWidth: "800px", mx: "auto", mb: 4 }}
                >
                  צעדים פשוטים שיכולים לעזור לך לשמור על מיקרוביום בריא ומאוזן
                </Typography>
              </Box>
              <Grid container spacing={3}>
                {recommendations.map((rec, index) => (
                  <Grid key={index} size={{ xs: 12, sm: 6 }}>
                    <Box
                      data-aos="fade-up"
                      data-aos-delay={index * 100}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        p: 2,
                        borderRadius: 2,
                        bgcolor: "background.default",
                        "&:hover": {
                          bgcolor: "background.paper",
                          boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                          transform: "translateY(-2px)",
                          transition: "all 0.3s ease-in-out",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: 60,
                          height: 60,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          ml: 2,
                          bgcolor: `${theme.palette.primary.main}15`,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={rec.icon}
                          style={{
                            fontSize: "1.8rem",
                            color: theme.palette.primary.main,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: 1.6,
                        }}
                      >
                        {rec.text}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>

            {/* Summary Section */}
            <StyledPaper>
              <Typography
                variant="h5"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                המיקרוביום הוא שחקן מרכזי בניהול המשקל, איזון הורמונלי והפחתת
                תחושת רעב בגיל המעבר. על ידי התאמת התזונה ואורח החיים, ניתן לטפח
                אוכלוסיית חיידקים מיטיבה שתסייע לגוף להתמודד עם השינויים
                הפיזיולוגיים באופן טבעי ובריא יותר.
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8, fontWeight: 500 }}
              >
                אם את חווה קשיים בניהול המשקל או בתסמינים הורמונליים, שילוב של
                אסטרטגיות תומכות למיקרוביום עשוי להיות הפתרון שאת מחפשת!
              </Typography>
            </StyledPaper>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
