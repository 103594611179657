import {
  faBrain,
  faChartLine,
  faMoon,
  faSunBright,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "60vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&w=1260",
  energy:
    "https://images.pexels.com/photos/1153369/pexels-photo-1153369.jpeg?auto=compress&w=640",
  mood: "https://images.pexels.com/photos/3184183/pexels-photo-3184183.jpeg?auto=compress&w=640",
  sleep:
    "https://images.pexels.com/photos/3771069/pexels-photo-3771069.jpeg?auto=compress&w=640",
  brain:
    "https://images.pexels.com/photos/3683074/pexels-photo-3683074.jpeg?auto=compress&w=640",
  metabolism:
    "https://images.pexels.com/photos/4397833/pexels-photo-4397833.jpeg?auto=compress&w=640",
};

const sections = [
  {
    id: "energy",
    title: "תזונה ואנרגיה יומיומית",
    icon: faSunBright,
    color: "#FF6B6B",
  },
  {
    id: "mood",
    title: "תזונה ומצב רוח",
    icon: faUtensils,
    color: "#4ECDC4",
  },
  {
    id: "sleep",
    title: "תזונה לשיפור איכות השינה והפחתת עייפות כרונית",
    icon: faMoon,
    color: "#45B7D1",
  },
  {
    id: "brain",
    title: "איך תזונה משפיעה על הריכוז והזיכרון",
    icon: faBrain,
    color: "#96CEB4",
  },
  {
    id: "metabolism",
    title: "תזונה ומטבוליזם: איך להאיץ את חילוף החומרים",
    icon: faChartLine,
    color: "#FF9F9F",
  },
];

const mockEnergyData = [
  { time: "6:00", balanced: 90, unbalanced: 70 },
  { time: "9:00", balanced: 85, unbalanced: 50 },
  { time: "12:00", balanced: 88, unbalanced: 85 },
  { time: "15:00", balanced: 82, unbalanced: 45 },
  { time: "18:00", balanced: 85, unbalanced: 75 },
  { time: "21:00", balanced: 80, unbalanced: 40 },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const ScientificNutritionBenefits = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    תזונה נכונה – איך היא משפיעה ומשפרת את איכות החיים?
                  </Typography>
                </Zoom>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg">
          <ContentSection>
            {/* Introduction */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  תזונה נכונה היא מפתח לשיפור איכות החיים, המשפיעה לא רק על
                  הבריאות הפיזית אלא גם על התפקוד המנטלי והרגשי. לעיתים אנו לא
                  עוצרים לחשוב על הרכב הארוחה הנכון לאותו רגע: האם ארוחה שונה
                  לפני השינה תשפר את איכות השינה? כיצד תזונה משפיעה על מצב הרוח
                  בזמני דכדוך או עייפות? מחקרים מדעיים מראים כי רכיבי תזונה
                  חיוניים יכולים לסייע לא רק בשיפור הבריאות הכללית, אלא גם לשפר
                  את מצב הרוח, איכות השינה, הריכוז והזיכרון, ואף להגביר את חילוף
                  החומרים.
                </Typography>
              </StyledCard>
            </motion.div>

            {/* Energy Section with Graph */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${sections[0].color}20`,
                            p: 2,
                            borderRadius: "50%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={sections[0].icon}
                            style={{
                              fontSize: 32,
                              color: sections[0].color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: sections[0].color, fontWeight: 700 }}
                        >
                          {sections[0].title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 3 }}
                      >
                        תזונה מאוזנת תורמת לרמות אנרגיה יציבות לאורך היום. ארוחה
                        כבדה ועשירה בפחמימות פשוטות עלולה לגרום לתחושת עייפות
                        בעקבות עלייה מהירה ברמות הסוכר בדם ולאחריה ירידה חדה.
                        במהלך יום עבודה, תחושת עייפות זו מובילה לעיתים קרובות
                        לצריכת מתוקים במטרה להתעורר, אך ההשפעה היא זמנית בלבד
                        ועלולה להגביר תנודות באנרגיה.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        מנגד, צריכת דגנים מלאים, סיבים תזונתיים, ירקות ופירות
                        מסייעת בשמירה על תחושת שובע ממושכת מבלי לגרום לתחושת
                        כבדות. מזונות אלה מתעכלים באיטיות ומספקים שחרור הדרגתי
                        של גלוקוז לדם, מה שתורם לאיזון רמות הסוכר ולשמירה על
                        אנרגיה יציבה לאורך זמן. שילוב חלבונים ושומנים בריאים,
                        כמו טופו, אגוזים ושמן זית, מסייע בתחושת שובע ומונע
                        נפילות אנרגיה פתאומיות.
                      </Typography>
                      <Box sx={{ height: 300 }}>
                        <ResponsiveContainer width="100%" height="100%">
                          <AreaChart data={mockEnergyData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="time" />
                            <YAxis />
                            <Tooltip />
                            <Area
                              type="monotone"
                              dataKey="balanced"
                              name="תזונה מאוזנת"
                              stroke={sections[0].color}
                              fill={`${sections[0].color}40`}
                            />
                            <Area
                              type="monotone"
                              dataKey="unbalanced"
                              name="תזונה לא מאוזנת"
                              stroke="#FF9F9F"
                              fill="#FF9F9F40"
                            />
                          </AreaChart>
                        </ResponsiveContainer>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.energy}
                    alt="אנרגיה ותזונה"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Mood Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.mood}
                    alt="מצב רוח ותזונה"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${sections[1].color}20`,
                            p: 2,
                            borderRadius: "50%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={sections[1].icon}
                            style={{
                              fontSize: 32,
                              color: sections[1].color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: sections[1].color, fontWeight: 700 }}
                        >
                          {sections[1].title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        הקשר בין תזונה למצב הרוח נובע מכמה גורמים, בראשם ההשפעה
                        ההדדית בין המיקרוביום (אוכלוסיית החיידקים במעי) לבין
                        מערכת העצבים. מחקרים מראים שהמיקרוביום משחק תפקיד מרכזי
                        בייצור מוליכים עצביים כמו סרוטונין, אשר משפיעים ישירות
                        על מצב הרוח. תזונה עשירה במזונות מהצומח, הכוללת סיבים
                        תזונתיים מדגנים מלאים, ירקות, פירות, אגוזים וקטניות,
                        תומכת במיקרוביום בריא ובכך משפיעה לטובה גם על מצב הרוח.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8, mt: 2 }}
                      >
                        מזונות עשירים באומגה-3, כמו אגוזי מלך ודגים שומניים,
                        מסייעים בהפחתת תחושות חרדה ודיכאון. טריפטופן, המצוי
                        בטופו, שקדים ובננות, מעודד את ייצור הסרוטונין – מוליך
                        עצבי המווסת את מצב הרוח ומקדם תחושת רוגע. בנוסף, שילוב
                        תזונה מאוזנת מגביר את תחושת הרווחה הכללית.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8, mt: 2 }}
                      >
                        מנגד, תפריט עשיר בסוכרים פשוטים ובמזון מעובד ומתועש עלול
                        לפגוע במיקרוביום ולגרום לתנודות חדות במצב הרוח, העלולות
                        להגביר תחושות מתח ועצבנות.
                      </Typography>
                      <Box
                        sx={{
                          mt: 4,
                          p: 3,
                          backgroundColor: `${sections[1].color}10`,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          מזונות המשפיעים על מצב הרוח:
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          {[
                            "אומגה-3 מאגוזי מלך",
                            "טריפטופן מטופו",
                            "סיבים תזונתיים",
                            "ירקות ופירות טריים",
                          ].map((item, index) => (
                            <Grid key={index} size={{ xs: 6 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: "50%",
                                    backgroundColor: sections[1].color,
                                  }}
                                />
                                <Typography>{item}</Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Sleep Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${sections[2].color}20`,
                            p: 2,
                            borderRadius: "50%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={sections[2].icon}
                            style={{
                              fontSize: 32,
                              color: sections[2].color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: sections[2].color, fontWeight: 700 }}
                        >
                          {sections[2].title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 3 }}
                      >
                        שינה איכותית חיונית להחלמה, תפקוד הגוף והנפש ולתחושה
                        כללית טובה. יש להבדיל בין רציפות השינה – היכולת לישון
                        ללא הפרעות לאורך הלילה – לבין איכות השינה, הכוללת את
                        עומק השינה ותחושת הרעננות עם היקיצה. רכיבי תזונה מסוימים
                        יכולים לתרום לשינה טובה יותר: מגנזיום, המצוי באגוזים
                        ותרד, מרגיע את מערכת העצבים ומשפר את איכות השינה.
                        פחמימות מורכבות, כגון דגנים מלאים וקטניות, תומכות בייצור
                        המלטונין – הורמון השינה הטבעי. ארוחות ערב קלות, הכוללות
                        חלבונים מתונים כמו טופו או קטניות יחד עם ירקות, מסייעות
                        להימנע מתחושת כבדות שמפריעה לשינה. מנגד, קפאין ואלכוהול
                        עלולים להפריע לשינה רציפה ולהפחית את איכות השינה. שמירה
                        על איזון רמות הסוכר בדם לאורך היום מפחיתה תנודות באנרגיה
                        ומסייעת להפחתת תחושת העייפות הכרונית ולהתעוררות רעננה
                        יותר בבוקר.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.sleep}
                    alt="שינה ותזונה"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Brain Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.brain}
                    alt="ריכוז וזיכרון"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${sections[3].color}20`,
                            p: 2,
                            borderRadius: "50%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={sections[3].icon}
                            style={{
                              fontSize: 32,
                              color: sections[3].color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: sections[3].color, fontWeight: 700 }}
                        >
                          {sections[3].title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        תפקוד קוגניטיבי אופטימלי דורש אספקת חומרים מזינים שמגנים
                        על המוח ומשפרים את התקשורת בין תאי העצב. חומצות שומן
                        אומגה-3, המצויות בזרעי פשתן ואגוזי מלך, תורמות לזיכרון
                        ולריכוז ותורמות גם לאיכות השינה, מה שמשפיע באופן עקיף על
                        יכולת הריכוז והלמידה. אנטי-אוקסידנטים כמו ויטמין C
                        (בתפוזים ופלפלים) מגנים על המוח מפני נזק חמצוני ותורמים
                        לשמירה על תפקוד קוגניטיבי תקין. תזונה ים תיכונית, הכוללת
                        ירקות, פירות, שמן זית ודגנים מלאים, הוכחה במחקרים כמשפרת
                        את היכולות הקוגניטיביות ומפחיתה את הסיכון לירידה בזיכרון
                        בגיל מבוגר. בנוסף, שתיית מים מספקת חיונית לריכוז ולתפקוד
                        מנטלי לאורך היום, שכן התייבשות קלה עלולה לפגוע ביכולת
                        הריכוז והזיכרון.
                      </Typography>
                      <Box
                        sx={{
                          mt: 4,
                          p: 3,
                          backgroundColor: `${sections[3].color}10`,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          מזונות לשיפור הריכוז והזיכרון:
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          {["אומגה-3", "ויטמין C", "אנטיאוקסידנטים", "מים"].map(
                            (item, index) => (
                              <Grid key={index} size={{ xs: 6 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 8,
                                      height: 8,
                                      borderRadius: "50%",
                                      backgroundColor: sections[3].color,
                                    }}
                                  />
                                  <Typography>{item}</Typography>
                                </Box>
                              </Grid>
                            )
                          )}
                        </Grid>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Metabolism Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${sections[4].color}20`,
                            p: 2,
                            borderRadius: "50%",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={sections[4].icon}
                            style={{
                              fontSize: 32,
                              color: sections[4].color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: sections[4].color, fontWeight: 700 }}
                        >
                          {sections[4].title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        חילוף החומרים משפיע על קצב שריפת הקלוריות והפקת האנרגיה
                        בגוף. חשוב לדעת כיצד לשלב את רכיבי התזונה בצורה נכונה
                        שתתמוך בתהליך הירידה במשקל. חלבון, המצוי בטופו, קטניות
                        ואגוזים, דורש יותר אנרגיה לעיכול בהשוואה לפחמימות
                        ושומנים. קפסאיצין בפלפלים חריפים וקפאין בקפה מזרזים את
                        חילוף החומרים באופן זמני. בנוסף, צריכת מזון מגוונת
                        המשלבת מזון מהצומח הוכחה כמשפיעה לטובה על המיקרוביום –
                        אוכלוסיית החיידקים במעי – אשר מעלה את תחושת השובע,
                        מפחיתה את הצורך בנשנושים ובכך תורמת ותומכת בתהליך הירידה
                        במשקל. סיבים תזונתיים מירקות ודגנים מלאים משפרים את
                        פעילות מערכת העיכול ומעודדים שריפת קלוריות יעילה יותר.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8, mt: 2 }}
                      >
                        תכנון נכון של הארוחות לאורך היום תומך בחילוף החומרים
                        ושומר על רמות אנרגיה יציבות. בנוסף, שתיית מים מספקת
                        חיונית לשמירה על קצב חילוף חומרים תקין ועל תחושת שובע
                        לאורך כל היום.
                      </Typography>
                      <Box
                        sx={{
                          mt: 4,
                          p: 3,
                          backgroundColor: `${sections[4].color}10`,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="h6" gutterBottom>
                          גורמים המשפיעים על חילוף החומרים:
                        </Typography>
                        <Grid container spacing={2} sx={{ mt: 1 }}>
                          {[
                            "חלבון מהצומח",
                            "קפסאיצין",
                            "סיבים תזונתיים",
                            "שתיית מים",
                          ].map((item, index) => (
                            <Grid key={index} size={{ xs: 6 }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Box
                                  sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: "50%",
                                    backgroundColor: sections[4].color,
                                  }}
                                />
                                <Typography>{item}</Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.metabolism}
                    alt="חילוף חומרים"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    סיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    תזונה נכונה מהווה גורם מפתח לשיפור איכות החיים, תוך השפעה
                    ישירה ועקיפה על אספקטים שונים בחיינו. שילוב מזונות עשירים
                    בוויטמינים, מינרלים וחומצות שומן חיוניות מאפשר לגוף ולמוח
                    לתפקד במיטבם. שמירה על תזונה מאוזנת ומגוונת לא רק תומכת
                    בבריאות הפיזית אלא גם תורמת לאיכות חיים טובה יותר לאורך כל
                    שלבי החיים.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    חשוב לציין כי התאמה אישית של התזונה בעזרת גורם מקצועי
                    ומוסמך, כמו דיאטנית קלינית, יכולה לסייע בהבנת הצרכים
                    הייחודיים של כל אדם ולהבטיח יישום נכון של העקרונות התזונתיים
                    לצורך שיפור הבריאות ואיכות החיים.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
