import {
  faCarrot,
  faChildReaching,
  faEgg,
  faLeaf,
  faSeedling,
  faSprout,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const MYTHS = [
  {
    id: "protein",
    title: "טבעונות מובילה למחסור בחלבון",
    icon: faLeaf,
    color: "#4CAF50",
    image:
      "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=640&auto=compress",
    description: (
      <>
        אחד המיתוסים הנפוצים ביותר הוא שטבעונים אינם צורכים מספיק חלבון. עם זאת,
        מחקרים מראים כי ניתן להגיע לצריכת חלבון מספקת בתזונה טבעונית על ידי
        שילוב נכון של מזונות כמו קטניות, טופו ועוד. סקירה מדעית שפורסמה
        ב-American Journal of Clinical Nutrition הראתה כי חלבון מהצומח יכול לספק
        את כל חומצות האמינו החיוניות כאשר התפריט מגוון ומאוזן.
      </>
    ),
  },
  {
    id: "minerals",
    title: "טבעונות אינה מספקת מספיק סידן וברזל",
    icon: faSeedling,
    color: "#8BC34A",
    image:
      "https://images.pexels.com/photos/1199590/pexels-photo-1199590.jpeg?w=640&auto=compress",
    description: (
      <>
        מיתוס נוסף הוא שטבעונים אינם יכולים לצרוך מספיק סידן וברזל ללא מוצרי חלב
        או בשר. מחקרים מצביעים על כך שצריכת סידן ממקורות צמחיים כמו טופו,
        ברוקולי, שקדים ושומשום יכולה לספק את הכמות המומלצת.
        <br />
        בנוגע לצריכת ברזל, מזונות כמו עדשים, תרד, קינואה וקטניות עשירים בו,
        ובשילוב נכון לצד מקורות המכילים ויטמין C, מתאפשרת ספיגה יעילה יותר. מחקר
        שהתפרסם ב-Journal of the American Dietetic Association הראה כי טבעונים
        יכולים להגיע לרמות ברזל תקינות אם הם מתכננים נכון את תפריטם, ומומלץ
        להיעזר באיש מקצוע לשם כך.
      </>
    ),
  },
  {
    id: "healthy",
    title: "טבעונות תמיד בריאה יותר",
    icon: faCarrot,
    color: "#FF9800",
    image:
      "https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?w=640&auto=compress",
    description: (
      <>
        אף על פי שטבעונות יכולה להוביל לשיפור הבריאות, היא אינה בהכרח בריאה אם
        היא מבוססת על תחליפים שונים, מוצרים אולטרה-מעובדים עתירי סוכר, שומן,
        נתרן וחומרים מוספים שונים. תחליפי בשר וגבינות טבעוניים, כמו גם חטיפים
        מעובדים, אינם עדיפים תזונתית על מקביליהם מהחי.
      </>
    ),
  },
  {
    id: "energy",
    title: "טבעונים מתקשים לשמור על רמות אנרגיה תקינות",
    icon: faSprout,
    color: "#66BB6A",
    image:
      "https://images.pexels.com/photos/775031/pexels-photo-775031.jpeg?w=640&auto=compress",
    description: (
      <>
        החשש מעייפות וחוסר אנרגיה נפוץ בקרב מי ששוקלים מעבר לטבעונות. עם זאת,
        מחקרים מראים כי צריכת פחמימות מורכבות, חלבונים ושומנים בריאים כמו אגוזים
        וזרעים מספקת אנרגיה זמינה וממושכת. חשוב להקפיד גם על נטילת תוסף ויטמין
        B12, החיוני למערכת העצבים ולייצור אנרגיה, שכן הוא אינו מצוי במזונות
        מהצומח
      </>
    ),
  },
  {
    id: "athletes",
    title: "טבעונות לא מתאימה לילדים, נשים בהריון וספורטאים",
    icon: faChildReaching,
    color: "#26A69A",
    image:
      "https://images.pexels.com/photos/3076509/pexels-photo-3076509.jpeg?w=640&auto=compress",
    description: (
      <>
        על פי עמדת ארגוני בריאות כמו Academy of Nutrition and Dietetics, תזונה
        טבעונית מאוזנת מתאימה לכל שלבי החיים, כולל הריון, ילדות וספורט תחרותי.
        ספורטאים יכולים לקבל את החלבון הדרוש להם מתזונה טבעונית מגוונת, תוך
        הקפדה על צריכת קלוריות מספקת ותוספת חומצות אמינו חיוניות בעת הצורך.
      </>
    ),
  },
  {
    id: "tofu",
    title: "טופו מזיק בגלל תכולת האסטרוגן שבו",
    icon: faEgg,
    color: "#78909C",
    image:
      "https://images.pexels.com/photos/884600/pexels-photo-884600.jpeg?w=640&auto=compress",
    description: (
      <>
        מיתוס נפוץ נוסף הוא שטופו ומוצרי סויה אחרים מזיקים לבריאות מכיוון שהם
        מכילים אסטרוגן. למעשה, טופו מכיל פיטואסטרוגנים – תרכובות צמחיות הדומות
        במבנן להורמון האסטרוגן, אך הן פועלות בצורה שונה בגוף האדם. מחקרים מראים
        כי צריכה מתונה של טופו לא רק שאינה מזיקה, אלא אף מספקת יתרונות
        בריאותיים, כולל הפחתת הסיכון למחלות לב, סוכרת וסוגים מסוימים של סרטן.
        פיטואסטרוגנים בטופו עשויים להגן מפני סרטן השד והערמונית בזכות השפעתם
        המאזנת על פעילות ההורמונים בגוף. בנוסף, טופו הוא מקור מצוין לחלבון מלא
        ולסידן, מה שהופך אותו למזון חשוב במיוחד בתזונה טבעונית. מחקרים שפורסמו
        ב-Journal of Nutrition מדגישים כי צריכה סדירה של מוצרי סויה במסגרת תפריט
        מאוזן אינה קשורה לסיכון מוגבר למחלות הורמונליות ומסייעת לשמירה על בריאות
        העצם והלב.
      </>
    ),
  },
];

const PROTEIN_SOURCES_DATA = [
  { name: "קטניות", גרם: 15 },
  { name: "טופו", גרם: 20 },
  { name: "סייטן", גרם: 25 },
  { name: "קינואה", גרם: 8 },
  { name: "עדשים", גרם: 18 },
  { name: "חומוס", גרם: 15 },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const MythCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  height: "100%",
  overflow: "hidden",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const ImageContainer = styled(Box)({
  width: "100%",
  height: 200,
  overflow: "hidden",
  borderRadius: "10px",
  marginBottom: "16px",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.3s ease-in-out",
  },
  "&:hover img": {
    transform: "scale(1.05)",
  },
});

const HERO_IMAGE =
  "https://images.pexels.com/photos/1458694/pexels-photo-1458694.jpeg?w=1260&auto=compress";

export const Myths = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                מיתוסים על טבעונות
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  color: "white",
                  maxWidth: "800px",
                  textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                }}
              >
                המעבר לתזונה טבעונית הפך נפוץ יותר ויותר בשנים האחרונות, אך יחד
                עם העלייה במודעות צצו גם מיתוסים רבים
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                המעבר לתזונה טבעונית הפך נפוץ יותר ויותר בשנים האחרונות, אך יחד
                עם העלייה במודעות צצו גם מיתוסים רבים שמובילים לאי-הבנות בנוגע
                להשלכות הבריאותיות, הערכים התזונתיים וההשפעות ארוכות הטווח של
                טבעונות. במאמר זה אציג את המיתוסים המרכזיים בתחום, אחדד ואבסס
                מחקרית את האמת ואעזור לנפץ מיתוסים.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Myths Section */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 6, fontWeight: 700 }}
              data-aos="fade-up"
            >
              מיתוסים נפוצים
            </Typography>
            <Grid container spacing={4}>
              {MYTHS.map((myth, index) => (
                <Grid
                  key={myth.id}
                  size={{ xs: 12, md: 6 }}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                >
                  <MythCard>
                    <ImageContainer>
                      <img src={myth.image} alt={myth.title} />
                    </ImageContainer>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <FontAwesomeIcon
                        icon={myth.icon}
                        style={{
                          color: myth.color,
                          fontSize: "2rem",
                          marginLeft: theme.spacing(2),
                        }}
                      />
                      <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {myth.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
                      {myth.description}
                    </Typography>
                  </MythCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Protein Sources Graph */}
          <Parallax translateY={[-20, 20]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3} sx={{ my: 8 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                מקורות חלבון צמחיים (גרם חלבון ל-100 גרם)
              </Typography>
              <Box sx={{ width: "100%", height: 400 }}>
                <ResponsiveContainer>
                  <AreaChart
                    data={PROTEIN_SOURCES_DATA}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="גרם"
                      stroke="#4CAF50"
                      fill="#4CAF50"
                      fillOpacity={0.3}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </StyledPaper>
          </Parallax>

          {/* Summary Section */}
          <Parallax translateY={[-10, 10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                סיכום
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                רבים מהמיתוסים סביב טבעונות נובעים ממידע שגוי או מחוסר ידע
                בתכנון תזונה נכונה. מחקרים מראים כי תזונה טבעונית מאוזנת יכולה
                לספק את כל אבות המזון הדרושים, לשמור על רמות אנרגיה תקינות
                ולתמוך בבריאות בכל שלבי החיים. ליווי של דיאטנית קלינית המומחית
                בתזונה טבעונית יכול לסייע בהתאמת התפריט לצרכים האישיים ולמניעת
                חוסרים תזונתיים.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
