import { Box } from "@mui/material";
import { withWrapper } from "app/hooks";
import { useMeta } from "modules/common";
import { ParallaxProvider } from "react-scroll-parallax";
import { AboutSection } from "./AboutSection";
import { ContactSection } from "./ContactSection";
import { ProblemHeroSection } from "./ProblemHeroSection";
import { ServicesSection } from "./ServicesSection";
import { SolutionSection } from "./SolutionSection";

export const Home = withWrapper(() => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        <ProblemHeroSection />

        <SolutionSection />

        <AboutSection />

        <ServicesSection />

        <ContactSection />
      </Box>
    </ParallaxProvider>
  );
});
