import { Product, ProductInfo } from "@monorepo/constants";
import { CssBaseline, useMediaQuery } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { getTheme } from "themes";

interface State {
  product?: Product;
  productTitle?: string;
}

const ProductContext = createContext<State>({});

export const useProduct = () => useContext(ProductContext);

interface Props {
  children: ReactNode;
}

export const ProductProvider = ({ children }: Props) => {
  const [product, setProduct] = useState<Product | undefined>();
  const location = useLocation();

  const updateProduct = (pathname: string) => {
    const [, pathProduct] = pathname.split("/");
    if (pathProduct) {
      if (Object.values(Product).includes(pathProduct as Product)) {
        setProduct(pathProduct as Product);
      }
    } else {
      setProduct(undefined);
    }
  };

  useEffect(() => {
    updateProduct(location.pathname);
  }, [location.pathname]);

  const theme = getTheme(product);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const productTitle = useMemo(() => {
    if (product) {
      const { title, shortTitle } = ProductInfo[product];

      if (isMobile && shortTitle) {
        return shortTitle;
      }

      return title;
    }

    return undefined;
  }, [product, isMobile]);

  return (
    <ProductContext.Provider
      value={{
        product,
        productTitle,
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ProductContext.Provider>
  );
};
