// ---------------------------
// Helper functions
// ---------------------------
export const updateOrCreateMeta = (
  identifier: string,
  content: string,
  attr: "name" | "property" = "name"
) => {
  const selector =
    attr === "property"
      ? `meta[property='${identifier}']`
      : `meta[name='${identifier}']`;
  let element = document.head.querySelector<HTMLMetaElement>(selector);
  if (!element) {
    element = document.createElement("meta");
    element.setAttribute(attr, identifier);
    document.head.appendChild(element);
  }
  element.setAttribute("content", content);
};

export const updateOrCreateLink = (rel: string, href: string) => {
  const selector = `link[rel='${rel}']`;
  let element = document.head.querySelector<HTMLLinkElement>(selector);
  if (!element) {
    element = document.createElement("link");
    element.setAttribute("rel", rel);
    document.head.appendChild(element);
  }
  element.setAttribute("href", href);
};

export const createAndAppendMeta = (attributes: Record<string, string>) => {
  const meta = document.createElement("meta");
  Object.keys(attributes).forEach((key) => {
    meta.setAttribute(key, attributes[key]);
  });
  document.head.appendChild(meta);
};

export const removeElements = (selector: string) => {
  document.querySelectorAll(selector).forEach((el) => {
    el.parentNode?.removeChild(el);
  });
};

export const updateOrCreateScript = (id: string, jsonObject: unknown) => {
  let script = document.getElementById(id);
  if (!script) {
    script = document.createElement("script");
    script.setAttribute("type", "application/ld+json");
    script.setAttribute("id", id);
    document.head.appendChild(script);
  }
  script.textContent = JSON.stringify(jsonObject);
};

export const removeElementById = (id: string) => {
  const el = document.getElementById(id);
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
};
