"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SportTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.SportTags = {
    "": {
        title: constants_1.ProductInfo.sport.description,
        canonicalUrl: "/sport",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/sport/articles",
    },
    "/articles/endurance-vs-strength-nutrition": {
        title: "תזונה תומכת אימוני סיבולת לעומת תזונה תומכת אימוני כוח",
        description: "השוואה מקיפה בין צרכים תזונתיים של ספורטאי סיבולת וספורטאי כוח, כולל המלצות תזונה ספציפיות לכל סוג אימון.",
        keywords: "תזונת ספורט, ספורטאי סיבולת, ספורטאי כוח, תזונה מותאמת אישית, פחמימות בספורט, חלבונים בספורט, שומנים בספורט, המלצות תזונה לספורטאים",
        canonicalUrl: "/sport/articles/endurance-vs-strength-nutrition",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2024-02-22",
                dateModified: "2024-02-22",
                articleSection: "תזונת ספורט",
            },
        },
    },
    "/articles/diets-sports-performance": {
        title: "דיאטות פופולריות וספורט: יתרונות, חסרונות והשפעתן על הביצועים",
        description: "סקירה מקיפה של חמש דיאטות מובילות - קטוגנית, פליאו, טבעונית, ים-תיכונית וצום לסירוגין, והשפעתן על ביצועים ספורטיביים. כולל ניתוח מעמיק של יתרונות, חסרונות והתאמה לענפי ספורט שונים.",
        keywords: "דיאטה וספורט, תזונה ספורטיבית, דיאטה קטוגנית, דיאטת פליאו, תזונה טבעונית, דיאטה ים-תיכונית, צום לסירוגין, ביצועים ספורטיביים, תזונת ספורטאים, סיבולת, כוח",
        canonicalUrl: "/sport/articles/diets-sports-performance",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונה וספורט",
            },
        },
    },
    "/articles/sports-nutrition-impact": {
        title: "השפעת התזונה על הישגים ספורטיביים",
        description: "תזונה מאוזנת היא מרכיב חיוני בהשגת ביצועים ספורטיביים מיטביים, שכן היא משפיעה ישירות על סיבולת, כוח ומהירות, כמו גם על התאוששות מהירה ומניעת פציעות. במאמר זה נבחן את ההשפעות המרכזיות של תזונה על הישגים ספורטיביים ואת תפקידי החלבון והפחמימות בשמירה על ביצועים אופטימליים.",
        keywords: "תזונת ספורט, ביצועים ספורטיביים, סיבולת, כוח, מהירות, התאוששות, פציעות ספורט, תזונה לספורטאים, חלבון, פחמימות, תזונה מותאמת אישית, תזונאית קלינית",
        canonicalUrl: "/sport/articles/sports-nutrition-impact",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4498151/pexels-photo-4498151.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונת ספורט",
            },
        },
    },
    "/articles/athlete-supplements": {
        title: "תוספי תזונה לספורטאים - מדריך מקיף",
        description: "מדריך מקיף על תוספי תזונה לספורטאים. כולל מידע מפורט על חלבון, קריאטין, BCAA, בטא-אלנין, קפאין, ויטמינים ומינרלים. למדו על היתרונות, המינונים המומלצים והשימוש הנכון בתוספים.",
        keywords: "תוספי תזונה, ספורטאים, חלבון, קריאטין, BCAA, בטא-אלנין, קפאין, ויטמינים, מינרלים, תזונה לספורטאים, תוספי ספורט, חלבון מי גבינה, חלבון טבעוני, התאוששות שרירים, בניית שריר, ביצועים ספורטיביים",
        canonicalUrl: "/sport/articles/athlete-supplements",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4397833/pexels-photo-4397833.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "תזונת ספורט",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/sport/lets-talk",
    },
};
