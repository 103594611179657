import {
  faBalanceScale,
  faBrain,
  faChild,
  faHandHoldingHeart,
  faHeart,
  faSeedling,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const PARENTING_STYLES_CONTENT = {
  provider: {
    description:
      'גישה זו מבוססת על דאגה שהתזונה תהיה מספקת ומזינה, אך פעמים רבות מובילה לאכילה מתוך מחויבות ולא מתוך תחושת רעב טבעית. הורים מסוג זה עלולים להפעיל לחץ על ילדיהם "לסיים מהצלחת" או "לאכול יותר ירקות", דבר שמוביל להתנגדות מצד הילדים.',
  },
  controller: {
    description:
      "הורים המנהלים באופן הדוק איך, כמה ומה הילד יאכל, כולל הטלת מגבלות מחמירות על מתוקים ומאכלים פחות בריאים. גישה זו עלולה ליצור מערכת יחסים שלילית עם האוכל עם ההורה ולגרום לילדים לפתח תחושת מחסור, מה שמוביל לאכילה בלתי מבוקרת כאשר ניתנת להם אפשרות, או לאכילה בסתר.",
  },
  pleaser: {
    description:
      "הורים שמנסים לרצות את הילד באמצעות אוכל, למשל, על ידי מתן פרסים או עידוד לאכול מזון מסוים בתמורה למאכל אחר. גישה זו מלמדת את הילד שאוכל בריא הינה מטלה שצריך לסיים בכדי להגיע לדבר הנכסף - הקינוח בסיום הארוחה. גישה זו אינה מעודדת אכילת מזון בריא ואינה מלמדת הרגלי אכילה נכונים.",
  },
  health: {
    description:
      'הורים שמתמקדים באופן אובססיבי באיכות ובריאות המזון, לעיתים עד כדי יצירת תחושת לחץ אצל הילד לגבי מה מותר ומה אסור לאכול. גישה זו יכולה לגרום לילדים לפתח חרדה סביב אוכל ולחוש אשמה כאשר הם צורכים מזון "לא בריא".',
  },
  comforter: {
    description:
      "הורים שמשתמשים באוכל כדרך לנחם את הילד במצבי מתח, עצב או תסכול. גישה זו מלמדת את הילד לקשר בין אוכל ורגשות, מה שעלול להוביל לאכילה רגשית ולבעיות בניהול תזונה בריאה בעתיד.",
  },
  nurturer: {
    description:
      "הורים הרואים במזון דרך להביע אהבה וטיפוח. הם נוטים להציע כמויות גדולות של אוכל ולדאוג שהילד יאכל מעבר לתחושת השובע. גישה זו עשויה לגרום לילדים להרגיש מחויבים לאכול כדי לרצות את ההורים וליצור הרגלי אכילה הכוללים אכילה בכמויות גדולות כדרך להראות קבלה של אהבה.",
  },
};

const GUIDELINES = [
  {
    id: "respect",
    title: "לכבד את תחושת השובע של הילד",
    description:
      'במקום לומר "תסיים מהצלחת", ניתן לשאול "אתה מרגיש מלא?" וללמד את הילד להקשיב לגוף שלו.',
    icon: "🎯",
  },
  {
    id: "variety",
    title: "להציע מגוון אפשרויות מזון",
    description:
      "מחקרים מצביעים על כך שילדים שמקבלים בחירה מבוקרת מתוך מזונות בריאים נוטים לפתח תזונה מאוזנת יותר.",
    icon: "🥗",
  },
  {
    id: "pressure",
    title: "להימנע מהפעלת לחץ בזמן הארוחה",
    description:
      "לחץ לאכול יותר או לסיים מהר יכול לגרום להתנגדות ולפגיעה בהנאה מהאוכל.",
    icon: "🚫",
  },
  {
    id: "emotions",
    title: "להפריד בין אוכל לרגש",
    description:
      "הימנעות משימוש במזון כפרס או עונש, כדי למנוע התניה של אכילה עם מצבים רגשיים.",
    icon: "❤️",
  },
  {
    id: "atmosphere",
    title: "ליצור אווירה חיובית סביב הארוחה",
    description:
      "ישיבה משותפת סביב השולחן ללא הסחות דעת (כגון מסכים) תורמת לתקשורת משפחתית ולאכילה מודעת.",
    icon: "🌟",
  },
  {
    id: "guidance",
    title: 'לאמץ את גישת "ההורה המנחה"',
    description:
      "גישה המוצאת איזון בין הצבת גבולות לבין מתן עצמאות לילדים בבחירות המזון שלהם, כך שהם ילמדו להיות אחראים להרגלי האכילה שלהם בעצמם.",
    icon: "🌱",
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ParentingStyle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const GuidelineCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const parentingStyles = [
  {
    id: "provider",
    title: "הורה מזין",
    icon: faChild,
    color: "#FF6B6B",
  },
  {
    id: "controller",
    title: "הורה שולט",
    icon: faBalanceScale,
    color: "#4ECDC4",
  },
  {
    id: "pleaser",
    title: "הורה מרצה",
    icon: faHeart,
    color: "#45B7D1",
  },
  {
    id: "health",
    title: 'הורה "המבריא אוכל"',
    icon: faBrain,
    color: "#96CEB4",
  },
  {
    id: "comforter",
    title: "הורה מנחם",
    icon: faHandHoldingHeart,
    color: "#FFB6B9",
  },
  {
    id: "nurturer",
    title: "הורה מטפח",
    icon: faSeedling,
    color: "#8785A2",
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/4262010/pexels-photo-4262010.jpeg?w=1260&auto=compress";
const PRINCIPLES_IMAGE =
  "https://images.pexels.com/photos/8363735/pexels-photo-8363735.jpeg?w=1260&auto=compress";

export const ParentingOvereating = () => {
  useMeta();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                שיטות חינוך שגורמות לילדים לאכול יותר – ואיך להימנע מהן
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                תזונת ילדים היא סוגיה חשובה בהתפתחות הבריאותית והנפשית של הדור
                הצעיר. מחקרים מראים כי דפוסי אכילה בילדות מושפעים במידה רבה
                מהשפעות הוריות, במיוחד בכל הנוגע לגישות חינוכיות סביב האוכל.
                בעוד שחלק מהשיטות נתפסות כנכונות ומועילות, מחקרים מצביעים על כך
                שחלקן דווקא גורמות לילדים לאכול יותר, לאכול בסתר ולחוש בושה
                ואשמה על הבחירות שלהם, כל אלו עלולים לגרום להם בעתיד מערכת יחסים
                מורכבת ובעייתית עם אוכל, אכילה ועם המשקל והגוף שלהם.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Parenting Styles */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 6, fontWeight: 700 }}
            >
              שיטות וגישות האכלה שונות של הורים
            </Typography>
            <Grid container spacing={4}>
              {parentingStyles.map((style, index) => (
                <Grid key={style.id} size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <ParentingStyle>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={style.icon}
                          style={{
                            fontSize: "2rem",
                            color: style.color,
                            marginLeft: "1rem",
                          }}
                        />
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>
                          {style.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                        {
                          PARENTING_STYLES_CONTENT[
                            style.id as keyof typeof PARENTING_STYLES_CONTENT
                          ].description
                        }
                      </Typography>
                    </ParentingStyle>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Principles Section */}
          <ParallaxBanner
            style={{
              height: "50vh",
              borderRadius: "20px",
              overflow: "hidden",
              margin: "4rem 0",
            }}
          >
            <ParallaxBannerLayer
              image={PRINCIPLES_IMAGE}
              speed={-15}
              scale={[1.1, 1]}
              opacity={[0.8, 1]}
            />
            <ParallaxBannerLayer speed={-10} opacity={[1, 0.9]}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: 4,
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    fontWeight: 700,
                  }}
                >
                  הצבת גבולות נכונים סביב אוכל
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>

          {/* Guidelines Section */}
          <Box sx={{ my: 8 }}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 4, fontWeight: 600 }}
              >
                עקרונות מנחים
              </Typography>
              <Typography variant="body1" sx={{ mb: 4, lineHeight: 1.8 }}>
                כדי לפתח מערכת יחסים בריאה עם האוכל ולמנוע בעיות אכילה עתידיות,
                ישנה חשיבות רבה לאופן שבו הורים מתווכים את נושא התזונה לילדיהם.
                הנה כמה עקרונות מנחים:
              </Typography>
              <Grid container spacing={4}>
                {GUIDELINES.map((guideline, index) => (
                  <Grid key={guideline.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <GuidelineCard>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize: "2.5rem",
                              lineHeight: 1,
                            }}
                          >
                            {guideline.icon}
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            {guideline.title}
                          </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                          {guideline.description}
                        </Typography>
                      </GuidelineCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>
          </Box>

          {/* Summary */}
          <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 3, fontWeight: 600 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                גישות חינוכיות סביב האוכל והאכילה של הילדים משפיעות לא רק על
                הרגלי האכילה של הילדים, אלא גם על הקשר והתקשורת מול ההורים, הן
                בטווח הקצר והן בעתיד. הורים רבים עשויים להרגיש חוסר ביטחון בנוגע
                לניהול האכילה של ילדיהם ואף לחוש לחץ, מתח או חרדה במהלך ארוחות
                משפחתיות, במיוחד כאשר הם נתקלים בקשיים כגון בררנות אכילה, אכילה
                רגשית והשמנה. במקרים כאלה, מומלץ לפנות לאיש מקצוע מוסמך, כמו
                דיאטנית ילדים, אשר תוכל להעניק ליווי והכוונה מותאמת אישית.
                דיאטנית מקצועית יכולה לעזור להורים להבין כיצד לשנות את הגישה
                שלהם לאוכל ולספק כלים מעשיים להתמודדות עם אתגרים תזונתיים בבית.
                התייעצות עם מומחה יכולה לשפר משמעותית את הרגלי האכילה של הילדים
                ולסייע להם לפתח יחס בריא ומאוזן כלפי אוכל לכל החיים.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
