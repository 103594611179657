"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LifestyleTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.LifestyleTags = {
    "": {
        title: constants_1.ProductInfo.lifestyle.description,
        description: "גלה את הדרך שלך לחיים בריאים ומאוזנים",
        canonicalUrl: "/lifestyle",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/lifestyle/articles",
    },
    "/articles/target-dress-dilemma": {
        title: "בגד מטרה: מנוע מוטיבציה או מעגל תסכול?",
        description: "מאמר מעמיק על היתרונות והאתגרים בשימוש בבגד מטרה כאמצעי מוטיבציה לירידה במשקל. למדו על האיזון בין מוטיבציה לתסכול, והדרך להצבת מטרות ריאליות.",
        keywords: "בגד מטרה, מוטיבציה לירידה במשקל, דימוי עצמי, הצבת מטרות, ירידה במשקל, תסכול בדיאטה, מטרות ריאליות, שינוי הרגלים, בריאות נפשית, תזונה נכונה, אורח חיים בריא, העצמה אישית",
        canonicalUrl: "/lifestyle/articles/target-dress-dilemma",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/5698851/pexels-photo-5698851.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-22",
                dateModified: "2025-02-22",
                articleSection: "אורח חיים בריא",
            },
        },
    },
    "/articles/popular-diets-pros-cons": {
        title: "דיאטות פופולריות: יתרונות וחסרונות",
        description: "סקירה מקיפה של הדיאטות הפופולריות ביותר: צום לסירוגין, קטוגנית, ים תיכונית ופליאו. כולל ניתוח מעמיק של היתרונות והחסרונות של כל דיאטה, המלצות ליישום והתאמה אישית.",
        keywords: "דיאטות פופולריות, צום לסירוגין, דיאטה קטוגנית, תזונה ים תיכונית, דיאטת פליאו, ירידה במשקל, תזונה בריאה, יתרונות וחסרונות דיאטות, השוואת דיאטות, תזונה נכונה, בריאות",
        canonicalUrl: "/lifestyle/articles/popular-diets-pros-cons",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-24",
                dateModified: "2025-02-24",
                articleSection: "תזונה ודיאטות",
            },
        },
    },
    "/articles/scientific-nutrition-benefits": {
        title: "תזונה נכונה – איך היא משפיעה ומשפרת את איכות החיים?",
        description: "מחקר מקיף על ההשפעות המדעיות של תזונה נכונה על הגוף והנפש: אנרגיה, מצב רוח, שינה, ריכוז וחילוף חומרים. כל מה שצריך לדעת על הקשר בין תזונה לאיכות חיים.",
        keywords: "תזונה נכונה, בריאות, אנרגיה, מצב רוח, שינה, ריכוז, חילוף חומרים, תזונה מאוזנת, מחקר תזונתי, השפעות תזונה, תזונה ובריאות, תזונה מדעית, שיפור בריאות",
        canonicalUrl: "/lifestyle/articles/scientific-nutrition-benefits",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-24",
                dateModified: "2025-02-24",
                articleSection: "תזונה ובריאות",
            },
        },
    },
    "/articles/hunger-types-guide": {
        title: "המדריך לזיהוי סוגי רעב ולניהול אכילה חכם",
        description: "מדריך מקיף להבנת סוגי הרעב השונים וכיצד להגיב אליהם בצורה מודעת ומאוזנת. כולל טיפים מעשיים לזיהוי והתמודדות עם כל סוג רעב.",
        keywords: "סוגי רעב, רעב פיזיולוגי, רעב רגשי, רעב הורמונלי, רעב חושי, רעב אוטומטי, רעב חברתי, רעב מתזכר, רעב מעייפות, תזונה נכונה, אכילה מודעת, אכילה רגשית, הרגלי אכילה, בריאות",
        canonicalUrl: "/lifestyle/articles/hunger-types-guide",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/5638732/pexels-photo-5638732.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-27",
                dateModified: "2025-02-27",
                articleSection: "תזונה ובריאות",
            },
        },
    },
    "/articles/everyday-diet-challenges-guide": {
        title: "התמודדות עם אתגרים יומיומיים בתזונה",
        description: "מדריך מעשי להתמודדות עם אתגרי התזונה בשגרה: ניהול תזונה במשרד, אכילה במסעדות, התמודדות עם חשקים למתוקים ופתרונות לתזונה נכונה כשאין זמן לבשל.",
        keywords: "תזונה בריאה, אתגרי תזונה, תזונה במשרד, אכילה במסעדות, חשקים למתוקים, בישול מהיר, תזונה מאוזנת, הרגלי אכילה, ניהול תזונה, אכילה בריאה, תכנון ארוחות, אורח חיים בריא",
        canonicalUrl: "/lifestyle/articles/everyday-diet-challenges-guide",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-27",
                dateModified: "2025-02-27",
                articleSection: "תזונה ואורח חיים בריא",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/lifestyle/lets-talk",
    },
};
