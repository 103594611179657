"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
const default_tags_1 = require("./default.tags");
const route_map_1 = require("./route-map");
__exportStar(require("./type"), exports);
exports.default = (route) => {
    const normalizedRoute = route.replace(/\/$/, "");
    const routeMetaTags = route_map_1.RouteMap[normalizedRoute];
    // Basic meta tags
    const routeTitle = routeMetaTags?.title || default_tags_1.DefaultTags.title;
    let title = default_tags_1.baseTitle;
    if (routeTitle) {
        title = `${routeTitle} - ${title}`;
    }
    if (title.length > 60) {
        const shortTitleOption1 = `${routeTitle} - ${default_tags_1.baseShortTitle}`;
        const shortTitleOption2 = `${routeTitle}`;
        if (shortTitleOption1.length <= 60) {
            title = shortTitleOption1;
        }
        else if (shortTitleOption2.length <= 60) {
            title = shortTitleOption2;
        }
        else {
            title = `${routeTitle}`;
        }
    }
    const description = routeMetaTags?.description || default_tags_1.DefaultTags.description;
    const keywords = routeMetaTags?.keywords || default_tags_1.DefaultTags.keywords;
    const canonicalUrl = default_tags_1.baseCanonicalUrl + (routeMetaTags?.canonicalUrl || "");
    const author = routeMetaTags?.author || default_tags_1.DefaultTags.author;
    const robots = routeMetaTags?.robots || default_tags_1.DefaultTags.robots;
    const themeColor = "#ffffff";
    const mobileWebAppCapable = "yes";
    const appleMobileWebAppCapable = "yes";
    const formatDetection = "telephone=yes";
    const google = "notranslate";
    // Open Graph / Facebook tags
    const ogType = routeMetaTags?.ogType || default_tags_1.DefaultTags.ogType;
    const ogImage = routeMetaTags?.ogImage || default_tags_1.DefaultTags.ogImage;
    const ogImageType = routeMetaTags?.ogImageType || default_tags_1.DefaultTags.ogImageType;
    const ogImageAlt = routeMetaTags?.ogImageAlt || default_tags_1.DefaultTags.ogImageAlt;
    const ogImageWidth = routeMetaTags?.ogImageWidth || default_tags_1.DefaultTags.ogImageWidth;
    const ogImageHeight = routeMetaTags?.ogImageHeight || default_tags_1.DefaultTags.ogImageHeight;
    const ogSiteName = "עדי יאנה - תזונאית קלינית";
    const ogLocale = "he_IL";
    // JSON‑LD Structured Data
    const jsonLdData = default_tags_1.DefaultTags.jsonLdData;
    if (routeMetaTags?.jsonLdData?.articleJsonLd) {
        const articleData = routeMetaTags.jsonLdData.articleJsonLd;
        jsonLdData.articleJsonLd = {
            "@context": "https://schema.org",
            "@type": "Article",
            headline: routeTitle,
            description,
            image: ogImage,
            author: { "@type": "Person", name: "עדי יאנה" },
            publisher: {
                "@type": "Organization",
                name: "עדי יאנה - ליווי תזונתי",
                logo: {
                    "@type": "ImageObject",
                    url: "https://www.adiyana.co.il/logo.png",
                },
            },
            datePublished: articleData.datePublished,
            dateModified: articleData.dateModified,
            mainEntityOfPage: {
                "@type": "WebPage",
                "@id": canonicalUrl,
            },
            articleSection: articleData.articleSection || "תזונה",
            keywords,
        };
    }
    return {
        // Basic meta tags
        routeTitle,
        title,
        description,
        keywords,
        canonicalUrl,
        author,
        robots,
        themeColor,
        mobileWebAppCapable,
        appleMobileWebAppCapable,
        formatDetection,
        google,
        // Open Graph / Facebook tags
        ogType,
        ogImage,
        ogImageType,
        ogImageAlt,
        ogImageWidth,
        ogImageHeight,
        ogSiteName,
        ogLocale,
        // JSON‑LD Structured Data
        jsonLdData,
    };
};
