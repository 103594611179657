"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteMap = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
const kids_tags_1 = require("./kids.tags");
const lifestyle_tags_1 = require("./lifestyle.tags");
const maternity_tags_1 = require("./maternity.tags");
const midlife_tags_1 = require("./midlife.tags");
const sport_tags_1 = require("./sport.tags");
const teens_tags_1 = require("./teens.tags");
const vegan_tags_1 = require("./vegan.tags");
const buildRouteMap = (product, tags) => {
    return Object.entries(tags).reduce((acc, [key, value]) => {
        return { ...acc, [`/${product}${key}`]: value };
    }, {});
};
exports.RouteMap = {
    "/": default_tags_1.DefaultTags,
    "/4xx-error": {
        title: "הדף לא נמצא",
        description: "הדף לא נמצא",
        canonicalUrl: "/4xx-error",
        robots: "noindex, nofollow",
    },
    ...buildRouteMap(constants_1.Product.Lifestyle, lifestyle_tags_1.LifestyleTags),
    ...buildRouteMap(constants_1.Product.Kids, kids_tags_1.KidsTags),
    ...buildRouteMap(constants_1.Product.Teens, teens_tags_1.TeensTags),
    ...buildRouteMap(constants_1.Product.Vegan, vegan_tags_1.VeganTags),
    ...buildRouteMap(constants_1.Product.Sport, sport_tags_1.SportTags),
    ...buildRouteMap(constants_1.Product.Maternity, maternity_tags_1.MaternityTags),
    ...buildRouteMap(constants_1.Product.Midlife, midlife_tags_1.MidlifeTags),
};
