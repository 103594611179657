import {
  faBaby,
  faChild,
  faHandHoldingHeart,
  faHandsHoldingChild,
  faHourglassHalf,
  faLeaf,
  faPersonRunning as faRunning,
} from "@fortawesome/pro-duotone-svg-icons";
import { Product, ProductInfo } from "@monorepo/constants";
import {
  Box,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import { ProductCard } from "./ProductCard";

export const ServicesSection = () => {
  const theme = useTheme();
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [0.95, 1, 1, 0.95]
  );

  const services = [
    {
      icon: faHandHoldingHeart,
      product: Product.Lifestyle,
      delay: 0.1,
    },
    {
      icon: faHandsHoldingChild,
      product: Product.Kids,
      delay: 0.2,
    },
    {
      icon: faChild,
      product: Product.Teens,
      delay: 0.3,
    },
    {
      icon: faLeaf,
      product: Product.Vegan,
      delay: 0.4,
    },
    {
      icon: faRunning,
      product: Product.Sport,
      delay: 0.5,
    },
    {
      icon: faBaby,
      product: Product.Maternity,
      delay: 0.6,
    },
    {
      icon: faHourglassHalf,
      product: Product.Midlife,
      delay: 0.7,
    },
  ];

  return (
    <Box
      ref={sectionRef}
      component={motion.div}
      style={{ opacity, scale }}
      sx={{
        py: { xs: 4 },
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `radial-gradient(circle at 30% 50%, ${theme.palette.primary.main}15, transparent 60%)`,
          zIndex: 0,
        },
      }}
    >
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6 }}
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "2rem",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography
              variant="h3"
              align="center"
              sx={{
                fontWeight: 800,
                mb: 2,
                background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                backgroundClip: "text",
                WebkitBackgroundClip: "text",
                color: "transparent",
                fontSize: { xs: "1.8rem", md: "2.8rem" },
                textShadow: "0 2px 10px rgba(0,0,0,0.1)",
                lineHeight: 1.4,
                position: "relative",
                display: "inline-block",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "-15px",
                  right: "-15px",
                  width: "30px",
                  height: "30px",
                  background: `radial-gradient(circle, ${theme.palette.primary.main}50, transparent)`,
                  borderRadius: "50%",
                  filter: "blur(8px)",
                },
              }}
            >
              כאן מתחילים
            </Typography>

            <br />

            <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 3,
                color: theme.palette.text.primary,
                fontWeight: 600,
                fontSize: { xs: "1.4rem", md: "2.2rem" },
                position: "relative",
                display: "inline-block",
                "&::after": {
                  content: '""',
                  position: "absolute",
                  bottom: "-10px",
                  left: "10%",
                  width: "80%",
                  height: "4px",
                  background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}, transparent)`,
                  borderRadius: "2px",
                },
              }}
            >
              כל אחד והמסלול שמתאים לו
            </Typography>
          </Box>
        </motion.div>

        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid size={{ xs: 12, sm: 6, md: 3 }} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: service.delay }}
                style={{ height: "100%" }}
              >
                <ProductCard
                  icon={service.icon}
                  title={ProductInfo[service.product].title}
                  description={ProductInfo[service.product].description}
                  to={`/${service.product.toLowerCase()}`}
                  product={service.product}
                  delay={service.delay}
                />
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
