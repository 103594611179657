import {
  Box,
  Card,
  Container,
  Divider,
  Fade,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useMeta } from "modules/common";
import { useInView } from "react-intersection-observer";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const DietCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&w=1260",
  fasting:
    "https://images.pexels.com/photos/4397833/pexels-photo-4397833.jpeg?auto=compress&w=640",
  keto: "https://images.pexels.com/photos/1640773/pexels-photo-1640773.jpeg?auto=compress&w=640",
  mediterranean:
    "https://images.pexels.com/photos/1279330/pexels-photo-1279330.jpeg?auto=compress&w=640",
  paleo:
    "https://images.pexels.com/photos/1640771/pexels-photo-1640771.jpeg?auto=compress&w=640",
};

const diets = [
  {
    id: "fasting",
    title: "צום לסירוגין",
    image: IMAGES.fasting,
    description: "תקופות אכילה מוגבלות בזמן ותקופות צום",
    mainDescription:
      "צום לסירוגין כולל תקופות אכילה המוגבלות בזמן ותקופות צום, כגון שיטת 16:8 (16 שעות צום ו-8 שעות אכילה) או שיטת 5:2 (יומיים של צריכת קלוריות מופחתת בשבוע). חשוב לציין שזה לא רק צום בין הארוחות, אלא גם הדגשה על שעת סיום האכילה. בתהליך זה הגוף משנה את המנגנון שלו משימוש בגלוקוז כמקור אנרגיה לשימוש בשומן כמקור אנרגיה עיקרי, מה שתורם לירידה באחוזי השומן ולירידה במשקל.",
    pros: [
      "שיפור חילוף החומרים",
      "סיוע בירידה במשקל",
      "הפחתת סיכון למחלות לב וסוכרת סוג 2",
      "גורם מגן מפני סוגי סרטן",
    ],
    cons: [
      "עייפות ואי נוחות בתחילת הדרך",
      "תחושת רעב משמעותית",
      "פחות מתאים לאנשים עם הפרעות אכילה",
      "לא מתאים לאנשים עם צרכים תזונתיים מיוחדים",
    ],
  },
  {
    id: "keto",
    title: "דיאטה קטוגנית (קיטו)",
    image: IMAGES.keto,
    description: "צריכת שומנים גבוהה וכמות מינימלית של פחמימות",
    mainDescription:
      "הדיאטה הקטוגנית מתמקדת בצריכת שומנים ברמה גבוהה, חלבונים במידה מתונה וכמות מינימלית של פחמימות כדי לגרום לגוף להיכנס למצב של קטוזיס.",
    pros: [
      "ירידה מהירה במשקל",
      "שיפור רמות הסוכר בדם",
      "הפחתת תיאבון",
      "יעילה בטיפול באפילפסיה",
    ],
    cons: [
      "חסרים תזונתיים בשל הימנעות מפירות ודגנים",
      "עייפות וסחרחורת בתחילת הדרך",
      "עצירות",
      "לא מתאימה לבעיות כליות או כבד",
    ],
  },
  {
    id: "mediterranean",
    title: "תזונה ים תיכונית",
    image: IMAGES.mediterranean,
    description: "מזונות טבעיים ועשירים בנוגדי חמצון",
    mainDescription:
      "תזונה זו מבוססת על מזונות טבעיים, לא מעובדים ועשירים בנוגדי חמצון כמו ירקות, פירות, דגנים מלאים, אגוזים, שמן זית ודגים, תוך הפחתת צריכת בשר אדום וסוכרים.",
    pros: [
      "תורמת לבריאות הלב וכלי הדם",
      "מפחיתה סיכון לסוכרת מסוג 2",
      "תומכת באיזון המשקל",
      "משפרת את תפקוד המוח",
    ],
    cons: [
      "דורשת תכנון והקפדה על איכות המזון",
      "עלולה להיות יקרה",
      "פחות יעילה לירידה מהירה במשקל",
    ],
  },
  {
    id: "paleo",
    title: "דיאטת פליאו",
    image: IMAGES.paleo,
    description: "תזונה המדמה את אבותינו הקדמונים",
    mainDescription:
      "דיאטת פליאו מדמה את התזונה של אבותינו הקדמונים, ומתמקדת בצריכת בשרים רזים, דגים, ירקות, פירות ואגוזים, תוך הימנעות מדגנים, מוצרי חלב ומזון מעובד. עם זאת, רבים מפרשים את התזונה הזו בצורה לא מדויקת וצורכים כמויות גדולות של בשר שמן, דבר שעלול לפגוע בבריאות, לעכב את תהליך הירידה במשקל ולגרום לבעיות בריאותיות שונות.",
    pros: [
      "עשירה בחלבונים וסיבים תזונתיים",
      "משפרת תחושת שובע",
      "מסייעת בירידה במשקל",
      "מפחיתה צריכת מזון מעובד",
    ],
    cons: [
      "חוסרים בויטמינים ומינרלים",
      "חוסר בסידן וויטמין D",
      "מאתגרת ליישום לטווח ארוך",
      "מגבלות תזונתיות רבות",
    ],
  },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const PopularDietsProsCons = () => {
  useMeta();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Fade in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2rem", md: "3.5rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    דיאטות פופולריות: יתרונות וחסרונות
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 3 }}
                >
                  מבוא
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  במהלך השנים האחרונות צברו דיאטות שונות פופולריות בקרב אנשים
                  המעוניינים לרדת במשקל או לשפר את בריאותם. דיאטות כמו צום
                  לסירוגין, קטוגנית (קיטו), תזונה ים תיכונית ופליאו מציעות גישות
                  מגוונות לתזונה, אך לכל אחת מהן יש יתרונות וחסרונות ייחודיים.
                </Typography>
              </StyledCard>
            </motion.div>

            {/* Diet Cards */}
            <Box ref={ref}>
              <Grid container spacing={4}>
                {diets.map((diet, index) => (
                  <Grid key={diet.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      style={{ height: "100%" }}
                      variants={fadeInUpVariants}
                      transition={{ duration: 0.6, delay: index * 0.2 }}
                    >
                      <DietCard elevation={3}>
                        <Box
                          sx={{
                            height: 200,
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: 2,
                            mb: 2,
                          }}
                        >
                          <img
                            src={diet.image}
                            alt={diet.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Typography variant="h5" gutterBottom color="primary">
                          {diet.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          gutterBottom
                        >
                          {diet.description}
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                          {diet.mainDescription}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="success.main"
                          >
                            יתרונות
                          </Typography>
                          <ul>
                            {diet.pros.map((pro, i) => (
                              <Typography component="li" key={i}>
                                {pro}
                              </Typography>
                            ))}
                          </ul>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="error.main"
                            sx={{ mt: 2 }}
                          >
                            חסרונות
                          </Typography>
                          <ul>
                            {diet.cons.map((con, i) => (
                              <Typography component="li" key={i}>
                                {con}
                              </Typography>
                            ))}
                          </ul>
                        </Box>
                      </DietCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 3 }}
                >
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  כל אחת מהדיאטות הפופולריות מציעה יתרונות ייחודיים שיכולים
                  להתאים לאנשים שונים בהתאם לצרכים ולמטרות האישיות שלהם. חשוב
                  לזכור כי הצלחה בדיאטה אינה נמדדת רק בירידה במשקל אלא גם בשמירה
                  על בריאות כללית ושיפור איכות החיים. על מנת להפוך כל אחת
                  מהדיאטות הללו לאורח חיים בר קיימא, נדרש ליווי מקצועי המותאם
                  לצרכים האישיים, תוך הדגשת הרגלי תזונה נכונים והתאמות אישיות.
                  בנוסף, יש לקחת בחשבון את ההעדפות התזונתיות ואת סגנון החיים כדי
                  להבטיח התמדה לאורך זמן והשגת מטרות בריאותיות ארוכות טווח.
                </Typography>
              </StyledCard>
            </motion.div>
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
