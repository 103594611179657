import {
  faBrain,
  faHeartPulse,
  faStomach,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.unsplash.com/photo-1623227866882-c005c26dfe41?auto=format&fit=crop&w=1260",
  pregnancy:
    "https://images.unsplash.com/photo-1493770348161-369560ae357d?auto=format&fit=crop&w=640",
  breastfeeding:
    "https://images.unsplash.com/photo-1579613832125-5d34a13ffe2a?auto=format&fit=crop&w=640",
  complementaryFood:
    "https://images.unsplash.com/photo-1505576399279-565b52d4ac71?auto=format&fit=crop&w=640",
  longTermHealth:
    "https://images.unsplash.com/photo-1490645935967-10de6ba17061?auto=format&fit=crop&w=640",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const nutritionData = [
  {
    id: "folate",
    title: "חומצה פולית",
    value: 25,
    color: "#FF6B6B",
    icon: faBrain,
    description: "חיונית למניעת מומים בתעלה העצבית",
  },
  {
    id: "protein",
    title: "חלבון",
    value: 25,
    color: "#4ECDC4",
    icon: faStomach,
    description: "תומך בבניית רקמות ובהתפתחות תקינה",
  },
  {
    id: "omega3",
    title: "אומגה 3",
    value: 25,
    color: "#45B7D1",
    icon: faBrain,
    description: "חיונית להתפתחות המוח והראייה",
  },
  {
    id: "iron",
    title: "ברזל",
    value: 25,
    color: "#96CEB4",
    icon: faHeartPulse,
    description: "חיוני לזרימת חמצן תקינה",
  },
];

export const First1000DaysNutrition = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    תזונת 1000 הימים הראשונים
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    יסודות להתפתחות מיטבית
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      מבוא
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      1000 הימים הראשונים לחיים מתחילים עוד לפני ההתעברות עצמה,
                      כאשר איכות הביצית ובריאותה של האם לעתיד משפיעים על בריאות
                      העובר. שלב זה כולל את תקופת ההיריון ועד גיל שנתיים, ומהווה
                      חלון הזדמנויות קריטי להתפתחות הפיזית, הקוגניטיבית והרגשית
                      של התינוק. תזונה נכונה בתקופה זו משפיעה על בריאותו לטווח
                      הארוך, ומחקרים מצביעים על קשר הדוק בין איכות התזונה בשלבים
                      מוקדמים לבין מניעת מחלות כרוניות בעתיד. בנוסף, מחקרים
                      מראים כי תזונת האם לפני הכניסה להריון יכולה לשפר את איכות
                      הביצית, את תהליך ההפריה ומגדילה את סיכויי ההריון התקין, מה
                      שמדגיש את חשיבות ההכנה התזונתית עוד לפני תחילת ההריון.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.pregnancy}
                  alt="Pregnancy nutrition"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Nutrition During Pregnancy */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                חשיבות התזונה במהלך ההיריון
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                התפתחות העובר מתחילה ברחם האם ועוברת שלבים קריטיים המשפיעים על
                בריאותו לכל החיים. בשבועות הראשונים להיריון מתפתחת מערכת העצבים
                המרכזית, ולכן חומצה פולית חיונית למניעת מומים בתעלה העצבית.
                במהלך השליש השני מתפתחות המוח והשלד, ולכן יש להקפיד על צריכת
                חלבון, סידן וברזל לתמיכה בבניית העצמות וזרימת חמצן תקינה. בשליש
                השלישי, המוח עובר שלב של גדילה מואצת, וחומצות שומן מסוג אומגה-3,
                במיוחד DHA, תורמות להתפתחות הקוגניטיבית והראייה.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
              >
                תקופות אלו נחשבות לזמני מפתח שבהם חוסרים תזונתיים עלולים להשפיע
                על בריאות התינוק בעתיד. לדוגמה, מחסור בברזל עלול לגרום לאנמיה
                בעובר, בעוד שמחסור בחלבון יכול לפגוע בצמיחה התקינה. תוספת של
                ויטמין D וסידן תומכת בשמירה על צפיפות העצם של האם והעובר,
                ומסייעת בהפחתת הסיכון לסיבוכים בלידה. בנוסף, הקפדה על תזונה
                עשירה בנוגדי חמצון מפירות וירקות מגנה מפני סטרס חמצוני שעלול
                לפגוע בהתפתחות העובר.
              </Typography>
              <Grid container spacing={4}>
                {nutritionData.map((item, index) => (
                  <Grid key={item.id} size={{ xs: 12, sm: 6, md: 3 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${item.color}20`,
                            p: 2,
                            borderRadius: "50%",
                            mb: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={{ fontSize: 32, color: item.color }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ color: item.color, fontWeight: 600 }}
                        >
                          {item.title}
                        </Typography>
                        <Typography variant="body2">
                          {item.description}
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Taste Preferences Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.breastfeeding}
                    alt="Breastfeeding and nutrition"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        השפעת תזונת האם בהריון על העדפות הטעם של הילד
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        מחקרים מצביעים על כך שתזונה מגוונת של האם במהלך ההיריון
                        עשויה להשפיע על העדפות הטעם של הילד ולתרום לאכילה מגוונת
                        בהמשך חייו. טעמים ממזונות שאוכלת האם עוברים למי השפיר
                        ומשפיעים על חוש הטעם המתפתח של העובר. לדוגמה, מחקרים
                        הראו שתינוקות שנחשפו לטעמי גזר ברחם נטו להראות העדפה
                        למזונות דומים לאחר הלידה. חשיפה מוקדמת זו מסייעת בפיתוח
                        הרגלי אכילה בריאים ומפחיתה סיכון לבררנות מזון בעתיד.
                        יתרה מכך, גם בתקופת ההנקה התינוק נחשף לטעמים שונים
                        המועברים בחלב האם, מה שתורם להסתגלות למגוון רחב של
                        מזונות מוצקים בהמשך.
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        חשוב לציין כי תזונה מגוונת ומזינה של האם במהלך ההיריון
                        אינה רק קריטית להתפתחות הפיזית והקוגניטיבית של העובר,
                        אלא גם תורמת להסתגלות התינוק לטעמים שונים, מה שמניח את
                        היסודות לאכילה מגוונת ובריאה בהמשך חייו.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Breastfeeding and Early Nutrition */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        הנקה ותזונת התינוק בחודשי החיים הראשונים
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        חלב האם מספק את כל הרכיבים התזונתיים הדרושים להתפתחות
                        התינוק בחודשים הראשונים לחייו. הוא עשיר בנוגדנים המחזקים
                        את מערכת החיסון ותורם להתפתחות מערכת העיכול. ארגון
                        הבריאות העולמי ממליץ על הנקה בלעדית עד גיל שישה חודשים
                        והמשך הנקה לצד מזון משלים עד גיל שנתיים. במקרים שבהם
                        התינוק ניזון מתמ״ל, תחליפי חלב מותאמים מספקים חלופה
                        ראויה ומבטיחים מענה לצרכים התזונתיים של התינוק.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.complementaryFood}
                    alt="Complementary feeding"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Long-term Health Impact */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.longTermHealth}
                    alt="Long-term health impact"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        הקשר בין תזונה מוקדמת לבריאות ארוכת טווח
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        מחקרים מראים כי תזונה מאוזנת במהלך 1000 הימים הראשונים
                        מהווה גורם מגן מפני מחלות כרוניות כמו סוכרת מסוג 2,
                        מחלות לב והשמנת יתר. חוסרים תזונתיים בגיל זה עלולים
                        לפגוע בהתפתחות המוח, במערכת החיסון וביכולת הלמידה. תזונה
                        עשירה בחלבון איכותי, ויטמינים ומינרלים מחזקת את מערכות
                        הגוף ומסייעת בבניית הרגלי אכילה בריאים לכל החיים ובחשיפה
                        למגוון רחב של מזונות בריאים, מה שמפחית את הסיכון לבררנות
                        אכילה.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    סיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    1000 הימים הראשונים מהווים תקופה קריטית להתפתחות הפיזית,
                    הקוגניטיבית והרגשית של התינוק. תזונה נכונה במהלך ההיריון,
                    ההנקה ותחילת תקופת האכילה תורמת לבריאותו בטווח הקצר והארוך.
                    הקפדה על צריכת חלבון, ברזל, חומצה פולית וחומצות שומן חיוניות
                    תומכת בהתפתחות המוח, מערכת החיסון והעצמות. מחקרים מדגישים כי
                    תזונה מאוזנת בשלבים אלה מפחיתה את הסיכון למחלות כרוניות
                    ומניחה את היסודות לבריאות מיטבית לאורך כל החיים. ליווי
                    תזונתי מקצועי בשלבים אלו – החל משלב התכנון וההכנה להריון,
                    דרך ההיריון עצמו, תקופת ההנקה ותחילת התזונה המשלימה – מסייע
                    להבטיח מענה מיטבי לצרכים התזונתיים של האם והילד, ותורם
                    להתפתחות תקינה והרגלי אכילה בריאים.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
