import {
  faBaby,
  faBottleDroplet,
  faBrain,
  faDroplet,
  faHeartPulse,
  faLeaf,
  faShieldHeart,
  faTriangleExclamation,
  faWineGlass,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/3875276/pexels-photo-3875276.jpeg?auto=compress&w=1260",
  nutrition:
    "https://images.pexels.com/photos/3875132/pexels-photo-3875132.jpeg?auto=compress&w=640",
  hydration:
    "https://images.pexels.com/photos/327090/pexels-photo-327090.jpeg?auto=compress&w=640",
  avoidFoods:
    "https://images.pexels.com/photos/2449665/pexels-photo-2449665.jpeg?auto=compress&w=640",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const nutritionData = [
  {
    title: "חלבון",
    description:
      "חיוני לבניית רקמות ולתמיכה בתהליך הצמיחה של התינוק. מומלץ לצרוך כ-1.1 גרם חלבון לכל קילוגרם משקל גוף ביום. מקורות טובים כוללים קטניות, טופו, אגוזים וזרעים.",
    icon: faBrain,
    color: "#FF6B6B",
  },
  {
    title: "סידן",
    description:
      'תומך בבריאות העצמות והשיניים של האם ושל התינוק. מומלץ לצרוך כ-1000 מ"ג סידן ביום, הנמצא בשקדים, טחינה, ברוקולי וירקות עליים.',
    icon: faShieldHeart,
    color: "#4ECDC4",
  },
  {
    title: "ברזל",
    description:
      "חיוני למניעת עייפות ואנמיה ולתמיכה באספקת חמצן לרקמות. מקורות ברזל כוללים עדשים, שעועית, קוואקר ותרד. לשיפור ספיגת הברזל, מומלץ לשלב מזונות עשירים בוויטמין C, כמו פלפלים ותפוזים.",
    icon: faHeartPulse,
    color: "#45B7D1",
  },
];

const avoidFoods = [
  {
    id: "caffeine",
    title: "קפאין",
    description:
      'עלול לגרום לעצבנות וקשיי שינה אצל התינוק. מומלץ להגביל את הצריכה ל-200 מ"ג ליום (ככוס קפה אחת).',
    icon: faBottleDroplet,
    color: "#FF6B6B",
  },
  {
    id: "spicy",
    title: "מזונות חריפים ומתובלים",
    description: "עשויים לגרום לגזים וחוסר נוחות אצל התינוק.",
    icon: faLeaf,
    color: "#4ECDC4",
  },
  {
    id: "garlic",
    title: "שום ובצל",
    description:
      "עלולים לשנות את טעם החלב ולגרום לסירוב הנקה אצל חלק מהתינוקות.",
    icon: faHeartPulse,
    color: "#45B7D1",
  },
  {
    id: "alcohol",
    title: "אלכוהול",
    description:
      "עובר לחלב האם ועלול להשפיע על מערכת העצבים של התינוק. מומלץ להימנע לחלוטין או להמתין לפחות שעתיים לאחר שתייה לפני הנקה.",
    icon: faWineGlass,
    color: "#96CEB4",
  },
];

export const BreastfeedingNutrition = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    תזונה במהלך ההנקה
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    השפעות, יתרונות והמלצות תזונתיות
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid container size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "flex-start",
                        mb: 3,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: `${theme.palette.primary.main}20`,
                          p: 2,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBaby}
                          style={{
                            fontSize: 32,
                            color: theme.palette.primary.main,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ fontWeight: 700 }}
                      >
                        מבוא
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      תזונה נכונה במהלך ההנקה משפיעה לא רק על בריאותה של האם אלא
                      גם על איכות חלב האם, המספק לתינוק את כל רכיבי התזונה
                      החיוניים להתפתחותו. מחקרים מראים כי הרכב החלב מותאם לצרכיו
                      המשתנים של התינוק, אך תזונה מאוזנת של האם תורמת לשיפור
                      איכותו ולתמיכה בייצור חלב בכמות מספקת. במאמר זה אבחן כיצד
                      תזונה משפיעה על חלב האם, מהם אבות המזון החשובים לתקופת
                      ההנקה, ואסקור את המזונות המומלצים ואלו שיש להימנע מהם.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid container size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.nutrition}
                  alt="Healthy nutrition during breastfeeding"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Milk Quality Impact */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    כיצד תזונה משפיעה על איכות חלב האם
                  </Typography>
                  <Grid container>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      חלב האם מורכב מחלבונים, שומנים, פחמימות, ויטמינים ומינרלים
                      החיוניים להתפתחות התינוק. בעוד שכמות החלב מושפעת בעיקר
                      מהביקוש וההיצע, איכות החלב מושפעת ישירות מהתזונה היומית של
                      האם. לדוגמה, חלבונים תורמים לגדילה ולהתפתחות מערכת השרירים
                      והעצמות של התינוק, בעוד שחומצות שומן חיוניות כמו DHA ו-ARA
                      תומכות בהתפתחות המוח והראייה. פחמימות, ובמיוחד הלקטוז,
                      מספקות אנרגיה להתפתחות המוח.
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      תזונת האם משפיעה גם על תכולת הוויטמינים והמינרלים בחלב.
                      צריכת ויטמין D חשובה לשמירה על בריאות העצמות של התינוק,
                      בעוד שמחסור בברזל עלול לגרום לאנמיה אצל האם ולהשפיע על
                      תכולת הברזל בחלב. סידן תומך בהתפתחות מערכת העצבים של
                      התינוק, ומזונות כמו שקדים, טחינה וירקות עליים עשירים בו.
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      דפוסי התזונה של האם משפיעים על כמות ואיכות החלב. תזונה
                      עשירה בקלוריות איכותיות שומרת על ייצור חלב תקין, בעוד
                      שתזונה דלת קלוריות עלולה להפחית את נפח החלב. מומלץ לשלב
                      מזונות כמו שקדים לשומנים בריאים, מוצרי חלב המספקים סידן
                      ומהווים מקור חלבון, ירקות עליים כהים המספקים ברזל וסידן.
                      כמו כן, שילוב מזונות עשירים בוויטמין C, כמו פלפלים
                      ותפוזים, מסייעים בשיפור ספיגת הברזל.
                    </Typography>
                  </Grid>
                </StyledCard>
              </motion.div>
            </Box>

            {/* Essential Nutrients */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                אבות המזון החשובים לתקופת ההנקה
              </Typography>
              <Grid container spacing={3}>
                {nutritionData.map((nutrient, index) => (
                  <Grid container key={nutrient.title} size={{ xs: 12, md: 4 }}>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${nutrient.color}20`,
                            p: 2,
                            borderRadius: "50%",
                            width: "fit-content",
                            mb: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={nutrient.icon}
                            style={{
                              fontSize: 32,
                              color: nutrient.color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ color: nutrient.color, fontWeight: 600 }}
                        >
                          {nutrient.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                        >
                          {nutrient.description}
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Hydration Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid container size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${theme.palette.primary.main}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faDroplet}
                            style={{
                              fontSize: 32,
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          color="primary"
                          sx={{ fontWeight: 700 }}
                        >
                          שתייה מספקת והידרציה להנקה מוצלחת
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        הנקה דורשת כמות גדולה של נוזלים, ולכן שתייה מספקת חיונית
                        לשמירה על ייצור חלב תקין. ההמלצה היא לשתות כ-2.5-3
                        ליטרים של מים ביום, בהתאם לצורך האישי ולכמות החלב
                        המיוצרת. שתייה מספקת לא רק תומכת בייצור החלב אלא גם
                        מסייעת במניעת התייבשות, שיכולה להוביל לעייפות, כאבי ראש
                        וירידה ברמות האנרגיה.
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        סימני התייבשות כוללים עייפות, יובש בפה, ירידה בכמות החלב
                        וצבע שתן כהה. לצד מים, אפשר לשלב תה צמחים נטול קפאין
                        ומשקאות טבעיים אחרים. מומלץ להימנע ממשקאות ממותקים
                        ומשתייה מופרזת של קפה או תה המכילים קפאין, שכן הם עלולים
                        לגרום לאובדן נוזלים ולהשפיע על מערכת העצבים של התינוק.
                        שמירה על הידרציה מסייעת גם לתפקוד מערכת העיכול של האם
                        ולשמירה על רמות אנרגיה לאורך היום.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid container size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.hydration}
                    alt="Hydration importance"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Milk Production Foods */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      alignItems: "flex-start",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: `${theme.palette.primary.main}20`,
                        p: 2,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faBottleDroplet}
                        style={{
                          fontSize: 32,
                          color: theme.palette.primary.main,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ fontWeight: 700 }}
                    >
                      האם קיימים מזונות המגבירים את ייצור החלב?
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    על אף האמונה הרווחת שמזונות מסוימים כמו שיבולת שועל, שקדים,
                    זרעי פשתן וחילבה יכולים להגביר את ייצור החלב, המחקרים
                    המדעיים בתחום אינם חד-משמעיים. בעוד שמזונות אלה עשירים
                    בחומרים מזינים התומכים בבריאות האם, אין עדויות מבוססות
                    שמוכיחות את השפעתם הישירה על כמות החלב המיוצרת.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    ייצור החלב מושפע בעיקר מגירוי פיזי של בלוטות החלב דרך הנקה
                    תכופה וריקון מלא של השד. עם זאת, תזונה מאוזנת הכוללת
                    חלבונים, שומנים בריאים וצריכת נוזלים מספקת חיונית לשמירה על
                    אנרגיה ובריאות כללית, אשר עשויות לתרום בעקיפין לתהליך ההנקה.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            {/* Foods to Avoid */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                מזונות שכדאי להימנע מהם
              </Typography>
              <Grid container spacing={3}>
                {avoidFoods.map((food, index) => (
                  <Grid container key={food.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${food.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={food.icon}
                              style={{ fontSize: 40, color: food.color }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: food.color, fontWeight: 600 }}
                            >
                              {food.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                            >
                              {food.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 3,
                      alignItems: "flex-start",
                      mb: 3,
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: `${theme.palette.primary.main}20`,
                        p: 2,
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faTriangleExclamation}
                        style={{
                          fontSize: 32,
                          color: theme.palette.primary.main,
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ fontWeight: 700 }}
                    >
                      לסיכום
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    תזונה מאוזנת במהלך ההנקה חיונית לבריאות האם ולהתפתחות
                    התינוק. חלבון, סידן וברזל הם אבות המזון המרכזיים שיש להקפיד
                    על צריכתם, לצד שתייה מספקת להבטחת ייצור חלב תקין. תזונה
                    מאוזנת הכוללת את אבות המזון ומיקרו-נוטריינטים חשובים תומכת
                    בבריאות הכללית של האם ועשויה להשפיע בעקיפין על הצלחה ותמיכה
                    בתהליך ההנקה. כמו כן, הימנעות ממזונות העלולים לגרום לאי
                    נוחות לתינוק תורמת להנקה מוצלחת ולבריאות מיטבית של האם
                    והתינוק. במידת הצורך, מומלץ להיעזר בליווי תזונתי מקצועי
                    להתאמת תפריט אישי ומאוזן.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
