import {
  Avatar,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useProduct } from "modules/core";
import { useMemo } from "react";

export const Logo = () => {
  const theme = useTheme();
  const { product } = useProduct();
  const isWeb = useMediaQuery(theme.breakpoints.up("sm"));
  const showLogo = useMemo(() => {
    if (isWeb) return true;
    if (!!product) return false;

    return true;
  }, [isWeb, product]);

  return (
    <Link
      to="/"
      style={{
        textDecoration: "none",
        display: "flex",
        alignItems: "center",
        color: "white",
        gap: "16px",
      }}
    >
      {showLogo && (
        <Avatar
          src="/logo.png"
          alt="Logo"
          sx={{
            width: 50,
            height: 50,
          }}
        />
      )}
      <Box>
        <Typography sx={{ fontSize: "24px" }}>עדי יאנה</Typography>
        <Typography sx={{ fontSize: "16px" }}>ליווי תזונתי</Typography>
      </Box>
    </Link>
  );
};
