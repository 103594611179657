import {
  faBookOpenReader,
  faComments,
  faHandHoldingHeart,
  faListCheck,
  faPuzzle,
  faShieldHeart,
  faUserDoctor,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { withWrapper } from "app/hooks";
import { motion } from "framer-motion";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HexagonCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  background: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "blur(10px)",
  transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  position: "relative",
  overflow: "visible",
  borderRadius: "15px",
  "&::before": {
    content: '""',
    position: "absolute",
    top: "-2px",
    left: "-2px",
    right: "-2px",
    bottom: "-2px",
    background: "linear-gradient(45deg, #2E7D32, #81C784)",
    zIndex: -1,
    borderRadius: "17px",
    transition: "all 0.4s ease",
    opacity: 0,
  },
  "&:hover": {
    transform: "translateY(-8px) rotate(2deg)",
    "&::before": {
      opacity: 1,
    },
  },
}));

const StyledTimeline = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    "&::before": {
      left: "20px",
      transform: "none",
    },
  },
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "2px",
    height: "100%",
    background: "linear-gradient(180deg, #4CAF50 0%, transparent 100%)",
  },
}));

interface TimelineItemProps {
  side?: "left" | "right";
}

const TimelineItem = styled(Box)<TimelineItemProps>(
  ({ theme, side = "left" }) => ({
    position: "relative",
    padding: theme.spacing(3),
    width: "50%",
    alignSelf: side === "left" ? "flex-start" : "flex-end",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      alignSelf: "flex-start",
      paddingLeft: theme.spacing(6),
      "&::before": {
        left: "10px",
        right: "auto",
      },
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      [side === "left" ? "right" : "left"]: "-10px",
      width: "20px",
      height: "20px",
      background: "#4CAF50",
      borderRadius: "50%",
      transform: "translateY(-50%)",
    },
  })
);

const nutritionJourney = [
  {
    icon: faShieldHeart,
    title: "היכרות ואבחון ראשוני",
    description:
      "שיחת עומק למיפוי הרגלים, השגרה והמצב הבריאותי – בסיס לתהליך מותאם אישית",
    color: "#D32F2F",
  },
  {
    icon: faPuzzle,
    title: "תכנון תזונתי אישי",
    description: "העוסק בשגרה, בהרגלים ובדפוסי החשיבה שמנהלים את האכילה",
    color: "#7B1FA2",
  },
  {
    icon: faComments,
    title: "יישום והטמעה",
    description: "הטמעת ההרגלים בשגרת היומיום המשתנה, לצד תמיכה ומענה שוטף",
    color: "#1976D2",
  },
  {
    icon: faListCheck,
    title: "מעקב והתקדמות",
    description:
      "מעקב רציף ודיאלוג מתמשך – בכדי להתאים את התהליך לשינויים בשגרה",
    color: "#388E3C",
  },
];

const expertiseAreas = [
  {
    icon: faUserDoctor,
    title: "ליווי אישי ומקצועי",
    description: "שילוב של מקצועיות, ידע ונוכחות אישית",
  },
  {
    icon: faHandHoldingHeart,
    title: "גישה הוליסטית",
    description: "הסתכלות רחבה על תזונה, שגרה והיבטים רגשיים",
  },
  {
    icon: faBookOpenReader,
    title: "תזונה מבוססת מדע",
    description: "שיטות תזונה מבוססות מדע, מותאמות לשגרת החיים",
  },
];

export const Home = withWrapper(() => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden", bgcolor: "#FAFAFA" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            minHeight: "400px",
            maxHeight: "600px",
            borderRadius: "16px",
            margin: "8px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image="https://images.pexels.com/photos/618491/pexels-photo-618491.jpeg?auto=compress&cs=tinysrgb&w=1260"
            speed={-20}
            style={{
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          />
          <ParallaxBannerLayer
            speed={-10}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: "0 16px",
              background:
                "linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0.3))",
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "4rem" },
                  fontWeight: 800,
                  mb: 2,
                  textShadow: "2px 2px 4px rgba(0,0,0,0.2)",
                  letterSpacing: "-0.02em",
                  maxWidth: "800px",
                  lineHeight: 1.2,
                  color: "white",
                }}
              >
                תזונה מותאמת אישית
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
            >
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.25rem", md: "1.75rem" },
                  fontWeight: 400,
                  mb: 4,
                  maxWidth: { xs: "100%", sm: "600px" },
                  textShadow: "1px 1px 2px rgba(0,0,0,0.2)",
                  lineHeight: 1.4,
                  mx: "auto",
                  color: "white",
                  px: { xs: 2, sm: 0 },
                }}
              >
                לשינוי הרגלים ושבירת מעגלי הדיאטה
              </Typography>
            </motion.div>

            <ArticlesButton />
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ px: { xs: 2, md: 3 } }}>
          {/* Journey Timeline Section */}
          <Box sx={{ py: { xs: 4 } }}>
            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: { xs: 4, md: 8 },
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
                  fontWeight: 800,
                  background: "linear-gradient(45deg, #2E7D32, #81C784)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  px: 2,
                }}
              >
                הדרך לאורח חיים בריא
              </Typography>
            </Parallax>

            <StyledTimeline>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 4 },
                }}
              >
                {nutritionJourney.map((step, index) => (
                  <TimelineItem
                    key={index}
                    side={index % 2 === 0 ? "left" : "right"}
                    sx={{
                      marginLeft: {
                        xs: 0,
                        md: index % 2 === 0 ? 0 : "50%",
                      },
                      marginRight: {
                        xs: 0,
                        md: index % 2 === 0 ? "50%" : 0,
                      },
                    }}
                  >
                    <Parallax
                      translateX={[index % 2 === 0 ? -30 : 30, 0]}
                      opacity={[0.5, 1]}
                      easing="easeOutQuad"
                      disabled={window.innerWidth < 960}
                    >
                      <motion.div
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                      >
                        <HexagonCard>
                          <Box
                            sx={{
                              display: "flex",
                              gap: { xs: 2, md: 3 },
                              alignItems: "center",
                              flexDirection: { xs: "column", sm: "row" },
                              textAlign: { xs: "center", sm: "left" },
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: `${step.color}15`,
                                p: { xs: 1.5, md: 2 },
                                borderRadius: "16px",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={step.icon}
                                style={{
                                  fontSize: "2rem",
                                  color: step.color,
                                }}
                              />
                            </Box>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{
                                  fontWeight: 700,
                                  mb: 1,
                                  fontSize: { xs: "1.25rem", md: "1.5rem" },
                                  color: theme.palette.text.primary,
                                }}
                              >
                                {step.title}
                              </Typography>
                              <Typography
                                variant="body1"
                                sx={{
                                  color: theme.palette.text.secondary,
                                  lineHeight: 1.6,
                                  fontSize: { xs: "0.875rem", md: "1rem" },
                                }}
                              >
                                {step.description}
                              </Typography>
                            </Box>
                          </Box>
                        </HexagonCard>
                      </motion.div>
                    </Parallax>
                  </TimelineItem>
                ))}
              </Box>
            </StyledTimeline>
          </Box>

          {/* Expertise Areas Section */}
          <Box sx={{ py: { xs: 4 } }}>
            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: { xs: 4, md: 8 },
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
                  fontWeight: 800,
                  color: theme.palette.text.primary,
                  px: 2,
                }}
              >
                הדרך שלי
              </Typography>
            </Parallax>

            <Grid container spacing={{ xs: 3, md: 6 }}>
              {expertiseAreas.map((area, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, md: 4 }}>
                  <Parallax
                    translateY={[30, -30]}
                    scale={[0.8, 1]}
                    opacity={[0.5, 1]}
                    easing="easeOutQuad"
                    disabled={window.innerWidth < 960}
                  >
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Paper
                        elevation={0}
                        sx={{
                          p: { xs: 3, md: 4 },
                          height: "100%",
                          background: "rgba(255, 255, 255, 0.8)",
                          backdropFilter: "blur(10px)",
                          borderRadius: "20px",
                          border: "1px solid rgba(0, 0, 0, 0.05)",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: { xs: "60px", md: "80px" },
                            height: { xs: "60px", md: "80px" },
                            borderRadius: "20px",
                            background:
                              "linear-gradient(135deg, #2E7D32, #81C784)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "0 auto 24px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={area.icon}
                            style={{
                              fontSize: "1.75rem",
                              color: "white",
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            mb: 2,
                            fontWeight: 700,
                            fontSize: { xs: "1.25rem", md: "1.5rem" },
                            color: theme.palette.text.primary,
                          }}
                        >
                          {area.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            color: theme.palette.text.secondary,
                            lineHeight: 1.6,
                            fontSize: { xs: "0.875rem", md: "1rem" },
                          }}
                        >
                          {area.description}
                        </Typography>
                      </Paper>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
});
