import store from "app/store";
import { ProductProvider, ScrollToTop } from "modules/core";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import { App } from "./App";
import "./index.scss";
import "./reportWebVitals";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ParallaxProvider>
          <ProductProvider>
            <ScrollToTop />
            <App />
          </ProductProvider>
        </ParallaxProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
