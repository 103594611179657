"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MidlifeTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.MidlifeTags = {
    "": {
        title: constants_1.ProductInfo.midlife.description,
        description: "ליווי תזונתי מקצועי לנשים בגיל המעבר. תזונה מותאמת אישית לאיזון הורמונלי, הפחתת תסמינים, שמירה על בריאות העצם ואיכות חיים. התייעצות עם תזונאית קלינית מומחית.",
        keywords: "תזונה בגיל המעבר, איזון הורמונלי, בריאות האישה, תזונה לגיל המעבר, תזונאית קלינית לגיל המעבר, תסמיני גיל המעבר, בריאות העצם, תזונה מותאמת אישית",
        canonicalUrl: "/midlife",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/midlife/articles",
    },
    "/articles/eating-hours": {
        title: "היתרונות של סיום אכילה מוקדם",
        description: "כיצד סיום אכילה מוקדם משפיע על שריפת שומן בגילאים שונים, ומה היתרונות הנוספים שהוא מביא לבריאות שלנו?",
        keywords: "סיום אכילה מוקדם, שריפת שומן, חילוף חומרים, שעון ביולוגי, איזון הורמונלי, גיל המעבר, איכות שינה, בריאות מטבולית",
        canonicalUrl: "/midlife/articles/eating-hours",
        ogType: "article",
        ogImage: "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונה בגיל המעבר",
            },
        },
    },
    "/articles/microbiome": {
        title: "המיקרוביום בגיל המעבר: המפתח לאיזון משקל, הורמונים ורעב",
        description: "גלי כיצד המיקרוביום משפיע על המשקל, האיזון ההורמונלי ותחושת הרעב בגיל המעבר, ואיך ניתן לטפח אותו באופן טבעי",
        keywords: "מיקרוביום, גיל המעבר, חיידקי מעיים, איזון הורמונלי, ניהול משקל, תזונה בריאה, פרוביוטיקה, חילוף חומרים, בריאות המעיים",
        canonicalUrl: "/midlife/articles/microbiome",
        ogType: "article",
        ogImage: "https://images.unsplash.com/photo-1576086213369-97a306d36557?q=80&w=1260",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "בריאות האישה",
            },
        },
    },
    "/articles/emotional-eating": {
        title: "תסמיני גיל המעבר ואכילה רגשית",
        description: "הקשר בין שינויים הורמונליים והרגלי אכילה בגיל המעבר, וכיצד ניתן להתמודד עם אכילה רגשית בתקופה זו",
        keywords: "אכילה רגשית, גיל המעבר, הורמונים, תזונה, דיאטה, בריאות נשים, שינויים הורמונליים, תזונה מאוזנת, ייעוץ תזונתי, תזונאית קלינית",
        canonicalUrl: "/midlife/articles/emotional-eating",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/7176305/pexels-photo-7176305.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונה בגיל המעבר",
            },
        },
    },
    "/articles/hormone-nutrition": {
        title: "תזונה ואיזון הורמונלי בגיל המעבר",
        description: "כיצד תזונה נכונה יכולה לסייע באיזון הורמונלי ובהקלה על תסמיני גיל המעבר, כולל המלצות תזונתיות ודרכים להפחתת סטרס",
        keywords: "גיל המעבר, איזון הורמונלי, תזונה בגיל המעבר, פיטואסטרוגנים, סידן, חלבון, הפחתת סטרס, תזונה מותאמת אישית, תזונאית קלינית, עדי יאנה",
        canonicalUrl: "/midlife/articles/hormone-nutrition",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונה בגיל המעבר",
            },
        },
    },
    "/articles/abdominal-fat": {
        title: "שומן בטני בגיל המעבר",
        description: "מדוע שומן בטני מצטבר בגיל המעבר וכיצד ניתן להתמודד? גלי את הסיבות הפיזיולוגיות והדרכים המבוססות מחקר לניהול משקל בריא.",
        keywords: "שומן בטני, גיל המעבר, הורמונים, חילוף חומרים, קורטיזול, מיקרוביום, מסת שריר, תזונה מותאמת, פעילות גופנית, ניהול סטרס",
        canonicalUrl: "/midlife/articles/abdominal-fat",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4098228/pexels-photo-4098228.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונה בגיל המעבר",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/midlife/lets-talk",
    },
};
