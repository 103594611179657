import { Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

export const ArticlesButton = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ py: 4 }}
    >
      <Button
        component={Link}
        to="articles"
        variant="contained"
        size="large"
        sx={{
          py: 1.5,
          px: 6,
          borderRadius: "50px",
          fontSize: "1.2rem",
        }}
      >
        תכנים וכלים שכדאי להכיר
      </Button>
    </Box>
  );
};
