import {
  faAppleWhole,
  faBed,
  faBone,
  faBrain,
  faHeartbeat,
  faLeaf,
  faSeedling,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useInView } from "react-intersection-observer";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const StyledCard = styled(Card)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 16,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
  },
}));

const CardContentStyled = styled(CardContent)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
  padding: "24px",
  "&:last-child": {
    paddingBottom: "24px",
  },
}));

const nutritionData = [
  {
    id: "healthy-fats",
    title: "שומנים בריאים",
    description:
      "חומצות שומן חד-בלתי רוויות ורב-בלתי רוויות המצויות באבוקדו, אגוזים, שקדים, זרעי צ'יה, זרעי פשתן ושמן זית מסייעות בהפחתת דלקתיות ובשמירה על איזון הורמונלי.",
    icon: faLeaf,
    color: "#4CAF50",
  },
  {
    id: "fibers",
    title: "סיבים תזונתיים",
    description:
      "סיבים מסייעים באיזון רמות הסוכר בדם ובהפחתת עומס על בלוטת יותרת הכליה, וכך תורמים להפחתת תסמיני גיל המעבר. מומלץ לצרוך קטניות, ירקות, פירות ודגנים מלאים.",
    icon: faAppleWhole,
    color: "#FF9800",
  },
  {
    id: "proteins",
    title: "חלבונים איכותיים",
    description:
      "חלבון חיוני לשמירה על מסת שריר, לאיזון רמות האינסולין ולתפקוד מטבולי תקין. ניתן לשלב קטניות, טופו, סייטן, טמפה, ואגוזים כמקורות חלבון איכותיים.",
    icon: faHeartbeat,
    color: "#E91E63",
  },
  {
    id: "antioxidants",
    title: "נוגדי חמצון",
    description:
      "ויטמינים כגון ויטמין C, ויטמין E ופוליפנולים המצויים בפירות יער, אגוזים, תה ירוק וירקות עליים כהים מסייעים בניטרול רדיקלים חופשיים ומפחיתים את השפעת השינויים ההורמונליים.",
    icon: faSeedling,
    color: "#2196F3",
  },
];

const phytoestrogensData = [
  {
    title: "מוצרי סויה",
    items: ["טופו", "טמפה", "חלב סויה"],
    icon: faBrain,
  },
  {
    title: "זרעים וקטניות",
    items: ["זרעי פשתן", "זרעי צ'יה", "חומוס", "עדשים", "שעועית"],
    icon: faSeedling,
  },
  {
    title: "ירקות מצליבים",
    items: ["ברוקולי", "כרוב", "נבטי ברוקולי"],
    icon: faLeaf,
  },
  {
    title: "מקורות סידן צמחיים",
    items: ["ירקות עליים ירוקים", "שקדים", "טחינה גולמית", "סויה ומוצריה"],
    icon: faBone,
  },
];

const stressReductionTips = [
  {
    title: "פעילות גופנית מתונה",
    description:
      "הליכה, יוגה, פילאטיס ושחייה מסייעות בהפחתת רמות הקורטיזול ובשיפור מצב הרוח.",
    icon: faHeartbeat,
  },
  {
    title: "תזונה מאוזנת",
    description:
      "הקפדה על צריכת מזונות עשירים במגנזיום, כמו שקדים, קקאו, בננות וקטניות, יכולה לסייע בהרפיית מערכת העצבים.",
    icon: faAppleWhole,
  },
  {
    title: "מיינדפולנס ומדיטציה",
    description:
      "תרגול יומיומי של נשימות עמוקות ומודעות עצמית יכול לשפר את התמודדות הגוף עם סטרס.",
    icon: faBrain,
  },
  {
    title: "שינה איכותית",
    description:
      "שמירה על שעות שינה מספקות, הימנעות ממסכים לפני השינה ושימוש בטכניקות הרפיה יכולות לשפר את איכות השינה ולמנוע השפעות שליליות על ההורמונים.",
    icon: faBed,
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=1260&auto=compress";

export const HormoneNutrition = () => {
  useMeta();

  const theme = useTheme();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  תזונה ואיזון הורמונלי בגיל המעבר
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  כיצד תזונה נכונה יכולה לסייע באיזון הורמונלי ובהקלה על תסמיני
                  גיל המעבר
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  גיל המעבר הוא שלב טבעי בחיי האישה, המאופיין בירידה בהפרשת
                  ההורמונים אסטרוגן ופרוגסטרון. שינויים אלו משפיעים על תפקוד
                  מערכות שונות בגוף ויכולים להוביל להשלכות כמו ירידה בצפיפות
                  העצם, שינוי בהרכב הגוף, הפרעות שינה ותנודות במצב הרוח. תזונה
                  מותאמת יכולה להוות כלי משמעותי באיזון הורמונלי ולהקל על
                  התסמינים הנלווים לתקופה זו.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Nutrition Cards */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                מזונות המסייעים באיזון הורמונלי
              </Typography>

              <Typography
                variant="body1"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8, mb: 4 }}
              >
                התאמת התזונה יכולה להשפיע ישירות על רמות ההורמונים בגוף, להפחית
                תסמינים ולהעניק תחושת חיוניות ואנרגיה. מספר רכיבים תזונתיים
                מרכזיים יכולים לסייע בכך:
              </Typography>

              <Grid container spacing={4}>
                {nutritionData.map((item) => (
                  <Grid key={item.id} size={{ xs: 12, sm: 6, md: 3 }}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      style={{ height: "100%" }}
                    >
                      <StyledCard>
                        <CardContentStyled>
                          <FontAwesomeIcon
                            icon={item.icon}
                            style={{
                              fontSize: "3rem",
                              color: item.color,
                              marginBottom: "1rem",
                            }}
                          />
                          <Typography
                            variant="h5"
                            sx={{ mb: 2, fontWeight: 600 }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ flex: 1 }}
                          >
                            {item.description}
                          </Typography>
                        </CardContentStyled>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Phytoestrogens Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                תפקידם של פיטואסטרוגנים בתזונה
              </Typography>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  פיטואסטרוגנים הם תרכובות טבעיות הנמצאות בצמחים ובעלות מבנה
                  דומה לאסטרוגן. צריכתם עשויה לסייע בהפחתת תסמיני גיל המעבר,
                  כגון גלי חום ושינויים במצב הרוח. מקורות טובים לפיטואסטרוגנים
                  כוללים:
                </Typography>
                <Grid container spacing={4}>
                  {phytoestrogensData.map((category, index) => (
                    <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
                      <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <StyledCard>
                          <CardContent sx={{ textAlign: "center" }}>
                            <FontAwesomeIcon
                              icon={category.icon}
                              style={{
                                fontSize: "2.5rem",
                                color: theme.palette.primary.main,
                                marginBottom: "1rem",
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ mb: 2, fontWeight: 600 }}
                            >
                              {category.title}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {category.items.map((item, idx) => (
                                <Chip
                                  key={idx}
                                  label={item}
                                  sx={{
                                    backgroundColor: "rgba(255,255,255,0.8)",
                                    m: 0.5,
                                  }}
                                />
                              ))}
                            </Box>
                          </CardContent>
                        </StyledCard>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </StyledPaper>
            </Box>

            {/* Calcium and Protein Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                החשיבות של צריכת סידן וחלבון
              </Typography>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  אחת ההשלכות המשמעותיות של ירידת רמות האסטרוגן היא ירידה
                  בצפיפות העצם, מה שמעלה את הסיכון לאוסטאופורוזיס. צריכת סידן
                  נאותה חשובה לשמירה על חוזק העצם, ומומלץ לשלב בתזונה מקורות
                  צמחיים לסידן כגון:
                </Typography>
                <Box sx={{ mb: 4 }}>
                  <Grid container spacing={2}>
                    {[
                      "ירקות עליים ירוקים (קייל, תרד, ברוקולי)",
                      "שקדים וטחינה גולמית",
                      "סויה ומוצריה",
                      "שומשום וקטניות",
                    ].map((item, index) => (
                      <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
                        <Chip
                          label={item}
                          sx={{
                            width: "100%",
                            height: "auto",
                            padding: "8px",
                            "& .MuiChip-label": {
                              whiteSpace: "normal",
                              lineHeight: 1.5,
                            },
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  צריכת חלבון איכותי בכל יום ובארוחות העיקריות חיונית לא רק
                  לשמירה על מסת השריר אלא גם להחלמה מהירה יותר ממחלות, חיזוק
                  מערכת החיסון ובנוסף ניראה ממחקרים כי צריכת חלבון מספקת בכל יום
                  תורמת להתאוששות טובה יותר מפציעות ומחלות, משפרת את תפקוד
                  השרירים ומסייעת לשמירה על רמות אנרגיה תקינות. מומלץ לכלול
                  בתפריט מקורות חלבון מגוונים: קטניות, טופו, טמפה, מוצרי חלב,
                  ביצים, דגים ועוף.
                </Typography>
              </StyledPaper>
            </Box>

            {/* Stress and Weight Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                כיצד סטרס משפיע על המשקל וכיצד להפחיתו?
              </Typography>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  סטרס כרוני מגביר את הפרשת ההורמון קורטיזול, אשר משפיע על אגירת
                  שומן בטני, מגביר חשק למתוקים ופוגע באיזון ההורמונלי. בנוסף,
                  סטרס ממושך גורם לשינויים באיכות השינה, דבר שמגביר אכילה רגשית
                  ועלייה במשקל.
                </Typography>
              </StyledPaper>
            </Box>

            {/* Stress Management Section */}
            <Box ref={ref} sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                הפחתת סטרס והשפעתו על המשקל
              </Typography>
              <Grid container spacing={4}>
                {stressReductionTips.map((tip, index) => (
                  <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
                    <motion.div
                      initial={{ opacity: 0, x: -20 }}
                      animate={inView ? { opacity: 1, x: 0 } : {}}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      style={{ height: "100%" }}
                    >
                      <StyledCard>
                        <CardContent
                          sx={{
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={tip.icon}
                            style={{
                              fontSize: "2.5rem",
                              color: theme.palette.secondary.main,
                              marginBottom: "1rem",
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{ mb: 2, fontWeight: 600 }}
                          >
                            {tip.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ flex: 1 }}
                          >
                            {tip.description}
                          </Typography>
                        </CardContent>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper
                elevation={3}
                sx={{
                  background:
                    "linear-gradient(145deg, #e3f2fd 0%, #f3e5f5 100%)",
                }}
              >
                <Typography variant="h4" sx={{ mb: 3, fontWeight: 700 }}>
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  תזונה מותאמת היא כלי חיוני לשמירה על איזון הורמונלי בגיל
                  המעבר, והיא משפיעה על תסמינים כמו שינויים במצב הרוח, עלייה
                  במשקל וירידה בצפיפות העצם. שילוב מזונות עשירים בפיטואסטרוגנים,
                  צריכת חלבון איכותי וסידן, לצד הפחתת סטרס ושיפור איכות השינה,
                  יכול להקל על תסמיני גיל המעבר ולשפר את איכות החיים. התאמה
                  אישית של התזונה בליווי דיאטנית מוסמכת תבטיח אסטרטגיה אפקטיבית
                  לשמירה על בריאות הגוף והנפש בתקופה זו.
                </Typography>
              </StyledPaper>
            </Parallax>
          </ContentSection>
        </Container>

        <ContactButtons />
      </Box>
    </ParallaxProvider>
  );
};
