import { Article } from "modules/core";

export const veganArticles: Article[] = [
  {
    path: "/vegan/articles/plant-based-weight-loss",
    image:
      "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&w=350",
  },
  {
    path: "/vegan/articles/myths",
    image:
      "https://images.pexels.com/photos/1458694/pexels-photo-1458694.jpeg?w=350&auto=compress",
  },
  {
    path: "/vegan/articles/plant-based-sports",
    image:
      "https://images.pexels.com/photos/4720236/pexels-photo-4720236.jpeg?auto=compress&w=350",
  },
  {
    path: "/vegan/articles/health-benefits",
    image:
      "https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?auto=compress&w=350",
  },
  {
    path: "/vegan/articles/life-stages",
    image:
      "https://images.pexels.com/photos/5529587/pexels-photo-5529587.jpeg?auto=compress&w=350",
  },
];
