import {
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  IconButton,
  Link,
  Skeleton,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useState } from "react";
import { useMeta } from "../hooks/use-meta.hook";
import { ContactButtons } from "./ContactButtons";

const StyledContactSection = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(8, 0),
}));

const ImageContainer = styled(motion.div)(({ theme }) => ({
  position: "relative",
  width: "100%",
  aspectRatio: "900/600",
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: theme.spacing(2),
    boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
  },
}));

const ContentContainer = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(4),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}));

const SocialButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1.5),
  fontSize: "1.5rem",
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(1),
    fontSize: "1.2rem",
  },
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const SocialLinksContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export const LetsTalk = () => {
  useMeta();

  const theme = useTheme();
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <StyledContactSection>
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid size={{ xs: 12, md: 6 }}>
            <ContentContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Typography
                variant="h2"
                component="h1"
                gutterBottom
                sx={{
                  fontWeight: 700,
                  color: theme.palette.primary.dark,
                }}
              >
                בואו נדבר
              </Typography>

              <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
                הצעד הראשון לשינוי מתחיל כאן!
              </Typography>

              <ContactButtons />

              <Box sx={{ mt: 4 }}>
                <SocialLinksContainer>
                  <Link
                    href="https://www.facebook.com/adi.oberman"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialButton color="primary" aria-label="פייסבוק">
                      <FontAwesomeIcon icon={faFacebook} size="2x" />
                    </SocialButton>
                  </Link>
                  <Link
                    href="https://www.instagram.com/adiyana.nutritionist/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialButton color="primary" aria-label="אינסטגרם">
                      <FontAwesomeIcon icon={faInstagram} size="2x" />
                    </SocialButton>
                  </Link>
                  <Link
                    href="https://www.linkedin.com/in/adiyana/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialButton color="primary" aria-label="לינקדאין">
                      <FontAwesomeIcon icon={faLinkedin} size="2x" />
                    </SocialButton>
                  </Link>
                </SocialLinksContainer>
              </Box>
            </ContentContainer>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <ImageContainer
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              {!imageLoaded && (
                <Skeleton
                  variant="rectangular"
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: theme.spacing(2),
                  }}
                />
              )}
              <img
                src="/letsTalk.jpeg"
                alt="תמונת פרופיל"
                onLoad={() => setImageLoaded(true)}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: imageLoaded ? "block" : "none",
                }}
              />
            </ImageContainer>
          </Grid>
        </Grid>
      </Container>
    </StyledContactSection>
  );
};
