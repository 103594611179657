import {
  faAppleWhole,
  faBolt,
  faDumbbell,
  faFire,
  faHeartPulse,
  faMedal,
  faPersonRunning,
  faStopwatch,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { withWrapper } from "app/hooks";
import { motion } from "framer-motion";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import "./Home.scss";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  height: "100%",
  background: "rgba(255, 255, 255, 0.95)",
  backdropFilter: "blur(10px)",
  border: "1px solid rgba(255, 255, 255, 0.2)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 40px rgba(0,0,0,0.12)",
  },
}));

const nutritionTips = [
  {
    icon: faDumbbell,
    title: "תזונה לבניית שריר",
    description:
      "תוכנית תזונה מותאמת אישית לספורטאים המעוניינים בבניית מסת שריר",
    color: "#2196f3",
  },
  {
    icon: faPersonRunning,
    title: "תזונה לריצה ואתלטיקה",
    description: "תזונה מותאמת לרצים ואתלטים להשגת ביצועים מיטביים",
    color: "#4caf50",
  },
  {
    icon: faHeartPulse,
    title: "תזונה לסיבולת",
    description: "תוכניות תזונה לשיפור סיבולת וביצועים ארוכי טווח",
    color: "#f44336",
  },
  {
    icon: faAppleWhole,
    title: "תזונה מאוזנת",
    description: "איזון תזונתי מושלם לספורטאים מקצועיים וחובבים",
    color: "#ff9800",
  },
];

const performanceFeatures = [
  {
    icon: faStopwatch,
    title: "תזמון ארוחות",
    description: "למידה על תזמון אופטימלי של ארוחות לפני ואחרי אימון",
    color: "#9c27b0",
  },
  {
    icon: faBolt,
    title: "אנרגיה מתמשכת",
    description: "תזונה שתספק לך אנרגיה לאורך כל היום ובמהלך האימונים",
    color: "#ffc107",
  },
  {
    icon: faFire,
    title: "חילוף חומרים",
    description: "שיפור חילוף החומרים והתאוששות מהירה יותר",
    color: "#e91e63",
  },
  {
    icon: faMedal,
    title: "ביצועים משופרים",
    description: "השגת תוצאות טובות יותר באמצעות תזונה נכונה",
    color: "#3f51b5",
  },
];

export const Home = withWrapper(() => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner style={{ height: "50vh" }}>
          <ParallaxBannerLayer
            speed={-20}
            scale={[1.2, 1]}
            opacity={[0.9, 1]}
            translateY={[0, 50]}
          >
            <Box
              sx={{
                height: "100%",
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1517836357463-d25dfeac3438?q=80&w=2070)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                filter: "brightness(0.7)",
              }}
            />
          </ParallaxBannerLayer>

          <ParallaxBannerLayer
            speed={-10}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                color: "white",
                padding: { xs: 2, md: 4 },
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "4.5rem" },
                    fontWeight: 800,
                    textShadow: "2px 4px 4px rgba(0,0,0,0.3)",
                    mb: 2,
                  }}
                >
                  תזונת ספורט מקצועית
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "1.2rem", md: "1.8rem" },
                    textShadow: "1px 2px 2px rgba(0,0,0,0.3)",
                    maxWidth: "800px",
                    margin: "0 auto",
                    mb: 4,
                  }}
                >
                  הדרך שלך להצלחה בספורט מתחילה בצלחת
                </Typography>
                <ArticlesButton />
              </motion.div>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container>
          {/* Main Content */}
          <Box sx={{ py: 8 }}>
            <Parallax translateY={[20, -20]} opacity={[0.5, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: 6,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                תוכניות תזונה מותאמות אישית
              </Typography>
            </Parallax>

            <Grid container spacing={4}>
              {nutritionTips.map((tip, index) => (
                <Grid key={index} size={{ xs: 12, md: 6 }}>
                  <Parallax
                    translateY={[30, -30]}
                    opacity={[0.5, 1]}
                    scale={[0.8, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{ display: "flex", gap: 3, alignItems: "center" }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${tip.color}15`,
                              p: 3,
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <motion.div
                              animate={{
                                rotate: [0, 10, 0],
                              }}
                              transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: "reverse",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={tip.icon}
                                style={{
                                  fontSize: 40,
                                  color: tip.color,
                                }}
                              />
                            </motion.div>
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ fontWeight: 700 }}
                            >
                              {tip.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1.1rem",
                                color: theme.palette.text.secondary,
                              }}
                            >
                              {tip.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Performance Features Section */}
          <Box sx={{ py: 4, position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `linear-gradient(135deg, ${theme.palette.primary.light}15, ${theme.palette.secondary.light}15)`,
                borderRadius: "30px",
                transform: "skewY(-3deg)",
                zIndex: -1,
              }}
            />

            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: 6,
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                שיפור ביצועים
              </Typography>
            </Parallax>

            <Grid container spacing={4}>
              {performanceFeatures.map((feature, index) => (
                <Grid key={index} size={{ xs: 12, md: 6 }}>
                  <Parallax
                    translateY={[20, -20]}
                    opacity={[0.5, 1]}
                    scale={[0.8, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{ display: "flex", gap: 3, alignItems: "center" }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${feature.color}15`,
                              p: 3,
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <motion.div
                              animate={{
                                y: [0, -5, 0],
                              }}
                              transition={{
                                duration: 2,
                                repeat: Infinity,
                                repeatType: "reverse",
                              }}
                            >
                              <FontAwesomeIcon
                                icon={feature.icon}
                                style={{
                                  fontSize: 40,
                                  color: feature.color,
                                }}
                              />
                            </motion.div>
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ fontWeight: 700 }}
                            >
                              {feature.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1.1rem",
                                color: theme.palette.text.secondary,
                              }}
                            >
                              {feature.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
});
