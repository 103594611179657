import {
  faBrain,
  faHeartPulse,
  faScaleBalanced,
  faStopwatch,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const principlesData = [
  {
    id: "timing",
    title: "מתי נכון להתחיל לרדת במשקל לאחר לידה",
    description: (
      <>
        לצד הרצון לחזור לשגרה ואף למשקל שלפני ההיריון, מחקרים מראים כי מומלץ
        להמתין לפחות שישה שבועות לאחר הלידה לפני התחלת תהליך ירידה במשקל, במיוחד
        בקרב נשים מניקות. בתקופה זו, לאחר ריקון המאגרים שהופנו לטובת התפתחות
        מיטבית של העובר במהלך ההיריון, הגוף זקוק לחידוש מאגרי המיקרו-נוטריינטים,
        לשיקום הרחם והשרירים ולהסתגלות לשגרת ההנקה. ירידה מהירה מדי במשקל עלולה
        לפגוע ברמות האנרגיה, לגרום לתחושת עייפות מתמשכת, לפגוע ביכולת התפקוד
        היומי ואף להוביל לתחושות של דכדוך ותסכול ועלולה אף לפגוע בייצור החלב.
        ירידה הדרגתית של כחצי קילוגרם עד קילוגרם בשבוע נחשבת בטוחה ואפקטיבית.
      </>
    ),
    icon: faStopwatch,
    color: "#FF6B6B",
  },
  {
    id: "nutrition",
    title: "איך לשלב תזונה בריאה מבלי לפגוע בייצור החלב",
    description: (
      <>
        ייצור חלב, במיוחד במהלך השבועות הראשונים שלאחר הלידה, מצריך תוספת
        קלוריות שנעה בין כ-300 ל-500 קלוריות ליום, בהתאם לשלבי ההנקה ולצרכי הגוף
        המשתנים לאורך התקופה, ולכן יש צורך בהתאמה נכונה של התזונה היומית בכדי
        לספק את צרכי האם, להזין אותה ולתמוך בתהליך ירידה נכון והדרגתי. תזונה
        עשירה בחלבון, כמו קטניות, טופו ואגוזים, תורמת לתחושת שובע ומסייעת בשמירה
        על מסת השריר. פחמימות מורכבות, דוגמת קינואה ודגנים מלאים, מספקות אנרגיה
        יציבה ותומכות בתפקוד מערכת העצבים. לצד הרצון לרדת במשקל, ישנה חשיבות
        לשלב שומנים חיוניים כחלק משיגרת אורח חיים בריא. עבור אם המניקה, צריכת
        שומנים בריאים מאבוקדו, שקדים ושמן זית מהווה מרכיב חיוני התומך בהתפתחות
        המוח של התינוק ותורם לשמירה על איזון הורמונלי ובריאותה הכללית של האם.
      </>
    ),
    icon: faHeartPulse,
    color: "#4ECDC4",
  },
  {
    id: "metabolism",
    title: "תזונה לשיפור חילוף החומרים ותמיכה במיקרוביום",
    description: (
      <>
        קצב חילוף החומרים עשוי להשתנות לאחר הלידה, ולכן ישנה חשיבות לשילוב
        מזונות המגבירים את המטבוליזם. חלבון מעלה את קצב חילוף החומרים בזכות אפקט
        התרמוגנזה – האנרגיה שהגוף משקיע בעיכולו. מזונות עשירים בסיבים תזונתיים,
        כמו ירקות, פירות וקטניות, תומכים במערכת העיכול ומסייעים בשמירה על משקל
        תקין. בנוסף, מזונות מן הצומח שתומכים ומזינים את המיקרוביום במעי מעודדים
        תחושת שובע, מפחיתים תחושות רעב וצורך בנשנושים ובכך תורמים לשמירה על משקל
        תקין. בין המזונות המומלצים נמנים ירקות מותססים כמו כרוב כבוש, קטניות
        ודגנים מלאים, אשר מגבירים את אוכלוסיית החיידקים המועילים ומשפרים את
        תפקוד מערכת העיכול.
      </>
    ),
    icon: faBrain,
    color: "#45B7D1",
  },
  {
    id: "energy",
    title: "איך להחזיר את הכוח והאנרגיה באמצעות תזונה",
    description: (
      <>
        העייפות המלווה את תקופת ההנקה והטיפול בתינוק דורשת אספקת אנרגיה רציפה
        ומאוזנת. ארוחות קלילות שאינן מכבידות בשילוי מזון נכונים יכולים לסייע
        בשמירה על רמות סוכר יציבות ולמנוע תחושת עייפות פתאומית. בנוסף, צריכת
        ירקות עשירים בברזל כמו תרד וברוקולי חשובים למניעת עייפות, במיוחד לנשים
        שסבלו מאובדן דם בלידה. ויטמין C משפר את ספיגת הברזל וניתן למצוא אותו
        בפלפלים, פירות הדר וקיווי.
      </>
    ),
    icon: faScaleBalanced,
    color: "#96CEB4",
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/3270224/pexels-photo-3270224.jpeg?auto=compress&w=1260",
  rest: "https://images.pexels.com/photos/4473870/pexels-photo-4473870.jpeg?auto=compress&w=640",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const PostpartumWeightLossNutrition = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    עקרונות תזונתיים בירידה במשקל לאחר לידה
                  </Typography>
                </Zoom>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      מבוא
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      תקופת ההחלמה שלאחר הלידה מהווה שלב משמעותי בחייה של כל
                      אישה, הן מבחינה פיזית והן מבחינה רגשית. הגוף משתנה רבות
                      במהלך ההיריון והלידה, והשינויים ההורמונליים, העייפות
                      והעומס שבטיפול בתינוק עלולים להשאיר את האם בתחושת אכזבה
                      ותסכול מהגוף. תחושות אלה טבעיות ומובנות, שכן תקופה זו
                      מלווה ברגשות מעורבים הכוללים שמחה לצד עייפות וחוסר ודאות.
                      עם זאת, חשוב לזכור שהגוף זקוק לזמן להתאוששות ושינויים
                      במראה אינם מעידים על כישלון ואינם גזירת גורל. גישה סבלנית
                      ומכילה כלפי הגוף בשילוב עם תזונה מאוזנת וחזרה הדרגתית
                      לכושר תומכת לא רק בבריאות הפיזית אלא גם בדימוי העצמי
                      ובתחושה הכללית. תזונה שתומכת בהתאוששות הגוף מבלי לפגוע
                      בייצור החלב יכולה לסייע בהתמודדות עם האתגרים הפיזיים
                      והנפשיים של התקופה שלאחר הלידה.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.rest}
                  alt="Mother resting with baby"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Principles Cards */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                עקרונות מרכזיים
              </Typography>
              <Grid container spacing={3}>
                {principlesData.map((principle, index) => (
                  <Grid key={principle.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${principle.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={principle.icon}
                              style={{ fontSize: 40, color: principle.color }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: principle.color, fontWeight: 600 }}
                            >
                              {principle.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                            >
                              {principle.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    לסיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    חשוב לזכור שבתהליך זה יש מקום לסבלנות ולקבלה עצמית, תוך
                    התמקדות בהתקדמות הדרגתית ובשמירה על בריאות הגוף והנפש
                    <br />
                    ניהול המשקל לאחר לידה דורש איזון בראייה הכוללת את צרכי הגוף
                    להתאוששות מהירה וטובה, לוקח בחשבון גם את הנפש אשר חווה
                    שינויים, הצפה רגשית ואף תנודות במצב הרוח בין אושר לכעס
                    ותסכול ולבין הרצון לרדת במשקל. תהליך הדרגתי, המשלב תזונה
                    עשירה בחלבון, פחמימות מורכבות ושומנים בריאים, מאפשר ירידה
                    במשקל בצורה מאוזנת התומכת את התקופה המאתגרת בה האם נמצאת
                    ומבלי לפגוע בייצור החלב, . תזונה מגוונת ועשירה בסיבים תורמת
                    לשיפור חילוף החומרים, תומכת במיקרוביום ומאפשרת תהליך ירידה
                    נכון ומותאם. ליווי תזונתי מקצועי יכול להבטיח מענה מותאם
                    לצרכים הייחודיים של כל אישה, תוך תמיכה בתהליך ההחלמה ושמירה
                    על בריאותה לטווח הארוך.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
