import {
  faChartLine,
  faFire,
  faMoon,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import { ResponsiveContainer } from "recharts";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const benefitsData = [
  {
    id: "sleep",
    title: "שיפור איכות השינה",
    description:
      "אכילה מוקדמת מאפשרת לגוף לעבור לתהליכי ריפוי והתחדשות במהלך הלילה, במקום להיות עסוק בעיכול מזון.",
    icon: faMoon,
    color: "#FF6B6B",
  },
  {
    id: "sugar",
    title: "איזון רמות הסוכר בדם",
    description:
      "סיום אכילה מוקדם מפחית את התנודות ברמות הסוכר ומסייע במניעת עמידות לאינסולין.",
    icon: faChartLine,
    color: "#4ECDC4",
  },
  {
    id: "hunger",
    title: "הפחתת תחושת רעב לילי",
    description:
      "כאשר הגוף מתרגל למסגרת שעות אכילה מוגדרת, הסיכוי לרעב בשעות מאוחרות קטן.",
    icon: faUtensils,
    color: "#45B7D1",
  },
  {
    id: "weight",
    title: "שיפור ביכולת ניהול המשקל",
    description:
      "מחקרים מראים כי אנשים שסיימו לאכול מוקדם יותר נטו לצרוך פחות קלוריות במשך היום ולשמור על משקל תקין יותר.",
    icon: faFire,
    color: "#96CEB4",
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const ParallaxSection = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  marginBottom: theme.spacing(8),
  marginTop: theme.spacing(8),
}));

const HERO_IMAGE =
  "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=1260";
const METABOLISM_IMAGE =
  "https://images.unsplash.com/photo-1495214783159-3503fd1b572d?q=80&w=1260";

export const EatingHours = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  היתרונות של סיום אכילה מוקדם
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  והשפעתו על שריפת שומן בגילאים שונים
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  במהלך השנים האחרונות, מחקרים רבים מצביעים על הקשר בין זמני
                  האכילה לבין חילוף החומרים בגוף. מסתבר שלא רק מה שאנחנו אוכלים
                  משפיע על הבריאות שלנו, אלא גם מתי אנחנו אוכלים. סיום אכילה
                  מוקדם יחסית במהלך היום עשוי לתרום באופן משמעותי לשריפת שומן,
                  לשיפור איזון ההורמונים ולבריאות המטבולית, במיוחד בגילאים
                  מבוגרים יותר.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Metabolism Section */}
            <ParallaxSection>
              <ParallaxBanner style={{ height: "100%" }}>
                <ParallaxBannerLayer
                  image={METABOLISM_IMAGE}
                  speed={-15}
                  scale={[1.1, 1]}
                  opacity={[0.8, 1]}
                />
                <ParallaxBannerLayer speed={-10} opacity={[1, 0.9]}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background:
                        "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                      padding: 4,
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        color: "white",
                        textAlign: "center",
                        textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                        maxWidth: "800px",
                        fontWeight: 700,
                      }}
                    >
                      כיצד סיום אכילה מוקדם משפיע על חילוף החומרים?
                    </Typography>
                  </Box>
                </ParallaxBannerLayer>
              </ParallaxBanner>
            </ParallaxSection>

            {/* Metabolism content */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  לגוף יש שעון ביולוגי הפועל במחזורים של 24 שעות, הידוע בשם
                  השעון הצירקדיאני. מחזור זה משפיע על פעילות מערכות שונות בגוף,
                  כולל חילוף החומרים, הפרשת הורמונים ומנגנוני עיכול. כאשר אנו
                  אוכלים בשעות הערב המאוחרות או בלילה, אנו למעשה פועלים בניגוד
                  לשעון הביולוגי שלנו, מה שעלול לפגוע בתפקוד המטבולי ולגרום
                  להצטברות שומן.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  מחקרים מראים כי כאשר הארוחה האחרונה של היום מתרחשת בשעות
                  מוקדמות יותר, הגוף מצליח לנצל את האנרגיה ביעילות רבה יותר
                  ולשרוף שומן במקום לאגור אותו. תופעה זו בולטת במיוחד בקרב אנשים
                  בגיל המעבר ובמבוגרים, שלהם קצב חילוף חומרים נמוך יותר באופן
                  טבעי.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Age Effect on Fat Burning */}
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                color="primary"
                sx={{ mb: 3 }}
              >
                השפעת הגיל על שריפת השומן
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                שריפת השומן בעקבות סיום אכילה מוקדם אינה אחידה בכל הגילאים.
                במחקרי מעבדה נמצא כי אצל צעירים (בני 18-30) הגוף מסוגל לשרוף
                שומן בצורה יעילה גם אם הם אוכלים בשעות מאוחרות יותר. לעומת זאת,
                בקרב מבוגרים (31-50) ובמיוחד בקרב בני 51 ומעלה, חילוף החומרים
                הופך לאיטי יותר, ולכן השפעת סיום אכילה מוקדם הופכת לקריטית יותר.
              </Typography>
            </StyledPaper>

            {/* Graph Section with Parallax */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  align="center"
                  sx={{ mb: 8 }}
                >
                  הגרף הבא ממחיש את ההבדלים בשריפת שומן לפי גיל וזמן סיום
                  האכילה:
                </Typography>
                <ResponsiveContainer width="100%">
                  <Box
                    component="img"
                    src="/articles/eatingHoursGraph.png"
                    alt="גרף המציג את הקשר בין גיל, זמן סיום האכילה ושריפת השומן"
                    sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                  />
                </ResponsiveContainer>
              </StyledPaper>
            </Parallax>

            {/* Benefits section with improved styling */}
            <StyledPaper elevation={3}>
              <Typography
                variant="h3"
                gutterBottom
                color="primary"
                sx={{ mb: 8, textAlign: "center" }}
              >
                יתרונות נוספים של סיום אכילה מוקדם
              </Typography>
              <Grid container spacing={4}>
                {benefitsData.map((benefit) => (
                  <Grid key={benefit.id} size={{ xs: 12, md: 6 }}>
                    <Parallax
                      translateY={[10, -10]}
                      opacity={[0.5, 1]}
                      speed={5}
                    >
                      <Box sx={{ display: "flex", gap: 3, height: "100%" }}>
                        <Box
                          sx={{
                            backgroundColor: `${benefit.color}15`,
                            p: 3,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "flex-start",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={benefit.icon}
                            style={{ fontSize: 32, color: benefit.color }}
                          />
                        </Box>
                        <Box>
                          <Typography variant="h5" gutterBottom>
                            {benefit.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "1.1rem" }}
                          >
                            {benefit.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Parallax>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>

            {/* Summary with Parallax */}
            <Parallax translateY={[10, -10]} opacity={[0.8, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 4 }}
                >
                  לסיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  סיום אכילה מוקדם אינו רק שיטה לשיפור הירידה במשקל, אלא כלי
                  לשיפור הבריאות הכללית ואיזון חילוף החומרים. עבור מבוגרים,
                  ובמיוחד בגיל המעבר, יישום הרגל זה יכול להיות קריטי למניעת
                  השמנה ולשמירה על איזון הורמונלי. אימוץ הרגלי אכילה המותאמים
                  לשעון הביולוגי שלנו עשוי לסייע בהגברת שריפת השומן ולשיפור
                  איכות החיים לאורך זמן.
                </Typography>
              </StyledPaper>
            </Parallax>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
