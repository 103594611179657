"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProdEnvs = exports.ReplEnvs = exports.DevEnvs = exports.NodeEnv = void 0;
var NodeEnv;
(function (NodeEnv) {
    NodeEnv["Development"] = "development";
    NodeEnv["ReplDevelopment"] = "repl-development";
    NodeEnv["Production"] = "production";
    NodeEnv["ReplProduction"] = "repl-production";
})(NodeEnv || (exports.NodeEnv = NodeEnv = {}));
exports.DevEnvs = [NodeEnv.Development, NodeEnv.ReplDevelopment];
exports.ReplEnvs = [NodeEnv.ReplDevelopment, NodeEnv.ReplProduction];
exports.ProdEnvs = [NodeEnv.Production, NodeEnv.ReplProduction];
