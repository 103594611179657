import {
  faBalanceScale,
  faBurger,
  faChartLine,
  faGlassWater,
  faLeaf,
  faPlateUtensils,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HERO_IMAGE =
  "https://images.pexels.com/photos/2983101/pexels-photo-2983101.jpeg?w=1260&auto=compress";
const BALANCE_IMAGE =
  "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=1260&auto=compress";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const SMART_CHOICES = [
  {
    id: "portions",
    title: "בחירה חכמה של מנות",
    icon: faPlateUtensils,
    color: "#FF6B6B",
    items: [
      "בחרו מנות עם חלבונים רזים וירקות כדי להרגיש שבעים בלי להעמיס קלוריות",
      "העדיפו מנות בגודל בינוני או קטן כדי לשלוט בכמות האוכל (למרות שזה ״פחות משתלם״ כלכלית)",
    ],
  },
  {
    id: "fried",
    title: "צמצום מזון מטוגן",
    icon: faBurger,
    color: "#4ECDC4",
    items: [
      "אם אפשר, העדיפו עוף בגריל במקום מטוגן, או צ'יפס אפוי במקום מטוגן",
      "פחות שמן אומר יותר אנרגיה בלי תחושת כבדות או צרבת",
    ],
  },
  {
    id: "drinks",
    title: "שתייה חכמה",
    icon: faGlassWater,
    color: "#45B7D1",
    items: [
      "שתייה ממותקת מוסיפה הרבה סוכר מיותר. נסו להחליף אותה במים או במשקה ללא סוכר",
    ],
  },
  {
    id: "balance",
    title: "איזון בין הארוחות",
    icon: faBalanceScale,
    color: "#96CEB4",
    items: [
      "אם אכלתם מזון מהיר בצהריים, דאגו ששאר הארוחות שלכם יהיו מאוזנות ועשירות בירקות, פירות ודגנים מלאים",
    ],
  },
  {
    id: "portion-size",
    title: "שימו לב לגודל המנה",
    icon: faWeightScale,
    color: "#FFB347",
    items: [
      "המנות המוגשות בדרך כלל גדולות יותר ממה שהגוף באמת צריך",
      "ניתן לחלוק מנות גדולות עם חברים או להזמין מנה קטנה יותר – גם אם זה נראה פחות משתלם כלכלית, זה יותר משתלם לבריאות שלכם",
    ],
  },
];

const PRACTICAL_TIPS = [
  {
    title: "תכננו מראש",
    description:
      "אם אתם יודעים שתאכלו מזון מהיר בערב, הקפידו שהארוחות האחרות שלכם יהיו קלות ומאוזנות",
    icon: faChartLine,
  },
  {
    title: "דעו מה אתם אוכלים",
    description:
      "שימו לב למידע התזונתי שמופיע בתפריטים או על האריזות כדי לבחור נכון",
    icon: faLeaf,
  },
  {
    title: "הכינו בבית גרסאות בריאות יותר",
    description:
      "המבורגר ביתי, פיצה או נאגטס שתכינו בעצמכם יכולים להיות טעימים לא פחות, ועם הרבה פחות שומן, מלח וסוכר",
    icon: faPlateUtensils,
  },
];

export const HealthyFastFoodBalance = () => {
  useMeta();

  const theme = useTheme();
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                מזון מהיר - איך ליהנות ממנו מבלי לפגוע בבריאות?
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                מזון מהיר הוא חלק בלתי נפרד מהחיים של בני נוער. בין אם זה בילוי
                עם חברים אחרי בית הספר, הפסקה מהלימודים או פינוק מהיר ביום עמוס
                – קל להבין למה הוא כל כך פופולרי. עם זאת, צריכה מופרזת של מזון
                מהיר יכולה להוביל לעלייה במשקל ולפגוע בבריאות. החדשות הטובות הן
                שאפשר ליהנות ממנו מבלי לפגוע בגוף – אם עושים את זה בחוכמה.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Why Fast Food is Problematic */}
          <Box sx={{ my: 6 }}>
            <Grid container spacing={4}>
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8 }}
                >
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontWeight: 600, mb: 3 }}
                  >
                    למה מזון מהיר נחשב בעייתי?
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                  >
                    מזון מהיר מכיל לרוב הרבה שומן רווי, סוכר ומלח, אבל כמעט ואין
                    בו רכיבים תזונתיים חשובים ואבות מבון. מחקר שפורסם ב-Journal
                    of Nutrition הראה שצריכה מוגזמת של מזון מעובד עלולה לגרום
                    לעלייה במשקל ולבעיות בריאות כמו סוכרת ומחלות לב. בנוסף, גודל
                    המנות והמשקאות הממותקים שנלווים אליהן מקשים על שמירה על
                    איזון קלורי.
                  </Typography>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={BALANCE_IMAGE}
                  sx={{
                    width: "100%",
                    height: "300px",
                    objectFit: "cover",
                    borderRadius: 2,
                  }}
                />
              </Grid>
            </Grid>
          </Box>

          {/* Smart Choices */}
          <Box sx={{ my: 6 }} ref={ref}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
            >
              איך ליהנות ממזון מהיר בצורה מאוזנת?
            </Typography>
            <Grid container spacing={3}>
              {SMART_CHOICES.map((choice, index) => (
                <Grid key={choice.id} size={{ xs: 12, sm: 6, md: 4 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 50 }}
                    animate={inView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <InfoCard>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={choice.icon}
                          style={{
                            color: choice.color,
                            fontSize: "2rem",
                            marginLeft: "1rem",
                          }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {choice.title}
                        </Typography>
                      </Box>
                      {choice.items.map((item, idx) => (
                        <Typography
                          key={idx}
                          variant="body2"
                          sx={{ mb: 1, fontSize: "1rem" }}
                        >
                          • {item}
                        </Typography>
                      ))}
                    </InfoCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Why Balance is Important */}
          <Box sx={{ my: 6 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
            >
              למה חשוב לשמור על איזון?
            </Typography>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={inView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.6 }}
            >
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                לאכול מזון מהיר מדי פעם זה לגמרי בסדר. המפתח הוא לא להפוך את זה
                להרגל יומיומי. מחקרים מראים שתזונה מגוונת ומאוזנת, שמכילה את כל
                אבות המזון, היא הדרך הכי טובה לשמור על גוף בריא בלי לוותר על
                ההנאות הקטנות.
              </Typography>
            </motion.div>
          </Box>

          {/* Practical Tips */}
          <Box sx={{ my: 6 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4, textAlign: "center" }}
            >
              טיפים פרקטיים לשילוב נכון של מזון מהיר
            </Typography>
            <Grid container spacing={4}>
              {PRACTICAL_TIPS.map((tip, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={inView ? { opacity: 1, scale: 1 } : {}}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                  >
                    <StyledPaper>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={tip.icon}
                          style={{
                            color: theme.palette.primary.main,
                            fontSize: "2rem",
                            marginLeft: "1rem",
                          }}
                        />
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                          {tip.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1">{tip.description}</Typography>
                    </StyledPaper>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Summary */}
          <Parallax translateY={[20, -20]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3} sx={{ mt: 6 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                מזון מהיר הוא לא אויב – כל עוד יודעים איך לשלב אותו בצורה חכמה
                ומאוזנת. בחירות נכונות כמו מנות קטנות יותר, פחות מזון מטוגן
                ושתייה ללא סוכר עוזרות לשמור על המשקל והבריאות. ואם תקפידו
                שהארוחות האחרות שלכם יהיו מזינות ומגוונות, תוכלו ליהנות מהמזון
                המהיר בלי רגשות אשם.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                לצד זאת, ליווי מקצועי על ידי דיאטנית מוסמכת יכול לסייע לכם למצוא
                את האיזון הנכון בין הנאה מאוכל לבין שמירה על בריאות ומשקל תקין.
                דיאטנית יכולה להעניק לכם כלים מעשיים לניהול תזונה מאוזנת גם
                כשאוכלים מחוץ לבית ולסייע לכם להבין איך לשלב מזון מהיר כחלק
                מאורח חיים בריא, בלי לוותר על ההנאות הקטנות. אז בפעם הבאה שאתם
                יוצאים עם חברים – תיהנו מהארוחה, אבל תזכרו שגם הגוף שלכם חשוב.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
