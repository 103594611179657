import {
  faBalanceScale,
  faBrain,
  faBullseye,
  faChartLine,
  faHeartbeat,
  faLightbulb,
  faStar,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/6567607/pexels-photo-6567607.jpeg?auto=compress&cs=tinysrgb&w=1260",
  realistic:
    "https://images.pexels.com/photos/5759235/pexels-photo-5759235.jpeg?auto=compress&cs=tinysrgb&w=640",
};

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "60vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const motivationPoints = [
  {
    title: "הגדרת מטרות ברורות",
    content:
      "מחקרים פסיכולוגיים מראים כי הגדרת מטרות ברורות עשויה לשפר את המוטיבציה והנחישות. נשים רבות מדווחות כי צפייה בבגד המטרה מעודדת אותן לבחור מזון בריא ולהתמיד באימונים.",
    icon: faBullseye,
    color: "#FF6B6B",
  },
  {
    title: "תזכורת מוחשית",
    content:
      "בגד מטרה עשוי לשמש תזכורת מוחשית ליעד הרצוי, מה שמסייע להתמיד בתהליך הירידה במשקל ובשמירה על אורח חיים בריא.",
    icon: faLightbulb,
    color: "#4ECDC4",
  },
  {
    title: "תחושת הישג",
    content:
      "הרעיון של חזרה לבגדים מהעבר יכול להעניק תחושת הישג וסיפוק כאשר היעד מושג, במיוחד בתקופה שלאחר הלידה המלווה בעייפות ובאתגרים פיזיים ורגשיים.",
    icon: faStar,
    color: "#45B7D1",
  },
  {
    title: "מוטיבציה מתמשכת",
    content:
      "חשוב לזכור שמוטיבציה היא לרוב רק גורם להתנעה ראשונית של התהליך. לכן, מומלץ לבחור מטרה שהיא לא רק מעוררת השראה אלא גם ריאלית ומדורגת.",
    icon: faChartLine,
    color: "#96CEB4",
  },
];

const emotionalChallenges = [
  {
    title: "לחץ פסיכולוגי",
    content:
      "בגד מטרה עלול ליצור לחץ פסיכולוגי ותחושת כישלון, במיוחד כאשר ההתקדמות איטית או שאינה עומדת בציפיות.",
    icon: faBrain,
    color: "#FF6B6B",
  },
  {
    title: "שינויי גוף טבעיים",
    content:
      "הגוף עובר שינויים טבעיים שאינם תמיד ניתנים לשליטה, ולעיתים גם אם מושגת המטרה המשקלית, הבגד עשוי להיראות ולהרגיש אחרת בשל שינויי מבנה הגוף.",
    icon: faChartLine,
    color: "#4ECDC4",
  },
  {
    title: "דימוי עצמי",
    content:
      "שאיפה לסטנדרטים לא מציאותיים עלולה לפגוע בהערכה העצמית ולהוביל לתסכול מתמשך, במיוחד כאשר ההתקדמות איטית.",
    icon: faHeartbeat,
    color: "#45B7D1",
  },
  {
    title: "אכילה רגשית",
    content:
      "תחושות תסכול וכישלון עלולות לעורר מעגל של אכילה רגשית, שמקשה עוד יותר על השגת המטרה.",
    icon: faBalanceScale,
    color: "#96CEB4",
  },
  {
    title: "התעלמות מהישגים",
    content:
      "התמקדות בבגד מטרה בלבד עלולה להתעלם מהישגים משמעותיים אחרים, כמו הטמעת הרגלי אכילה בריאים ושיפור הכושר הגופני.",
    icon: faStar,
    color: "#FFB347",
  },
];

export const PostpartumTargetDress = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    בגד מטרה לאחר לידה
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    מנוע מוטיבציה או מעגל תסכול?
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <motion.div
                initial="hidden"
                animate="visible"
                variants={fadeInUpVariants}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    לאחר הלידה, רבות מהנשים מוצאות עצמן שואפות לחזור למשקל
                    ולמראה שהיו להן לפני ההיריון. אחת השיטות הפופולריות להנעת
                    תהליך זה היא הצבת "בגד מטרה" – פריט לבוש שמטרתו לסמל את היעד
                    הרצוי. נשאלת השאלה האם בגד זה אכן מגביר מוטיבציה או שמא מציב
                    יעד לא ריאלי אשר עלול להוביל לתחושות תסכול ואכזבה.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    לצד התקופה המאתגרת - אשר מאופיינת בעומס רב, ומחסור בשעות
                    שינה, מטרה רחוקה ואולי לא ריאלית שעלולה להשפיע על המצב הנפשי
                    וההתנהגותי יכולה להוביל לתזונה לא מאוזנת, תפריט דל בקלוריות
                    ומקרים של אכילה מופרזת המלווה בתחושות אשמה קשות.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Grid>

            {/* Motivation Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                בגד מטרה כמקור למוטיבציה
              </Typography>
              <Grid container spacing={3}>
                {motivationPoints.map((point, index) => (
                  <Grid key={point.title} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${point.color}20`,
                              p: 2,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={point.icon}
                              style={{ fontSize: 32, color: point.color }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: point.color, fontWeight: 600 }}
                            >
                              {point.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                            >
                              {point.content}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Emotional Challenges Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                האתגרים הרגשיים שבבגד מטרה
              </Typography>
              <Grid container spacing={3}>
                {emotionalChallenges.map((challenge, index) => (
                  <Grid key={challenge.title} size={{ xs: 12, sm: 4 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${challenge.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mb: 2,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={challenge.icon}
                              style={{ fontSize: 40, color: challenge.color }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ color: challenge.color, fontWeight: 600 }}
                          >
                            {challenge.title}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontSize: "1.1rem" }}
                          >
                            {challenge.content}
                          </Typography>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Realistic Goals Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid size={{ xs: 12, md: 6 }}>
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 4 }}
                      >
                        החשיבות בהצבת מטרות ריאליות
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        כדי למנוע מעגלי תסכול ולהצליח להתמיד בתהליך ובהטמעת
                        השינויים, מומלץ להציב מטרות הדרגתיות וריאליות. במקום
                        להתמקד בפריט לבוש אחד המסמל יעד רחוק, ניתן לבחור מספר
                        בגדים במידות שונות שמייצגים שלבים שונים בתהליך.
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        גישה זו מאפשרת לחגוג הישגים קטנים לאורך הדרך, מה שמגביר
                        את המוטיבציה ומעניק תחושת סיפוק. בנוסף, חשוב לזכור שהגוף
                        לאחר הלידה אינו זהה לגוף שלפניה, והצבת מטרות המתחשבות
                        בכך תורמת לשמירה על בריאות הנפש והגוף.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        כדאי לשקול גם הצבת מטרות שאינן משקליות, כמו שיפור הכושר
                        הגופני, תחושת אנרגיה גבוהה יותר או יכולת לבצע פעילות
                        פיזית בקלות. דרך נוספת להגביר את המוטיבציה היא להשתמש
                        בצנצנת חיזוקים אישית, שבה כותבים משפטי העצמה או מתעדים
                        רגעים שבהם התמודדת והתגברת על קשיים. כל פתק בצנצנת יכול
                        לשמש תזכורת להצלחות קטנות ולחיזוק התחושה שאת בתהליך
                        שמוביל לשינוי חיובי.
                      </Typography>
                    </StyledCard>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      component="img"
                      src={IMAGES.realistic}
                      alt="Setting realistic goals"
                      sx={{
                        width: "100%",
                        height: 400,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    סיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    בגד מטרה יכול לשמש כלי עוצמתי להנעת תהליך הירידה במשקל, אך
                    הוא טומן בחובו גם סיכון לתסכול אם היעד רחוק מדי או לא ריאלי.
                    תהליך החזרה למשקל תקין צריך להיות מלווה בסבלנות ובקבלה
                    עצמית, תוך הצבת מטרות הדרגתיות וחגיגת השינויים וההתקדמות
                    לאורך הדרך. גישה מאוזנת זו מאפשרת לשמור על מוטיבציה גבוהה
                    מבלי לפגוע בדימוי העצמי ובתחושת המסוגלות של כל אישה בתקופה
                    המאתגרת שלאחר הלידה.
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    חשוב לזכור כי תהליך בירידה במשקל והטמעת הרגלים אינו מתמקד רק
                    בחזרה למשקל, אלא גם בשיפור איכות החיים והבריאות הכללית,
                    המעניקים תחושת סיפוק והעצמה לטווח הארוך.
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    ליווי מקצועי של דיאטנית המבינה את המורכבות של התקופה – הן
                    בפן הרגשי והן בפן המעשי – יכול להוות עוגן חשוב, לספק תמיכה
                    מותאמת אישית ולעזור להימנע ממלכודות תסכול, תוך חיזוק
                    המוטיבציה ושמירה על בריאות הגוף והנפש.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
