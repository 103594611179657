import {
  faAppleWhole,
  faBowlRice,
  faHeartPulse,
  faPersonWalking,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import { withWrapper } from "app/hooks";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import { ParallaxBanner, ParallaxProvider } from "react-scroll-parallax";
import "./Home.scss";

export const Home = withWrapper(() => {
  useMeta();

  return (
    <ParallaxProvider>
      <div className="landing-page">
        <ParallaxBanner
          layers={[
            {
              image:
                "https://images.unsplash.com/photo-1490645935967-10de6ba17061?q=80&w=1260",
              speed: -20,
            },
          ]}
          className="parallax-banner"
          style={{ height: "50vh" }}
        >
          <div className="banner-content">
            <Typography variant="h1" className="text-center banner-title">
              תזונה נכונה בגיל המעבר
            </Typography>
            <Typography variant="h4" className="text-center banner-subtitle">
              הדרך שלך לאיזון, בריאות ואיכות חיים
            </Typography>

            <ArticlesButton />
          </div>
        </ParallaxBanner>

        <Container>
          <Grid container spacing={4} className="mt-8">
            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="feature-box">
                <FontAwesomeIcon icon={faHeartPulse} className="feature-icon" />
                <Typography variant="h5" className="mb-3">
                  איזון הורמונלי
                </Typography>
                <Typography>
                  תזונה מותאמת אישית לאיזון הורמונלי, המסייעת בהפחתת תסמיני גיל
                  המעבר ותורמת לבריאות העצם, הלב וכלי הדם.
                </Typography>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="feature-box">
                <FontAwesomeIcon icon={faAppleWhole} className="feature-icon" />
                <Typography variant="h5" className="mb-3">
                  תזונה מותאמת אישית
                </Typography>
                <Typography>
                  תפריט עשיר בסידן, ויטמין D, חלבון איכותי ונוגדי חמצון, המותאם
                  במיוחד לצרכים המשתנים של גופך.
                </Typography>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="feature-box">
                <FontAwesomeIcon icon={faBowlRice} className="feature-icon" />
                <Typography variant="h5" className="mb-3">
                  מתכונים בריאים
                </Typography>
                <Typography>
                  מגוון מתכונים טעימים ובריאים, עשירים ברכיבים תזונתיים חיוניים
                  התומכים בבריאות האישה בגיל המעבר.
                </Typography>
              </Box>
            </Grid>

            <Grid size={{ xs: 12, md: 6 }}>
              <Box className="feature-box">
                <FontAwesomeIcon
                  icon={faPersonWalking}
                  className="feature-icon"
                />
                <Typography variant="h5" className="mb-3">
                  אורח חיים בריא
                </Typography>
                <Typography>
                  טיפים והמלצות לשילוב פעילות גופנית, ניהול מתח וטיפוח איכות
                  החיים בתקופת המעבר.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <ContactButtons />
        </Container>
      </div>
    </ParallaxProvider>
  );
});
