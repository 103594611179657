import { Article } from "modules/core";

export const maternityArticles: Article[] = [
  {
    path: "/maternity/articles/postpartum-mind-nutrition",
    image:
      "https://images.pexels.com/photos/3875225/pexels-photo-3875225.jpeg?auto=compress&w=350",
  },
  {
    path: "/maternity/articles/breastfeeding-nutrition",
    image:
      "https://images.pexels.com/photos/3875276/pexels-photo-3875276.jpeg?auto=compress&w=350",
  },
  {
    path: "/maternity/articles/first-1000-days-nutrition",
    image:
      "https://images.unsplash.com/photo-1623227866882-c005c26dfe41?auto=format&fit=crop&w=350",
  },
  {
    path: "/maternity/articles/postpartum-weight-loss-nutrition",
    image:
      "https://images.pexels.com/photos/3270224/pexels-photo-3270224.jpeg?auto=compress&w=350",
  },
  {
    path: "/maternity/articles/postpartum-target-dress",
    image:
      "https://images.pexels.com/photos/6567607/pexels-photo-6567607.jpeg?auto=compress&w=350",
  },
];
