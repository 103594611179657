import { faBowlRice, faDumbbell } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/4498151/pexels-photo-4498151.jpeg?auto=compress&w=1260",
  nutrition:
    "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&w=640",
  recovery:
    "https://images.pexels.com/photos/3823207/pexels-photo-3823207.jpeg?auto=compress&w=640",
  performance:
    "https://images.pexels.com/photos/3822583/pexels-photo-3822583.jpeg?auto=compress&w=640",
  prevention:
    "https://images.pexels.com/photos/4498604/pexels-photo-4498604.jpeg?auto=compress&w=640",
};

const performanceData = [
  { name: "סיבולת", value: 35, color: "#FF6B6B" },
  { name: "כוח", value: 25, color: "#4ECDC4" },
  { name: "מהירות", value: 20, color: "#45B7D1" },
  { name: "התאוששות", value: 20, color: "#96CEB4" },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const SportsNutritionImpact = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    השפעת התזונה על הישגים ספורטיביים
                  </Typography>
                </Zoom>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      מבוא
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      תזונה מאוזנת היא מרכיב חיוני בהשגת ביצועים ספורטיביים
                      מיטביים, שכן היא משפיעה ישירות על סיבולת, כוח ומהירות, כמו
                      גם על התאוששות מהירה ומניעת פציעות. במאמר זה נבחן את
                      ההשפעות המרכזיות של תזונה על הישגים ספורטיביים ואת תפקידי
                      החלבון והפחמימות בשמירה על ביצועים אופטימליים.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.nutrition}
                  alt="Balanced nutrition for athletes"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Performance Impact Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                תזונה וביצועים: איך תזונה נכונה משפיעה על סיבולת, כוח ומהירות
              </Typography>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        השפעה על סיבולת, כוח ומהירות
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        לתזונה השפעה ישירה על יכולות הסיבולת, הכוח והמהירות של
                        המתאמן. פחמימות הן מקור האנרגיה העיקרי לפעילות גופנית
                        אינטנסיבית ומתמשכת, והן מסייעות בשמירה על רמות גלוקוז
                        יציבות בדם. חלבונים תורמים לבניית מסת שריר ולשמירה על
                        כוח השרירים במהלך האימונים.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        מחקרים מראים כי תזונה עשירה בפחמימות מורכבות כמו דגנים
                        מלאים, אורז חום וקינואה משפרת את ביצועי הסיבולת בכך שהיא
                        מספקת אנרגיה מתמשכת. באימוני כוח ומהירות, צריכת חלבון
                        בכמות מספקת מחזקת את השרירים ותורמת לשיפור ביצועי
                        השרירים. כמו כן, שומנים בריאים מאגוזים, אבוקדו ושמן זית
                        תורמים לשמירה על מאגרי אנרגיה ארוכי טווח.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box sx={{ height: 400 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart>
                        <Pie
                          data={performanceData}
                          cx="50%"
                          cy="50%"
                          innerRadius={60}
                          outerRadius={120}
                          paddingAngle={5}
                          dataKey="value"
                        >
                          {performanceData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={entry.color}
                              stroke={entry.color}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        gap: 2,
                        mt: 2,
                      }}
                    >
                      {performanceData.map((item) => (
                        <Box
                          key={item.name}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: 16,
                              height: 16,
                              backgroundColor: item.color,
                              borderRadius: "50%",
                            }}
                          />
                          <Typography>{item.name}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {/* Recovery Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.recovery}
                    alt="Recovery and nutrition"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        הקשר בין תזונה להתאוששות מהירה יותר בין אימונים
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        התאוששות מהירה בין אימונים היא קריטית לשיפור ההישגים
                        ולמניעת עייפות מצטברת. חלבון הוא גורם מפתח בשיקום רקמות
                        השריר שנפגעות במהלך האימון, בעוד שהפחמימות ממלאות מחדש
                        את מאגרי הגליקוגן בשרירים. מחקרים הראו כי צריכת חלבון
                        ופחמימות בפרק זמן של עד שעתיים לאחר האימון מייעלת את
                        תהליך ההתאוששות.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        שילוב של חלבון מהצומח כמו טופו, עדשים וקטניות עם פחמימות
                        מורכבות משפר את ספיגת החומרים המזינים ומאיץ את תהליך
                        התיקון של רקמות השריר. כמו כן, נוגדי חמצון מפירות וירקות
                        עוזרים להפחית דלקתיות ומזרזים את ההחלמה.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Deficiency Impact Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                השפעת מחסור בחלבון או פחמימות על ביצועים ספורטיביים
              </Typography>
              <Grid container spacing={4}>
                {[
                  {
                    icon: faBowlRice,
                    title: "מחסור בפחמימות",
                    description:
                      "מחסור בפחמימות מוביל לעייפות מוקדמת ולירידה בסיבולת בשל חוסר באנרגיה זמינה. כאשר הגוף אינו מקבל מספיק פחמימות, הוא מתחיל לפרק שומנים וחלבונים כדי להפיק אנרגיה, דבר שעלול להוביל לפירוק רקמות שריר.",
                    color: "#FF6B6B",
                  },
                  {
                    icon: faDumbbell,
                    title: "מחסור בחלבון",
                    description:
                      "מחסור בחלבון פוגע ביכולת השרירים להתאושש ולהתחזק, מה שמגביל את השיפור בכוח ובמהירות. בנוסף, צריכת חלבון בלתי מספקת עלולה לגרום לאובדן מסת שריר ולהחלשת מערכת החיסון, מה שמגביר את הסיכון לפציעות וזיהומים.",
                    color: "#4ECDC4",
                  },
                ].map((item, index) => (
                  <Grid key={item.title} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ duration: 0.6, delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 3,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${item.color}20`,
                              p: 2,
                              borderRadius: "50%",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              style={{
                                fontSize: 32,
                                color: item.color,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            color="primary"
                            sx={{ fontWeight: 600 }}
                          >
                            {item.title}
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                        >
                          {item.description}
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Injury Prevention Section */}
            <Box sx={{ my: 8 }}>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3 }}
                      >
                        תזונה כגורם מונע לפציעות ספורט
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        תזונה נכונה מסייעת במניעת פציעות ספורט על ידי חיזוק
                        השרירים, העצמות והרקמות הרכות. סידן וויטמין D חיוניים
                        לשמירה על צפיפות העצם ומניעת שברים. חלבון תורם לתחזוקה
                        ולתיקון של רקמות השריר והרצועות, בעוד שנוגדי חמצון
                        מפחיתים דלקת ומגנים על הגוף מפני נזקי חמצון הנגרמים
                        במהלך אימונים אינטנסיביים.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        מחקרים מצביעים על כך שצריכת מזון עשיר בברזל משפרת את
                        אספקת החמצן לשרירים ומפחיתה עייפות, בעוד שאומגה-3 מדגים
                        השפעות אנטי-דלקתיות שמסייעות במניעת פציעות חוזרות.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.prevention}
                    alt="Injury prevention through nutrition"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    סיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    תזונה נכונה משפיעה באופן משמעותי על סיבולת, כוח ומהירות, כמו
                    גם על התאוששות מהירה ומניעת פציעות ספורט. פחמימות מספקות
                    אנרגיה זמינה לסיבולת וכוח, בעוד שחלבון חיוני לבניית ושיקום
                    השרירים. מחסור באחד מאבות המזון הללו עלול לפגוע בביצועים
                    ובבריאות הכללית. תזונה מאוזנת, עשירה בוויטמינים, מינרלים
                    ונוגדי חמצון, תורמת לא רק לשיפור ההישגים אלא גם לשמירה על
                    בריאות מיטבית לאורך זמן. לכן, חיוני לתכנן אותה בצורה מושכלת,
                    תוך התאמה לצרכים האישיים של כל מתאמן, ובליווי תזונתי מקצועי
                    שיתמוך בשגרת האימונים ובשיפור הביצועים.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
