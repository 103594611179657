import {
  faBottleDroplet,
  faBrain,
  faDumbbell,
  faHeartPulse,
  faLeaf,
  faPersonRunning,
  faShieldHeart,
  faStopwatch,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "60vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const SupplementCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/4397833/pexels-photo-4397833.jpeg?auto=compress&w=1260",
  protein:
    "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&w=640",
  creatine:
    "https://images.pexels.com/photos/4397840/pexels-photo-4397840.jpeg?auto=compress&w=640",
  bcaa: "https://images.pexels.com/photos/3822363/pexels-photo-3822363.jpeg?auto=compress&w=640",
  vitamins:
    "https://images.pexels.com/photos/4397835/pexels-photo-4397835.jpeg?auto=compress&w=640",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const AthleteSupplements = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    תוספי תזונה לספורטאים
                  </Typography>
                </Zoom>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg">
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 8 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      העלייה בצריכת תוספי תזונה בשנים האחרונות נובעת מהמודעות
                      הגוברת לחשיבותם בשיפור ביצועים ספורטיביים, התאוששות מהירה
                      ובניית שריר. חלבון זוכה לתשומת לב רבה בשל תפקידו המרכזי
                      בתהליכי שיקום השריר, והעלייה במגוון מוצרים עשירים בחלבון
                      נובעת מהצורך לספק מענה נוח למתאמנים בכל הרמות. בנוסף,
                      השילוב של תוספי תזונה מאפשר התאמה אישית לפי מטרות האימון
                      ודרישות הגוף, מה שתורם לא רק לשיפור הביצועים אלא גם לשמירה
                      על בריאות כללית לאורך זמן.
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      בשנים האחרונות אנו עדים לעלייה בצריכת מוצרים עשירים
                      בחלבון, כגון חטיפים, משקאות ויוגורטים. בקרב ספורטאים
                      ומתאמנים חלבון תופס מקום מרכזי בתהליך זה בשל תפקידו בבניית
                      ושיקום רקמות השריר, שכן הם מספקים מענה נוח ויעיל למתאמנים
                      ולספורטאים המעוניינים לשפר את הביצועים הפיזיים ואת תהליך
                      ההתאוששות לאחר אימונים אינטנסיביים.
                      <br />
                      במאמר זה אציג את היתרונות והחסרונות של התוספים השונים ואת
                      השפעתם על תפקוד הגוף והביצועים הספורטיביים.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 4 }}>
                <Box
                  component="img"
                  src={IMAGES.protein}
                  alt="Protein supplements"
                  sx={{
                    width: "100%",
                    height: 300,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Protein Comparison Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                חלבון מי גבינה מול חלבון מהצומח
              </Typography>
              <Grid container spacing={4}>
                {/* Whey Protein Card */}
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "visible",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: -30,
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: "50%",
                          width: 60,
                          height: 60,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faBottleDroplet}
                          style={{ fontSize: 24, color: "white" }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        align="center"
                        sx={{ mt: 4, mb: 3, fontWeight: 700 }}
                      >
                        חלבון מי גבינה
                      </Typography>
                      <Typography variant="body1" paragraph>
                        חלבון הוא מרכיב חיוני בבניה ושיקום רקמות השריר. חלבון מי
                        גבינה נחשב לאחד התוספים הפופולריים ביותר בשל תכולת
                        חומצות האמינו המלאה והספיגה המהירה שלו.
                      </Typography>

                      <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ mb: 4 }}>
                          <Typography
                            variant="h6"
                            color="primary"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <FontAwesomeIcon icon={faShieldHeart} /> יתרונות
                          </Typography>
                          <Typography variant="body1" paragraph>
                            עשיר בלאוצין – חומצת אמינו חיונית המעודדת סינתזת
                            חלבון בשריר, מסייע בהתאוששות מהירה ומעודד עלייה במסת
                            השריר.
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            color="error"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <FontAwesomeIcon icon={faHeartPulse} /> חסרונות
                          </Typography>
                          <Typography variant="body1">
                            אינו מתאים לטבעונים ולסובלים מאי סבילות ללקטוז.
                          </Typography>
                        </Box>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Plant Protein Card */}
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    <StyledCard
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "visible",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: -30,
                          left: "50%",
                          transform: "translateX(-50%)",
                          backgroundColor: theme.palette.success.main,
                          borderRadius: "50%",
                          width: 60,
                          height: 60,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faLeaf}
                          style={{ fontSize: 24, color: "white" }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        align="center"
                        sx={{ mt: 4, mb: 3, fontWeight: 700 }}
                      >
                        חלבון מהצומח
                      </Typography>
                      <Typography variant="body1" paragraph>
                        מנגד, חלבון מהצומח (כמו אפונה, סויה ואורז) מציע
                        אלטרנטיבה ידידותית לסביבה ומתאימה לאורח חיים טבעוני.
                      </Typography>
                      <Box sx={{ flexGrow: 1 }}>
                        <Box sx={{ mb: 4 }}>
                          <Typography
                            variant="h6"
                            color="primary"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <FontAwesomeIcon icon={faShieldHeart} /> יתרונות
                          </Typography>
                          <Typography variant="body1" paragraph>
                            עשיר בסיבים תזונתיים ונוגדי חמצון, תורם לבריאות
                            מערכת העיכול ומסייע בבניית שריר כאשר נצרך בכמות
                            מספקת.
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            variant="h6"
                            color="error"
                            gutterBottom
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <FontAwesomeIcon icon={faHeartPulse} /> חסרונות
                          </Typography>
                          <Typography variant="body1">
                            לרוב מכיל כמות נמוכה יותר של לאוצין ונדרשת כמות
                            גדולה יותר כדי להגיע לאפקט זהה לזה של חלבון מי
                            גבינה.
                          </Typography>
                        </Box>
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* Creatine Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                קריאטין: שיפור כוח ומסת שריר – מיתוסים ומציאות
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${theme.palette.primary.main}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faDumbbell}
                            style={{
                              fontSize: 32,
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          color="primary"
                          sx={{ fontWeight: 700 }}
                        >
                          מהו קריאטין?
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        קריאטין הוא אחד התוספים הנחקרים ביותר בעולם הספורט ונמצא
                        יעיל במיוחד בשיפור כוח ומסת שריר. הוא מתאים במיוחד
                        למתאמנים בענפי כוח, ספורטאים העוסקים באימונים קצרים
                        ועצימים,
                        <br />
                        קריאטין הינה תרכובת טבעית המיוצרת בגוף ונמצאת בעיקר
                        בשרירי השלד. הוא ממלא תפקיד מרכזי בייצור אנרגיה במהלך
                        מאמצים קצרים ועצימים. במהלך פעילות אנאירובית, הקריאטין
                        מאוחסן בשרירים בצורת קריאטין פוספט (Phosphocreatine)
                        ומשמש לייצור מהיר של ATP – מקור האנרגיה העיקרי של התאים.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.creatine}
                    alt="Creatine supplements"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
              </Grid>

              {/* Creatine Benefits */}
              <Grid container spacing={4} sx={{ mt: 4 }}>
                {[
                  {
                    icon: faWeightScale,
                    title: "הגדלת מסת שריר",
                    description:
                      "קריאטין מגדיל את תכולת המים בתאי השריר, מה שתורם לנפח שריר גדול יותר ולמראה מוצק. בנוסף, הוא מעודד סינתזת חלבון ומגביר את קצב בניית השריר, מה שמוביל להגדלת מסת שריר רזה לאורך זמן.",
                  },
                  {
                    icon: faPersonRunning,
                    title: "שיפור ביצועים",
                    description:
                      "קריאטין מסייע לשפר ביצועים במאמצים קצרים ועצימים כמו הרמת משקולות, ספרינטים ואימונים אנאירוביים. הוא מאפשר לבצע מספר חזרות גבוה יותר או להרים משקל כבד יותר לפני הופעת עייפות. בנוסף, הוא מאיץ את ההתאוששות בין סטים ומפחית את תחושת הכאב השרירי לאחר אימונים אינטנסיביים.",
                  },
                  {
                    icon: faStopwatch,
                    title: "המלצות שימוש",
                    description:
                      "קריאטין מומלץ לצרוך בעיקר לאחר אימון כדי למקסם את ספיגתו בשרירים, אך ניתן גם לצרוך אותו בכל זמן ביום כל עוד נשמרת כמות יומית קבועה. בנוסף, ישנם ספורטאים המעדיפים לצרוך קריאטין לפני אימון לתחושת כוח מוגברת.",
                  },
                ].map((benefit, index) => (
                  <Grid key={benefit.title} size={{ xs: 12, md: 4 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <SupplementCard>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${theme.palette.primary.main}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={benefit.icon}
                              style={{
                                fontSize: 40,
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            {benefit.title}
                          </Typography>
                          <Typography variant="body1">
                            {benefit.description}
                          </Typography>
                        </Box>
                      </SupplementCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Beta-Alanine and Caffeine Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 2, fontWeight: 700 }}
              >
                בטא-אלנין וקפאין: חיזוק הביצועים האנאירוביים
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8, mb: 4 }}
              >
                בטא-אלנין וקפאין הם תוספים נפוצים לשיפור ביצועים באימונים
                אינטנסיביים וקצרי טווח.
              </Typography>
              <Grid container spacing={4}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${theme.palette.primary.main}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPersonRunning}
                            style={{
                              fontSize: 32,
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          color="primary"
                          sx={{ fontWeight: 700 }}
                        >
                          בטא-אלנין
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        בטא-אלנין מגביר את רמות הקרנוזין בשרירים, מה שמפחית את
                        הצטברות החומצה הלקטית ומעכב עייפות בזמן מאמץ אנאירובי.
                        מומלץ לצרוך 3-6 גרם ביום.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${theme.palette.primary.main}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBrain}
                            style={{
                              fontSize: 32,
                              color: theme.palette.primary.main,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          color="primary"
                          sx={{ fontWeight: 700 }}
                        >
                          קפאין
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        קפאין משפר את הריכוז, מפחית תחושת מאמץ ומשפר ביצועים
                        בענפי ספורט שונים. המינון האפקטיבי נע בין 3-6 מ"ג לכל
                        ק"ג משקל גוף.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>
            </Box>

            {/* BCAA Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                תוספי BCAA – האם הם באמת תורמים להתאוששות?
              </Typography>
              <Grid container spacing={4} alignItems="center">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box
                    component="img"
                    src={IMAGES.bcaa}
                    alt="BCAA supplements"
                    sx={{
                      width: "100%",
                      height: 400,
                      objectFit: "cover",
                      borderRadius: 4,
                      boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                    transition={{ duration: 0.6 }}
                  >
                    <StyledCard>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        BCAA (חומצות אמינו מסועפות שרשרת) כוללות לאוצין,
                        איזולאוצין וולין, המהוות רכיב חשוב בהתאוששות השרירים
                        ובמניעת פירוק רקמות שריר לאחר מאמץ גופני. הן נבדלות משאר
                        חומצות האמינו בכך שהן מתפרקות ישירות בשריר ולא בכבד, מה
                        שמאפשר אספקת אנרגיה מהירה יותר במהלך האימון ולאחריו.
                      </Typography>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>

              {/* BCAA Benefits and Limitations */}
              <Grid container spacing={4} sx={{ mt: 4 }}>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                  >
                    <StyledCard>
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3, fontWeight: 600 }}
                      >
                        יתרונות
                      </Typography>
                      <Box component="ul" sx={{ pl: 2 }}>
                        {[
                          "מסייעות בהפחתת כאבי שרירים לאחר אימון ומאיצות את תהליך ההתאוששות.",
                          "תורמות למניעת פירוק שרירים בזמן אימונים ארוכים ומאומצים.",
                          "עשויות לשפר את סיבולת השרירים ולהפחית תחושת עייפות במהלך אימונים אינטנסיביים.",
                        ].map((benefit, index) => (
                          <Box
                            key={index}
                            component="li"
                            sx={{ mb: 2, fontSize: "1.1rem" }}
                          >
                            {benefit}
                          </Box>
                        ))}
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <Grid size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={fadeInUpVariants}
                  >
                    <StyledCard>
                      <Typography
                        variant="h5"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 3, fontWeight: 600 }}
                      >
                        מגבלות וחסרונות
                      </Typography>
                      <Box component="ul" sx={{ pl: 2 }}>
                        {[
                          "מחקרים עדכניים מצביעים על כך שצריכת BCAA בלבד אינה מספיקה ללא חלבון מלא, שכן חומצות אמינו נוספות נחוצות לסינתזת חלבון מיטבית.",
                          "מינון עודף עלול להוביל לאי-איזון בחומצות האמינו האחרות בגוף.",
                        ].map((limitation, index) => (
                          <Box
                            key={index}
                            component="li"
                            sx={{ mb: 2, fontSize: "1.1rem" }}
                          >
                            {limitation}
                          </Box>
                        ))}
                      </Box>
                    </StyledCard>
                  </motion.div>
                </Grid>
                <StyledCard>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="primary"
                    sx={{ mt: 4, mb: 2 }}
                  >
                    שימוש מומלץ
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                  >
                    מומלץ לצרוך 5-10 גרם BCAA לפני או במהלך האימון כדי להפחית
                    עייפות ולתמוך בהתאוששות. לאחר האימון, שילוב של BCAA עם מקור
                    חלבון מלא יכול לשפר את בניית השריר ולזרז את תהליך ההתאוששות.
                  </Typography>
                </StyledCard>
              </Grid>
            </Box>

            {/* Vitamins and Minerals Section */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                ויטמינים ומינרלים חיוניים לספורטאים
              </Typography>
              <Typography
                variant="body1"
                align="center"
                sx={{ fontSize: "1.2rem", lineHeight: 1.8, mb: 4 }}
              >
                ויטמינים ומינרלים ממלאים תפקידים מרכזיים בתפקוד השרירים ובמניעת
                עייפות:
              </Typography>
              <Grid container spacing={4}>
                {[
                  {
                    icon: faHeartPulse,
                    title: "ברזל",
                    description: "משפר את אספקת החמצן לשרירים ומונע עייפות.",
                  },
                  {
                    icon: faBrain,
                    title: "ויטמין D",
                    description: "חיוני לבריאות העצמות ולתפקוד השרירים.",
                  },
                  {
                    icon: faBottleDroplet,
                    title: "מגנזיום",
                    description:
                      "תומך בתפקוד העצבים והשרירים ומסייע במניעת התכווצויות.",
                  },
                  {
                    icon: faShieldHeart,
                    title: "סידן",
                    description: "חיוני לצפיפות העצם ולשמירה על חוזקה.",
                  },
                ].map((vitamin, index) => (
                  <Grid key={vitamin.title} size={{ xs: 12, sm: 6, md: 3 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <SupplementCard>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textAlign: "center",
                            gap: 2,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${theme.palette.primary.main}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={vitamin.icon}
                              style={{
                                fontSize: 40,
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            {vitamin.title}
                          </Typography>
                          <Typography variant="body1">
                            {vitamin.description}
                          </Typography>
                        </Box>
                      </SupplementCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    לסיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    תוספי תזונה מהווים כלי עזר חשוב לשיפור ביצועים ספורטיביים
                    ולהאצת תהליך ההתאוששות, אך הם אינם מהווים תחליף לתזונה נכונה
                    ומאוזנת. תזונה עשירה בירקות, פירות, חלבונים ושאר נוטריינטים
                    חיוניים היא הבסיס לבריאות מיטבית ולתפקוד גופני אופטימלי.
                    תוספי תזונה יכולים לסייע במיוחד למתאמנים מקצועיים או לאלו עם
                    צרכים תזונתיים מיוחדים, אך עבור רוב המתאמנים החובבים, תזונה
                    מאוזנת יכולה לספק מענה הולם לתמיכה בשיפור הביצועים, בניית
                    מסת שריר ושמירה על בריאות כללית. בחירת תוספים צריכה להיעשות
                    תוך התייעצות עם גורם מקצועי ובהתאם למטרות האימון והצרכים
                    האישיים.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
