import { Article } from "modules/core";

export const lifestyleArticles: Article[] = [
  {
    path: "/lifestyle/articles/target-dress-dilemma",
    image:
      "https://images.pexels.com/photos/5698851/pexels-photo-5698851.jpeg?auto=compress&w=350",
  },
  {
    path: "/lifestyle/articles/popular-diets-pros-cons",
    image:
      "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&w=350",
  },
  {
    path: "/lifestyle/articles/scientific-nutrition-benefits",
    image:
      "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&w=350",
  },
  {
    path: "/lifestyle/articles/hunger-types-guide",
    image:
      "https://images.pexels.com/photos/5638732/pexels-photo-5638732.jpeg?auto=compress&w=350",
  },
  {
    path: "/lifestyle/articles/everyday-diet-challenges-guide",
    image:
      "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&w=350",
  },
];
