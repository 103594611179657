import {
  faCircleExclamation,
  faFileCircleXmark,
  faScaleUnbalanced,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { motion } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const problems = [
  {
    icon: faCircleExclamation,
    text: "מתחילים שוב ושוב כל יום מחדש ושוברים את הכלים בכל ערב?",
    color: "#FF6B6B",
  },
  {
    icon: faScaleUnbalanced,
    text: "מוצאים את עצמכם שוב בין משטר נוקשה לאכילה חסרת שליטה?",
    color: "#4ECDC4",
  },
  {
    icon: faFileCircleXmark,
    text: "מיואשים מעוד תפריט שלא החזיק מעמד?",
    color: "#45B7D1",
  },
];

export const ProblemHeroSection = () => {
  const theme = useTheme();
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top center",
        end: "bottom center",
        scrub: 1,
      },
    });

    // Image animation
    tl.fromTo(
      imageRef.current,
      { scale: 1.2, opacity: 0.8, rotateY: -15 },
      { scale: 1, opacity: 1, rotateY: 0, duration: 1.5, ease: "power2.out" }
    );

    // Content animations
    gsap.fromTo(
      contentRef.current,
      { opacity: 0, y: 50 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        scrollTrigger: {
          trigger: contentRef.current,
          start: "top 80%",
          end: "bottom center",
          toggleActions: "play none none reverse",
        },
      }
    );

    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <Box
      ref={containerRef}
      sx={{
        minHeight: "90vh",
        display: "flex",
        alignItems: "center",
        background: `linear-gradient(135deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        position: "relative",
        pt: { xs: 4, md: 0 },
      }}
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={4}
          alignItems="center"
          sx={{ position: "relative", zIndex: 1 }}
        >
          {/* Left side - Image */}
          <Grid size={{ xs: 12, md: 6 }}>
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1, delay: 0.5 }}
            >
              <Box
                ref={imageRef}
                sx={{
                  position: "relative",
                  perspective: "1000px",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    left: -20,
                    right: -20,
                    bottom: -20,
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}20, ${theme.palette.primary.light}20)`,
                    borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
                    zIndex: -1,
                    animation: "morphing 15s ease-in-out infinite",
                  },
                  "@keyframes morphing": {
                    "0%": {
                      borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
                    },
                    "50%": {
                      borderRadius: "70% 30% 30% 70% / 70% 70% 30% 30%",
                    },
                    "100%": {
                      borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
                    },
                  },
                }}
              >
                <Box
                  component="img"
                  src="/main.jpeg"
                  alt="Nutrition Expert"
                  sx={{
                    width: "100%",
                    maxWidth: 500,
                    height: "auto",
                    borderRadius: "20px",
                    boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
                    transform: "perspective(1000px) rotateY(-5deg)",
                    transition: "all 0.5s ease-in-out",
                    filter: "brightness(1.02)",
                    "&:hover": {
                      transform:
                        "perspective(1000px) rotateY(0deg) scale(1.02)",
                      boxShadow: "0 30px 60px rgba(0,0,0,0.15)",
                      filter: "brightness(1.05)",
                    },
                  }}
                />
              </Box>
            </motion.div>
          </Grid>

          {/* Right side - Content */}
          <Grid size={{ xs: 12, md: 6 }}>
            <Box ref={contentRef} sx={{ p: { xs: 2, md: 4 } }}>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    fontWeight: 800,
                    mb: 4,
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    color: "transparent",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    textShadow: "0 2px 10px rgba(0,0,0,0.1)",
                  }}
                >
                  מצאת את עצמך במעגל של דיאטות שלא עובדות?
                </Typography>
              </motion.div>

              <Box sx={{ mt: 4 }}>
                {problems.map((problem, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: index * 0.2 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 3,
                        p: 2,
                        borderRadius: "15px",
                        backgroundColor: "rgba(255,255,255,0.8)",
                        boxShadow: "0 8px 32px rgba(0,0,0,0.05)",
                        backdropFilter: "blur(10px)",
                        border: "1px solid rgba(255,255,255,0.2)",
                        transition: "all 0.3s ease",
                        "&:hover": {
                          transform: "translateX(-10px)",
                          boxShadow: "0 12px 48px rgba(0,0,0,0.1)",
                          backgroundColor: "rgba(255,255,255,0.95)",
                          borderColor: `${problem.color}40`,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: `${problem.color}15`,
                          ml: 2,
                          transition: "all 0.3s ease",
                          "&:hover": {
                            backgroundColor: `${problem.color}25`,
                            transform: "scale(1.05)",
                          },
                        }}
                      >
                        <FontAwesomeIcon
                          icon={problem.icon}
                          style={{ color: problem.color, fontSize: "1.5rem" }}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.text.primary,
                          fontSize: { xs: "1rem", md: "1.1rem" },
                        }}
                      >
                        {problem.text}
                      </Typography>
                    </Box>
                  </motion.div>
                ))}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
