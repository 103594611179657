import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faPhone } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { Parallax } from "react-scroll-parallax";

export const ContactButtons = () => {
  const theme = useTheme();

  const buttonVariants = {
    hover: {
      scale: 1.05,
      y: -5,
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 10,
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  // A small wrapper to safely call the global function
  const onClickHandler = (url: string, newTab = false) => {
    if (typeof window.gtagSendEvent === "function") {
      window.gtagSendEvent(url, newTab);
    } else {
      // Fallback: if gtagSendEvent is not available, use default behavior.
      if (newTab) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    }
  };

  return (
    <Box sx={{ py: 4 }}>
      <Parallax speed={5} translateY={[0, -20]} opacity={[0.8, 1]}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.5, duration: 0.8, ease: "easeOut" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              mt: 4,
              "& .contact-button": {
                px: 4,
                py: 1.8,
                borderRadius: "50px",
                fontSize: "1.2rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: 1.5,
                minWidth: "220px",
                justifyContent: "center",
                textTransform: "none",
                position: "relative",
                overflow: "hidden",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.1)",
                  transform: "translateX(-100%) skewX(-15deg)",
                  transition: "transform 0.5s ease",
                },
                "&:hover::before": {
                  transform: "translateX(100%) skewX(-15deg)",
                },
              },
            }}
          >
            <motion.div
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <Button
                component="a"
                onClick={() =>
                  onClickHandler("https://wa.me/972547363669", true)
                }
                variant="contained"
                className="contact-button"
                sx={{
                  background: "#25D366",
                  boxShadow: "0 8px 25px rgba(37, 211, 102, 0.3)",
                  "&:hover": {
                    background: "#22c35e",
                    boxShadow: "0 12px 30px rgba(37, 211, 102, 0.4)",
                  },
                }}
              >
                <FontAwesomeIcon icon={faWhatsapp} size="lg" />
                <Box sx={{ alignItems: "center" }}>
                  <Box>שלח הודעה</Box>
                  <Box sx={{ fontSize: "0.8rem" }}>ב - Whatsapp</Box>
                </Box>
              </Button>
            </motion.div>

            <motion.div
              variants={buttonVariants}
              whileHover="hover"
              whileTap="tap"
            >
              <Button
                component="a"
                onClick={() => onClickHandler("tel:054-7363669")}
                variant="contained"
                className="contact-button"
                sx={{
                  background: theme.palette.primary.main,
                  boxShadow: "0 8px 25px rgba(33, 150, 243, 0.3)",
                  "&:hover": {
                    background: theme.palette.primary.dark,
                    boxShadow: "0 12px 30px rgba(33, 150, 243, 0.4)",
                  },
                }}
              >
                <FontAwesomeIcon icon={faPhone} size="lg" />
                <Box sx={{ alignItems: "center" }}>
                  <Box>חייג עכשיו</Box>
                  <Box sx={{ fontSize: "0.8rem" }}>054-7363669</Box>
                </Box>
              </Button>
            </motion.div>
          </Box>
        </motion.div>
      </Parallax>
    </Box>
  );
};
