import { Article } from "modules/core";

export const teensArticles: Article[] = [
  {
    path: "/teens/articles/sleep-screen-obesity",
    image:
      "https://images.pexels.com/photos/3771069/pexels-photo-3771069.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/teens/articles/diet-weight-body-image",
    image:
      "https://images.pexels.com/photos/3768894/pexels-photo-3768894.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/teens/articles/weight-body-image-balance",
    image:
      "https://images.pexels.com/photos/4149201/pexels-photo-4149201.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/teens/articles/healthy-fast-food-balance",
    image:
      "https://images.pexels.com/photos/2983101/pexels-photo-2983101.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/teens/articles/nutrition-myths",
    image:
      "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
];
