import { Box, Button, Container, Typography } from "@mui/material";
import { withWrapper } from "app/hooks";
import { useProduct } from "modules/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

interface Props {
  message: string;
  children: ReactNode;
}

export const Error = withWrapper(({ message, children }: Props) => {
  const { product } = useProduct();

  return (
    <Box sx={{ my: 5, pt: 5 }}>
      <Container>
        <Box sx={{ textAlign: "center", mb: 5 }}>
          <Typography variant="h1" sx={{ fontSize: "4rem", fontWeight: 500 }}>
            {children}
          </Typography>
          <Typography variant="h4">{message}</Typography>
          <Box sx={{ mt: 5, textAlign: "center" }}>
            <Button
              component={Link}
              to={`/${product || ""}`}
              variant="contained"
              color="primary"
            >
              לדף הראשי
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
});
