import { Product } from "@monorepo/constants";
import { LetsTalk } from "modules/common";
import { Articles } from "modules/core";
import {
  kidsArticles,
  FoodMarketingEatingHabits as KidsFoodMarketingEatingHabits,
  Home as KidsHome,
  ParentalAttentionTrap as KidsParentalAttentionTrap,
  ParentingOvereating as KidsParentingOvereating,
  ScreenSedentaryObesity as KidsScreenSedentaryObesity,
  SleepObesityLink as KidsSleepObesityLink,
} from "modules/kids";
import {
  EverydayDietChallengesGuide,
  HungerTypesGuide,
  lifestyleArticles,
  Home as LifestyleHome,
  PopularDietsProsCons,
  ScientificNutritionBenefits,
  TargetDressDilemma,
} from "modules/lifestyle";
import {
  BreastfeedingNutrition,
  First1000DaysNutrition,
  maternityArticles,
  Home as MaternityHome,
  PostpartumMindNutrition,
  PostpartumTargetDress,
  PostpartumWeightLossNutrition,
} from "modules/maternity";
import {
  AbdominalFat as MidlifeAbdominalFat,
  midlifeArticles,
  EatingHours as MidlifeEatingHours,
  EmotionalEating as MidlifeEmotionalEating,
  Home as MidlifeHome,
  HormoneNutrition as MidlifeHormoneNutrition,
  Microbiome as MidlifeMicrobiome,
} from "modules/midlife";
import {
  AthleteSupplements,
  DietsSportsPerformance,
  EnduranceVsStrengthNutrition,
  sportArticles,
  Home as SportHome,
  SportsNutritionImpact,
} from "modules/sport";
import {
  DietWeightBodyImage as TeenDietWeightBodyImage,
  HealthyFastFoodBalance as TeenHealthyFastFoodBalance,
  NutritionMyths as TeenNutritionMyths,
  teensArticles,
  Home as TeensHome,
  SleepScreenObesity as TeensSleepScreenObesity,
  WeightBodyImageBalance as TeenWeightBodyImageBalance,
} from "modules/teens";
import {
  PlantBasedSports,
  PlantBasedWeightLoss,
  HealthBenefits as VeganHealthBenefits,
  Home as VeganHome,
  LifeStages as VeganLifeStages,
  Myths as VeganMyths,
} from "modules/vegan";
import { veganArticles } from "modules/vegan/constants";
import { Navigate, RouteObject } from "react-router-dom";

interface NavLink {
  path: string;
  label: string;
}

export const productPublicRoutes: Record<Product, RouteObject[]> = {
  [Product.Lifestyle]: [
    { path: "", element: <LifestyleHome /> },

    { path: "articles", element: <Articles articles={lifestyleArticles} /> },
    {
      path: "articles/target-dress-dilemma",
      element: <TargetDressDilemma />,
    },
    {
      path: "articles/popular-diets-pros-cons",
      element: <PopularDietsProsCons />,
    },
    {
      path: "articles/scientific-nutrition-benefits",
      element: <ScientificNutritionBenefits />,
    },
    {
      path: "articles/hunger-types-guide",
      element: <HungerTypesGuide />,
    },
    {
      path: "articles/everyday-diet-challenges-guide",
      element: <EverydayDietChallengesGuide />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Kids]: [
    { path: "", element: <KidsHome /> },

    { path: "articles", element: <Articles articles={kidsArticles} /> },
    {
      path: "articles/parental-attention-trap",
      element: <KidsParentalAttentionTrap />,
    },
    {
      path: "articles/sleep-obesity-link",
      element: <KidsSleepObesityLink />,
    },
    {
      path: "articles/parenting-overeating",
      element: <KidsParentingOvereating />,
    },
    {
      path: "articles/screen-sedentary-obesity",
      element: <KidsScreenSedentaryObesity />,
    },
    {
      path: "articles/food-marketing-eating-habits",
      element: <KidsFoodMarketingEatingHabits />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Teens]: [
    { path: "", element: <TeensHome /> },

    { path: "articles", element: <Articles articles={teensArticles} /> },
    {
      path: "articles/sleep-screen-obesity",
      element: <TeensSleepScreenObesity />,
    },
    {
      path: "articles/diet-weight-body-image",
      element: <TeenDietWeightBodyImage />,
    },
    {
      path: "articles/weight-body-image-balance",
      element: <TeenWeightBodyImageBalance />,
    },
    {
      path: "articles/healthy-fast-food-balance",
      element: <TeenHealthyFastFoodBalance />,
    },
    {
      path: "articles/nutrition-myths",
      element: <TeenNutritionMyths />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Vegan]: [
    { path: "", element: <VeganHome /> },

    { path: "articles", element: <Articles articles={veganArticles} /> },
    {
      path: "articles/plant-based-weight-loss",
      element: <PlantBasedWeightLoss />,
    },
    {
      path: "articles/myths",
      element: <VeganMyths />,
    },
    {
      path: "articles/plant-based-sports",
      element: <PlantBasedSports />,
    },
    {
      path: "articles/health-benefits",
      element: <VeganHealthBenefits />,
    },
    {
      path: "articles/life-stages",
      element: <VeganLifeStages />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Sport]: [
    { path: "", element: <SportHome /> },

    { path: "articles", element: <Articles articles={sportArticles} /> },
    {
      path: "articles/endurance-vs-strength-nutrition",
      element: <EnduranceVsStrengthNutrition />,
    },
    {
      path: "articles/diets-sports-performance",
      element: <DietsSportsPerformance />,
    },
    {
      path: "articles/sports-nutrition-impact",
      element: <SportsNutritionImpact />,
    },
    {
      path: "articles/athlete-supplements",
      element: <AthleteSupplements />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Maternity]: [
    { path: "", element: <MaternityHome /> },

    { path: "articles", element: <Articles articles={maternityArticles} /> },
    {
      path: "articles/postpartum-mind-nutrition",
      element: <PostpartumMindNutrition />,
    },
    {
      path: "articles/breastfeeding-nutrition",
      element: <BreastfeedingNutrition />,
    },
    {
      path: "articles/first-1000-days-nutrition",
      element: <First1000DaysNutrition />,
    },
    {
      path: "articles/postpartum-weight-loss-nutrition",
      element: <PostpartumWeightLossNutrition />,
    },
    {
      path: "articles/postpartum-target-dress",
      element: <PostpartumTargetDress />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
  [Product.Midlife]: [
    { path: "", element: <MidlifeHome /> },

    { path: "articles", element: <Articles articles={midlifeArticles} /> },
    {
      path: "articles/eating-hours",
      element: <MidlifeEatingHours />,
    },
    {
      path: "articles/microbiome",
      element: <MidlifeMicrobiome />,
    },
    {
      path: "articles/emotional-eating",
      element: <MidlifeEmotionalEating />,
    },
    {
      path: "articles/abdominal-fat",
      element: <MidlifeAbdominalFat />,
    },
    {
      path: "articles/hormone-nutrition",
      element: <MidlifeHormoneNutrition />,
    },

    { path: "lets-talk", element: <LetsTalk /> },

    { path: "*", element: <Navigate to="/4xx-error" replace /> },
  ],
};

export const productNavLinks: Record<Product, NavLink[]> = {
  [Product.Lifestyle]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Kids]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Teens]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Vegan]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Sport]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Maternity]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
  [Product.Midlife]: [
    { path: "", label: "בית" },
    { path: "articles", label: "תכנים" },
    { path: "lets-talk", label: "בואו נדבר" },
  ],
};
