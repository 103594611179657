import {
  Box,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Lottie from "lottie-react";
import { useEffect, useRef, useState } from "react";
import { ContactButtons } from "../common/components/ContactButtons";

gsap.registerPlugin(ScrollTrigger);

export const SolutionSection = () => {
  const theme = useTheme();
  const sectionRef = useRef(null);
  const [animationData, setAnimationData] = useState(null);

  useEffect(() => {
    // Dynamically import the Lottie animation
    fetch("/animations/contact-animation.json")
      .then((response) => response.json())
      .then((data) => setAnimationData(data))
      .catch((error) => console.error("Error loading animation:", error));
  }, []);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [0.9, 1, 1, 0.9]
  );

  return (
    <Box
      ref={sectionRef}
      component={motion.div}
      style={{ opacity, scale }}
      sx={{ position: "relative", overflow: "hidden" }}
    >
      <Container maxWidth="xl">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <Box
            sx={{
              mb: 8,
              p: { xs: 3, md: 5 },
              borderRadius: "24px",
              background: `linear-gradient(135deg, ${theme.palette.primary.main}15, ${theme.palette.primary.light}25)`,
              border: `1px solid ${theme.palette.primary.main}30`,
              position: "relative",
              overflow: "hidden",
              transition: "transform 0.5s ease-in-out",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "-50%",
                right: "-50%",
                width: "200%",
                height: "200%",
                background: `radial-gradient(circle, ${theme.palette.primary.main}20, transparent 70%)`,
                zIndex: 0,
                animation: "pulse 8s infinite ease-in-out",
              },
              "&::after": {
                content: '""',
                position: "absolute",
                bottom: "-10px",
                left: "-10px",
                width: "120px",
                height: "120px",
                borderRadius: "50%",
                background: `radial-gradient(circle, ${theme.palette.secondary.main}20, transparent 70%)`,
                filter: "blur(15px)",
                animation: "float 10s infinite ease-in-out",
              },
              "@keyframes pulse": {
                "0%": { transform: "scale(1)" },
                "50%": { transform: "scale(1.05)" },
                "100%": { transform: "scale(1)" },
              },
              "@keyframes float": {
                "0%": { transform: "translate(0, 0)" },
                "50%": { transform: "translate(20px, -20px)" },
                "100%": { transform: "translate(0, 0)" },
              },
            }}
          >
            <Grid
              container
              spacing={3}
              sx={{ position: "relative", zIndex: 1 }}
            >
              <Grid size={{ xs: 12, md: 7 }}>
                <Box
                  alignItems="center"
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    <Typography
                      variant="h3"
                      align="center"
                      sx={{
                        fontWeight: 800,
                        mb: 2,
                        background: `linear-gradient(135deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                        backgroundClip: "text",
                        WebkitBackgroundClip: "text",
                        color: "transparent",
                        fontSize: { xs: "1.8rem", md: "2.8rem" },
                        textShadow: "0 2px 10px rgba(0,0,0,0.1)",
                        lineHeight: 1.4,
                        position: "relative",
                        display: "inline-block",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: "-15px",
                          right: "-15px",
                          width: "30px",
                          height: "30px",
                          background: `radial-gradient(circle, ${theme.palette.primary.main}50, transparent)`,
                          borderRadius: "50%",
                          filter: "blur(8px)",
                        },
                      }}
                    >
                      אם אחד המשפטים האלה נכתב עליך...
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                  >
                    <Typography
                      variant="h4"
                      align="center"
                      sx={{
                        mb: 3,
                        color: theme.palette.text.primary,
                        fontWeight: 600,
                        fontSize: { xs: "1.4rem", md: "2.2rem" },
                        position: "relative",
                        display: "inline-block",
                        "&::after": {
                          content: '""',
                          position: "absolute",
                          bottom: "-10px",
                          left: "10%",
                          width: "80%",
                          height: "4px",
                          background: `linear-gradient(90deg, transparent, ${theme.palette.primary.main}, transparent)`,
                          borderRadius: "2px",
                        },
                      }}
                    >
                      יש פיתרון - ליווי אישי, מקצועי ותומך
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                  >
                    <Typography
                      variant="h5"
                      align="center"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: { xs: "1.1rem", md: "1.5rem" },
                        fontWeight: 500,
                        position: "relative",
                        textShadow: "0 1px 2px rgba(0,0,0,0.05)",
                      }}
                    >
                      לתיאום שיחת אבחון וייעוץ ללא תשלום
                    </Typography>
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Box
                      sx={{ transform: { xs: "scale(0.9)", md: "scale(1)" } }}
                    >
                      <ContactButtons />
                    </Box>
                  </motion.div>
                </Box>
              </Grid>

              <Grid
                size={{ xs: 12, md: 5 }}
                sx={{
                  display: { xs: "block", md: "block" },
                }}
              >
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8, delay: 0.3 }}
                  animate={{
                    y: [0, -10, 0],
                    transition: {
                      duration: 4,
                      repeat: Infinity,
                      repeatType: "reverse",
                      ease: "easeInOut",
                    },
                  }}
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: { xs: "200px", md: "300px" },
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: { xs: "200px", md: "280px" },
                        height: { xs: "200px", md: "280px" },
                        borderRadius: "50%",
                        background: `radial-gradient(circle, ${theme.palette.primary.main}30, transparent)`,
                        filter: "blur(15px)",
                        zIndex: 0,
                        animation: "pulse 4s infinite ease-in-out",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        filter: "drop-shadow(0 10px 20px rgba(0,0,0,0.1))",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {animationData && (
                        <Box
                          sx={{
                            width: { xs: "100%", md: "90%" },
                            height: { xs: "100%", md: "90%" },
                            maxWidth: "320px",
                            position: "relative",
                            "&::after": {
                              content: '""',
                              position: "absolute",
                              bottom: "-15px",
                              left: "50%",
                              transform: "translateX(-50%)",
                              width: "80%",
                              height: "15px",
                              borderRadius: "50%",
                              background: "rgba(0,0,0,0.1)",
                              filter: "blur(10px)",
                            },
                          }}
                        >
                          <Lottie
                            animationData={animationData}
                            loop={true}
                            style={{
                              width: "100%",
                              height: "100%",
                              transform: "scale(1.2)",
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </motion.div>
              </Grid>
            </Grid>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};
