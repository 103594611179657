import {
  faBookOpen,
  faBrain,
  faHashtag,
  faLightbulb,
  faShieldHeart,
  faShoppingBag,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out",
  marginBottom: theme.spacing(4),
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const MARKETING_STRATEGIES = [
  {
    id: "engineering",
    title: "הנדסת מזון",
    icon: faBrain,
    color: "#FF6B6B",
    description: "יצירת מוצרי מזון ממכרים",
  },
  {
    id: "packaging",
    title: "עיצוב אריזות",
    icon: faShoppingBag,
    color: "#4ECDC4",
    description: "פרסום ממוקד לילדים",
  },
  {
    id: "social",
    title: "מדיה חברתית",
    icon: faHashtag,
    color: "#45B7D1",
    description: "פרסום סמוי במדיה החברתית",
  },
];

const SOLUTIONS = [
  {
    id: "awareness",
    title: "פיתוח מודעות",
    icon: faLightbulb,
    description: "שיחה עם ילדים על השפעות הפרסום",
  },
  {
    id: "protection",
    title: "הגנה מפרסום",
    icon: faShieldHeart,
    description: "הגבלת חשיפה לתכנים פרסומיים",
  },
  {
    id: "education",
    title: "חינוך תזונתי",
    icon: faBookOpen,
    description: "לימוד קריאת תוויות והשוואת מוצרים",
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/3985062/pexels-photo-3985062.jpeg?w=1260&auto=compress";

export const FoodMarketingEatingHabits = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                השפעת שיווק המזון על הרגלי האכילה של ילדים
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                השמנה בקרב ילדים נחשבת כיום למגפה עולמית, אשר גורמת לעלייה
                בסיכון לבעיות בריאותיות ארוכות טווח כמו סוכרת מסוג 2, מחלות לב
                וכלי דם. הורים רבים מרגישים חסרי אונים מול כוחה של תעשיית המזון,
                אשר משקיעה מיליוני דולרים בפיתוח אסטרטגיות שיווק מתוחכמות
                המכוונות במיוחד לילדים. מטרתן היא ליצור תלות מגיל צעיר שתמשיך
                לאורך זמן, תוך הגברת ההעדפה למוצרים עתירי סוכר, מלח ושומן.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                מחקרים מראים כי לחשיפה לשיווק מזון יש השפעה מכרעת על בחירות
                המזון של ילדים, מה שעלול להוביל לצריכת יתר של מזון מתועש ולהגברת
                הסיכון להשמנה, למחלות כרוניות בעתיד וגם לברננות אכילה ובעיקר
                לסלידה מאוכל ״פשוט״, כמו פירות, ירקות ודגנים מלאים. תעשיית המזון
                משקיעה משאבים עצומים בפיתוח אסטרטגיות שיווקיות המיועדות לילדים.
                מטרתן היא ליצור הרגלי צריכה מגיל צעיר ולהגביר את ההעדפה למוצרים
                מסוימים, שלרוב מכילים כמויות גבוהות של סוכר, מלח ושומן. מחקרים
                מראים כי לחשיפה לשיווק מזון יש השפעה מכרעת על בחירות המזון של
                ילדים, מה שעלול להוביל לצריכת יתר של מזון מתועש ולהגברת הסיכון
                להשמנה ולמחלות כרוניות בעתיד.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Marketing Strategies */}
          <Typography
            variant="h3"
            align="center"
            sx={{ my: 6, fontWeight: 700 }}
          >
            אסטרטגיות שיווק מרכזיות
          </Typography>
          <Grid container spacing={4}>
            {MARKETING_STRATEGIES.map((strategy) => (
              <Grid
                key={strategy.id}
                data-aos="fade-up"
                size={{ xs: 12, md: 4 }}
              >
                <ContentSection>
                  <Box sx={{ textAlign: "center", mb: 2 }}>
                    <IconButton
                      sx={{
                        backgroundColor: strategy.color,
                        color: "white",
                        "&:hover": { backgroundColor: strategy.color },
                        width: 80,
                        height: 80,
                        mb: 2,
                      }}
                    >
                      <FontAwesomeIcon icon={strategy.icon} size="2x" />
                    </IconButton>
                    <Typography
                      variant="h5"
                      gutterBottom
                      sx={{ fontWeight: 600 }}
                    >
                      {strategy.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {strategy.description}
                    </Typography>
                  </Box>
                  {strategy.id === "engineering" && (
                    <Typography paragraph sx={{ mt: 2 }}>
                      יצרני המזון משתמשים בטכניקות מדעיות מתקדמות כדי לפתח
                      מוצרים המעוררים תגובה ממכרת במוח. מזונות אלה מכילים שילוב
                      מדויק של סוכר, מלח ושומן, אשר מפעיל את מערכת התגמול במוח
                      וגורם לילדים לחזור ולצרוך אותם שוב ושוב. מחקרים מראים כי
                      צריכה גבוהה של מזונות מעובדים מגבירה הפרשת דופמין, מוליך
                      עצבי המקושר לתחושת עונג, באופן הדומה להשפעת חומרים ממכרים.
                      מחקר שפורסם ב-Nature Neuroscience מצא כי מזונות מעובדים
                      במיוחד יוצרים דפוסי צריכה כפייתיים, במיוחד בקרב ילדים, מה
                      שמוביל להרגלי אכילה המעודדים השמנה ואכילה לא מאוזנת או
                      בריאה.
                    </Typography>
                  )}
                  {strategy.id === "packaging" && (
                    <Typography paragraph sx={{ mt: 2 }}>
                      האריזות של מוצרים המיועדים לילדים עוצבו בקפידה כדי למשוך
                      את תשומת ליבם ולהגביר את הרצון לצרוך את המוצרים. מחקרים
                      מראים כי שילוב של צבעים בולטים, דמויות מצוירות, וסלוגנים
                      קליטים מעלה משמעותית את ההעדפה של ילדים למזון מסוים. לפי
                      מחקר שפורסם ב-Pediatrics, ילדים שהוצגו בפניהם שני מוצרים
                      זהים לחלוטין, אך באחד מהם הייתה אריזה עם דמות מוכרת,
                      העדיפו את המוצר המעוצב בצורה מושכת יותר, גם כאשר טעמו היה
                      זהה. ממצאים אלו ממחישים כיצד שיווק יכול לעקוף את שיקול
                      הדעת של הילד ולהשפיע על הרגלי האכילה שלו.
                    </Typography>
                  )}
                  {strategy.id === "social" && (
                    <Typography paragraph sx={{ mt: 2 }}>
                      בנוסף לפרסום המסורתי בטלוויזיה, תעשיית המזון עושה שימוש
                      גובר בפרסום סמוי במדיה החברתית, באמצעות משפיענים ויוצרי
                      תוכן פופולריים. ילדים ובני נוער נחשפים לפרסומות מוסוות בהן
                      מובילי דעה צורכים מוצרים מסוימים מבלי לציין שהם חלק
                      מקמפיין שיווקי. מחקרים מראים כי ילדים רואים במשפיענים
                      דמויות אמינות ולכן מושפעים מהמלצותיהם יותר מאשר מפרסומות
                      מסורתיות. לפי מחקר שנערך ב-Journal of Consumer Psychology,
                      ילדים שנחשפו לסרטונים של משפיענים אוכלים חטיפים מתועשים
                      נטו לצרוך אותם יותר בהשוואה לילדים שלא נחשפו לפרסומת
                      סמויה.
                    </Typography>
                  )}
                </ContentSection>
              </Grid>
            ))}
          </Grid>

          {/* Solutions */}
          <StyledPaper elevation={3} sx={{ mt: 6 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4 }}
            >
              כיצד ללמד ילדים לזהות מניפולציות שיווקיות?
            </Typography>
            <Typography variant="body1" paragraph sx={{ mb: 3 }}>
              בתהליך ליווי תזונה נכון לומדים לפתח מודעות ביקורתית כדי להתמודד עם
              השפעות הפרסום:
            </Typography>
            <Grid container spacing={4}>
              {SOLUTIONS.map((solution) => (
                <Grid
                  key={solution.id}
                  data-aos="fade-up"
                  size={{ xs: 12, md: 4 }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 3,
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      sx={{
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.dark,
                        },
                        width: 60,
                        height: 60,
                        mb: 2,
                      }}
                    >
                      <FontAwesomeIcon icon={solution.icon} size="lg" />
                    </IconButton>
                    <Typography variant="h6" gutterBottom>
                      {solution.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {solution.description}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </StyledPaper>

          {/* Summary */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3} sx={{ mt: 6 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                לתעשיית המזון יש השפעה עמוקה על הרגלי האכילה של ילדים, באמצעות
                הנדסת מזון ממכרת, עיצוב אריזות מפתות ופרסום סמוי במדיה החברתית.
                מנגנונים אלה מעודדים ילדים לצרוך יותר מזון מעובד, מה שמוביל
                לעלייה בשכיחות ההשמנה ולבעיות בריאותיות.
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                עם זאת, ניתן להתמודד עם האתגר הזה באמצעות חינוך תזונתי נכון
                וליווי מקצועי. הורים יכולים להקנות לילדיהם כלים מעשיים להתמודד
                עם שפע המזון המעובד וללמוד לבחור מזונות בריאים יותר. חינוך
                למודעות צרכנית, עידוד גיוון תזונתי והתנסות בטעמים טבעיים הם
                צעדים משמעותיים להרחבת ההעדפות התזונתיות של ילדים.
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                בנוסף, ליווי מקצועי על ידי דיאטן מוסמך יכול להוות כלי מרכזי
                בהתמודדות עם אתגרי השיווק התזונתי. דיאטן מקצועי יכול לסייע
                למשפחות בפיתוח אסטרטגיות מעשיות לשיפור הרגלי האכילה ולספק תמיכה
                אישית בהתאמת התפריט לצורכי הילד, תוך יצירת גישה חיובית ומאוזנת
                כלפי אוכל.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
