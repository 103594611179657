import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { withWrapper } from "app/hooks";
import { productNavLinks, ProductSwitcher, useProduct } from "modules/core";
import { useMemo, useState } from "react";
import { NavLink as NavLinkRouter, useNavigate } from "react-router-dom";
import { Logo } from "./Logo";

export const Navigator = withWrapper(() => {
  const { product } = useProduct();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navLinks = useMemo(() => {
    if (product) {
      return productNavLinks[product];
    }
    return [];
  }, [product]);

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (path: string) => {
    navigate(`/${product}${path ? `/${path}` : ""}`);
    handleMenuClose();
  };

  const showNavigation = navLinks.length > 0;

  return (
    <AppBar
      position="sticky"
      sx={{
        marginBottom: 3,
        backgroundColor: (theme) => theme.palette.primary.main,
      }}
    >
      <Container>
        <Toolbar
          sx={{
            gap: 2,
            py: 1,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              flex: { xs: "1", md: "0 1 auto" },
            }}
          >
            <Logo />
            <ProductSwitcher />
          </Box>

          {showNavigation &&
            (isMobile ? (
              <>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  keepMounted
                >
                  {navLinks.map((link) => (
                    <MenuItem
                      key={link.path}
                      onClick={() => handleMenuItemClick(link.path)}
                    >
                      {link.label}
                    </MenuItem>
                  ))}
                </Menu>
              </>
            ) : (
              <Box sx={{ marginLeft: "auto" }}>
                <Tabs
                  sx={{
                    "& .MuiTab-root": {
                      color: "white",
                      fontSize: "20px",
                      opacity: "1 !important",
                      minWidth: "auto",
                      padding: "12px 16px",
                    },
                  }}
                  role="navigation"
                >
                  {navLinks.map((link) => (
                    <Tab
                      key={link.path}
                      component={NavLinkRouter}
                      to={`/${product}${link.path ? `/${link.path}` : ""}`}
                      label={link.label}
                      end={link.path === ""}
                      sx={{
                        "&.active": { borderBottom: "2px solid white" },
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            ))}
        </Toolbar>
      </Container>
    </AppBar>
  );
});
