import {
  faDna,
  faDroplet,
  faHeartPulse,
  faStomach,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { ContactButtons, useMeta } from "modules/common";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const ArticleSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(8px)",
}));

const HERO_IMAGE =
  "https://images.pexels.com/photos/1435904/pexels-photo-1435904.jpeg?auto=compress&w=1260";

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  textAlign: "center",
  borderRadius: theme.spacing(2),
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  borderRadius: theme.spacing(1),
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
  direction: "rtl",
  textAlign: "right",
  "& .MuiListItemIcon-root": {
    minWidth: 42,
    marginLeft: theme.spacing(2),
    marginRight: -theme.spacing(1),
  },
  "& .MuiListItemText-primary": {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: theme.spacing(1),
  },
  "& .MuiListItemText-secondary": {
    fontSize: "1rem",
    lineHeight: 1.6,
    color: theme.palette.text.secondary,
  },
}));

export const HealthBenefits = () => {
  useMeta();

  const theme = useTheme();
  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.1 });

  return (
    <ParallaxProvider>
      <Box
        sx={{
          overflow: "hidden",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)",
          minHeight: "100vh",
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `url(${HERO_IMAGE})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "brightness(0.7)",
              zIndex: 0,
            },
          }}
        >
          <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
            <Parallax translateY={[-20, 20]}>
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                  mb: 3,
                  maxWidth: "1000px",
                  textAlign: "right",
                }}
              >
                היתרונות הבריאותיים של תזונה טבעונית
              </Typography>
            </Parallax>
          </Container>
        </Box>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <ArticleSection>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                color: theme.palette.text.primary,
                textAlign: "right",
                direction: "rtl",
              }}
            >
              תזונה טבעונית זוכה להכרה הולכת וגוברת בשל תרומתה לבריאות ולמניעת
              מחלות כרוניות. מחקרים מדעיים מצביעים על כך שתפריט טבעוני ומאוזן
              עשיר במזונות מלאים, ירקות, פירות, דגנים וקטניות, יכול לשפר את
              בריאות הלב, להפחית את הסיכון למחלות מטבוליות ואף לתרום לאריכות
              ימים. במאמר זה אציג את היתרונות הבריאותיים של תזונה טבעונית.
            </Typography>
          </ArticleSection>

          {/* Statistics Grid */}
          <Grid container spacing={3} ref={ref} sx={{ mb: 6 }}>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <StatCard elevation={3}>
                <FontAwesomeIcon
                  icon={faHeartPulse}
                  style={{
                    fontSize: "2.5rem",
                    color: theme.palette.primary.main,
                    marginBottom: theme.spacing(2),
                  }}
                />
                <Typography variant="h4" gutterBottom>
                  {inView && <CountUp end={25} duration={2} suffix="%" />}
                </Typography>
                <Typography variant="body2">הפחתה בסיכון למחלות לב</Typography>
              </StatCard>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <StatCard elevation={3}>
                <FontAwesomeIcon
                  icon={faDroplet}
                  style={{
                    fontSize: "2.5rem",
                    color: theme.palette.primary.main,
                    marginBottom: theme.spacing(2),
                  }}
                />
                <Typography variant="h4" gutterBottom>
                  {inView && <CountUp end={50} duration={2} suffix="%" />}
                </Typography>
                <Typography variant="body2">
                  הפחתה בסיכון לסוכרת סוג 2
                </Typography>
              </StatCard>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <StatCard elevation={3}>
                <FontAwesomeIcon
                  icon={faDna}
                  style={{
                    fontSize: "2.5rem",
                    color: theme.palette.primary.main,
                    marginBottom: theme.spacing(2),
                  }}
                />
                <Typography variant="h4" gutterBottom>
                  {inView && <CountUp end={40} duration={2} suffix="%" />}
                </Typography>
                <Typography variant="body2">
                  הפחתה בסיכון לסוגי סרטן שונים
                </Typography>
              </StatCard>
            </Grid>
            <Grid size={{ xs: 12, sm: 6, md: 3 }}>
              <StatCard elevation={3}>
                <FontAwesomeIcon
                  icon={faWeightScale}
                  style={{
                    fontSize: "2.5rem",
                    color: theme.palette.primary.main,
                    marginBottom: theme.spacing(2),
                  }}
                />
                <Typography variant="h4" gutterBottom>
                  BMI
                </Typography>
                <Typography variant="body2">נמוך יותר באופן משמעותי</Typography>
              </StatCard>
            </Grid>
          </Grid>

          {/* Main Benefits */}
          <ArticleSection>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                mb: 4,
                textAlign: "right",
              }}
            >
              היתרונות העיקריים של תזונה טבעונית
            </Typography>

            <List sx={{ direction: "rtl" }}>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faHeartPulse}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="מניעת מחלות לב וכלי דם"
                  secondary="מחקרים מראים כי תזונה טבעונית עשירה בסיבים תזונתיים, נוגדי חמצון ושומנים בלתי רוויים מפחיתה את רמות הכולסטרול הרע (LDL) ומסייעת לשמירה על לחץ דם תקין. מחקר שפורסם ב-Journal of the American Heart Association הראה כי טבעונים נמצאים בסיכון נמוך ב-25% לפתח מחלות לב בהשוואה לאוכלי כל. הימנעות מבשר אדום ומוצרים עתירי שומן רווי מפחיתה את התהליכים הדלקתיים בכלי הדם ותורמת לשמירה על תפקוד הלב."
                />
              </StyledListItem>

              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faDroplet}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="איזון רמות הסוכר ומניעת סוכרת סוג 2"
                  secondary="תזונה טבעונית עשירה בפחמימות מורכבות ובסיבים תזונתיים משפרת את הרגישות לאינסולין ומסייעת לשמור על רמות סוכר מאוזנות בדם. סקירה מדעית שהתפרסמה ב-Diabetes Care מצאה כי טבעונים נמצאים בסיכון מופחת של עד 50% לפתח סוכרת סוג 2 בהשוואה לאוכלי כל. המזונות הצמחיים מקטינים את העומס הגליקמי, מונעים קפיצות חדות ברמות הסוכר ומפחיתים את הסיכון לתנגודת אינסולין."
                />
              </StyledListItem>

              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faDna}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="הפחתת הסיכון לסרטן"
                  secondary="מחקרים מעידים כי תזונה המבוססת על מזונות צמחיים קשורה כגורם מגן מפני סוגי סרטן שונים, כולל סרטן המעי הגס, השד והערמונית. הסיבים התזונתיים הנמצאים בפירות, ירקות וקטניות מסייעים לשמירה על מערכת עיכול בריאה ומפחיתים את זמן המגע בין רעלנים לרירית המעי. בנוסף, הפיטוכימיקלים ונוגדי החמצון המצויים במזונות צמחיים מגנים על תאי הגוף מפני נזקי חמצון ודלקת, גורמים המובילים להתפתחות סרטן."
                />
              </StyledListItem>

              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faWeightScale}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שמירה על משקל גוף תקין ומניעת השמנה"
                  secondary="מחקר שהתפרסם ב-Obesity Reviews הראה כי תזונה טבעונית מאוזנת מקושרת למדד מסת גוף (BMI) נמוך יותר ולסיכון מופחת להשמנה. מזונות צמחיים עשירים בסיבים תורמים לתחושת שובע ממושכת ומפחיתים את צריכת הקלוריות הכללית. הימנעות ממזונות מעובדים ועתירי שומן רווי מסייעת בשמירה על משקל גוף תקין ומפחיתה את הסיכון למחלות מטבוליות."
                />
              </StyledListItem>

              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faStomach}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שיפור בריאות מערכת העיכול"
                  secondary="תזונה טבעונית מספקת כמות גבוהה של סיבים תזונתיים התומכים בפעילות תקינה של מערכת העיכול ומקדמים איזון של חיידקי המעי הטובים. מחקר שפורסם ב-Gut Microbes מצא כי לתזונה טבעונית השפעה חיובית על המיקרוביום של המעי, המפחיתה דלקות ומשפרת את ספיגת החומרים המזינים."
                />
              </StyledListItem>
            </List>
          </ArticleSection>

          {/* Summary */}
          <ArticleSection>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                mb: 4,
                textAlign: "right",
              }}
            >
              סיכום
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                textAlign: "right",
                direction: "rtl",
              }}
            >
              תזונה טבעונית מאוזנת, המבוססת על מזונות בריאים, איכותיים ומזינים,
              מציעה יתרונות בריאותיים רבים, כולל הפחתת הסיכון למחלות לב, סוכרת
              סוג 2 וסרטן, שמירה על משקל גוף תקין ושיפור תפקוד מערכת העיכול,
              בהשוואה לאוכלי כל. הודות לתכולת הסיבים הגבוהה והימנעות ממזונות
              עתירי שומן רווי, תזונה זו מסייעת לשמירה על בריאות מטבולית כללית
              ותורמת לאיכות חיים גבוהה יותר. יחד עם זאת, חשוב לוודא שהצרכים
              התזונתיים האישיים מתמלאים באמצעות תכנון תפריט מאוזן או בליווי
              דיאטנית קלינית המתמחה בתזונה טבעונית.
            </Typography>
          </ArticleSection>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
