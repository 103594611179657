import { Article } from "modules/core";

export const midlifeArticles: Article[] = [
  {
    path: "/midlife/articles/eating-hours",
    image:
      "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?q=80&w=350",
  },
  {
    path: "/midlife/articles/microbiome",
    image:
      "https://images.unsplash.com/photo-1576086213369-97a306d36557?q=80&w=350",
  },
  {
    path: "/midlife/articles/emotional-eating",
    image:
      "https://images.pexels.com/photos/7176305/pexels-photo-7176305.jpeg?w=350&auto=compress",
  },
  {
    path: "/midlife/articles/hormone-nutrition",
    image:
      "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=350&auto=compress",
  },
  {
    path: "/midlife/articles/abdominal-fat",
    image:
      "https://images.pexels.com/photos/4098228/pexels-photo-4098228.jpeg?w=350&auto=compress",
  },
];
