import {
  Box,
  Card,
  Container,
  Divider,
  Fade,
  Grid2 as Grid,
  Paper,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { useMeta } from "modules/common";
import { useInView } from "react-intersection-observer";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const DietCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-10px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?w=1260&auto=compress",
  keto: "https://images.pexels.com/photos/1640773/pexels-photo-1640773.jpeg?w=640&auto=compress",
  paleo:
    "https://images.pexels.com/photos/1640771/pexels-photo-1640771.jpeg?w=640&auto=compress",
  vegan:
    "https://images.pexels.com/photos/1640775/pexels-photo-1640775.jpeg?w=640&auto=compress",
  mediterranean:
    "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=640&auto=compress",
  fasting:
    "https://images.pexels.com/photos/1640772/pexels-photo-1640772.jpeg?w=640&auto=compress",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const diets = [
  {
    id: "keto",
    title: "דיאטה קטוגנית",
    image: IMAGES.keto,
    description: "דל פחמימות ועשיר בשומנים",
    mainDescription: (
      <>
        הדיאטה הקטוגנית מתמקדת בצריכת שומנים ברמה גבוהה, חלבון מתון ומעט מאוד
        פחמימות (פחות מ-50 גרם ליום). הגוף עובר למצב של קטוזיס, שבו הוא משתמש
        בשומנים כמקור עיקרי לאנרגיה במקום גלוקוז.
        <br />
        גלוקוז הוא מקור האנרגיה המועדף על הגוף, במיוחד עבור מתאמני סיבולת בשל
        יכולתו לספק אנרגיה מהירה במהלך פעילות ממושכת.
        <br />
        מהו מתאמן סיבולת? מתאמן סיבולת הוא אדם העוסק בפעילות גופנית ממושכת כגון
        ריצה למרחקים ארוכים, רכיבה על אופניים, שחייה ועוד. פעילות זו דורשת אספקת
        אנרגיה רציפה לאורך זמן, ולכן צריכת הפחמימות והשומנים משחקת תפקיד קריטי
        בשמירה על ביצועים מיטביים.
      </>
    ),
    pros: [
      "ירידה במשקל",
      "שיפור הסיבולת בקרב מתאמנים",
      "הפחתת דלקתיות",
      "רמות סוכר יציבות",
    ],
    cons: [
      "פגיעה בביצועים בענפי ספורט עצימים",
      "מגבלת אנרגיה מיידית",
      "לא מתאים לאנשים עם פרופיל שומנים גבוה",
      "לא מתאים לחולי לב וסוכרת סוג 1",
    ],
    performance:
      "בעוד שמתאמני סיבולת יכולים להפיק תועלת מהשימוש בשומנים כמקור אנרגיה לאורך זמן, מתאמנים בענפי כוח ומהירות עלולים לחוות ירידה בביצועים בשל מחסור בגליקוגן בשרירים.",
  },
  {
    id: "paleo",
    title: "דיאטת פליאו",
    image: IMAGES.paleo,
    description: "מזון טבעי מהתקופה הפרה-היסטורית",
    mainDescription:
      "דיאטת הפליאו מדגישה מזונות לא מעובדים כגון בשר רזה, דגים, ירקות, פירות ואגוזים, תוך הימנעות מדגנים, מוצרי חלב ומזון מעובד.",
    pros: [
      "עשירה בחלבון",
      "בניית מסת שריר",
      "שיקום לאחר אימונים",
      "איזון רמות סוכר",
      "בריאות מערכת העיכול",
    ],
    cons: [
      "הימנעות מדגנים מקשה על ספורטאים",
      "תכולת סידן נמוכה",
      "חוסר בפחמימות",
      "חוסר בויטמין B",
      "השפעה על פרופיל השומנים",
    ],
    performance:
      "מתאמני כוח יכולים להפיק תועלת מצריכת החלבון הגבוהה, אך בענפי סיבולת ייתכן קושי לשמור על רמות אנרגיה בשל הגבלת הפחמימות.",
  },
  {
    id: "vegan",
    title: "תזונה טבעונית",
    image: IMAGES.vegan,
    description: "מבוססת על מזון מהצומח בלבד",
    mainDescription:
      "תזונה טבעונית מבוססת על ירקות, פירות, קטניות, דגנים ואגוזים ונמנעת לחלוטין מצריכת מוצרים מהחי.",
    pros: [
      "עשירה בסיבים תזונתיים",
      "עשירה בנוגדי חמצון",
      "הפחתת דלקתיות",
      "התאוששות מהירה",
      "שמירה על משקל גוף תקין",
    ],
    cons: [
      "חוסר בברזל",
      "חוסר בויטמין B12",
      "אתגר בצריכת חלבון מלא",
      "פגיעה אפשרית בבניית מסת שריר",
    ],
    performance:
      "ספורטאים טבעונים יכולים להצליח בכל ענף ספורט, אך עליהם להקפיד על תכנון תזונתי נכון כדי למנוע חוסרים ולהבטיח אספקת חלבון וחומרים מזינים חיוניים.",
  },
  {
    id: "mediterranean",
    title: "דיאטה ים-תיכונית",
    image: IMAGES.mediterranean,
    description: "תזונה מאוזנת ומגוונת",
    mainDescription:
      "הדיאטה הים-תיכונית מבוססת על פירות, ירקות, דגנים מלאים, קטניות, אגוזים, דגים ושמן זית, תוך צריכה מתונה של בשר אדום ומוצרי חלב.",
    pros: [
      "בריאות הלב",
      "הפחתת דלקתיות",
      "התאוששות טובה לאחר אימונים",
      "איזון אידיאלי של אבות המזון",
    ],
    cons: ["צריכת שומן עודפת אפשרית", "צריכת קלוריות גבוהה"],
    performance:
      "מתאימה למתאמני סיבולת ולמתאמני כוח כאחד בשל האיזון בין פחמימות לחלבון והשפעתה החיובית על התאוששות השרירים.",
  },
  {
    id: "fasting",
    title: "צום לסירוגין",
    image: IMAGES.fasting,
    description: "חלונות זמן לאכילה וצום",
    mainDescription:
      "צום לסירוגין הוא שיטת תזונה הכוללת שעות מוגדרות של צום ושעות שבהן מותר לאכול. דפוסים נפוצים כוללים צום של 16 שעות ואכילה במשך 8 שעות או צום של 24 שעות אחת לשבוע.",
    pros: [
      "ירידה במשקל",
      "שיפור הרגישות לאינסולין",
      "הפחתת דלקתיות",
      "שיפור המטבוליזם",
      "שיפור ההתאוששות והעמידות",
    ],
    cons: [
      "חולשה באימונים אינטנסיביים",
      "עייפות בזמן הצום",
      "קושי בצריכת חלבון מספקת",
      "מגבלת זמן לצריכת חומרים מזינים",
    ],
    performance:
      "בזמן אימונים אירוביים בצום, הגוף נוטה להשתמש בשומן כמקור אנרגיה, מה שיכול להועיל למתאמני סיבולת. עם זאת, באימוני כוח ואימונים אנאירוביים, חוסר בגלוקוז זמין עלול לפגוע בביצועים וביכולת ההתאוששות. דגש הוא על צריכת חלבון מספקת במהלך חלון האכילה כדי לשמור על מסת שריר ולשפר ביצועים.",
  },
];

const EnduranceAthleteInfo = () => (
  <StyledCard>
    <Typography variant="h5" gutterBottom color="primary">
      מהו מתאמן סיבולת?
    </Typography>
    <Typography variant="body1" sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}>
      מתאמן סיבולת הוא אדם העוסק בפעילות גופנית ממושכת כגון ריצה למרחקים ארוכים,
      רכיבה על אופניים, שחייה ועוד. פעילות זו דורשת אספקת אנרגיה רציפה לאורך
      זמן, ולכן צריכת הפחמימות והשומנים משחקת תפקיד קריטי בשמירה על ביצועים
      מיטביים.
    </Typography>
  </StyledCard>
);

export const DietsSportsPerformance = () => {
  useMeta();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        <ParallaxHeader style={{ height: "50vh" }}>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2rem", md: "3.5rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    דיאטות פופולריות וספורט
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.2rem", md: "1.8rem" },
                      fontWeight: 500,
                    }}
                  >
                    יתרונות, חסרונות והשפעתן על הביצועים
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 3 }}
                >
                  מבוא
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  תזונה מהווה גורם מרכזי בהשגת ביצועים ספורטיביים מיטביים. בשנים
                  האחרונות צצו דיאטות פופולריות רבות, כל אחת עם גישתה הייחודית
                  לאספקת אנרגיה וחומרי בניין לגוף. במאמר זה אסקור את היתרונות
                  והחסרונות של דיאטות קטוגנית, פליאו, טבעונית ודיאטה ים-תיכונית,
                  תוך בחינת השפעתן על ביצועים ספורטיביים ושיפור ההישגים.
                </Typography>
              </StyledCard>
            </motion.div>

            {/* Endurance Athlete Info */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <EnduranceAthleteInfo />
            </motion.div>

            {/* Diet Cards */}
            <Box ref={ref}>
              <Grid container spacing={4}>
                {diets.map((diet, index) => (
                  <Grid key={diet.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate={inView ? "visible" : "hidden"}
                      variants={fadeInUpVariants}
                      transition={{ duration: 0.6, delay: index * 0.2 }}
                      style={{ height: "100%" }}
                    >
                      <DietCard elevation={3}>
                        <Box
                          sx={{
                            height: 200,
                            width: "100%",
                            overflow: "hidden",
                            borderRadius: 2,
                            mb: 2,
                          }}
                        >
                          <img
                            src={diet.image}
                            alt={diet.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </Box>
                        <Typography variant="h5" gutterBottom color="primary">
                          {diet.title}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          gutterBottom
                        >
                          {diet.description}
                        </Typography>
                        <Typography variant="body1" paragraph sx={{ mt: 2 }}>
                          {diet.mainDescription}
                        </Typography>
                        <Divider sx={{ my: 2 }} />
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="success.main"
                          >
                            יתרונות
                          </Typography>
                          <ul>
                            {diet.pros.map((pro, i) => (
                              <Typography component="li" key={i}>
                                {pro}
                              </Typography>
                            ))}
                          </ul>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="error.main"
                            sx={{ mt: 2 }}
                          >
                            חסרונות
                          </Typography>
                          <ul>
                            {diet.cons.map((con, i) => (
                              <Typography component="li" key={i}>
                                {con}
                              </Typography>
                            ))}
                          </ul>
                          <Typography
                            variant="h6"
                            gutterBottom
                            color="info.main"
                            sx={{ mt: 2 }}
                          >
                            השפעה על ביצועים
                          </Typography>
                          <Typography variant="body1" paragraph>
                            {diet.performance}
                          </Typography>
                        </Box>
                      </DietCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 3 }}
                >
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  הבחירה בדיאטה המתאימה תלויה בסוג הספורט, במטרות האישיות ובאורח
                  החיים של המתאמן. הדיאטה הקטוגנית ופליאו עשויות להתאים יותר
                  למתאמני סיבולת ולמתאמני כוח הזקוקים לחלבון רב, בעוד שהתזונה
                  הטבעונית והדיאטה הים-תיכונית מציעות יתרונות בכל ענפי הספורט,
                  תוך שיפור ההתאוששות והבריאות הכללית. צום לסירוגין עשוי לסייע
                  בירידה במשקל ולשפר את המטבוליזם, אך יש לשים לב להשפעתו על
                  הביצועים באימונים אינטנסיביים. כל דיאטה דורשת תכנון נכון כדי
                  למנוע חוסרים תזונתיים ולהבטיח ביצועים מיטביים, ולכן מומלץ
                  להיעזר באנשי מקצוע בתחום התזונה והספורט.
                </Typography>
              </StyledCard>
            </motion.div>
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
