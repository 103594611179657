import {
  Box,
  Container,
  Fade,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const nutritionComparisonData = [
  {
    type: "סיבולת",
    פחמימות: 8,
    חלבונים: 1.3,
    שומנים: 1.2,
  },
  {
    type: "כוח",
    פחמימות: 5,
    חלבונים: 1.9,
    שומנים: 0.8,
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&w=1260";

const ENDURANCE_IMAGE =
  "https://images.pexels.com/photos/2294361/pexels-photo-2294361.jpeg?auto=compress&w=640";

const STRENGTH_IMAGE =
  "https://images.pexels.com/photos/1229356/pexels-photo-1229356.jpeg?auto=compress&w=640";

export const EnduranceVsStrengthNutrition = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  return (
    <ParallaxProvider>
      {/* Hero Section */}
      <Box sx={{ height: "50vh", width: "100%" }}>
        <ParallaxBanner style={{ height: "100%" }}>
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            scale={[1.2, 1]}
            opacity={[0.9, 1]}
            translateY={[0, 50]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                padding: { xs: 2, md: 4 },
              }}
            >
              <Zoom in timeout={1000}>
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "4rem" },
                    lineHeight: 1.2,
                    marginBottom: 3,
                  }}
                >
                  תזונה תומכת אימוני סיבולת לעומת תזונה תומכת אימוני כוח
                </Typography>
              </Zoom>
              <Fade in timeout={2000}>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2.2rem" },
                    fontWeight: 500,
                  }}
                >
                  צרכים והבדלים עיקריים
                </Typography>
              </Fade>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>
      </Box>

      <Container maxWidth="lg">
        {/* Introduction */}
        <Box sx={{ p: 4, mb: 4 }}>
          <Typography variant="body1">
            תזונה נכונה היא מרכיב מפתח להצלחה בספורט, אך צרכיהם של מתאמני סיבולת
            ושל מתאמני כוח שונים זה מזה בשל אופי הפעילות הגופנית והדרישות
            הפיזיולוגיות הייחודיות לכל תחום. פעילות אירובית מאופיינת במאמץ ממושך
            שמצריך אספקת חמצן רציפה, בעוד פעילות אנאירובית מתבצעת בעצימות גבוהה
            לפרקי זמן קצרים ודורשת אנרגיה מיידית ממקורות אחרים בגוף. מאמר זה
            יבחן את הצרכים התזונתיים של כל סוג ספורט - אירובי ואנאירובי, את
            תפקידי אבות המזון בביצועים ובשיקום, וכן את ההבדלים המרכזיים בין
            תזונת סיבולת לתזונת כוח.
          </Typography>
        </Box>

        {/* Endurance Section */}
        <Box data-aos="fade-right" sx={{ mb: 8 }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ color: theme.palette.primary.main }}
          >
            1. צרכים תזונתיים של מתאמני סיבולת
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body1">
                מתאמני סיבולת, כמו רצים למרחקים ארוכים, רוכבי אופניים ושחיינים,
                זקוקים לאספקת אנרגיה מתמשכת שתתמוך בפעילות ממושכת. על פי מחקרים
                שפורסמו ב-Journal of the International Society of Sports
                Nutrition, פחמימות הן מקור הדלק העיקרי עבור מתאמני סיבולת,
                מכיוון שהן מתפרקות במהירות לגלוקוז המספק אנרגיה לשרירים.
              </Typography>
              <Typography variant="body1">
                ההמלצה למתאמני סיבולת היא לצרוך כ-6-10 גרם פחמימות לכל קילוגרם
                משקל גוף ביום, בהתאם לעומס האימונים. מקורות פחמימות מורכבות כמו
                שיבולת שועל, אורז חום וקינואה מספקים אנרגיה יציבה לאורך זמן,
                בעוד שפחמימות פשוטות כמו בננות וג'לים אנרגטיים מומלצות לפני
                ובמהלך הפעילות כדי לשמור על רמות גלוקוז תקינות.
              </Typography>
              <Typography variant="body1">
                בנוסף לפחמימות, צריכת חלבון מספקת חיונית לשיקום השרירים לאחר
                אימון ממושך. צריכת החלבון המומלצת עומדת על 1.2-1.4 גרם לק"ג משקל
                גוף ביום. שומנים בריאים מאגוזים, אבוקדו ושמן זית מספקים מקור
                אנרגיה נוסף לפעילות ממושכת ומסייעים בשמירה על בריאות הלב.
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                component="img"
                src={ENDURANCE_IMAGE}
                alt="אימוני סיבולת ותזונה"
                sx={{
                  width: "100%",
                  height: 300,
                  objectFit: "cover",
                  borderRadius: 2,
                }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Strength Section */}
        <Box data-aos="fade-left" sx={{ mb: 8 }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ color: theme.palette.primary.main }}
          >
            2. צרכים תזונתיים של מתאמני כוח
          </Typography>
          <Grid container spacing={4} alignItems="center">
            <Grid size={{ xs: 12, md: 6 }}>
              <Box
                component="img"
                src={STRENGTH_IMAGE}
                alt="אימוני כוח ותזונה"
                sx={{
                  width: "100%",
                  height: 300,
                  objectFit: "cover",
                  borderRadius: 2,
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant="body1">
                מתאמני כוח, כגון מפתחי גוף, מרימי משקולות ומתאמני קרוספיט,
                מתמקדים בבניית מסת שריר ובהגדלת כוחם הפיזי. חלבון הוא אבן הבניין
                המרכזית של רקמות השריר, ולכן צריכתו היא קריטית עבור מתאמני כוח.
                מחקרים ב-American Journal of Clinical Nutrition מראים כי צריכת
                חלבון של 1.6-2.2 גרם לכל ק"ג משקל גוף ביום מקדמת בניית שריר
                ושיקום מהיר יותר.
              </Typography>
              <Typography variant="body1">
                בניגוד למתאמני סיבולת, מתאמני כוח אינם זקוקים לכמות גדולה של
                פחמימות, אך פחמימות עדיין חיוניות לייצור אנרגיה במהלך אימוני כוח
                אינטנסיביים. ההמלצה היא לצרוך כ-4-6 גרם פחמימות לכל קילוגרם משקל
                גוף ביום, תוך התמקדות בפחמימות מורכבות. שומנים בריאים מסייעים
                בשמירה על מאזן הורמונלי חיובי ותומכים בהתאוששות.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Key Differences Section */}
        <Box data-aos="fade-up" sx={{ mb: 8 }}>
          <Typography
            variant="h4"
            component="h2"
            gutterBottom
            sx={{ color: theme.palette.primary.main }}
          >
            3. ההבדלים העיקריים בין תזונת סיבולת לתזונת כוח
          </Typography>
          <Paper elevation={3} sx={{ p: 4, mb: 4 }}>
            <Typography variant="body1">
              ההבדל המרכזי בין תזונת סיבולת לתזונת כוח הוא ביחס בין הפחמימות
              לחלבונים ובכמות הקלוריות הנדרשת. מתאמני סיבולת זקוקים לכמות גדולה
              יותר של פחמימות לשמירה על אנרגיה לאורך זמן, בעוד שמתאמני כוח
              זקוקים ליותר חלבון לבניית ושיקום השרירים. בנוסף, צריכת השומנים אצל
              מתאמני סיבולת גבוהה יותר מאשר אצל מתאמני כוח, שכן היא מהווה מקור
              אנרגיה לפעילות ממושכת.
            </Typography>
            <Typography variant="body1">
              זמן הצריכה גם הוא חשוב: מתאמני סיבולת צריכים להטעין את מאגרי
              הפחמימות לפני פעילות ממושכת, בעוד שמתאמני כוח צריכים לוודא שהם
              צורכים חלבון בכמות מספקת לאורך היום כדי לתמוך בבניית השריר
              והשיקום. בניגוד לדעה הרווחת בעבר, אין הכרח לצרוך חלבון מיד לאחר
              האימון, שכן חלון הזמן לבניית שריר נמשך מספר שעות לאחר האימון. עם
              זאת, צריכת חלבון בפרק זמן של שעתיים לאחר האימון עדיין יכולה לתרום
              להתאוששות מיטבית. בשני המקרים, איזון נכון של אבות המזון מסייע
              בשיפור הביצועים ובמניעת פציעות.
            </Typography>
          </Paper>

          {/* Comparison Chart */}
          <Paper elevation={3} sx={{ p: 4 }}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={nutritionComparisonData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="type" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="פחמימות" fill="#8884d8" />
                <Bar dataKey="חלבונים" fill="#82ca9d" />
                <Bar dataKey="שומנים" fill="#ffc658" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Box>

        {/* Summary */}
        <Box data-aos="fade-up">
          <Paper elevation={3} sx={{ p: 4, bgcolor: theme.palette.grey[50] }}>
            <Typography
              variant="h4"
              component="h2"
              gutterBottom
              sx={{ color: theme.palette.primary.main }}
            >
              סיכום
            </Typography>
            <Typography variant="body1">
              תזונת סיבולת ותזונת כוח נבדלות בעיקר בכמות הפחמימות והחלבונים
              הנדרשת ובזמן הצריכה שלהן. מתאמני סיבולת זקוקים לכמות גדולה יותר של
              פחמימות כדי לשמור על אספקת אנרגיה מתמשכת, בעוד שמתאמני כוח מתמקדים
              בצריכת חלבון לצורך בניית מסת שריר ושיקום מהיר. לצד הכמות, ישנה
              חשיבות גם לאיכות החלבונים והפחמימות הנצרכים, כמו גם לשילוב נכון של
              ויטמינים, מינרלים ושאר נוטריינטים חיוניים התומכים בביצועים
              ובשיקום. בין אם מדובר במתאמן מקצועי או במי שמשלב אימונים כחלק
              מאורח חיים בריא, ליווי של גורם מוסמך ומקצועי, כמו דיאטנית קלינית
              או תזונאי ספורט, יכול לסייע בהתאמת התפריט לצרכים האישיים, לשיפור
              ההישגים ולשמירה על בריאות מיטבית לאורך זמן.
            </Typography>
          </Paper>
        </Box>

        <ContactButtons />
      </Container>
    </ParallaxProvider>
  );
};
