import {
  faBalanceScale,
  faCommentDots,
  faComments,
  faDumbbell,
  faUserCheck,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons } from "modules/common";
import { useMeta } from "modules/common/hooks/use-meta.hook";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HERO_IMAGE =
  "https://images.pexels.com/photos/3768894/pexels-photo-3768894.jpeg?w=1260&auto=compress";
const SUPPORT_IMAGE =
  "https://images.pexels.com/photos/4149201/pexels-photo-4149201.jpeg?w=1260&auto=compress";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const SupportCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const supportMethods = [
  {
    id: "communication",
    title: "תקשורת תומכת ופתוחה",
    icon: faComments,
    color: "#FF6B6B",
    description:
      "מחקרים מדגישים את החשיבות של שיח חיובי ופתוח על הגוף, תוך הימנעות מהתמקדות במשקל או במראה חיצוני. חשוב לעודד את הילד לדבר על רגשותיו ולפתח מודעות לגורמים המשפיעים על התיאבון והמשקל.",
  },
  {
    id: "boundaries",
    title: "הצבת גבולות בריאים",
    icon: faBalanceScale,
    color: "#4ECDC4",
    description:
      "הצבת גבולות ברורים סביב הרגלי האכילה ללא ביקורת או שיפוטיות. לדוגמה, עידוד אכילה מסודרת של שלוש ארוחות עיקריות ושתיים-שלוש ארוחות ביניים ביום, תוך צמצום מזון מעובד ושתייה ממותקת.",
  },
  {
    id: "language",
    title: "שימוש בשפה חיובית ומעצימה",
    icon: faCommentDots,
    color: "#45B7D1",
    description:
      "חשוב להימנע מהערות פוגעניות על משקל או אכילה. במקום זאת, יש להשתמש בשפה המעודדת בחירה מודעת של מזון בריא תוך הדגשת היתרונות הבריאותיים ולא המראה החיצוני.",
  },
  {
    id: "activity",
    title: "עידוד פעילות גופנית",
    icon: faDumbbell,
    color: "#96CEB4",
    description:
      "פעילות גופנית תורמת לשמירה על משקל תקין ולשיפור מצב הרוח. מומלץ לעודד את בני הנוער לבחור בפעילות גופנית שהם נהנים ממנה, כמו ריקוד, רכיבה על אופניים או משחקי כדור.",
  },
  {
    id: "example",
    title: "מתן דוגמה אישית",
    icon: faUserCheck,
    color: "#FFB6B9",
    description:
      "הורים המפגינים יחס חיובי כלפי הגוף שלהם ומקפידים על אורח חיים בריא מהווים מודל לחיקוי עבור ילדיהם.",
  },
];

export const WeightBodyImageBalance = () => {
  useMeta();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                איך לעזור למתבגר שלי לשמור על משקל בריא בלי לפגוע בדימוי הגוף?
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                גיל ההתבגרות מלווה בשינויים פיזיולוגיים ורגשיים משמעותיים, אשר
                משפיעים על המשקל ודימוי הגוף. הסערה ההורמונלית והגוף המשתנה
                משפיעים לא רק על מראה חיצוני אלא גם על הביטחון העצמי. מחקרים
                מראים כי בתקופה זו בני נוער הופכים רגישים יותר להערות ולביקורת,
                מה שעשוי להוביל לדימוי גוף שלילי, חוסר ביטחון ואף הפרעות אכילה.
                על כן, חשוב שההורים ידעו כיצד לתמוך בילדיהם המתבגרים בצורה
                שתמקדמת, שאינה פוגעת בתקשורת ובקשר אל מול הנער, וגם שתעודד שמירה
                על משקל תקין תוך חיזוק תחושת הערך העצמי והימנעות מהשוואות או
                ביקורת פוגענית.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Physiological Changes */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                שינויים פיזיולוגיים ותזונתיים בגיל ההתבגרות
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                העלייה בקצב חילוף החומרים והשינויים ההורמונליים משפיעים על
                התיאבון והמשקל. בני נוער זקוקים לאנרגיה ולחומרי תזונה בכמות
                מספקת כדי לתמוך בגדילה ולהתפתחות תקינה. יחד עם זאת, תזונה לא
                מאוזנת בשילוב עם אורח חיים יושבני עלולה להוביל לעלייה במשקל
                ולתחושות אשמה ובושה הקשורות למראה החיצוני.
              </Typography>
            </StyledPaper>
          </motion.div>

          {/* Support Methods */}
          <Typography
            variant="h4"
            align="center"
            sx={{ mt: 8, mb: 4, fontWeight: 600 }}
          >
            כיצד לתמוך במתבגר תוך שמירה על דימוי גוף חיובי
          </Typography>

          <Grid container spacing={3}>
            {supportMethods.map((method) => (
              <Grid key={method.id} size={{ xs: 12, md: 4 }}>
                <SupportCard data-aos="fade-up">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={method.icon}
                      style={{
                        fontSize: "2.5rem",
                        color: method.color,
                        marginBottom: "1rem",
                      }}
                    />
                    <Typography variant="h6" gutterBottom>
                      {method.title}
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                      {method.description}
                    </Typography>
                  </Box>
                </SupportCard>
              </Grid>
            ))}
          </Grid>

          {/* Avoiding Criticism */}
          <Box sx={{ mt: 8 }}>
            <ParallaxBanner
              style={{
                height: "40vh",
                borderRadius: "20px",
                overflow: "hidden",
                marginBottom: "2rem",
              }}
            >
              <ParallaxBannerLayer
                image={SUPPORT_IMAGE}
                speed={-15}
                opacity={[0.7, 1]}
                scale={[1.1, 1]}
              />
            </ParallaxBanner>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                כיצד להימנע מביקורת הפוגעת בדימוי הגוף
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                ביקורת על המשקל או על הרגלי האכילה עלולה לגרום לתחושות שליליות
                ולעודד התנהגויות אכילה לא בריאות. מחקרים מצביעים על כך שהערות
                שליליות מצד הורים קשורות לסיכון מוגבר לפתח דפוסי אכילה רגשית
                ולהתמודדות עם דימוי גוף שלילי גם בבגרות. במקום ביקורת, מומלץ
                להציע תמיכה והכוונה:
              </Typography>
              <Box component="ul" sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}>
                <Typography component="li" sx={{ mb: 1 }}>
                  שיח מכבד: להתמקד בתחושות ובצרכים של הילד ולא במספר שעל המשקל
                  (ובכלל רצוי שהשיח בבית לא יסוב סביב משקל).
                </Typography>
                <Typography component="li" sx={{ mb: 1 }}>
                  חיזוק חיובי: לשבח מאמצים והשקעה באורח חיים בריא ולא רק תוצאות
                  חיצוניות.
                </Typography>
                <Typography component="li" sx={{ mb: 1 }}>
                  לעזור ולתמוך ברמה הפרקטית: להכין אוכל, להנגיש ירקות וחלבונים
                  איכותיים
                </Typography>
                <Typography component="li">
                  מודעות עצמית: ללמד את הילדים לזהות את תחושות הרעב והשובע
                  ולהימנע מאכילה מתוך שעמום או לחץ רגשי.
                </Typography>
              </Box>
            </StyledPaper>
          </Box>

          {/* Summary */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <StyledPaper elevation={3} sx={{ mt: 6 }}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                ליווי נכון מצד ההורים יכול לסייע למתבגרים לשמור על משקל תקין
                מבלי לפגוע בדימוי הגוף. באמצעות תקשורת תומכת, הצבת גבולות בריאים
                והימנעות מהערות ביקורתיות, ניתן לעודד את המתבגרים לאמץ הרגלי
                אכילה מאוזנים ולפתח גישה חיובית כלפי גופם. בנוסף, ליווי מקצועי
                על ידי דיאטן מוסמך יכול להעניק כלים מעשיים להתמודדות עם אתגרי
                התזונה בגיל ההתבגרות ולסייע ביצירת תשתית לבריאות טובה לכל החיים.
              </Typography>
            </StyledPaper>
          </motion.div>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
