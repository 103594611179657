import {
  faAppleWhole,
  faBed,
  faBrain,
  faCarrot,
  faDna,
  faDumbbell,
  faHeartbeat,
  faPersonRunning,
  faWeight,
  faYinYang,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const FactorCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  borderRadius: 15,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const SolutionCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
  position: "relative",
  overflow: "hidden",
  marginBottom: theme.spacing(3),
}));

const factors = [
  {
    id: "hormones",
    title: "שינויים הורמונליים",
    description:
      "הירידה ברמות האסטרוגן משפיעה על האופן שבו הגוף מאחסן שומן. בעוד שבגיל הפוריות השומן נוטה להצטבר באזור הירכיים והמותניים, לאחר גיל המעבר ישנה נטייה מוגברת לאגירת שומן באזור הבטן (השמנה ויסצרלית).",
    icon: faDna,
    color: "#FF6B6B",
  },
  {
    id: "metabolism",
    title: "ירידה בקצב חילוף החומרים",
    description:
      "עם השנים, הגוף צורך פחות אנרגיה במנוחה, מה שעלול להוביל לעודף קלורי ולאגירת שומן.",
    icon: faHeartbeat,
    color: "#4ECDC4",
  },
  {
    id: "cortisol",
    title: "השפעת רמות הקורטיזול",
    description:
      "לחץ נפשי וחשיפה ממושכת לסטרס מעלים את רמות הורמון הקורטיזול, אשר תורם לאגירת שומן בטני ומקשה על פירוקו.",
    icon: faBrain,
    color: "#45B7D1",
  },
  {
    id: "microbiome",
    title: "שינויים במיקרוביום",
    description:
      "חיידקי המעיים משפיעים על עיבוד המזון ועל האופן שבו הגוף שומר או משחרר שומן. שינויים במאזן החיידקים עלולים להוביל לדלקתיות ולעלייה במשקל.",
    icon: faDna,
    color: "#9B59B6",
  },
  {
    id: "muscle",
    title: "אובדן מסת שריר",
    description:
      "ירידה במסת השריר עם הגיל מובילה לירידה בהוצאה האנרגטית היומית ולעלייה באגירת שומן.",
    icon: faWeight,
    color: "#96CEB4",
  },
  {
    id: "lifestyle",
    title: "שינויים בקצב החיים והרגלי האכילה",
    description:
      "בגיל המעבר, קצב החיים משתנה, ויש יותר זמן פנוי בערבים, יותר יציאות ומפגשים חברתיים הכוללים אוכל ואלכוהול. בנוסף, אכילה מאוחרת בגיל זה משפיעה על חילוף החומרים, ועלולה להוביל לעלייה.",
    icon: faHeartbeat,
    color: "#E67E22",
  },
];

const solutions = [
  {
    category: "תזונה מותאמת לגיל המעבר",
    icon: faAppleWhole,
    color: "#4CAF50",
    items: [
      {
        text: "תזונה עשירה בחלבון – מסייעת לשמירה על מסת השריר ומגבירה את תחושת השובע.",
        icon: faCarrot,
      },
      {
        text: "הפחתת צריכת סוכרים ופחמימות מעובדות – אלו מעלים את הפרשת האינסולין ותורמים לאגירת שומן בטני.",
        icon: faDna,
      },
      {
        text: "שילוב שומנים בריאים – חומצות שומן חד ורב בלתי רוויות (כגון אבוקדו, אגוזים ושמן זית) תורמות לאיזון הורמונלי ומפחיתות דלקתיות.",
        icon: faAppleWhole,
      },
      {
        text: "סיבים תזונתיים – מסייעים לאיזון רמות הסוכר בדם ולתחושת שובע ממושכת, מה שמונע אכילת יתר.",
        icon: faCarrot,
      },
    ],
  },
  {
    category: "פעילות גופנית להמרצת חילוף החומרים",
    icon: faDumbbell,
    color: "#2196F3",
    items: [
      {
        text: "אימוני כוח – שימור מסת שריר חיוני לשיפור הוצאה קלורית במנוחה.",
        icon: faDumbbell,
      },
      {
        text: "אימוני אירובי – הליכה, ריצה, שחייה או רכיבה על אופניים מסייעים לשריפת קלוריות ולהפחתת שומן בטני.",
        icon: faPersonRunning,
      },
      {
        text: "אימוני אינטרוולים (HIIT) – מחקרים מראים כי אימונים עצימים קצרי טווח יעילים במיוחד לשריפת שומן בטני.",
        icon: faHeartbeat,
      },
    ],
  },
  {
    category: "ניהול סטרס ושיפור איכות השינה",
    icon: faYinYang,
    color: "#9C27B0",
    items: [
      {
        text: "תרגולי נשימה, מדיטציה ויוגה מפחיתים רמות קורטיזול, שתורם להצטברות שומן בטני.",
        icon: faYinYang,
      },
      {
        text: "שינה איכותית בת 7-8 שעות בלילה חיונית לאיזון הורמונלי ולשמירה על משקל תקין.",
        icon: faBed,
      },
      {
        text: "מציאת הדרך הנכונה עבור כל אחד להרגע, לפרוק עומס ומתח ללא קשר לאוכל ואכילה",
        icon: faBrain,
      },
    ],
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const HERO_IMAGE =
  "https://images.pexels.com/photos/4098228/pexels-photo-4098228.jpeg?w=1260&auto=compress";

export const AbdominalFat = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  שומן בטני בגיל המעבר
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  מדוע הוא מצטבר וכיצד ניתן להתמודד?
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  גיל המעבר מביא עמו שינויים הורמונליים רבים, המשפיעים על מבנה
                  הגוף, חילוף החומרים ופיזור השומן. אחד השינויים הבולטים ביותר
                  הוא הצטברות השומן באזור הבטן, גם בקרב נשים שבעבר לא נטו להשמנה
                  בטנית. במאמר זה נסקור את הסיבות הפיזיולוגיות לשינוי זה ונתמקד
                  בדרכים מבוססות מחקרים לניהול ושמירה על משקל גוף בריא בגיל
                  המעבר.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Factors Grid */}
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 4, fontWeight: 700 }}
            >
              מדוע שומן בטני מצטבר בגיל המעבר?
            </Typography>
            <Grid container spacing={3}>
              {factors.map((factor) => (
                <Grid key={factor.id} size={{ xs: 12, sm: 6, md: 3 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ height: "100%" }}
                  >
                    <FactorCard elevation={3}>
                      <FontAwesomeIcon
                        icon={factor.icon}
                        size="3x"
                        style={{ color: factor.color }}
                      />
                      <Typography variant="h6" sx={{ fontWeight: 700, my: 2 }}>
                        {factor.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {factor.description}
                      </Typography>
                    </FactorCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>

            {/* Solutions Section */}
            <Box sx={{ mt: 8 }}>
              <Typography
                variant="h2"
                align="center"
                sx={{
                  mb: 6,
                  fontWeight: 700,
                  fontSize: { xs: "2rem", md: "2.5rem" },
                }}
              >
                כיצד ניתן להפחית שומן בטני?
              </Typography>
              <Box>
                {solutions.map((solution, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.2 }}
                  >
                    <SolutionCard
                      elevation={0}
                      sx={{
                        borderRight: `4px solid ${solution.color}`,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 3,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={solution.icon}
                          style={{
                            color: solution.color,
                            fontSize: "2rem",
                            marginLeft: "1.5rem",
                          }}
                        />
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 700,
                            color: theme.palette.text.primary,
                            fontSize: { xs: "1.5rem", md: "1.75rem" },
                          }}
                        >
                          {solution.category}
                        </Typography>
                      </Box>
                      <Box sx={{ pl: 7 }}>
                        {solution.items.map((item, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              mb: 2.5,
                              "&:last-child": { mb: 0 },
                            }}
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              style={{
                                color: solution.color,
                                fontSize: "1.2rem",
                                marginLeft: "1rem",
                                marginTop: "0.2rem",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "1.1rem",
                                lineHeight: 1.6,
                                flex: 1,
                                color: theme.palette.text.secondary,
                              }}
                            >
                              {item.text}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </SolutionCard>
                  </motion.div>
                ))}
              </Box>
            </Box>

            {/* Summary */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ fontWeight: 700, textAlign: "center", mb: 3 }}
                >
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  הצטברות שומן בטני בגיל המעבר היא תוצאה של שינויים הורמונליים,
                  ירידה בקצב חילוף החומרים וגורמים סביבתיים כמו סטרס ושינה
                  לקויה. עם זאת, ניתן להתמודד עם התופעה באמצעות תזונה מאוזנת
                  מותאמת לשלב במעגל החיים, רכישת והטמעת הרגלים שמקדמים אורח חיים
                  בריא. אימוץ אסטרטגיות אלו יסייע לשמירה על בריאות כללית, ויסייע
                  להפחתת הסיכון למחלות מטבוליות הנפוצות בגיל זה.
                </Typography>
              </StyledPaper>
            </Parallax>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
