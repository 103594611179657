import {
  faAppleWhole,
  faBuilding,
  faClock,
  faCutlery,
  faLightbulb,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const TipBox = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.palette.primary.main}10`,
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  marginTop: theme.spacing(2),
  "& ul": {
    paddingLeft: theme.spacing(3),
    marginBottom: 0,
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/958545/pexels-photo-958545.jpeg?auto=compress&w=1260",
  office:
    "https://images.pexels.com/photos/4065876/pexels-photo-4065876.jpeg?auto=compress&w=640",
  restaurant:
    "https://images.pexels.com/photos/1267320/pexels-photo-1267320.jpeg?auto=compress&w=640",
  sweets:
    "https://images.pexels.com/photos/1291712/pexels-photo-1291712.jpeg?auto=compress&w=640",
  cooking:
    "https://images.pexels.com/photos/1660030/pexels-photo-1660030.jpeg?auto=compress&w=640",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const challenges = [
  {
    id: "office",
    title: "ניהול תזונה בשגרת העבודה ובמשרד",
    description:
      "הסביבה המשרדית טומנת בחובה פיתויים כמו חטיפים במטבחון, ארוחות צוות והזמנות בוולט. מחקרים מראים כי תכנון מוקדם תורם לשמירה על תזונה מאוזנת גם בסביבה זו.",
    icon: faBuilding,
    color: "#4CAF50",
    tips: [
      "היערכות מראש: הכינו בבית ארוחות מאוזנות והביאו אותן לעבודה בקופסאות. שלבו חלבונים (דג, עוף, ביצה, גבינותף טופו ...), פחמימות מורכבות (כגון קינואה או דגנים מלאים) וירקות.",
      "התמודדות עם הזמנות בוולט: לפני ההזמנה, בחנו את האפשרויות הבריאות בתפריט, העדיפו מנותעשירות בירקות הכוללות חלבון בכמות מספקת. עשו שינויים במנה - אפשר להוריד מרכיבים או להוסיף וכך להתאים את המנה לצרכים ולרצונות שלכם.",
      "אכילה חברתית: אם משתתפים בארוחות צוות, לצד שימת דגש על שתייה מרובה של מים / תה במהלך היום, נסו להגיע במידת רעב המאפשרת לכם לחשוב ולבחור ולא רעבים מידי, מנת ביניים חשובה בעיקר בימים כאלה של מפגשים / אירועי צוות",
    ],
    image: IMAGES.office,
  },
  {
    id: "restaurant",
    title: "אכילה בחוץ: איך התפריט משפיע על הבחירות שלנו",
    description:
      'תפריטי מסעדות בנויים כך שיגרמו לנו להזמין מנות עתירות קלוריות באמצעות תיאורים מפתים כמו "גבינה נמסה", "בציפוי פריך" ו"רוטב עשיר". מחקרים מוכיחים שתיאורים אלו מגבירים את החשק לאכול גם כשאיננו רעבים.',
    icon: faCutlery,
    color: "#FF5722",
    tips: [
      "כשאתם שבעים ורגועים, קראו את התפריט מראש. כך תוכלו לבחור את המנה הנכונה עבורכם, שתואמת את המטרות האישיות שלכם ופחות מושפעת מאכילה חברתית או ממידת הרעב.",
      "העדיפו מנות מבוססות חלבון עם ירקות והשתדלו לבחור תוספות אפויות, מאודות או צלויות במקום מטוגנות.",
      "אם ישנה אפשרות, בקשו את הרטבים בצד כדי לשלוט בכמות ולהפחית את הצריכה הקלורית.",
    ],
    image: IMAGES.restaurant,
  },
  {
    id: "sweets",
    title: "התמודדות עם חשקים פתאומיים למתוקים",
    description:
      "חשקים למתוקים נובעים לעיתים מצרכים רגשיים, עייפות או ירידה ברמות הסוכר בדם. מחקרים מראים כי אכילה מאוזנת לאורך היום מפחיתה את התשוקה למזונות עתירי סוכר.",
    icon: faAppleWhole,
    color: "#E91E63",
    tips: [
      "הפכו את אכילת המתוק לארוחת ביניים - שלבו יוגורט / חלב / פרי - כדי להגדיל את המנה ולהאריך את משך הזמן בו אתם אוכלים.",
      "הפנו קשב לאכילת המתוק - עצרו את העבודה, העיסוק במחשב או הפלאפון, כך תוכלי להפנות קשב ותשומת לב לטעמים ולמרקמים השונים שיש במתוק שבחרתם",
      "נסו לשנות חלק מהמתוקים לפירות במהלך היום",
      "פזרו את הארוחות לאורך היום והשתדלו להמנע מארוחת צהריים גדולה וכבדה -שכן פעמים רבות הצורך במקום נובע מעייפות הנגרמת לאחר ארוחה כבדה וקשה לעיכול",
    ],
    image: IMAGES.sweets,
  },
  {
    id: "cooking",
    title: "איך לאכול נכון גם כשאין זמן לבשל",
    description:
      "העומס היומיומי מקשה על היערכות והכנת אוכל ביתי, אך ניתן להפוך את המשימה לקלה יותר גם כשאין זמן או חשק לבשל. הכנת אוכל מהירה וזמינות של רכיבים בריאים יכולים לסייע לשמור על תזונה מאוזנת.",
    icon: faClock,
    color: "#2196F3",
    tips: [
      "הכינו מראש מנות והקפיאו אותן לשימוש מהיר בזמנים עמוסים.",
      "שלבו ירקות שלמים ולא סלטים מורכבים בשגרה, או השתמשו בתערובות ירקות חתוכים מראש.",
      "השתמשו בירקות קפואים להקפצה, אפייה או בישול – הם מוכנים לשימוש ומוסיפים סיבים, צבע וטעם בקלות לכל ארוחה.",
      "סדרו את המקרר כך שהמזונות הבריאים יהיו נגישים: שטפו את הירקות לפני ההכנסה למקרר, והניחו בקדמת המדפים גבינות רזות, יוגורטים עתירי חלבון, ביצים ועוד – כך הבחירה תהיה קלה גם כשממהרים או עייפים.",
      "לשעת חירום: החזיקו במזווה מוצרים בסיסיים כמו קטניות מבושלות בקופסה, שימורי טונה או טופו, פסטה מחיטה מלאה או אורז מלא – שמאפשרים הכנה מהירה ומזינה",
    ],
    image: IMAGES.cooking,
  },
];

export const EverydayDietChallengesGuide = () => {
  useMeta();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    התמודדות עם אתגרים יומיומיים בתזונה
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    מדריך מעשי
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 3 }}
                >
                  מבוא
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  שגרה עמוסה, עבודה במשרד ואירועים חברתיים מציבים אתגרים
                  יומיומיים בניהול תזונה בריאה. לצד החשיבות הבריאותית של תזונה
                  נכונה, והרצון להרגיש טוב עם עצמנו, עם הבחירות ועם הגוף שלנו.
                  רבים מגלים כי ניהול האוכל והמשקל נשכח בקלות מול עומס המשימות
                  והלחצים היום-יומיים. מאמר זה בוחן את האתגרים שמציבה השגרה
                  השוחקת ומציע כלים מעשיים להתמודדות שיאפשרו לשמור על איזון מבלי
                  להרגיש מוגבלים.
                </Typography>
              </StyledCard>
            </motion.div>

            {/* Challenges Section */}
            {challenges.map((challenge, index) => (
              <motion.div
                key={challenge.id}
                initial="hidden"
                animate="visible"
                variants={fadeInUpVariants}
                transition={{ duration: 0.6, delay: index * 0.2 }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${challenge.color}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={challenge.icon}
                            style={{
                              fontSize: 32,
                              color: challenge.color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: challenge.color, fontWeight: 700 }}
                        >
                          {challenge.title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        {challenge.description}
                      </Typography>
                      <TipBox>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 2,
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faLightbulb}
                            style={{
                              fontSize: 24,
                              color: challenge.color,
                            }}
                          />
                          <Typography
                            variant="h6"
                            sx={{ color: challenge.color }}
                          >
                            טיפים מעשיים
                          </Typography>
                        </Box>
                        <ul>
                          {challenge.tips.map((tip, tipIndex) => (
                            <motion.li
                              key={tipIndex}
                              initial={{ opacity: 0, x: -20 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                delay: 0.1 * tipIndex,
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontSize: "1.1rem",
                                  lineHeight: 1.6,
                                  mb: 1,
                                }}
                              >
                                {tip}
                              </Typography>
                            </motion.li>
                          ))}
                        </ul>
                      </TipBox>
                    </StyledCard>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}
                  >
                    <Box
                      component="img"
                      src={challenge.image}
                      alt={challenge.title}
                      sx={{
                        width: "100%",
                        height: 400,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            ))}

            {/* Summary Section */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Typography
                  variant="h4"
                  gutterBottom
                  color="primary"
                  sx={{ mb: 4, fontWeight: 700 }}
                >
                  לסיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  ניהול תזונה בריאה בסביבה היומיומית דורש מודעות ותכנון מראש, אך
                  אינו חייב להיות מאתגר או מגביל. בחירה מושכלת במסעדות, היערכות
                  לעבודה והתמודדות נכונה עם חשקים פתאומיים מאפשרים לשמור על אורח
                  חיים בריא מבלי לוותר על ההנאה מאוכל. באמצעות יישום הטיפים
                  המעשיים המובאים כאן, ניתן להתמודד עם הפיתויים היומיומיים
                  ולהשיג את המטרות התזונתיות בצורה מאוזנת ומספקת.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  תפריט גנרי מקשה מאוד על התמדה לאורך זמן, שכן הוא לא מאפשר
                  גמישות ויוצר תסכול ותחושת כישלון, ולכן ליווי מקצועי של דיאטנית
                  מנוסה יכול להוות מפתח להצלחה. דיאטנית תדע לספק כלים להתגמשות
                  מקדמת ותלעזור בניהול האתגרים היומיומיים, תוך התאמה אישית
                  שמאפשרת לשמור על אורח חיים בריא ומאוזן גם בטווח הארוך.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  חשוב לזכור שגם שינויים קטנים לאורך זמן מובילים לתוצאות
                  משמעותיות. היכולת לשלב תזונה בריאה בחיי היומיום היא אפשרית
                  לכולם, ועם התמדה וגישה גמישה תוכלו ליהנות מאוכל ועדיין להשיג
                  את מטרותיכם.
                </Typography>
              </StyledCard>
            </motion.div>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
