import {
  faAppleWhole,
  faBed,
  faBrain,
  faChartLine,
  faHeartbeat,
  faRunning,
  faTv,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  "& svg": {
    marginLeft: theme.spacing(2),
    fontSize: "2rem",
    color: theme.palette.primary.main,
  },
}));

const factorsData = [
  {
    icon: faTv,
    title: "אכילה בהיסח דעת",
    description:
      "ילדים הצופים בטלוויזיה או משחקים במשחקי וידאו נוטים לאכול ללא מודעות לכמות המזון שהם צורכים, מה שמוביל לצריכת יתר של קלוריות. מחקר שפורסם ב-American Journal of Clinical Nutrition מצא כי ילדים שאכלו תוך כדי צפייה בטלוויזיה נטו לאכול יותר בהשוואה לילדים שאכלו ללא הסחות דעת",
  },
  {
    icon: faAppleWhole,
    title: "פרסום מזון לא בריא",
    description:
      "ילדים חשופים באופן מוגבר לפרסומות למזון עתיר שומן, סוכר ומלח, מה שמשפיע על העדפותיהם התזונתיות ומגביר את הצריכה של מזונות לא בריאים. מחקר שנערך על ידי The Lancet Child & Adolescent Health הראה כי ילדים הצורכים יותר פרסומות טלוויזיה צורכים גם יותר חטיפים ומזון מהיר",
  },
  {
    icon: faBed,
    title: "שיבוש במנגנוני רעב ושובע",
    description:
      "חשיפה לאור הכחול הנפלט ממסכים לפני השינה פוגעת בהפרשת המלטונין, מה שמוביל להפרעות שינה. שינה לא מספקת נקשרה לשינויים הורמונליים הגורמים להגברת תחושת הרעב ולהעדפת מזונות עתירי קלוריות",
  },
];

const sedentaryData = [
  {
    icon: faRunning,
    title: "ירידה בהוצאה האנרגטית",
    description:
      "ילדים המבלים שעות רבות בצפייה במסכים או בפעילויות ישיבה אחרות (כגון שיעורי בית או משחקי מחשב) אינם שורפים כמות מספקת של קלוריות, מה שמוביל לחוסר איזון קלורי ולעלייה במשקל",
  },
  {
    icon: faRunning,
    title: "הפחתת פעילות גופנית ספונטנית",
    description:
      "בעבר, ילדים נהגו לבלות זמן רב יותר במשחקי חוץ ובפעילות פיזית בלתי מובנית. עם זאת, העלייה בשימוש בטכנולוגיה הובילה להפחתת ההשתתפות בפעילויות גופניות, מה שמגביר את הסיכון להשמנה",
  },
  {
    icon: faHeartbeat,
    title: "שינויים מטבוליים",
    description:
      "מחקרים הראו כי ישיבה ממושכת גורמת לשינויים מטבוליים, כולל ירידה ברגישות לאינסולין והפרעה במטבוליזם של שומנים, מה שמגביר את הסיכון להשמנה ולתסמונת מטבולית",
  },
];

const recommendations = [
  {
    icon: faChartLine,
    title: "הגבלת זמן מסך",
    description:
      "האקדמיה האמריקאית לרפואת ילדים (AAP) ממליצה להגביל את זמן המסך לשעתיים ביום לכל היותר עבור ילדים מעל גיל שנתיים.",
  },
  {
    icon: faHeartbeat,
    title: "הגברת פעילות גופנית",
    description:
      "עידוד ילדים לעסוק בפעילויות חוץ, כגון רכיבה על אופניים, משחקי כדור ושחייה, יכול לסייע בשיפור הבריאות המטבולית.",
  },
  {
    icon: faRunning,
    title: "שילוב תנועה ביומיום",
    description:
      "הורים יכולים לעודד ילדים לבצע פעילויות גופניות קלות במהלך היום, כמו הליכה לבית הספר, שימוש במדרגות במקום במעלית ושילוב הפסקות תנועה בזמן שיעורי בית.",
  },
  {
    icon: faBrain,
    title: "אכילה מודעת",
    description:
      "יש להימנע מהגשת מזון בזמן צפייה במסכים ולעודד ילדים לאכול ליד שולחן האוכל, תוך תשומת לב לאותות רעב ושובע.",
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/4740529/pexels-photo-4740529.jpeg?w=1260&auto=compress";

export const ScreenSedentaryObesity = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  השפעת זמן מסך ואורח חיים יושבני על השמנת ילדים
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg">
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: theme.palette.primary.main }}
                >
                  הקדמה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  השמנה בקרב ילדים היא סוגיה בריאותית מורכבת, הנובעת משילוב של
                  גורמים גנטיים, סביבתיים והתנהגותיים. בשנים האחרונות, מחקרים
                  רבים הצביעו על כך שאורח חיים יושבני וזמן מסך ממושך מהווים
                  גורמים משמעותיים התורמים להשמנה בילדים. החשיפה הגוברת למסכים,
                  לצד ירידה בפעילות הגופנית, יוצרת חוסר איזון אנרגטי העלול
                  להוביל לעלייה במשקל ולסיכון מוגבר למחלות כרוניות.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Factors Grid */}
            <Box sx={{ my: 6 }}>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ mb: 4, color: theme.palette.primary.main }}
              >
                הקשר בין זמן מסך להשמנת ילדים
              </Typography>
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{ mb: 4, color: theme.palette.text.secondary }}
              >
                מחקרים מצביעים על מספר מנגנונים שדרכם זמן מסך מוגבר תורם להשמנה
              </Typography>
              <Grid container spacing={3}>
                {factorsData.map((factor, index) => (
                  <Grid key={index} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledPaper>
                        <IconBox>
                          <FontAwesomeIcon icon={factor.icon} size="2x" />
                          <Typography variant="h6">{factor.title}</Typography>
                        </IconBox>
                        <Typography variant="body1">
                          {factor.description}
                        </Typography>
                      </StyledPaper>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Sedentary Data */}
            <Box sx={{ my: 6 }}>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ mb: 4, color: theme.palette.primary.main }}
              >
                אורח חיים יושבני והשפעתו על השמנת ילדים
              </Typography>
              <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{ mb: 4, color: theme.palette.text.secondary }}
              >
                אורח חיים יושבני, המאופיין בישיבה ממושכת ובהפחתת פעילות גופנית,
                קשור באופן ישיר לעלייה במשקל בקרב ילדים
              </Typography>
              <Grid container spacing={3}>
                {sedentaryData.map((data, index) => (
                  <Grid key={index} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledPaper>
                        <IconBox>
                          <FontAwesomeIcon icon={data.icon} size="2x" />
                          <Typography variant="h6">{data.title}</Typography>
                        </IconBox>
                        <Typography variant="body1">
                          {data.description}
                        </Typography>
                      </StyledPaper>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Recommendations */}
            <Box sx={{ my: 6 }}>
              <ParallaxBanner
                style={{
                  height: "400px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              >
                <ParallaxBannerLayer
                  image="https://images.pexels.com/photos/4260325/pexels-photo-4260325.jpeg?w=1260&auto=compress"
                  speed={-15}
                  scale={[1.1, 1]}
                  opacity={[0.8, 1]}
                />
                <ParallaxBannerLayer
                  speed={-10}
                  opacity={[1, 0.9]}
                  style={{
                    background:
                      "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="h3"
                    sx={{
                      color: "white",
                      textAlign: "center",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      padding: 4,
                    }}
                  >
                    כיצד ניתן לצמצם את ההשפעה השלילית?
                  </Typography>
                </ParallaxBannerLayer>
              </ParallaxBanner>

              <Typography
                variant="h6"
                align="center"
                gutterBottom
                sx={{ my: 6, color: theme.palette.text.secondary }}
              >
                כדי להפחית את ההשלכות השליליות של זמן מסך ואורח חיים יושבני על
                השמנה בילדים, מומלץ לנקוט בצעדים הבאים
              </Typography>

              <Grid container spacing={3}>
                {recommendations.map((rec, index) => (
                  <Grid key={index} size={{ xs: 12, md: 3 }}>
                    <motion.div
                      data-aos="fade-up"
                      data-aos-delay={index * 100}
                      animate={{ opacity: 1, y: 0 }}
                      style={{ height: "100%" }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledPaper
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <IconBox>
                          <FontAwesomeIcon icon={rec.icon} size="2x" />
                          <Typography variant="h6">{rec.title}</Typography>
                        </IconBox>
                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                          {rec.description}
                        </Typography>
                      </StyledPaper>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{ color: theme.palette.primary.main }}
                >
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  השפעת זמן מסך ממושך ואורח חיים יושבני על השמנת ילדים היא
                  משמעותית ונתמכת היטב במחקרים מדעיים. שילוב של אכילה בהיסח דעת,
                  חשיפה לפרסום מזון לא בריא, חוסר בפעילות גופנית ושינויים
                  מטבוליים תורמים יחדיו לעלייה בשיעורי ההשמנה בקרב ילדים. עם
                  זאת, באמצעות התערבות מודעת ושינוי הרגלים, ניתן לצמצם את
                  ההשפעות השליליות ולסייע לילדים לפתח אורח חיים בריא ומאוזן.
                  <br />
                  כדי להבטיח שינוי מוצלח בהרגלי האכילה והפעילות הגופנית, מומלץ
                  להיעזר בליווי מקצועי של דיאטנית ילדים. דיאטנית מוסמכת תוכל
                  להעניק הנחיות תזונתיות מותאמות אישית, לסייע בבניית תפריט מאוזן
                  ולהעניק כלים מעשיים להתמודדות עם אתגרים תזונתיים. בנוסף, הדרכת
                  הורים על ידי מומחית בתחום יכולה לשפר את הדינמיקה המשפחתית סביב
                  האוכל ולהבטיח גישה חיובית ומעצימה לניהול תזונה ואורח חיים
                  בריאים. השפעת זמן מסך ממושך ואורח חיים יושבני על השמנת ילדים
                  היא משמעותית ונתמכת היטב במחקרים מדעיים. שילוב של אכילה בהיסח
                  דעת, חשיפה לפרסום מזון לא בריא, חוסר בפעילות גופנית ושינויים
                  מטבוליים תורמים יחדיו לעלייה בשיעורי ההשמנה בקרב ילדים. עם
                  זאת, באמצעות התערבות מודעת ושינוי הרגלים, ניתן לצמצם את
                  ההשפעות השליליות ולסייע לילדים לפתח אורח חיים בריא ומאוזן.
                </Typography>
              </StyledPaper>
            </Parallax>
          </ContentSection>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
