import {
  faBookMedical,
  faBrain,
  faHeart,
  faLeaf,
  faListCheck,
  faMoon,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { ContactButtons, useMeta } from "modules/common";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

const ArticleSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  marginBottom: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: "rgba(255, 255, 255, 0.95)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  backdropFilter: "blur(8px)",
}));

const HERO_IMAGE =
  "https://images.pexels.com/photos/7176305/pexels-photo-7176305.jpeg?w=1260&auto=compress";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  borderRadius: theme.spacing(1),
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.05)",
  direction: "rtl",
  textAlign: "right",
  "& .MuiListItemIcon-root": {
    minWidth: 42,
    marginLeft: theme.spacing(2),
    marginRight: -theme.spacing(1),
  },
  "& .MuiListItemText-primary": {
    fontWeight: 600,
    fontSize: "1.1rem",
    marginBottom: theme.spacing(1),
  },
  "& .MuiListItemText-secondary": {
    fontSize: "1rem",
    lineHeight: 1.6,
    color: theme.palette.text.secondary,
  },
}));

export const EmotionalEating = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box
        sx={{
          overflow: "hidden",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4e7eb 100%)",
          minHeight: "100vh",
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            position: "relative",
            height: "50vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: `url(${HERO_IMAGE})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              filter: "brightness(0.7)",
              zIndex: 0,
            },
          }}
        >
          <Container maxWidth="lg" sx={{ position: "relative", zIndex: 1 }}>
            <Parallax translateY={[-20, 20]}>
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                  mb: 3,
                  maxWidth: "1000px",
                }}
              >
                תסמיני גיל המעבר ואכילה רגשית: הקשר בין שינויים הורמונליים
                והרגלי אכילה
              </Typography>
            </Parallax>
          </Container>
        </Box>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <ArticleSection>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                color: theme.palette.text.primary,
              }}
            >
              גיל המעבר הוא תקופה משמעותית בחייה של אישה, המאופיינת בשינויים
              הורמונליים המשפיעים על הבריאות הפיזית והנפשית כאחד. בין הסימפטומים
              המרכזיים ניתן למנות גלי חום, הפרעות שינה, שינויים במצב הרוח, עלייה
              במשקל וירידה בצפיפות העצם. לצד תסמינים אלו, נשים רבות חוות שינוי
              בהרגלי האכילה, המתבטא במיוחד באכילה רגשית.
            </Typography>
          </ArticleSection>

          {/* What Causes Emotional Eating */}
          <ArticleSection>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                mb: 4,
                textAlign: "right",
              }}
            >
              מה גורם לאכילה רגשית בגיל המעבר?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                mb: 4,
                textAlign: "right",
              }}
            >
              אכילה רגשית מוגדרת כאכילה הנובעת מרגשות ולא מצורך פיזיולוגי אמיתי.
              בתקופת גיל המעבר, קיימים מספר גורמים המשפיעים על התופעה:
            </Typography>
            <List sx={{ direction: "rtl" }}>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faBrain}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שינויים הורמונליים"
                  secondary="ירידה ברמות האסטרוגן משפיעה על מנגנוני הרעב והשובע, גורמת לשינויים בחילוף החומרים ומגבירה את התשוקה למזונות עתירי שומן וסוכר. מחקרים מראים כי אסטרוגן משחק תפקיד מרכזי בוויסות רמות הסרוטונין – נוירוטרנסמיטר הקשור למצבי רוח טובים ולתחושת שובע. ירידה בסרוטונין יכולה להוביל לדכדוך ולעלייה בצריכת מזונות מנחמים."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faMoon}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="הפרעות שינה ועייפות"
                  secondary="נדודי שינה הם תופעה נפוצה בגיל המעבר, והם עלולים לגרום לעלייה ברמות ההורמון גרלין (המגביר רעב) ולירידה ברמות ההורמון לפטין (האחראי על תחושת שובע). מצב זה תורם להגברת הצורך באכילה רגשית, במיוחד בשעות הערב והלילה."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שינויים במצב הרוח ולחץ נפשי"
                  secondary="גיל המעבר מלווה לעיתים בתחושות חרדה, דכדוך ואף דיכאון קל. נשים רבות משתמשות במזון כדרך לוויסות רגשות, במיוחד מאכלים מתוקים ופחמימות פשוטות, הידועים כמעודדי הפרשת דופמין וסרוטונין בטווח הקצר."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faWeightScale}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שינויים מטבוליים"
                  secondary="ההאטה בקצב חילוף החומרים ונטייה לעלייה במשקל משפיעים על הדימוי העצמי ועלולה לגרום לתסכול, מה שמוביל להחמרת דפוסי האכילה הרגשית."
                />
              </StyledListItem>
            </List>
          </ArticleSection>

          {/* How to Deal */}
          <ArticleSection>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                mb: 4,
                textAlign: "right",
              }}
            >
              כיצד ניתן להתמודד עם אכילה רגשית בתקופת גיל המעבר?
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                mb: 4,
                textAlign: "right",
              }}
            >
              למרות שהשינויים בגיל המעבר יכולים להיות מאתגרים, ישנם מספר כלים
              שיכולים לעזור בהתמודדות עם אכילה רגשית:
            </Typography>
            <List sx={{ direction: "rtl" }}>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faLeaf}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="תזונה מאוזנת"
                  secondary="שילוב של חלבונים, סיבים תזונתיים ושומנים בריאים בתפריט היומי יכול לסייע באיזון רמות הסוכר בדם ולמנוע התקפי אכילה רגשית."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faHeart}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="פעילות גופנית"
                  secondary="פעילות גופנית סדירה מסייעת בהפחתת סטרס, משפרת מצב רוח ומאזנת את רמות ההורמונים בגוף."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faBookMedical}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="ניהול רגשות ולחץ"
                  secondary="טכניקות כגון מדיטציה, יוגה ותרגול נשימות עמוקות יכולות לסייע בוויסות רגשות ולמנוע אכילה לא מבוקרת."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faListCheck}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="תמיכה רגשית"
                  secondary="שיחה עם דיאטנית, קבוצות תמיכה או טיפול רגשי יכולים לעזור בזיהוי טריגרים רגשיים לאכילה ולפתח אסטרטגיות התמודדות בריאות יותר."
                />
              </StyledListItem>
              <StyledListItem>
                <ListItemIcon>
                  <FontAwesomeIcon
                    icon={faMoon}
                    style={{
                      fontSize: "1.5rem",
                      color: theme.palette.primary.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="שיפור איכות השינה"
                  secondary="יצירת הרגלי שינה טובים כמו הקפדה על שעות קבועות, הימנעות מקפאין לפני השינה ושימוש בטכניקות הרפיה יכולים לסייע באיזון הורמונלי ולמנוע אכילה לילית."
                />
              </StyledListItem>
            </List>
          </ArticleSection>

          {/* Summary */}
          <ArticleSection>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                color: theme.palette.primary.main,
                mb: 4,
                textAlign: "right",
              }}
            >
              סיכום
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.2rem",
                lineHeight: 1.8,
                textAlign: "right",
              }}
            >
              אכילה רגשית בגיל המעבר היא תופעה שכיחה, אך ניתנת לניהול באמצעות
              תזונה מותאמת, פעילות גופנית, הפחתת סטרס ושיפור איכות השינה. עם
              זאת, ליווי מקצועי של דיאטנית מוסמכת הוא כלי חיוני בהתמודדות עם
              שינויים אלו. דיאטנית מוסמכת יכולה להתאים תוכנית אישית, לזהות
              טריגרים רגשיים ולספק כלים מעשיים לניהול אורח חיים בריא ומאוזן.
              ההכוונה המקצועית תומכת במעבר חלק יותר, תוך שמירה על הבריאות הפיזית
              והנפשית, ומסייעת לנשים בגיל המעבר לשפר את איכות חייהן ולחוש בשליטה
              על הרגלי האכילה שלהן.
            </Typography>
          </ArticleSection>

          {/* Contact Section */}
          <Box sx={{ mt: 8 }}>
            <ContactButtons />
          </Box>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
