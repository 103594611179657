"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TeensTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.TeensTags = {
    "": {
        title: constants_1.ProductInfo.teens.description,
        description: "מידע מקצועי על תזונה נכונה לבני נוער, כולל טיפים לאכילה מאוזנת, תזונה לספורטאים צעירים, ועצות לשיפור הריכוז והלמידה דרך תזונה נכונה.",
        keywords: "תזונת מתבגרים, תזונה לבני נוער, תזונה לספורטאים צעירים, תזונה ולמידה, תזונה מאוזנת לנוער, אנרגיה ופעילות גופנית, תזונה לשיפור ריכוז",
        canonicalUrl: "/teens",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/teens/articles",
    },
    "/articles/sleep-screen-obesity": {
        title: "מתבגרים עייפים אוכלים יותר",
        description: "הקשר בין שעות שינה, מסכים והשמנה בקרב מתבגרים. כיצד חוסר שינה ושימוש במסכים משפיעים על הרגלי האכילה והמשקל של בני נוער.",
        keywords: "שינה מתבגרים, השמנת מתבגרים, הפרעות שינה, שימוש במסכים, הורמוני שינה, גרלין, לפטין, הורמון גדילה, תזונת מתבגרים, איכות חיים",
        canonicalUrl: "/teens/articles/sleep-screen-obesity",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3771069/pexels-photo-3771069.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "בריאות מתבגרים",
            },
        },
    },
    "/articles/diet-weight-body-image": {
        title: "הקשר בין תזונת מתבגרים, משקל ודימוי גוף",
        description: "מאמר מקיף על השפעת השינויים ההורמונליים והפיזיולוגיים בגיל ההתבגרות על הרגלי האכילה, המשקל ודימוי הגוף. כולל המלצות לתזונה מאוזנת ותפקיד ההורים בקידום הרגלים בריאים.",
        keywords: "תזונת מתבגרים, דימוי גוף, משקל בריא, הפרעות אכילה, תזונה מאוזנת, בריאות מתבגרים, שינויים הורמונליים, הרגלי אכילה, בריאות נפשית, התפתחות גופנית",
        canonicalUrl: "/teens/articles/diet-weight-body-image",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3768894/pexels-photo-3768894.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-19",
                dateModified: "2025-02-21",
                articleSection: "תזונת מתבגרים",
            },
        },
    },
    "/articles/weight-body-image-balance": {
        title: "איך לעזור למתבגר שלי לשמור על משקל בריא בלי לפגוע בדימוי הגוף?",
        description: "מדריך מקיף להורים על תמיכה במתבגרים בנושאי משקל ודימוי גוף, כולל טיפים מעשיים לתקשורת תומכת, הצבת גבולות בריאים ויצירת סביבה מעצימה.",
        keywords: "דימוי גוף מתבגרים, משקל בריא, תזונת מתבגרים, הפרעות אכילה, ביטחון עצמי, תקשורת הורית, בריאות נוער, תזונה נכונה, ליווי הורי",
        canonicalUrl: "/teens/articles/weight-body-image-balance",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3768894/pexels-photo-3768894.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-19",
                dateModified: "2025-02-21",
                articleSection: "תזונת מתבגרים",
            },
        },
    },
    "/articles/healthy-fast-food-balance": {
        title: "מזון מהיר - איך ליהנות ממנו מבלי לפגוע בבריאות?",
        description: "מדריך מעשי לבני נוער על איך לשלב מזון מהיר בתזונה בצורה מאוזנת, כולל טיפים לבחירות חכמות, הגבלת מזון מטוגן ושמירה על איזון בין הארוחות.",
        keywords: "מזון מהיר לבני נוער, תזונה מאוזנת, בחירות בריאות במזון מהיר, תזונת מתבגרים, הרגלי אכילה בריאים, איזון תזונתי",
        canonicalUrl: "/teens/articles/healthy-fast-food-balance",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/2983101/pexels-photo-2983101.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-19",
                dateModified: "2025-02-21",
                articleSection: "תזונת מתבגרים",
            },
        },
    },
    "/articles/nutrition-myths": {
        title: "מיתוסים בתזונה בגיל ההתבגרות - מה נכון ומה לא?",
        description: "סקירה מקיפה של מיתוסים נפוצים בתזונת מתבגרים, כולל הסברים מבוססי מחקר על פחמימות, שומנים, אקנה, דיאטות ועוד. המאמר כולל המלצות מעשיות להורים ולבני נוער לפיתוח הרגלי תזונה בריאים.",
        keywords: "תזונת מתבגרים, מיתוסים בתזונה, תזונה בריאה למתבגרים, דיאטות למתבגרים, אקנה ותזונה, פחמימות ושומנים, תזונה מאוזנת, הרגלי אכילה בריאים",
        canonicalUrl: "/teens/articles/nutrition-myths",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-19",
                dateModified: "2025-02-21",
                articleSection: "תזונת מתבגרים",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/teens/lets-talk",
    },
};
