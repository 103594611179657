import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBookOpen,
  faCarrot,
  faDumbbell,
  faGraduationCap,
  faMedal,
  faPeopleRoof,
  faWeightScale,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

interface Achievement {
  value: number;
  label: string;
  icon: IconProp;
  color: string;
}

interface Qualification {
  icon: IconProp;
  text: string;
  color: string;
}

const achievements: Achievement[] = [
  {
    value: 8,
    label: "שנות ניסיון",
    icon: faMedal,
    color: "#4ECDC4",
  },
  {
    value: 500,
    label: "מטופלים מרוצים",
    icon: faPeopleRoof,
    color: "#FF6B6B",
  },
];

const qualifications: Qualification[] = [
  {
    icon: faGraduationCap,
    text: "בוגרת תואר ראשון במדעי התזונה בפקולטה לחקלאות",
    color: "#4CAF50",
  },
  {
    icon: faBookOpen,
    text: "תואר שני בתזונה והתנהגות באוניברסיטת חיפה",
    color: "#2196F3",
  },
  {
    icon: faWeightScale,
    text: "טיפול והדרכה בהשמנת ילדים ומתבגרים",
    color: "#9C27B0",
  },
  {
    icon: faCarrot,
    text: "התמחות בתזונה טבעונית וצמחונית",
    color: "#43A047",
  },
  {
    icon: faDumbbell,
    text: "ייעוץ והכוונה בתזונת ספורט",
    color: "#FF5722",
  },
];

export const AboutSection = () => {
  const theme = useTheme();
  const [, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const scale = useTransform(
    scrollYProgress,
    [0, 0.2, 0.8, 1],
    [0.95, 1, 1, 0.95]
  );

  return (
    <Box
      ref={sectionRef}
      component={motion.div}
      style={{ opacity, scale }}
      sx={{
        py: { xs: 4 },
        background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={8} alignItems="flex-start">
          {/* Content Section - Right Side */}
          <Grid size={{ xs: 12, md: 6 }}>
            <motion.div
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              <Box>
                <Typography
                  variant="h2"
                  gutterBottom
                  sx={{
                    fontWeight: 800,
                    color: theme.palette.primary.main,
                    fontSize: { xs: "2.5rem", md: "3rem" },
                    textShadow: "0 2px 10px rgba(0,0,0,0.1)",
                    mb: 3,
                  }}
                >
                  הסיפור שלי
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{
                    color: theme.palette.text.secondary,
                    fontSize: "1.1rem",
                    lineHeight: 1.8,
                  }}
                >
                  במהלך השנים ליוויתי ילדים, מתבגרים ומבוגרים בתהליכי שינוי
                  תזונתי מתוך אמונה שידע מקצועי הוא המפתח, הדרך להצלחה עוברת דרך
                  ליווי אישי והקשבה לצרכים הייחודיים של כל אדם.
                  <br />
                  <br />
                  בגישה הוליסטית המתבוננת לא רק במה שאוכלים אלא גם בקשר שבין גוף
                  לנפש, אני מתאימה לכל אחד ואחת מענה מדויק שמאפשר ליצור הרגלים
                  בריאים ולשמר אותם לאורך זמן.
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    fontSize: "1.2rem",
                    mt: 4,
                    textShadow: "0 1px 2px rgba(0,0,0,0.1)",
                  }}
                >
                  אם אתם מחפשים ליווי שמבין אתכם באמת – אני כאן לצעוד איתכם בדרך
                  לשינוי
                </Typography>
              </Box>
            </motion.div>
            <Box
              sx={{
                background: "rgba(255,255,255,0.8)",
                backdropFilter: "blur(10px)",
                borderRadius: "24px",
                boxShadow: "0 10px 40px rgba(0,0,0,0.05)",
                mt: 6,
                p: 4,
                display: "flex",
                justifyContent: "center",
                gap: 4,
                border: "1px solid rgba(255,255,255,0.2)",
              }}
            >
              {achievements.map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Box
                    sx={{
                      textAlign: "center",
                      p: 3,
                      borderRadius: "16px",
                      transition: "all 0.3s ease",
                      minWidth: "160px",
                      "&:hover": {
                        transform: "translateY(-5px)",
                        "& .stat-icon": {
                          transform: "scale(1.1) rotate(5deg)",
                          boxShadow: `0 15px 30px ${stat.color}40`,
                        },
                      },
                    }}
                  >
                    <Box
                      className="stat-icon"
                      sx={{
                        width: 80,
                        height: 80,
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: `linear-gradient(135deg, ${stat.color}, ${stat.color}99)`,
                        boxShadow: `0 10px 20px ${stat.color}20`,
                        mx: "auto",
                        mb: 3,
                        transition: "all 0.3s ease",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={stat.icon}
                        style={{ color: "#fff", fontSize: "2.5rem" }}
                      />
                    </Box>
                    <Typography
                      variant="h3"
                      sx={{
                        fontWeight: 700,
                        color: stat.color,
                        fontSize: { xs: "2.2rem", md: "2.8rem" },
                        mb: 1,
                        textShadow: `0 2px 10px ${stat.color}40`,
                      }}
                    >
                      <CountUp
                        end={stat.value}
                        duration={2.5}
                        separator=","
                        start={inView ? 0 : undefined}
                      />
                      +
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: theme.palette.text.secondary,
                        fontWeight: 500,
                        fontSize: "1.1rem",
                      }}
                    >
                      {stat.label}
                    </Typography>
                  </Box>
                </motion.div>
              ))}
            </Box>
          </Grid>

          {/* Stats Section - Left Side */}
          <Grid size={{ xs: 12, md: 6 }}>
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <Box sx={{ mb: 6 }}>
                {qualifications.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        p: 3,
                        borderRadius: "16px",
                        backgroundColor: "rgba(255,255,255,0.8)",
                        backdropFilter: "blur(10px)",
                        border: "1px solid rgba(255,255,255,0.2)",
                        transition: "all 0.3s ease",
                        position: "relative",
                        overflow: "hidden",
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          bottom: 0,
                          background: `linear-gradient(135deg, ${item.color}10, transparent)`,
                          opacity: 0,
                          transition: "opacity 0.3s ease",
                        },
                        "&:hover": {
                          transform: "translateX(-10px)",
                          backgroundColor: "rgba(255,255,255,0.95)",
                          boxShadow: "0 12px 48px rgba(0,0,0,0.1)",
                          "&::before": {
                            opacity: 1,
                          },
                          "& .qualification-icon": {
                            transform: "scale(1.1) rotate(5deg)",
                            boxShadow: `0 10px 30px ${item.color}40`,
                          },
                        },
                      }}
                    >
                      <Box
                        className="qualification-icon"
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          background: `linear-gradient(135deg, ${item.color}, ${item.color}99)`,
                          boxShadow: `0 8px 16px ${item.color}20`,
                          ml: 3,
                          transition: "all 0.3s ease",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={item.icon}
                          style={{ color: "#fff", fontSize: "1.5rem" }}
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 500,
                          color: theme.palette.text.primary,
                          fontSize: { xs: "1rem", md: "1.1rem" },
                          lineHeight: 1.6,
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  </motion.div>
                ))}
              </Box>
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
