import MetaTags from "@monorepo/metatags";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { withWrapper } from "app/hooks";
import { motion } from "framer-motion";
import { useMeta } from "modules/common";
import { Link } from "react-router-dom";

const StyledPaper = styled(Paper)(({ theme }) => ({
  height: "100%",
  borderRadius: theme.spacing(2),
  overflow: "hidden",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 20px rgba(0,0,0,0.2)",
  },
}));

const ArticleImage = styled("img")({
  width: "100%",
  height: "200px",
  objectFit: "cover",
});

const ArticleContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

export interface Article {
  path: string;
  image: string;
}

interface Props {
  articles: Article[];
}

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

export const Articles = withWrapper(({ articles }: Props) => {
  useMeta();

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <Typography
        variant="h2"
        component="h1"
        align="center"
        gutterBottom
        sx={{
          mb: 6,
          fontWeight: "bold",
          color: "primary.main",
        }}
      >
        תכנים
      </Typography>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Grid container spacing={4} sx={{ direction: "rtl" }}>
          {articles.map((article, index) => {
            const metaTags = MetaTags(article.path);

            return (
              <Grid
                key={`article-${index}`}
                size={{ xs: 12, md: 6, lg: 4 }}
                sx={{ display: "flex" }}
              >
                <motion.div variants={itemVariants} style={{ width: "100%" }}>
                  <Link
                    to={article.path}
                    style={{
                      textDecoration: "none",
                      height: "100%",
                      display: "block",
                    }}
                  >
                    <StyledPaper elevation={3}>
                      <ArticleImage
                        src={article.image}
                        alt={metaTags.routeTitle}
                      />
                      <ArticleContent>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{
                            fontWeight: "bold",
                            color: "text.primary",
                            mb: 2,
                          }}
                        >
                          {metaTags.routeTitle}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{ lineHeight: 1.6 }}
                        >
                          {metaTags.description}
                        </Typography>
                      </ArticleContent>
                    </StyledPaper>
                  </Link>
                </motion.div>
              </Grid>
            );
          })}
        </Grid>
      </motion.div>

      {articles.length === 0 && (
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          sx={{ mt: 4 }}
        >
          תכנים חדשים יתווספו בקרוב...
        </Typography>
      )}
    </Container>
  );
});
