import {
  faBrain,
  faChartLine,
  faClockRotateLeft,
  faFaceSadTear,
  faHeartPulse,
  faLightbulb,
  faMoon,
  faPeopleGroup,
  faPersonRunning,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const hungerTypes = [
  {
    id: "physiological",
    title: "רעב פיזיולוגי",
    description:
      "נובע מצורך אמיתי של הגוף באנרגיה ובחומרים מזינים. הוא מתבטא בתחושת רעב בבטן ולעיתים גם בירידה ברמות האנרגיה.",
    tip: "מופיע בהדרגה ואינו נעלם גם אם מתעלמים ממנו. אכילה מאוזנת הכוללת חלבונים, שומנים בריאים וסיבים תורמת לשובע ממושך.",
    icon: faHeartPulse,
    color: "#FF6B6B",
  },
  {
    id: "emotional",
    title: "רעב רגשי",
    description:
      "מתעורר כתוצאה ממצבים רגשיים כמו שעמום, עצב, כעס או מתח. לעיתים מתבטא כרצון עז למאכלים מנחמים ומעובדים, מה שעשוי להוביל לאכילה לא מודעת",
    tip: 'מופיע פתאום ולעיתים קרובות קשור לרגשות ספציפיים. לפני האכילה, שאלו את עצמכם: "האם אני רעב פיזית או שאני מחפש נחמה?" ניתן להפחית רעב רגשי באמצעות פעילויות מסיחות, הפניית תשומת הלב לנושא בוער אחר ובעיקר, התרחקות מאזור האכילה (מטבח, פינת אוכל, שולחן כיבוד) אם אתם מזהים שאתם לא מעוניינים באכילה ומרגישים כאילו הגוף מושך לשם.',
    icon: faFaceSadTear,
    color: "#4ECDC4",
  },
  {
    id: "hormonal",
    title: "רעב הורמונלי",
    description:
      "הורמונים כמו גרלין ולפטין משפיעים ישירות על תחושת הרעב והשובע, ואלו משתנים לאורך החודש, במיוחד אצל נשים, וכן בהתאם למזג האוויר – כמו חורף, חשיפה לקור ותחושת צורך להתחמם, אשר יכולים גם הם להשפיע על תחושת הרעב.",
    tip: "מתגבר בתקופות מסוימות, כמו לפני מחזור חודשי, לאחר לילה של שינה מועטה. כשמזג האוויר משתנה ומתקרר. שמירה על תזונה מאוזנת ומגוונת עוזרת לווסת את ההורמונים ולהפחית רעב לא מאוזן.",
    icon: faChartLine,
    color: "#45B7D1",
  },
  {
    id: "sensory",
    title: "רעב חושי",
    description:
      "נובע מחשיפה למראה, ריח או זיכרון של מזון מגרה, גם אם אין תחושת רעב אמיתית.",
    tip: "מופיע במהירות ונעלם אם מסיחים את הדעת. אם אינכם רעבים באמת, שתייה של מים / תה או עיסוק בפעילות אחרת יכולים להפחית את הדחף.",
    icon: faBrain,
    color: "#96CEB4",
  },
  {
    id: "automatic",
    title: 'רעב "דייקן" או אוטומטי',
    description:
      'מתעורר מתוך הרגל או מחשבות כמו "עכשיו זמן לאכול", גם כשאין רעב אמיתי.',
    tip: "שימו לב האם אתם רעבים באמת או שאוכלים רק כי הגיעה שעת הארוחה. נסו להקשיב לאותות הגוף ולבחור לאכול כאשר אתם חשים רעב אמיתי.",
    icon: faClockRotateLeft,
    color: "#FFB6B9",
  },
  {
    id: "social",
    title: "רעב חברתי",
    description:
      "נובע מהשתתפות באירועים חברתיים, כמו מפגשים משפחתיים או בילוי עם חברים, שבהם האכילה היא חלק מהחוויה.",
    tip: "שאלו את עצמכם האם אתם אוכלים מתוך רעב או כדי להשתלב באווירה. אם אתם ממשיכים לאכול או לנשנש גם כשאתם כבר שבעים, נסו להתרחק מהצלחת או משולחן הארוחה תוך שמירה על ההווי החברתי. ניתן גם להיעזר בקיסם שיניים או לעיסת מסטיק כדי למנוע אכילה בהיסח הדעת",
    icon: faPeopleGroup,
    color: "#FFA07A",
  },
  {
    id: "reminder",
    title: "רעב מתזכר",
    description:
      "רעב חזק המופיע לאחר יום שבו האכילה לא הייתה מסודרת או שהייתם עסוקים ושכחתם לאכול.",
    tip: "מלווה בתחושת רעב עוצמתית ולעיתים גם בתחושת חולשה. כדי למנוע מצב זה, הקפידו על ארוחות מאוזנות לאורך היום.",
    icon: faPersonRunning,
    color: "#DDA0DD",
  },
  {
    id: "tiredness",
    title: "רעב מעייפות",
    description:
      "הגוף מפרש עייפות כחוסר באנרגיה ולכן מגביר את תחושת הרעב כדי להשלים את האנרגיה החסרה. פעמים רבות אכילה זו תהיה מלווה בתחושת שובע יתר על המידה ואף ברגשות חרטה ואובדן שליטה. פעמים רבות רעב זה מתעורר בסיום יום עמוס וגדוש, שבו לא הוקדשה מספיק תשומת לב לאכילה. לצד ירידת מתח וריקון אנרגיה, אנו נוטים לפרש את המצב כרעב להשלים את האנרגיה החסרה.",
    tip: "מופיע כאשר אתם עייפים ולאחר לילה של שינה לא מספקת. במקום לפנות למתוקים, בחרו במזונות עשירים בחלבון ובסיבים שיעניקו אנרגיה לטווח ארוך.",
    icon: faMoon,
    color: "#9370DB",
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/5638732/pexels-photo-5638732.jpeg?auto=compress&w=1260",
  mindful:
    "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress&w=600",
  balanced:
    "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&w=600",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const HungerTypesGuide = () => {
  useMeta();
  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    המדריך לזיהוי סוגי רעב ולניהול אכילה חכם
                  </Typography>
                </Zoom>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 3,
                        alignItems: "flex-start",
                        mb: 3,
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: `${theme.palette.primary.main}20`,
                          p: 2,
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faLightbulb}
                          style={{
                            fontSize: 32,
                            color: theme.palette.primary.main,
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ fontWeight: 700 }}
                      >
                        מבוא
                      </Typography>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      רעב הוא תחושה טבעית שכולנו חווים, ולעיתים היא מתבטאת
                      בדרכים שונות בהתאם לנסיבות ולצרכים האישיים. לדוגמה, לאחר
                      אימון גופני ייתכן שנרגיש רעב פיזיולוגי שמאותת על צורך
                      באנרגיה, בעוד שראיית קינוח מפתה יכולה לעורר רעב חושי גם אם
                      איננו רעבים באמת. הכרת ההבדלים בין סוגי הרעב מאפשרת לנו
                      להגיב אליהם בצורה מודעת ומאוזנת המשקפת את צרכי הגוף, אך לא
                      תמיד הוא נובע מסיבות פיזיולוגיות בלבד. זיהוי נכון של סוגי
                      הרעב עשוי לאפשר לנו לבחור כיצד להגיב אליו בצורה מודעת,
                      ומקדמת. לקבל את הימים בהם הרעב עשוי להיות מוגבר ולשחרר את
                      הלחץ סביב הרצון לצמצם קלוריות, אלא לנסות להבין מאיפה הרעב
                      נובע ובכך לספק מענה הולם, קשוב יותר לגוף
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.mindful}
                  alt="Mindful eating"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Hunger Types Grid */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                סוגי הרעב
              </Typography>
              <Grid container spacing={3}>
                {hungerTypes.map((type, index) => (
                  <Grid key={type.id} size={{ xs: 12 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${type.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={type.icon}
                              style={{ fontSize: 40, color: type.color }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: type.color, fontWeight: 600 }}
                            >
                              {type.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              paragraph
                              sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                            >
                              {type.description}
                            </Typography>
                            <Box
                              sx={{
                                backgroundColor: `${type.color}10`,
                                p: 2,
                                borderRadius: 2,
                                mt: 2,
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{
                                  fontSize: "1rem",
                                  color: type.color,
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faLightbulb}
                                  style={{ fontSize: 16 }}
                                />
                                <strong>טיפ לזיהוי:</strong>
                              </Typography>
                              {type.tip}
                            </Box>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      component="img"
                      src={IMAGES.balanced}
                      alt="Balanced nutrition"
                      sx={{
                        width: "100%",
                        height: 400,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <StyledCard>
                      <Typography
                        variant="h4"
                        gutterBottom
                        color="primary"
                        sx={{ mb: 4, fontWeight: 700 }}
                      >
                        לסיכום
                      </Typography>
                      <Typography
                        variant="body1"
                        paragraph
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        זיהוי סוג הרעב מאפשר לנו להגיב לו בצורה נכונה ולהימנע
                        מאכילה מיותרת. כדי לשמור על בריאות ואיזון, חשוב להקשיב
                        לאותות הגוף ולאכול כשמרגישים רעב פיזיולוגי. עם זאת, הכרה
                        בסוגי הרעב האחרים ועיסוק בדרכים חלופיות למילוי הצרכים
                        הרגשיים והחברתיים תסייע לשמור על אורח חיים בריא ומאוזן.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                      >
                        חשוב להקדיש תשומת לב לסוג הרעב המורגש ולנתח את הסיטואציה
                        שבה הוא מתעורר, מאחר שתפריט גנרי אינו תמיד נותן מענה
                        מתאים לכל אדם. ישנה חשיבות רבה לליווי של אשת מקצוע, כמו
                        דיאטנית קלינית, אשר מספקת תמיכה מקיפה המשלבת היבטים
                        נפשיים ומקצועיים. ליווי זה עוזר להבין את הגורמים לרעב
                        ולבנות הרגלי אכילה בריאים המותאמים לצרכים האישיים.
                      </Typography>
                    </StyledCard>
                  </Grid>
                </Grid>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
