import {
  faBalanceScale,
  faBrain,
  faChartLine,
  faHeartbeat,
  faMedal,
  faSmile,
  faStairs,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(6),
  margin: theme.spacing(3),
  borderRadius: 30,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 10px 40px rgba(0,0,0,0.08)",
  position: "relative",
  overflow: "hidden",
  "&::before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "4px",
    background: "linear-gradient(90deg, #4CAF50, #2196F3, #E91E63)",
  },
}));

const ContentCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  borderRadius: 20,
  transition: "all 0.3s ease-in-out",
  background: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: "0 12px 30px rgba(0,0,0,0.12)",
  },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  overflow: "hidden",
  height: "100%",
  minHeight: "400px",
  boxShadow: "0 20px 40px rgba(0,0,0,0.12)",
  position: "relative",
  "&::after": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background:
      "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.1) 100%)",
    transition: "opacity 0.3s ease-in-out",
    opacity: 0,
  },
  "&:hover::after": {
    opacity: 1,
  },
  "& img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "transform 0.5s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

const SectionContent = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
}));

const SectionTitle = styled(Typography)(() => ({
  position: "relative",
  display: "inline-block",
  "&::after": {
    content: '""',
    position: "absolute",
    bottom: "-10px",
    left: "0",
    width: "40%",
    height: "4px",
    background: "currentColor",
    borderRadius: "2px",
  },
}));

const sections = [
  {
    id: "motivation",
    title: "בגד מטרה כמקור למוטיבציה",
    icon: faMedal,
    color: "#4CAF50",
    image:
      "https://images.pexels.com/photos/3076516/pexels-photo-3076516.jpeg?auto=compress&w=640",
  },
  {
    id: "challenges",
    title: "האתגרים הרגשיים שבבגד מטרה",
    icon: faBrain,
    color: "#E91E63",
    image:
      "https://images.pexels.com/photos/3807738/pexels-photo-3807738.jpeg?auto=compress&w=640",
  },
  {
    id: "realistic-goals",
    title: "החשיבות בהצבת מטרות ריאליות",
    icon: faStairs,
    color: "#2196F3",
    image:
      "https://images.pexels.com/photos/3759659/pexels-photo-3759659.jpeg?auto=compress&w=640",
  },
];

const benefits = [
  {
    icon: faHeartbeat,
    title: "בריאות כללית",
    description: "שיפור בריאות הגוף והנפש",
  },
  {
    icon: faSmile,
    title: "דימוי עצמי",
    description: "חיזוק הביטחון העצמי",
  },
  {
    icon: faChartLine,
    title: "התקדמות הדרגתית",
    description: "מעקב אחר הישגים קטנים",
  },
  {
    icon: faBalanceScale,
    title: "איזון",
    description: "יצירת אורח חיים מאוזן",
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/5698851/pexels-photo-5698851.jpeg?auto=compress&w=1260";

export const TargetDressDilemma = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner style={{ height: "50vh" }}>
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
            translateY={[0, 50]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.8))",
                padding: { xs: 3, md: 6 },
                textAlign: "center",
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    color: "white",
                    fontWeight: 900,
                    fontSize: { xs: "2.5rem", sm: "3.5rem", md: "5rem" },
                    textShadow: "2px 2px 4px rgba(0,0,0,0.3)",
                    mb: 3,
                    letterSpacing: "-0.02em",
                  }}
                >
                  בגד מטרה
                </Typography>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 30 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.3 }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "white",
                    fontSize: { xs: "1.5rem", sm: "2rem", md: "2.75rem" },
                    textShadow: "1px 1px 2px rgba(0,0,0,0.3)",
                    maxWidth: "800px",
                    mx: "auto",
                    fontWeight: 500,
                  }}
                >
                  בגד מטרה: מנוע מוטיבציה או מעגל תסכול?
                </Typography>
              </motion.div>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 8 }}>
          {/* Introduction */}
          <Parallax translateY={[20, -20]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: "1.3rem",
                  lineHeight: 1.8,
                  color: theme.palette.text.primary,
                  textAlign: "justify",
                }}
              >
                במהלך החיים, רבים מוצאים את עצמם שואפים לחזור למשקל או למראה
                שהיו להם בעבר. אחת השיטות הפופולריות להנעת תהליך זה היא הצבת
                "בגד מטרה" – פריט לבוש המסמל את היעד הרצוי. נשאלת השאלה האם בגד
                זה אכן מגביר מוטיבציה או שמא מציב יעד לא ריאלי העלול להוביל
                לתחושות תסכול ואכזבה. לצד שגרת חיים עמוסה הכוללת עבודה לחוצה,
                עייפות וחוסר זמן, מטרה רחוקה ולא מציאותית עלולה להשפיע על המצב
                הנפשי וההתנהגותי ולהוביל לתזונה לא מאוזנת או לאכילה מופרזת
                המלווה בתחושות אשמה.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Main Sections */}
          {sections.map((section, index) => (
            <motion.div
              key={section.id}
              initial={{ opacity: 0, y: 30 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: index * 0.2 }}
              viewport={{ once: true }}
            >
              <Box sx={{ my: 8 }}>
                <SectionTitle
                  variant="h3"
                  sx={{
                    textAlign: "center",
                    fontWeight: 800,
                    color: section.color,
                    mb: 6,
                    fontSize: { xs: "2rem", md: "2.5rem" },
                  }}
                >
                  <FontAwesomeIcon
                    icon={section.icon}
                    style={{ marginLeft: "1rem", opacity: 0.9 }}
                  />
                  {section.title}
                </SectionTitle>

                <Grid container spacing={4} alignItems="stretch">
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}
                  >
                    <SectionContent>
                      <StyledPaper elevation={3}>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.2rem",
                            lineHeight: 1.9,
                            color: theme.palette.text.primary,
                            textAlign: "justify",
                          }}
                        >
                          {section.id === "motivation" && (
                            <>
                              מחקרים פסיכולוגיים מראים כי הגדרת מטרות ברורות
                              עשויה לשפר את המוטיבציה והנחישות. בגד מטרה עשוי
                              לשמש תזכורת מוחשית ליעד הרצוי, מה שמסייע להתמיד
                              בתהליך הירידה במשקל ובשמירה על אורח חיים בריא.
                              הרעיון של חזרה לבגדים מהעבר מעניק תחושת הישג
                              וסיפוק כאשר היעד מושג. רבים מדווחים כי צפייה בבגד
                              המטרה מעודדת אותם לבחור מזון בריא ולהתמיד בפעילות
                              גופנית, במיוחד בתקופות מאתגרות המלוות בעומס
                              ובעייפות.
                              <br />
                              <br />
                              חשוב לזכור שמוטיבציה היא לרוב רק גורם להתנעה
                              ראשונית של התהליך ואינה נשארת לאורך זמן. לכן, בעת
                              בחירת היעד מומלץ לבחור מטרה שהיא לא רק מעוררת
                              השראה אלא גם ריאלית, מדורגת ומותאמת לתהליך ממושך
                              ומציאותי, כך שההצלחה תהיה בת קיימא לאורך זמן.
                            </>
                          )}
                          {section.id === "challenges" && (
                            <>
                              לצד היתרונות המוטיבציוניים, בגד מטרה עלול ליצור
                              לחץ פסיכולוגי ותחושת כישלון. הגוף עובר שינויים
                              טבעיים במהלך השנים בשל גורמים כמו גיל, שינויים
                              הורמונליים ואורח חיים. לעיתים גם אם מושגת המטרה
                              המשקלית, הבגד עשוי להיראות ולהרגיש אחרת בשל שינויי
                              מבנה הגוף. הצבת יעד מרוחק מדי עלולה לגרום לתחושת
                              כישלון בכל פעם שמודדים את הבגד ולא ניכרת הצלחה.
                              <br />
                              <br />
                              בנוסף, התמקדות בבגד מטרה בלבד עלולה לצמצם את
                              ההסתכלות על תהליך הירידה במשקל ולהתעלם מהישגים
                              משמעותיים אחרים, כמו שיפור מערכת היחסים עם אוכל,
                              תחושת שליטה בניהול הארוחות והאכילה, שיפור הכושר
                              הגופני והגברת תחושת החיוניות. מחקרים בתחום הדימוי
                              העצמי מראים כי שאיפה לסטנדרטים לא מציאותיים עלולה
                              לפגוע בהערכה העצמית ולהוביל לתסכול מתמשך, במיוחד
                              כאשר ההתקדמות איטית או שאינה עומדת בציפיות. תחושות
                              אלה עלולות לעורר מעגל של אכילה רגשית המקשה עוד
                              יותר על השגת המטרה. שינוי הרגלים, הטמעתם ויישומם
                              כחלק מאורח חיים מאוזן ובריא מהווים הישג ארוך טווח
                              הממשיך לתרום לבריאות הרבה מעבר למספר על המשקל.
                            </>
                          )}
                          {section.id === "realistic-goals" && (
                            <>
                              כדי למנוע מעגלי תסכול ולהצליח להתמיד בתהליך
                              ובהטמעת השינויים, מומלץ להציב מטרות הדרגתיות
                              וריאליות. במקום להתמקד בפריט לבוש אחד המסמל יעד
                              רחוק, ניתן לבחור מספר בגדים במידות שונות המייצגים
                              שלבים שונים בתהליך. גישה זו מאפשרת לחגוג הישגים
                              קטנים לאורך הדרך, מה שמגביר את המוטיבציה ומעניק
                              תחושת סיפוק. בנוסף, חשוב לזכור שהגוף משתנה עם
                              הזמן, והצבת מטרות המתחשבות בכך תורמת לשמירה על
                              בריאות הנפש והגוף.
                              <br />
                              <br />
                              כדאי לשקול גם הצבת מטרות שאינן משקליות, כמו שיפור
                              מערכת היחסים עם אוכל, הנאה מאוכל ואכילה, תחושת
                              שליטה, הגברת תחושת החיוניות ושיפור היכולת לבצע
                              פעילויות יומיומיות בקלות. דרך נוספת להגביר את
                              המוטיבציה היא להשתמש בצנצנת חיזוקים אישית, שבה
                              כותבים משפטי העצמה או מתעדים רגעים שבהם התגברתם על
                              קשיים. כל פתק בצנצנת יכול לשמש תזכורת להצלחות
                              קטנות ולחזק את התחושה שאתם בתהליך המוביל לשינוי
                              חיובי.
                            </>
                          )}
                        </Typography>
                      </StyledPaper>
                    </SectionContent>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}
                  >
                    <ImageBox>
                      <img src={section.image} alt={section.title} />
                    </ImageBox>
                  </Grid>
                </Grid>
              </Box>
            </motion.div>
          ))}

          {/* Benefits Grid */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h4"
              align="center"
              sx={{
                fontWeight: 700,
                mb: 6,
                color: theme.palette.primary.main,
              }}
            >
              יתרונות הגישה המאוזנת
            </Typography>
            <Grid container spacing={4}>
              {benefits.map((benefit, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    viewport={{ once: true }}
                  >
                    <ContentCard>
                      <Box
                        sx={{
                          background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
                          borderRadius: "50%",
                          width: 80,
                          height: 80,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mb: 3,
                          mx: "auto",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={benefit.icon}
                          style={{
                            fontSize: "2rem",
                            color: "white",
                          }}
                        />
                      </Box>
                      <Typography
                        variant="h6"
                        gutterBottom
                        align="center"
                        sx={{ fontWeight: 600 }}
                      >
                        {benefit.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        align="center"
                        sx={{ fontSize: "1rem" }}
                      >
                        {benefit.description}
                      </Typography>
                    </ContentCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Summary */}
          <Parallax translateY={[20, -20]} opacity={[0.7, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  textAlign: "center",
                  fontWeight: 800,
                  color: theme.palette.primary.main,
                  mb: 4,
                }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{
                  fontSize: "1.2rem",
                  lineHeight: 1.9,
                  color: theme.palette.text.primary,
                  textAlign: "justify",
                }}
              >
                בגד מטרה יכול לשמש כלי להנעת תהליך הירידה במשקל, אך הוא טומן
                בחובו גם סיכון לתסכול אם היעד רחוק מדי או לא ריאלי. תהליך החזרה
                למשקל תקין צריך להיות מלווה בסבלנות ובקבלה עצמית, תוך הצבת מטרות
                הדרגתיות וחגיגת השינויים וההתקדמות לאורך הדרך. גישה מאוזנת זו
                מאפשרת לשמור על מוטיבציה גבוהה מבלי לפגוע בדימוי העצמי ובתחושת
                המסוגלות, במיוחד בתקופות של עומס ועייפות.
                <br />
                <br />
                חשוב לזכור כי תהליך הירידה במשקל והטמעת הרגלים אינו מתמקד רק
                בחזרה למשקל קודם, אלא גם בשיפור איכות החיים והבריאות הכללית,
                המעניקים תחושת סיפוק והעצמה לטווח הארוך.
                <br />
                <br />
                ליווי מקצועי של דיאטנית או איש מקצוע המבינים את המורכבות של
                התקופה – הן בפן הרגשי והן בפן המעשי – יכול להוות עוגן חשוב, לספק
                תמיכה מותאמת אישית ולעזור להימנע ממלכודות תסכול, תוך חיזוק
                המוטיבציה ושמירה על בריאות הגוף והנפש.
              </Typography>
            </StyledPaper>
          </Parallax>

          <Box sx={{ mt: 8 }}>
            <ContactButtons />
          </Box>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
