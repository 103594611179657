import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faAppleWhole,
  faBowlRice,
  faCarrot,
  faChartLine,
  faFaceWeary,
  faFamily,
  faGraduationCap,
  faMortarPestle,
  faPersonChalkboard,
  faSeedling,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { withWrapper } from "app/hooks";
import { motion } from "framer-motion";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import { useState } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(3),
  height: "100%",
  background: "rgba(255, 255, 255, 0.9)",
  backdropFilter: "blur(10px)",
  border: "2px solid rgba(255, 255, 255, 0.3)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-8px) scale(1.02)",
    boxShadow: "0 12px 40px rgba(0,0,0,0.12)",
  },
}));

interface FloatingIconProps {
  icon: IconProp;
  color: string;
  delay?: number;
  size?: number;
}

const FloatingIcon = ({
  icon,
  color,
  delay = 0,
  size = 40,
}: FloatingIconProps) => (
  <motion.div
    initial={{ opacity: 0, scale: 0 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{ delay, duration: 0.5 }}
  >
    <motion.div
      animate={{
        y: [0, -15, 0],
        rotate: [-5, 5, -5],
      }}
      transition={{
        duration: 3,
        repeat: Infinity,
        delay,
      }}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{
          fontSize: size,
          color,
          filter: "drop-shadow(2px 2px 4px rgba(0,0,0,0.2))",
        }}
      />
    </motion.div>
  </motion.div>
);

const nutritionTips = [
  {
    icon: faSeedling,
    title: "תזונה ודימוי גוף חיובי",
    description:
      "הרגלי אכילה בריאים היוצרים שינוי פנימי ומסייעים בחיזוק ביטחון עצמי ותחושת המסוגלות.",
    color: "#4CAF50",
  },
  {
    icon: faGraduationCap,
    title: "צמיחה והתפתחות",
    description: "תזונה תומכת בגדילה ובהתפתחות בשלבים השונים.",
    color: "#2196F3",
  },
  {
    icon: faMortarPestle,
    title: "בניית הרגלי אכילה בריאים",
    description:
      "ליווי הורים בהקניית הרגלים נכונים ושיפור השיח בנושא אוכל ואכילה.",
    color: "#E91E63",
  },
  {
    icon: faFaceWeary,
    title: "התמודדות עם בררנות באכילה",
    description: "הגדלת והעשרת המגוון התזונתי בדרך מקדמת.",
    color: "#9C27B0",
  },
];

const parentingGuidance = [
  {
    title: "ליווי אישי",
    description: "מיפוי הרגלים ואתגרים, עם כלים פרקטיים והכוונה יומיומית.",
    icon: faPersonChalkboard,
    color: "#2196F3",
  },
  {
    title: "הדרכת הורים",
    description: "כלים מעשיים לשיפור הרגלי אכילה ולתקשורת בריאה סביב אוכל.",
    icon: faFamily,
    color: "#4CAF50",
  },
  {
    title: "מעקב והתקדמות",
    description: "המשכיות וגמישות – התאמת הליווי בהתאם להתקדמות ולשגרה בבית.",
    icon: faChartLine,
    color: "#9C27B0",
  },
];

export const Home = withWrapper(() => {
  useMeta();

  const theme = useTheme();
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden", position: "relative" }}>
        {/* Animated Background */}
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            opacity: 0.1,
            background: `radial-gradient(circle at 20% 20%, ${theme.palette.primary.light}30 0%, transparent 50%),
                        radial-gradient(circle at 80% 80%, ${theme.palette.secondary.light}30 0%, transparent 50%)`,
          }}
        />

        {/* Hero Section */}
        <ParallaxBanner style={{ height: "50vh" }}>
          <ParallaxBannerLayer
            speed={-20}
            scale={[1.2, 1]}
            opacity={[0.9, 1]}
            translateY={[0, 50]}
          >
            <Box
              sx={{
                height: "100%",
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1547592180-85f173990554?q=80&w=1260)",
                backgroundSize: "cover",
                backgroundPosition: "center 30%",
                backgroundColor: "#FFB74D", // Fallback color
                filter: "brightness(0.75)",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background:
                    "linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.5))",
                  zIndex: 1,
                },
              }}
            />
          </ParallaxBannerLayer>

          {/* Floating Elements */}
          {[faCarrot, faAppleWhole, faBowlRice].map((icon, index) => (
            <ParallaxBannerLayer key={index} speed={-5 + index * 2}>
              <Box
                sx={{
                  position: "absolute",
                  top: `${15 + index * 10}%`,
                  left: `${10 + index * 25}%`,
                  zIndex: 2,
                }}
              >
                <FloatingIcon
                  icon={icon}
                  color="#FFD700"
                  delay={index * 0.2}
                  size={35 - index * 3}
                />
              </Box>
            </ParallaxBannerLayer>
          ))}

          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                padding: { xs: 2, md: 4 },
                position: "relative",
                zIndex: 2,
              }}
            >
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "4.5rem" },
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 4px 4px rgba(0,0,0,0.3)",
                    mb: 2,
                  }}
                >
                  תזונת ילדים
                </Typography>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: { xs: "1.2rem", md: "1.6rem" },
                    color: "white",
                    textShadow: "1px 2px 2px rgba(0,0,0,0.3)",
                    maxWidth: "800px",
                    margin: "0 auto",
                    mb: 4,
                  }}
                >
                  ליווי, תמיכה וכלים להורים
                </Typography>

                <ArticlesButton />
              </motion.div>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg">
          {/* Nutrition Tips Section */}
          <Box sx={{ py: 6 }}>
            <Grid container spacing={3}>
              {nutritionTips.map((tip, index) => (
                <Grid key={index} size={{ xs: 12, md: 6 }}>
                  <Parallax
                    translateY={[20, -20]}
                    opacity={[0.5, 1]}
                    scale={[0.8, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      onHoverStart={() => setHoveredCard(index)}
                      onHoverEnd={() => setHoveredCard(null)}
                    >
                      <StyledCard>
                        <Box
                          sx={{ display: "flex", gap: 3, alignItems: "center" }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${tip.color}15`,
                              p: 3,
                              borderRadius: "20px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <motion.div
                              animate={{
                                rotate: hoveredCard === index ? 360 : 0,
                              }}
                              transition={{ duration: 0.5 }}
                            >
                              <FontAwesomeIcon
                                icon={tip.icon}
                                style={{
                                  fontSize: 40,
                                  color: tip.color,
                                }}
                              />
                            </motion.div>
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ fontWeight: 700 }}
                            >
                              {tip.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1.1rem",
                                color: theme.palette.text.secondary,
                              }}
                            >
                              {tip.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Parenting Guidance Section */}
          <Box sx={{ py: 6, position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background:
                  "linear-gradient(135deg, rgba(255, 152, 0, 0.1), rgba(255, 183, 77, 0.1))",
                borderRadius: "30px",
                transform: "skewY(-3deg)",
                zIndex: -1,
              }}
            />

            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: 4,
                  fontSize: { xs: "2.2rem", md: "3rem" },
                  fontWeight: 800,
                  background: "linear-gradient(45deg, #FF9800, #FFB74D)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                }}
              >
                ליווי תזונתי צמוד
              </Typography>
            </Parallax>

            <Grid container spacing={3} justifyContent="center">
              {parentingGuidance.map((item, index) => (
                <Grid key={index} size={{ xs: 12, md: 4 }}>
                  <Parallax
                    translateY={[30, -25]}
                    scale={[0.8, 1]}
                    opacity={[0.5, 1]}
                    easing="easeOutQuad"
                  >
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <StyledCard
                        sx={{
                          textAlign: "center",
                          p: 4,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <motion.div
                          animate={{
                            y: [0, -10, 0],
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            delay: index * 0.3,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${item.color}15`,
                              p: 3,
                              borderRadius: "50%",
                              mb: 2,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={item.icon}
                              style={{
                                fontSize: 40,
                                color: item.color,
                              }}
                            />
                          </Box>
                        </motion.div>
                        <Typography
                          variant="h5"
                          gutterBottom
                          sx={{ fontWeight: 700 }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1.1rem",
                            color: theme.palette.text.secondary,
                          }}
                        >
                          {item.description}
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Parallax>
                </Grid>
              ))}
            </Grid>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
});
