import { Product } from "@monorepo/constants";
import { PublicLayout } from "modules/common";
import { NotFound, productPublicRoutes, ServerError } from "modules/core";
import { Home } from "modules/main";
import { Navigate, Outlet, RouteObject } from "react-router-dom";

const publicRoutes: RouteObject[] = [
  { path: "/", element: <Home /> },

  // error pages
  { path: "4xx-error", element: <NotFound /> },
  { path: "5xx-error", element: <ServerError /> },

  // product routes
  ...Object.values(Product).map((product) => ({
    path: `/${product}/*`,
    element: <Outlet />,
    children: productPublicRoutes[product],
  })),

  // not found routes
  {
    path: "*",
    element: <Navigate to="/4xx-error" replace />,
  },
];

const get = (): RouteObject[] => [
  {
    path: `/`,
    element: <PublicLayout />,
    children: publicRoutes,
  },
];

export { get };
