import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product, ProductInfo } from "@monorepo/constants";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

interface Props {
  icon: IconProp;
  title: string;
  description: string;
  to: string;
  product: Product;
  delay?: number;
}

export const ProductCard = ({
  icon,
  title,
  description,
  to,
  product,
  delay = 0,
}: Props) => {
  const theme = useTheme();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const variants = {
    hidden: {
      opacity: 0,
      y: 30,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.5,
        delay,
        ease: "easeOut",
      },
    },
  };

  const { theme: productTheme } = ProductInfo[product];

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      style={{ height: "100%" }}
    >
      <Card
        component={Link}
        to={to}
        sx={{
          height: "100%",
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          cursor: "pointer",
          textDecoration: "none",
          borderRadius: 4,
          position: "relative",
          overflow: "hidden",
          background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.background.default} 100%)`,
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            transform: "translateY(-8px)",
            boxShadow: "0 20px 40px rgba(0,0,0,0.12)",
            "& .icon-container": {
              transform: "scale(1.1)",
            },
            "&::before": {
              opacity: 0.8,
            },
          },
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "4px",
            background: `linear-gradient(to right, ${productTheme.main}, ${productTheme.gradient})`,
            opacity: 0.6,
            transition: "opacity 0.3s ease",
          },
        }}
      >
        <Box
          className="icon-container"
          sx={{
            width: 70,
            height: 70,
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: `linear-gradient(135deg, ${productTheme.main}, ${productTheme.gradient})`,
            boxShadow: `0 8px 16px ${productTheme.main}40`,
            mb: 2,
            transition: "transform 0.3s ease",
          }}
        >
          <FontAwesomeIcon
            icon={icon}
            size="lg"
            style={{
              color: "#fff",
            }}
          />
        </Box>

        <Typography
          variant="h6"
          sx={{
            fontWeight: 600,
            color: theme.palette.text.primary,
            mb: 1.5,
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="body2"
          sx={{
            color: theme.palette.text.secondary,
            lineHeight: 1.6,
            flexGrow: 1,
          }}
        >
          {description}
        </Typography>
      </Card>
    </motion.div>
  );
};
