import {
  faBalanceScale,
  faBrain,
  faCarrot,
  faHeartPulse,
  faLeaf,
  faSeedling,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HERO_IMAGE =
  "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?auto=compress&w=1260";
const PROTEIN_IMAGE =
  "https://images.pexels.com/photos/1143754/pexels-photo-1143754.jpeg?auto=compress&w=1260";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const StrategyCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out",
  height: "100%",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const GuidelineCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const strategies = [
  {
    id: "carbs",
    title: "איזון בין פחמימות לחלבונים בכדי למנוע אובדן מסת שריר",
    description:
      "אחד האתגרים המרכזיים בתזונה טבעונית וצמחונית הוא צריכת פחמימות בכמות מופרזת, במיוחד ממקורות מעובדים. מחקר שפורסם ב-Journal of Nutrition מצביע על כך שעודף פחמימות עלול להקשות על ירידה במשקל ולעכב שריפת שומן. לכן, מומלץ להעדיף פחמימות מורכבות בעלות אינדקס גליקמי נמוך כמו קינואה, כוסמת ושיבולת שועל, המעניקות תחושת שובע ומייצבות את רמות הסוכר בדם.",
    icon: faCarrot,
    color: "#FF6B6B",
  },
  {
    id: "protein",
    title: "חלבונים ומסת שריר",
    description:
      "חשוב לצרוך חלבונים בכמות מספקת כדי לשמר את מסת השריר, החיונית לא רק להרכב גוף בריא אלא גם לתמיכה בירידה במשקל. ככל שמסת השריר גבוהה יותר – כך קצב חילוף החומרים במנוחה עולה ומאפשר שריפת קלוריות גבוהה יותר לאורך היום. מקורות חלבון איכותיים בתזונה טבעונית וצמחונית כוללים עדשים, טופו, קטניות ועוד.",
    icon: faSeedling,
    color: "#4ECDC4",
  },
  {
    id: "fats",
    title: "שמירה על איזון בצריכת שומנים בריאים",
    description:
      "שומנים בריאים תורמים לתחושת שובע ולתפקוד המוח וההורמונים. יחד עם זאת, יש לצרוך אותם בכמות מדודה בשל צפיפותם הקלורית הגבוהה (כ-9 קלוריות לגרם). מומלץ לשלב אגוזים, שקדים, זרעים, חמאת בוטנים טבעית, אבוקדו ושמן זית בכמות מאוזנת כדי למנוע צריכת קלוריות מופרזת.",
    icon: faLeaf,
    color: "#45B7D1",
  },
  {
    id: "calcium",
    title: "צריכת סידן לשמירה על בריאות העצמות וחילוף חומרים תקין",
    description:
      "סידן חיוני לבריאות העצמות ולתפקוד מערכת השרירים והעצבים. בתזונה טבעונית יש להקפיד על מקורות סידן כגון: טופו, ברוקולי, שקדים, שומשום, טחינה גולמית וכד׳. במידת הצורך, ובהתאם להמלצת גורם מקצועי, ניתן לשקול שימוש בתוספי תזונה להשלמת הכמות המומלצת.",
    icon: faBalanceScale,
    color: "#96CEB4",
  },
];

const guidelines = [
  {
    id: "processed",
    title: "הימנעות ממזונות מעובדים ותחליפים לא מזינים",
    description:
      "תחליפי בשר וגבינות טבעוניים רבים הם מוצרים אולטרה - מעובדים המכילים שומן רווי, נתרן, חומרים משמרים, צבעי מאכל מזיקים ועוד שאינם תורמים לבריאות ואף עלולים להקשות על הירידה במשקל. ניתן להתייעץ עם דיאטנית בכדי לבחור תחליפי מזון אשר מיטיבים עם תהליך הירידה במשקל ומשלבים את אבות המזון החשובים ונוטריינטים חשובים ובנוסף, אפשר להכין מנות טבעוניות שונות ובהם גם ממרחים וקציצות בבית.",
    icon: faBrain,
    color: "#FF6B6B",
  },
  {
    id: "eating-out",
    title: "התמודדות עם אכילה מחוץ לבית ונשנושים לא מתוכננים",
    description:
      "אכילה במסעדות ובאירועים חברתיים עלולה להוות אתגר, שכן מנות צמחוניות וטבעוניות מתבססות פעמים רבות על פחמימות כמו פסטה, פוקצ׳ה ושלל מטוגנים. חשוב להיערך בצורה נכונה לקראת אירועים או מפגשים אלו וכך להצליח לשלב יציאות מהשיגרה ואירועים חברתיים ועדיין לתמוך בתהליך הירידה במשקל.",
    icon: faHeartPulse,
    color: "#4ECDC4",
  },
  {
    id: "snacks",
    title: "חשיבות מנות הביניים",
    description:
      "מנות ביניים חשובות, שכן הם מאפשרות להגיע לארוחה העיקרית ברמת רעב נכונה, מנות ביניים יכולות להיות טבעוניות אך מעובדות כמו חטיפים שונים, גם אלו המשווקים כעשירים בחלבון - מכילים חומרים מוספים רבים ונחשבים מוצרים מעובדים אשר אותם עדיף לצרוך במידה מועטה ככל האפשר. מנות ביניים או נשנושים יכולים להיות משביעים, מזינים וגם טבעוניים או צמחונים אשר יעזרו להעשיר את יומכם ולקדם את בריאותכם ומטרתם המשקלית",
    icon: faLeaf,
    color: "#96CEB4",
  },
];

export const PlantBasedWeightLoss = () => {
  useMeta();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                אסטרטגיות וטיפים מעשיים לירידה במשקל בתזונה טבעונית וצמחונית
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                בעוד שתזונה טבעונית וצמחונית נתפסת לעיתים כבריאה ומאוזנת, המעבר
                אליה אינו מבטיח בהכרח ירידה במשקל או תזונה נכונה. ללא ידע
                והכוונה מתאימה, קיימת נטייה לצרוך כמויות גדולות של פחמימות,
                חלבונים בכמות לא מספקת, שומנים בכמות מופרזת ומזונות מעובדים
                שאינם תורמים לירידה במשקל. במאמר זה אציג אסטרטגיות מעשיות לירידה
                במשקל במסגרת תזונה טבעונית וצמחונית, תוך הדגשת האתגרים המרכזיים
                והפתרונות המבוססים על מחקרים מדעיים.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Strategies Section */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 6, fontWeight: 700 }}
            >
              אסטרטגיות מרכזיות
            </Typography>
            <Grid container spacing={4}>
              {strategies.map((strategy, index) => (
                <Grid key={strategy.id} size={{ xs: 12, md: 6 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <StrategyCard>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 2,
                        }}
                      >
                        <FontAwesomeIcon
                          icon={strategy.icon}
                          style={{
                            fontSize: "2rem",
                            color: strategy.color,
                            marginLeft: "1rem",
                          }}
                        />
                        <Typography variant="h5" sx={{ fontWeight: 600 }}>
                          {strategy.title}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                        {strategy.description}
                      </Typography>
                    </StrategyCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Protein Section with Image */}
          <ParallaxBanner
            style={{
              height: "50vh",
              borderRadius: "20px",
              overflow: "hidden",
              margin: "4rem 0",
            }}
          >
            <ParallaxBannerLayer
              image={PROTEIN_IMAGE}
              speed={-15}
              scale={[1.1, 1]}
              opacity={[0.8, 1]}
            />
            <ParallaxBannerLayer speed={-10} opacity={[1, 0.9]}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: 4,
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: "white",
                    textAlign: "center",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    fontWeight: 700,
                  }}
                >
                  חלבון צמחי ומסת שריר
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>

          {/* Guidelines Section */}
          <Box sx={{ my: 8 }}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 4, fontWeight: 600 }}
              >
                המלצות מעשיות
              </Typography>
              <Grid container spacing={4}>
                {guidelines.map((guideline, index) => (
                  <Grid key={guideline.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.2 }}
                    >
                      <GuidelineCard>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            mb: 2,
                            gap: 2,
                          }}
                        >
                          <Typography
                            variant="h2"
                            sx={{
                              fontSize: "2.5rem",
                              lineHeight: 1,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={guideline.icon}
                              style={{ color: guideline.color }}
                            />
                          </Typography>
                          <Typography variant="h5" sx={{ fontWeight: 600 }}>
                            {guideline.title}
                          </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ lineHeight: 1.7 }}>
                          {guideline.description}
                        </Typography>
                      </GuidelineCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </StyledPaper>
          </Box>

          {/* Summary */}
          <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 3, fontWeight: 600 }}
              >
                סיכום
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                ירידה במשקל בתזונה טבעונית וצמחונית דורשת איזון נכון בין פחמימות
                לחלבונים, צריכת שומנים בריאים בכמות מדודה, אספקת סידן מספקת
                והבנה מעמיקה לגבי תחליפי המזון הקיימים. בחירה במזונות מלאים ולא
                מעובדים מסייעת לשמור על תחושת שובע ולמנוע צריכת קלוריות מיותרת.
                בנוסף, מומלץ להיעזר בדיאטנית קלינית המומחית בתזונה טבעונית
                וצמחונית להתאמת התפריט לצרכים האישיים ולמניעת חוסרים תזונתיים.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
