"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KidsTags = void 0;
const constants_1 = require("@monorepo/constants");
const default_tags_1 = require("./default.tags");
exports.KidsTags = {
    "": {
        title: constants_1.ProductInfo.kids.description,
        description: "ליווי תזונתי מקצועי לילדים המותאם אישית לצרכי הילד והמשפחה. מומחיות בהקניית הרגלי אכילה בריאים, התמודדות עם בררנות באכילה, והתפתחות בריאה.",
        keywords: "תזונת ילדים, דיאטנית ילדים, תזונה בריאה לילדים, בררנות באכילה, גדילה והתפתחות, הרגלי אכילה, ליווי תזונתי לילדים, תזונאית קלינית ילדים",
        canonicalUrl: "/kids",
    },
    "/articles": {
        ...default_tags_1.ArticlesTags,
        canonicalUrl: "/kids/articles",
    },
    "/articles/parental-attention-trap": {
        title: "הפניית הקשב הסלקטיבית של ההורים",
        description: "כיצד הפניית הקשב הסלקטיבית משפיעה על בחירות המזון שלנו לילדינו, וכיצד ניתן להימנע ממלכודת זו לטובת תזונה מאוזנת ובריאה יותר.",
        keywords: "תזונת ילדים, הרגלי אכילה, בחירות מזון, תזונה בריאה, הורות, חינוך תזונתי, אכילה מאוזנת, התפתחות ילדים",
        canonicalUrl: "/kids/articles/parental-attention-trap",
        ogType: "article",
        ogImage: "https://images.unsplash.com/photo-1556909114-f6e7ad7d3136?q=80&w=1200",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונת ילדים",
            },
        },
    },
    "/articles/sleep-obesity-link": {
        title: "הקשר בין שינה והשמנה אצל ילדים",
        description: "מחקרים חדשים מראים את הקשר המשמעותי בין איכות השינה להשמנה בקרב ילדים, וכיצד שיפור הרגלי השינה יכול לתרום לבריאות הילד.",
        keywords: "שינה בילדים, השמנת ילדים, הורמוני רעב, גדילת ילדים, שעות שינה מומלצות, תזונת ילדים, הורמון גדילה, איכות שינה",
        canonicalUrl: "/kids/articles/sleep-obesity-link",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3771663/pexels-photo-3771663.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-16",
                dateModified: "2025-02-21",
                articleSection: "תזונת ילדים",
            },
        },
    },
    "/articles/parenting-overeating": {
        title: "שיטות חינוך שגורמות לילדים לאכול יותר",
        description: "כיצד גישות הוריות שונות משפיעות על הרגלי האכילה של ילדים, והדרכים להימנע מגישות שעלולות לגרום לאכילת יתר.",
        keywords: "תזונת ילדים, חינוך לתזונה נכונה, אכילת יתר אצל ילדים, גישות הורות לאוכל, הרגלי אכילה בריאים, התמודדות עם אכילת יתר, תזונה מאוזנת לילדים, דפוסי אכילה",
        canonicalUrl: "/kids/articles/parenting-overeating",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4262010/pexels-photo-4262010.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-17",
                dateModified: "2025-02-21",
                articleSection: "תזונת ילדים",
            },
        },
    },
    "/articles/screen-sedentary-obesity": {
        title: "השפעת זמן מסך ואורח חיים יושבני על השמנת ילדים",
        description: "כיצד זמן מסך ממושך ואורח חיים יושבני משפיעים על השמנת ילדים, והדרכים לצמצום ההשפעות השליליות ופיתוח אורח חיים בריא ומאוזן.",
        keywords: "השמנת ילדים, זמן מסך, אורח חיים יושבני, תזונת ילדים, פעילות גופנית לילדים, הרגלי אכילה, בריאות ילדים, תזונה נכונה, דיאטנית ילדים",
        canonicalUrl: "/kids/articles/screen-sedentary-obesity",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/4740529/pexels-photo-4740529.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-17",
                dateModified: "2025-02-21",
                articleSection: "תזונת ילדים",
            },
        },
    },
    "/articles/food-marketing-eating-habits": {
        title: "השפעת שיווק המזון על הרגלי האכילה של ילדים",
        description: "כיצד תעשיית המזון משפיעה על הרגלי האכילה של ילדים באמצעות טכניקות שיווק מתוחכמות, והדרכים להתמודד עם השפעות אלו דרך חינוך וליווי מקצועי.",
        keywords: "שיווק מזון לילדים, הרגלי אכילה, תזונת ילדים, השמנת ילדים, פרסום מזון, הנדסת מזון, תזונה בריאה, חינוך תזונתי, התמודדות עם פרסום מזון",
        canonicalUrl: "/kids/articles/food-marketing-eating-habits",
        ogType: "article",
        ogImage: "https://images.pexels.com/photos/3985062/pexels-photo-3985062.jpeg?w=1260&auto=compress",
        jsonLdData: {
            articleJsonLd: {
                datePublished: "2025-02-19",
                dateModified: "2025-02-21",
                articleSection: "תזונת ילדים",
            },
        },
    },
    "/lets-talk": {
        ...default_tags_1.LetsTalkTags,
        canonicalUrl: "/kids/lets-talk",
    },
};
