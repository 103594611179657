import { Article } from "modules/core";

export const sportArticles: Article[] = [
  {
    path: "/vegan/articles/plant-based-sports",
    image:
      "https://images.pexels.com/photos/4720236/pexels-photo-4720236.jpeg?auto=compress&w=350",
  },
  {
    path: "/sport/articles/endurance-vs-strength-nutrition",
    image:
      "https://images.pexels.com/photos/3253501/pexels-photo-3253501.jpeg?auto=compress&w=350",
  },
  {
    path: "/sport/articles/diets-sports-performance",
    image:
      "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&w=350",
  },
  {
    path: "/sport/articles/sports-nutrition-impact",
    image:
      "https://images.pexels.com/photos/4498151/pexels-photo-4498151.jpeg?auto=compress&w=350",
  },
  {
    path: "/sport/articles/athlete-supplements",
    image:
      "https://images.pexels.com/photos/4397833/pexels-photo-4397833.jpeg?auto=compress&w=350",
  },
];
