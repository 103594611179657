import {
  faBatteryBolt,
  faHeart,
  faLeafHeart,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid2 as Grid,
  Typography,
  useTheme,
} from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { withWrapper } from "app/hooks";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import { useEffect, useState } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider,
} from "react-scroll-parallax";
import "./Home.scss";

const nutritionChallenges = [
  {
    title: "המחיר של דיאטות קיצוניות",
    description: "רעב מוגבר, אכילה לא נשלטת ותחושת כישלון ותסכול.",
    icon: faBatteryBolt,
    color: "#2196f3",
  },
  {
    title: "האוכל והחיים החברתיים",
    description:
      "הימנעות ממפגשים, חשש מאכילה ליד אחרים והשפעה על הדימוי העצמי.",
    icon: faHeart,
    color: "#4caf50",
  },
  {
    title: "כשאוכל הופך למאבק",
    description: "הלחץ סביב אוכל ומשקל פוגע בביטחון העצמי ובתחושת השליטה.",
    icon: faUtensils,
    color: "#FF5722",
  },
];

export const Home = withWrapper(() => {
  useMeta();
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    setIsMobile(window.innerWidth < 960);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 960);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ParallaxProvider>
      <div className="teens-home">
        <ParallaxBanner
          layers={[
            {
              image:
                "https://images.pexels.com/photos/1187086/pexels-photo-1187086.jpeg?auto=compress&cs=tinysrgb&w=1260",
              speed: -20,
            },
          ]}
          className="parallax-banner"
        >
          <div className="banner-content">
            <Typography variant="h1" className="main-title">
              תזונה חכמה בגיל ההתבגרות
            </Typography>
            <Typography variant="h2" className="subtitle" gutterBottom>
              לאכול חכם – תזונה שתומכת בגדילה, בצרכים ובשגרה היומיומית
            </Typography>

            <ArticlesButton />
          </div>
        </ParallaxBanner>

        <Container>
          <Box className="features-section" my={8}>
            <Grid container spacing={4}>
              <Grid data-aos="fade-up" size={{ xs: 12, md: 4 }}>
                <Card className="feature-card">
                  <CardContent>
                    <FontAwesomeIcon
                      icon={faLeafHeart}
                      className="feature-icon"
                    />
                    <Typography variant="h5">תמיכה בגדילה והתפתחות</Typography>
                    <Typography>
                      תזונה שמספקת לגוף את הדרוש לו לצמיחה ולהתחזקות.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-delay="100"
                size={{ xs: 12, md: 4 }}
              >
                <Card className="feature-card">
                  <CardContent>
                    <FontAwesomeIcon
                      icon={faBatteryBolt}
                      className="feature-icon"
                    />
                    <Typography variant="h5">תזונה מאוזנת ומותאמת</Typography>
                    <Typography>
                      התאמת הרגלי האכילה לשגרה היומיומית, לשיפור הריכוז, הכוח
                      והחיוניות.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-delay="200"
                size={{ xs: 12, md: 4 }}
              >
                <Card className="feature-card">
                  <CardContent>
                    <FontAwesomeIcon
                      icon={faUtensils}
                      className="feature-icon"
                    />
                    <Typography variant="h5">תזונה בהתאמה אישית</Typography>
                    <Typography>
                      מציאת האיזון הנכון – אכילה שמתאימה לשגרה, להעדפות ולצרכים
                      האישיים.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ py: { xs: 4 } }}>
            <Parallax translateY={[-20, 20]} opacity={[0.7, 1]}>
              <Typography
                variant="h2"
                sx={{
                  textAlign: "center",
                  mb: { xs: 4, md: 8 },
                  fontSize: { xs: "2rem", sm: "2.5rem", md: "3.5rem" },
                  fontWeight: 800,
                  color: "#2196f3",
                  px: 2,
                }}
                data-aos="fade-up"
              >
                אכילה בגיל ההתבגרות – בין אתגרים להצלחה
              </Typography>
            </Parallax>

            <Box
              sx={{
                position: "relative",
                padding: (theme) => theme.spacing(2),
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "2px",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, #2196f3 0%, transparent 100%)",
                  [theme.breakpoints.down("md")]: {
                    left: "20px",
                    transform: "none",
                  },
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: 2, md: 4 },
                }}
              >
                {nutritionChallenges.map((step, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: "relative",
                      padding: (theme) => theme.spacing(3),
                      width: "50%",
                      alignSelf: index % 2 === 0 ? "flex-start" : "flex-end",
                      marginLeft: {
                        xs: 0,
                        md: index % 2 === 0 ? 0 : "50%",
                      },
                      marginRight: {
                        xs: 0,
                        md: index % 2 === 0 ? "50%" : 0,
                      },
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        [index % 2 === 0 ? "right" : "left"]: "-10px",
                        width: "20px",
                        height: "20px",
                        background: step.color,
                        borderRadius: "50%",
                        transform: "translateY(-50%)",
                        [theme.breakpoints.down("md")]: {
                          left: "10px",
                          right: "auto",
                        },
                      },
                      [theme.breakpoints.down("md")]: {
                        width: "100%",
                        alignSelf: "flex-start",
                        paddingLeft: (theme) => theme.spacing(6),
                      },
                    }}
                  >
                    <Parallax
                      translateX={[index % 2 === 0 ? -30 : 30, 0]}
                      opacity={[0.5, 1]}
                      easing="easeOutQuad"
                      disabled={isMobile}
                    >
                      <Card
                        sx={{
                          padding: (theme) => theme.spacing(3),
                          background: "rgba(255, 255, 255, 0.95)",
                          backdropFilter: "blur(10px)",
                          transition: "all 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
                          position: "relative",
                          overflow: "visible",
                          borderRadius: "15px",
                          "&:hover": {
                            transform: "translateY(-8px) rotate(1deg)",
                            boxShadow: (theme) => theme.shadows[10],
                          },
                        }}
                        data-aos={index % 2 === 0 ? "fade-right" : "fade-left"}
                        data-aos-delay={index * 100}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: { xs: 2, md: 3 },
                            alignItems: "center",
                            flexDirection: { xs: "column", sm: "row" },
                            textAlign: { xs: "center", sm: "right" },
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${step.color}15`,
                              p: { xs: 1.5, md: 2 },
                              borderRadius: "16px",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={step.icon}
                              style={{
                                fontSize: "2rem",
                                color: step.color,
                              }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              sx={{
                                fontWeight: 700,
                                mb: 1,
                                fontSize: { xs: "1.25rem", md: "1.5rem" },
                              }}
                            >
                              {step.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{
                                lineHeight: 1.6,
                                fontSize: { xs: "0.875rem", md: "1rem" },
                              }}
                            >
                              {step.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    </Parallax>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

          <ContactButtons />
        </Container>
      </div>
    </ParallaxProvider>
  );
});
