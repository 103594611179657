import {
  faAppleWhole,
  faBicycle,
  faBolt,
  faBone,
  faDumbbell,
  faLeaf,
  faMedal,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.pexels.com/photos/4720236/pexels-photo-4720236.jpeg?auto=compress&w=1260",
  performance:
    "https://images.pexels.com/photos/2294361/pexels-photo-2294361.jpeg?auto=compress&w=640",
  muscleBuilding:
    "https://images.pexels.com/photos/1552242/pexels-photo-1552242.jpeg?auto=compress&w=640",
  boneDensity:
    "https://images.pexels.com/photos/4498151/pexels-photo-4498151.jpeg?auto=compress&w=640",
  recovery:
    "https://images.pexels.com/photos/3822583/pexels-photo-3822583.jpeg?auto=compress&w=640",
  nutrition:
    "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?auto=compress&w=640",
};

const performanceData = [
  { name: "שבוע 1", צמחונים: 85, "אוכלי כל": 87 },
  { name: "שבוע 2", צמחונים: 88, "אוכלי כל": 89 },
  { name: "שבוע 3", צמחונים: 90, "אוכלי כל": 90 },
  { name: "שבוע 4", צמחונים: 92, "אוכלי כל": 91 },
  { name: "שבוע 5", צמחונים: 93, "אוכלי כל": 92 },
  { name: "שבוע 6", צמחונים: 95, "אוכלי כל": 94 },
];

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

const sections = [
  {
    id: "performance",
    title: "השפעת התזונה על הישגים ספורטיביים",
    content: `הישגים ספורטיביים תלויים בכמות מספקת של אנרגיה, חלבונים ופחמימות זמינות. מחקר שפורסם ב-Journal of the International Society of Sports Nutrition מצא כי ספורטאים טבעונים וצמחונים יכולים להגיע לביצועים שווים לאלו של אוכלי כל, כל עוד התזונה שלהם מאוזנת וכוללת את כל אבות המזון. טבעונים יכולים לנצל את היתרון שבצריכת פחמימות מורכבות המספקות אנרגיה מתמשכת, אך עליהם להקפיד על צריכת חלבון מספקת לצורך התאוששות ובניית מסת שריר. חשוב להדגיש כי צריכת חלבון מספקת חיונית להתאוששות ולבניית מסת שריר אצל כל המתאמנים – גם אוכלי כל וגם צמחונים.`,
    icon: faBicycle,
    color: "#4CAF50",
    image: IMAGES.performance,
  },
  {
    id: "muscle",
    title: "בניית שריר והבדלים בצריכת חלבון",
    content: `אחד המיתוסים הנפוצים הוא שטבעונים מתקשים לבנות שריר בשל חוסר בחלבון מלא. עם זאת, מחקרים מראים כי שילוב קטניות, דגנים מלאים וטופו מאפשר לצרוך את כל חומצות האמינו החיוניות. לפי מחקר שפורסם ב-American Journal of Clinical Nutrition, צריכת חלבון צמחי בכמות מספקת (1.2-2 גרם לק"ג משקל גוף ביום) מסייעת בבניית מסת שריר בדומה לחלבון מהחי. יחד עם זאת, אוכלי כל נוטים לצרוך חלבון מלא בקלות רבה יותר, ולכן טבעונים צריכים להקפיד על שילוב נכון של מקורות חלבון לאורך היום.`,
    icon: faDumbbell,
    color: "#FF5722",
    image: IMAGES.muscleBuilding,
  },
  {
    id: "bone",
    title: "שמירה על מסת עצם והשוואה בין קבוצות תזונה",
    content: `תזונה עשירה בסידן וויטמין D חיונית לשמירה על בריאות העצם, במיוחד אצל ספורטאים שחשופים לעומסים גבוהים. מחקר שהתפרסם ב-Journal of Bone and Mineral Research הראה כי אוכלי כל צורכים סידן בכמות גבוהה יותר, אך טבעונים יכולים לקבל כמות מספקת מטופו מועשר, שקדים, שומשום וירקות ירוקים כהים. בנוסף, תוסף ויטמין D מומלץ במיוחד בחורף כאשר החשיפה לשמש נמוכה.`,
    icon: faBone,
    color: "#2196F3",
    image: IMAGES.boneDensity,
  },
  {
    id: "recovery",
    title: "השפעת התזונה על ההתאוששות והפחתת דלקתיות",
    content: `מחקרים מצביעים על כך שתזונה טבעונית עשירה בנוגדי חמצון יכולה לסייע בהתאוששות מהירה יותר ובהפחתת דלקתיות לאחר אימון. לדוגמה, פירות יער, אגוזים וירקות צבעוניים מספקים כמות גבוהה של ויטמינים ונוגדי חמצון שעוזרים לגוף להתמודד עם העומס הפיזי. לעומת זאת, צריכת בשר אדום עלולה להגביר תהליכי דלקת בגוף, מה שעשוי לעכב את תהליך ההתאוששות ולהגביר את הכאב שלאחר האימון. בנוסף, עיכול חלבון מן החי דורש יותר אנרגיה וזמן לעומת חלבון מהצומח, מה שעלול להפחית את כמות האנרגיה הזמינה שהגוף יכול להקדיש לתהליך ההתאוששות ובניית השריר. לעומת תזונה צמחונית או טבעונית המאפשרת עיכול קל ומהיר יותר של חלבון, ובכך מסייעת להפחתת תחושת הכבדות לאחר הארוחה ומפנה יותר אנרגיה לשיקום הגוף.`,
    icon: faBolt,
    color: "#9C27B0",
    image: IMAGES.recovery,
  },
  {
    id: "calories",
    title: "הבדלים בצריכת קלוריות ואנרגיה זמינה",
    content: `ספורטאים טבעונים וצמחונים צריכים לשים לב לצריכת קלוריות מספקת, שכן מזונות צמחיים מכילים לעיתים קרובות פחות קלוריות לנפח מזון נתון. מחקר שנערך באוניברסיטת אוקספורד מצא כי חוסר בצריכת קלוריות עלול לפגוע בביצועים הספורטיביים ובתהליך בניית השריר. לפיכך, חשוב לכלול בתפריט מזונות בעלי צפיפות קלורית גבוהה כמו אגוזים, אבוקדו, קטניות וטופו.`,
    icon: faAppleWhole,
    color: "#FF9800",
    image: IMAGES.nutrition,
  },
];

export const PlantBasedSports = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    צמחונות, טבעונות וספורט
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    מדריך מקיף להישגים ספורטיביים בתזונה צמחונית וטבעונית
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg">
          <ContentSection>
            {/* Introduction */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInUpVariants}
              transition={{ duration: 0.6 }}
            >
              <StyledCard>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    alignItems: "flex-start",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: `${theme.palette.primary.main}20`,
                      p: 2,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLeaf}
                      style={{
                        fontSize: 32,
                        color: theme.palette.primary.main,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" gutterBottom color="primary">
                    מבוא
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem" }}
                >
                  בשנים האחרונות גוברת ההתעניינות בקשר בין תזונה טבעונית
                  וצמחונית לבין הישגים ספורטיביים. האם ניתן להגיע להישגים זהים
                  לאלו של ספורטאים שאוכלים כל? במאמר זה נבחן כיצד תזונה צמחונית
                  וטבעונית משפיעה על ביצועים ספורטיביים, בניית שריר ושמירה על
                  העצמות תוך השוואה לאוכלי כל, אציג אילו נתונים מגובים מחקרית
                  ואחדד נושאים הדורשים תשומת לב מיוחדת. ההקדמה נערכה כך שתשמור
                  על אופייה המקורי תוך הבהרת המטרה המרכזית.
                </Typography>
              </StyledCard>
            </motion.div>

            {/* Performance Graph */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInUpVariants}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <StyledCard>
                <Typography
                  variant="h5"
                  gutterBottom
                  color="primary"
                  align="center"
                  sx={{ mb: 4 }}
                >
                  השוואת ביצועים ספורטיביים לאורך זמן
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    height: 400,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <LineChart
                    width={800}
                    height={400}
                    data={performanceData}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="צמחונים"
                      stroke="#4CAF50"
                      strokeWidth={2}
                    />
                    <Line
                      type="monotone"
                      dataKey="אוכלי כל"
                      stroke="#2196F3"
                      strokeWidth={2}
                    />
                  </LineChart>
                </Box>
              </StyledCard>
            </motion.div>

            {/* Main Sections */}
            {sections.map((section, index) => (
              <motion.div
                key={section.id}
                initial="hidden"
                whileInView="visible"
                variants={fadeInUpVariants}
                viewport={{ once: true }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 2, md: index % 2 === 0 ? 1 : 2 }}
                  >
                    <StyledCard>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 3,
                          alignItems: "flex-start",
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            backgroundColor: `${section.color}20`,
                            p: 2,
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={section.icon}
                            style={{
                              fontSize: 32,
                              color: section.color,
                            }}
                          />
                        </Box>
                        <Typography
                          variant="h4"
                          gutterBottom
                          sx={{ color: section.color }}
                        >
                          {section.title}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                      >
                        {section.content}
                      </Typography>
                    </StyledCard>
                  </Grid>
                  <Grid
                    size={{ xs: 12, md: 6 }}
                    order={{ xs: 1, md: index % 2 === 0 ? 2 : 1 }}
                  >
                    <Box
                      component="img"
                      src={section.image}
                      alt={section.title}
                      sx={{
                        width: "100%",
                        height: 400,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            ))}

            {/* Summary */}
            <motion.div
              initial="hidden"
              whileInView="visible"
              variants={fadeInUpVariants}
              viewport={{ once: true }}
              transition={{ duration: 0.8 }}
            >
              <StyledCard>
                <Box
                  sx={{
                    display: "flex",
                    gap: 3,
                    alignItems: "flex-start",
                    mb: 3,
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: `${theme.palette.primary.main}20`,
                      p: 2,
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faMedal}
                      style={{
                        fontSize: 32,
                        color: theme.palette.primary.main,
                      }}
                    />
                  </Box>
                  <Typography variant="h4" gutterBottom color="primary">
                    סיכום
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{ fontSize: "1.2rem" }}>
                  ספורטאים טבעונים וצמחונים יכולים להגיע להישגים זהים לאלו של
                  אוכלי כל, כל עוד הם מקפידים על תזונה מאוזנת הכוללת את כל אבות
                  המזון. צריכת חלבון מספקת חיונית לבניית מסת שריר הן עבור
                  טבעונים והן עבור אוכלי כל, ואילו סידן וויטמין D חשובים לשמירה
                  על מסת עצם. תזונה עשירה בנוגדי חמצון יכולה לשפר את ההתאוששות
                  ולהפחית דלקתיות. עם זאת, יש להקפיד על צריכת קלוריות מספקת כדי
                  לתמוך בפעילות הגופנית. ליווי של דיאטנית קלינית המומחית בתזונת
                  ספורט יכול לסייע בהתאמת התפריט לצרכים האישיים ולהשגת תוצאות
                  מיטביות.
                </Typography>
              </StyledCard>
            </motion.div>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
