import {
  faAppleWhole,
  faChartLine,
  faHeartPulse,
  faScaleBalanced,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const MYTHS = [
  {
    id: "carbs",
    title: "פחמימות גורמות להשמנה",
    icon: faUtensils,
    color: "#FF6B6B",
  },
  {
    id: "fats",
    title: "צריך להימנע משומנים כדי לשמור על משקל תקין",
    icon: faScaleBalanced,
    color: "#4ECDC4",
  },
  {
    id: "acne",
    title: "מזון שומני גורם לאקנה",
    icon: faHeartPulse,
    color: "#45B7D1",
  },
  {
    id: "diets",
    title: "דיאטות קיצוניות הן הדרך הכי מהירה לרדת במשקל",
    icon: faChartLine,
    color: "#96CEB4",
  },
  {
    id: "healthy",
    title: "מזון בריא הוא לא טעים",
    icon: faAppleWhole,
    color: "#FFB6B9",
  },
];

const RECOMMENDATIONS = [
  {
    id: "variety",
    title: "אכלו מגוון רחב של מזונות",
    description:
      "הקפידו לשלב בתפריט פחמימות מורכבות, חלבונים איכותיים, שומנים בריאים, ירקות ופירות.",
    icon: "🥗",
  },
  {
    id: "meals",
    title: "שמרו על ארוחות מסודרות",
    description:
      "שלוש ארוחות עיקריות ושתי ארוחות ביניים יומיות יסייעו לשמור על רמות אנרגיה מאוזנות.",
    icon: "⏰",
  },
  {
    id: "comparison",
    title: "הימנעו מהשוואות ומהגבלות מיותרות",
    description:
      "כל גוף הוא ייחודי, ואין צורך לעקוב אחרי טרנדים תזונתיים שאינם מתאימים לכם.",
    icon: "🚫",
  },
  {
    id: "attitude",
    title: "שמרו על גישה חיובית כלפי אוכל",
    description:
      "תזונה בריאה היא דרך חיים ולא עונש. למדו ליהנות מהאוכל תוך הקשבה לצורכי הגוף.",
    icon: "💪",
  },
];

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const MythCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  transition: "transform 0.3s ease-in-out",
  height: "100%",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const RecommendationCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const HERO_IMAGE =
  "https://images.pexels.com/photos/1640774/pexels-photo-1640774.jpeg?w=1260&auto=compress";

export const NutritionMyths = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                מיתוסים בתזונה בגיל ההתבגרות - מה נכון ומה לא?
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                גיל ההתבגרות הוא תקופה של שינויים גופניים, הורמונליים ורגשיים,
                שבה מתעצבת גם מערכת היחסים עם האוכל. בעידן של מידע זמין מכל עבר,
                בני נוער והוריהם נתקלים במיתוסים רבים הקשורים לתזונה, שעלולים
                להוביל להרגלים לא בריאים או להימנעויות מיותרות ממזונות חיוניים.
                במאמר זה אסקור כמה מיתוסים נפוצים, אסביר מה נכון ומה לא על פי
                מחקרים עדכניים, ואספק המלצות מעשיות לשמירה על תזונה מאוזנת.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Myths Section */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 6, fontWeight: 700 }}
              data-aos="fade-up"
            >
              מיתוסים נפוצים
            </Typography>
            <Grid container spacing={4}>
              {MYTHS.map((myth, index) => (
                <Grid
                  key={myth.id}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                  size={{ xs: 12, md: 6 }}
                >
                  <MythCard>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <FontAwesomeIcon
                        icon={myth.icon}
                        style={{
                          color: myth.color,
                          fontSize: "2rem",
                          marginLeft: theme.spacing(2),
                        }}
                      />
                      <Typography variant="h5" sx={{ fontWeight: 600 }}>
                        {myth.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
                      {myth.id === "carbs" &&
                        "האמת: פחמימות הן מקור אנרגיה חיוני, במיוחד בגיל ההתבגרות שבו הגוף גדל ומתפתח במהירות. מחקרים מראים כי צריכת פחמימות מורכבות כמו דגנים מלאים, קטניות וירקות תורמת לשובע ולתפקוד תקין של המוח והשרירים. עם זאת, יש להימנע מצריכת יתר של פחמימות מעובדות וסוכרים פשוטים, המגבירים את הסיכון לעלייה במשקל ולבעיות מטבוליות."}
                      {myth.id === "fats" &&
                        "האמת: שומנים בריאים, כמו אלו המצויים באגוזים, אבוקדו, שמן זית ודגים, חיוניים להתפתחות המוח ולהפקת הורמונים. מחקר שפורסם ב-Journal of Adolescent Health הראה כי שילוב שומנים בריאים בתפריט תורם לשמירה על תחושת שובע ומסייע במניעת אכילה מופרזת. לעומת זאת, יש להמעיט בצריכת שומנים רוויים ושומני טראנס המצויים במזון מעובד."}
                      {myth.id === "acne" &&
                        "האמת: הקשר בין תזונה לאקנה נבדק במחקרים רבים, אך מזון שומני כשלעצמו אינו הגורם הישיר להופעת פצעונים. עם זאת, מחקרים מראים כי מזון עשיר בסוכר מעובד ובפחמימות פשוטות עלול להגביר דלקתיות בגוף ולתרום להתפרצות אקנה. לדוגמה, צריכה מוגזמת של משקאות ממותקים ומאפים מתוקים נקשרה להחמרת תסמיני האקנה. מנגד, שילוב שומנים בריאים כמו אלו המצויים באגוזים, דגים ואבוקדו עשוי לתרום לבריאות העור. לכן, חשוב לאזן את התפריט ולשלב מזונות עשירים בנוגדי חמצון, כמו ירקות ופירות, התורמים לשמירה על מראה עור בריא."}
                      {myth.id === "diets" &&
                        "האמת: דיאטות קיצוניות עלולות להזיק לבריאות, לגרום לחסרים תזונתיים ואף להוביל לאכילה מופרעת - צימצום שמוביל לאכילה לפרצי אכילה בלתי נשלטת ואף להפרעות אכילה. מחקרים מצביעים על כך שירידה איטית ומבוקרת במשקל היא הדרך היעילה , והבטוחה ביותר לשמירה על משקל תקין בטווח הארוך. במקום להימנע מקבוצות מזון שלמות, יש להתמקד באכילה מאוזנת ומגוונת ובסיגול הרגלי אכילה נכונים הכוללים צריכת ירקות, פירות, דגנים מלאים, חלבונים מזינים ועוד..."}
                      {myth.id === "healthy" &&
                        "האמת: תזונה בריאה יכולה להיות מגוונת, טעימה ומעוררת תיאבון. באמצעות שילוב נכון של תבלינים, עשבי תיבול ושיטות בישול מגוונות, ניתן להכין מנות בריאות שמספקות גם הנאה קולינרית. לדוגמה, ירקות קלויים בתנור עם שמן זית ותבלינים, סלטים צבעוניים עם רטבים מעניינים או יוגורט עם פירות טריים ואגוזים – כל אלה לא רק טעימים אלא גם מזינים."}
                    </Typography>
                  </MythCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Social Media Section */}
          <Parallax translateY={[-20, 20]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3} sx={{ my: 8 }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                השפעת הרשתות החברתיות
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                בנוסף, כיום הרשתות החברתיות מלאות במשפיענים שמקדמים אכילה בריאה
                וטעימה. באמצעות צפייה בסרטונים והדרכות, ניתן לקבל השראה, ללמוד
                כיצד לשלב מזונות בריאים בתפריט היומי ולגלות מתכונים חדשים
                ומעניינים. הניסיון וההתנסות האישית הם המפתח למציאת המנות שהכי
                מתאימות לטעם האישי שלכם.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Recommendations Section */}
          <Box sx={{ my: 8 }}>
            <Typography
              variant="h3"
              align="center"
              sx={{ mb: 6, fontWeight: 700 }}
              data-aos="fade-up"
            >
              המלצות מעשיות להורים ולבני נוער
            </Typography>
            <Grid container spacing={4}>
              {RECOMMENDATIONS.map((rec, index) => (
                <Grid
                  key={rec.id}
                  data-aos="fade-up"
                  data-aos-delay={index * 100}
                  size={{ xs: 12, sm: 6 }}
                >
                  <RecommendationCard>
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Typography variant="h2" sx={{ ml: 2 }}>
                        {rec.icon}
                      </Typography>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {rec.title}
                      </Typography>
                    </Box>
                    <Typography variant="body1" sx={{ lineHeight: 1.8 }}>
                      {rec.description}
                    </Typography>
                  </RecommendationCard>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Summary Section */}
          <Parallax translateY={[-10, 10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3} sx={{ my: 8 }}>
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                סיכום
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                המידע הרב הזמין היום עלול לבלבל ולעיתים להוביל להחלטות לא
                נכונות. חשוב להפריד בין מיתוסים לעובדות מבוססות מחקר ולבחור
                בתזונה מאוזנת שתומכת בבריאות הפיזית והנפשית. הורים ובני נוער
                כאחד יכולים להיעזר בליווי מקצועי של דיאטנית מוסמכת שתסייע להם
                להבין מה נכון עבורם ולפתח הרגלי אכילה בריאים לטווח הארוך.
              </Typography>
            </StyledPaper>
          </Parallax>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
