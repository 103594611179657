import {
  faAppleWhole,
  faBrain,
  faChildReaching,
  faLightbulb,
  faScaleBalanced,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Card,
  Container,
  Fade,
  Grid2 as Grid,
  Typography,
  useTheme,
  Zoom,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const impactData = [
  {
    id: "selective",
    title: "הפניית קשב סלקטיבית",
    description:
      "התמקדות ברכיב חיובי אחד או שניים במזון תוך התעלמות מרכיבים פחות טובים, מה שמוביל לבחירות תזונתיות לא מאוזנות.",
    icon: faBrain,
    color: "#FF6B6B",
  },
  {
    id: "habits",
    title: "השפעה על הרגלי אכילה",
    description:
      "ילדים לומדים להעריך מזון על בסיס רכיב בודד במקום התמונה המלאה, מה שמוביל להעדפת מזון מעובד.",
    icon: faChildReaching,
    color: "#4ECDC4",
  },
  {
    id: "nutrition",
    title: "ערך תזונתי מופחת",
    description:
      "התעלמות מרכיבים שליליים במזון מובילה לצריכה מוגברת של סוכר, שומן רווי ונתרן.",
    icon: faAppleWhole,
    color: "#45B7D1",
  },
  {
    id: "balance",
    title: "חוסר איזון תזונתי",
    description:
      "קושי בהטמעת הרגלי אכילה מאוזנים והעדפה של מזון מעובד על פני מזון טבעי ומזין.",
    icon: faScaleBalanced,
    color: "#96CEB4",
  },
];

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(6),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(8),
  },
}));

const IMAGES = {
  hero: "https://images.unsplash.com/photo-1556909114-f6e7ad7d3136?q=80&w=1200",
  family:
    "https://images.unsplash.com/photo-1588528402605-1f9d0eb7a6d6?q=80&w=600",
  healthy:
    "https://images.unsplash.com/photo-1606787619248-f301830a5a57?q=80&w=600",
  cooking:
    "https://images.unsplash.com/photo-1484723091739-30a097e8f929?q=80&w=600",
  foodChoices:
    "https://images.unsplash.com/photo-1586511925558-a4c6376fe65f?q=80&w=600",
  balancedMeal:
    "https://images.unsplash.com/photo-1547592166-23ac45744acd?q=80&w=600",
};

const fadeInUpVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export const ParentalAttentionTrap = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Enhanced Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={IMAGES.hero}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.7))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Zoom in timeout={1000}>
                  <Typography
                    variant="h1"
                    align="center"
                    sx={{
                      fontWeight: 800,
                      color: "white",
                      textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                      maxWidth: "1000px",
                      fontSize: { xs: "2.5rem", md: "4rem" },
                      lineHeight: 1.2,
                      marginBottom: 3,
                    }}
                  >
                    הפניית הקשב הסלקטיבית של ההורים
                  </Typography>
                </Zoom>
                <Fade in timeout={2000}>
                  <Typography
                    variant="h4"
                    align="center"
                    sx={{
                      color: "white",
                      textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                      maxWidth: "800px",
                      mt: 2,
                      fontSize: { xs: "1.5rem", md: "2.2rem" },
                      fontWeight: 500,
                    }}
                  >
                    ״מלכודת״ בבחירת המזון לילדנו
                  </Typography>
                </Fade>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 4 }}>
          <ContentSection>
            {/* Introduction with Image */}
            <Grid container spacing={4} alignItems="center">
              <Grid size={{ xs: 12, md: 6 }}>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  variants={fadeInUpVariants}
                  transition={{ duration: 0.6 }}
                >
                  <StyledCard>
                    <Typography
                      variant="h4"
                      gutterBottom
                      color="primary"
                      sx={{ mb: 3 }}
                    >
                      מבוא
                    </Typography>
                    <Typography
                      variant="body1"
                      paragraph
                      sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                    >
                      תזונת הילדים היא אחד מהנושאים המרכזיים שמשפיעים על בריאותם
                      והתפתחותם לאורך זמן. כהורים, אנו מנהלים את הרכב הארוחות
                      ומעצבים את הרגלי האכילה של ילדינו – נושא אשר טומן בחובו
                      אחריות רבה. לעיתים, הורים ״נופלים למלכודת״ של הפניית הקשב
                      הסלקטיבית, כלומר, ההתמקדות ברכיב חיובי אחד או שניים במזון
                      / מוצר או מנה מסויימת על חשבון התעלמות משאר המרכיבים הפחות
                      טובים. דוגמה לכך היא כאשר אנו מגישים לילד פיצה ומנחמים את
                      עצמנו: &ldquo;לפחות יש שם גבינה צהובה, מקור טוב
                      לסידן&rdquo;, או כשאנחנו בוחרים לשתות שוקו ומתמלאים בשמחה
                      על מנת &ldquo;חלבון וחלב טובה&rdquo;. בחירה כזו, מתעלמת
                      מרכיבים משמעותיים נוספים - כמו סוכר, שומן רווי, נתרן
                      וחומרים נוספים, מלמדים את הילדים להתרגל למרקם וטעם
                      מעובדים, מקשים על העשרת הארוחות במזון ״פשוט לחיך״ כמו
                      ירקות, פירות, חלבון ודגנים מלאים ובנוסף אף יכולה להוביל
                      להטמעת הרגלי אכילה אשר אותם יהיה קשה לשנות בקרב הילדים.
                    </Typography>
                  </StyledCard>
                </motion.div>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Box
                  component="img"
                  src={IMAGES.cooking}
                  alt="Parent and child cooking together"
                  sx={{
                    width: "100%",
                    height: 400,
                    objectFit: "cover",
                    borderRadius: 4,
                    boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                  }}
                />
              </Grid>
            </Grid>

            {/* Impact Cards with Enhanced Design */}
            <Box sx={{ my: 8 }}>
              <Typography
                variant="h3"
                align="center"
                color="primary"
                sx={{ mb: 6, fontWeight: 700 }}
              >
                ההשפעות של הפניית קשב סלקטיבית
              </Typography>
              <Grid container spacing={3}>
                {impactData.map((impact, index) => (
                  <Grid key={impact.id} size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial="hidden"
                      animate="visible"
                      variants={fadeInUpVariants}
                      transition={{ delay: index * 0.2 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${impact.color}20`,
                              p: 3,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={impact.icon}
                              style={{ fontSize: 40, color: impact.color }}
                            />
                          </Box>
                          <Box>
                            <Typography
                              variant="h5"
                              gutterBottom
                              sx={{ color: impact.color, fontWeight: 600 }}
                            >
                              {impact.title}
                            </Typography>
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "1.1rem", lineHeight: 1.6 }}
                            >
                              {impact.description}
                            </Typography>
                          </Box>
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Solutions Section with Interactive Elements */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: 0.3 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${theme.palette.primary.main}20`,
                              p: 2,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLightbulb}
                              style={{
                                fontSize: 32,
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            sx={{ fontWeight: 700 }}
                          >
                            המנגנון של הפניית הקשב הסלקטיבית
                          </Typography>
                        </Box>
                        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
                          כאשר אנו מציגים מזון לילדינו, אנו לעיתים נוטים להתרכז
                          בתכונה אחת או בשתי תכונות חיוביות של המנה, ובכך
                          מפספסים את התמונה הכוללת. לדוגמה, הפיצה עשויה להכיל
                          גבינה עשירה בסידן, אך היא גם עשויה להיות עתירת שומן,
                          מלח ופחמימות מעובדות. אותו עיקרון חל גם על שוקו,
                          ״חטיפי בריאות״, אקטימל ומעדנים ועוד – למרות שהם מכיל
                          מעט רכיבים חיוביים כמו חלבון מהחלב, או סיבים תזונתיים
                          ודגן מלא, הם גם מכילים בסוכר, חומרין מוספים, משפרי טעם
                          ובעלי מרקם וטעם רחוקים מהחומרי הגלם הבסיסיים. הרגלים
                          אלן לרוב מובילים להעדפה של מזון מעובד, מתועש וממותק.
                          התמקדות כזו מפחיתה את הערך התזונתי של הארוחה כולה,
                          מגבירה את הסיכון לפיתוח הרגלי אכילה לא בריאים, ולאורך
                          זמן יכולים לגרום לצימצום המגוון האוכל המקובל ע״י הילד
                          - הדרך לתסכול, לחץ וריבים סביב האכילה והארוחות לא
                          רחוקה…
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: 0.3 }}
                    >
                      <Box
                        component="img"
                        src={IMAGES.balancedMeal}
                        alt="Balanced healthy meal"
                        sx={{
                          width: "100%",
                          height: 400,
                          objectFit: "cover",
                          borderRadius: 4,
                          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                        }}
                      />
                    </motion.div>
                  </Grid>
                </Grid>
              </motion.div>
            </Box>

            {/* Impact on Children's Eating Habits Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      component="img"
                      src={IMAGES.foodChoices}
                      alt="Child making food choices"
                      sx={{
                        width: "100%",
                        height: 300,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: 0.3 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                            mb: 3,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${theme.palette.primary.main}20`,
                              p: 2,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faChildReaching}
                              style={{
                                fontSize: 32,
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            sx={{ fontWeight: 700 }}
                          >
                            ההשפעה על הרגלי האכילה של הילדים
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                        >
                          כשההורים מנהלים את הארוחות מתוך הפניית קשב סלקטיבית,
                          הם לא רק מעבירים מסר של &ldquo;זה בסדר, כי לפחות יש פה
                          משהו טוב&rdquo;, אלא גם מורים לילדים כיצד לבחור
                          ולהעריך מזון. הילדים לומדים מזה שהדגש הוא לא על התמונה
                          הכוללת של המזון, אלא על רכיב מסוים. כך, הם נוטים לפתח
                          העדפות תזונתיות המבוססות על בחירות סלקטיביות – הם
                          ילמדו לחפש את הפן החיובי במזון גם אם מדובר במזון מעובד
                          או לא איכותי - כמובן שחברות השיווק השונות יודעות ששם
                          נמצא היתרון שלהן ומרבות לפעול בצורה זו (&rdquo;רק חצי
                          כפית סוכר למנה&rdquo; / מופחת סוכר / מכיל דגנים מלאים
                          ועוד…). לאורך זמן, התנהגות זו עלולה להוביל לאכילה
                          בררנית, נטייה למזון מעובד ולעיתים אף לבעיות בריאותיות
                          כגון עודף משקל, סוכרת ובעיות לב.
                        </Typography>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                </Grid>
              </motion.div>
            </Box>

            {/* How to Improve Food Choices Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.8, ease: "easeOut" }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid size={{ xs: 12, md: 6 }}>
                    <motion.div
                      initial={{ opacity: 0, x: 50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.6, delay: 0.3 }}
                    >
                      <StyledCard>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 3,
                            alignItems: "flex-start",
                            mb: 3,
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: `${theme.palette.primary.main}20`,
                              p: 2,
                              borderRadius: "50%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLightbulb}
                              style={{
                                fontSize: 32,
                                color: theme.palette.primary.main,
                              }}
                            />
                          </Box>
                          <Typography
                            variant="h4"
                            gutterBottom
                            color="primary"
                            sx={{ fontWeight: 700 }}
                          >
                            כיצד להתמודד ולשפר את בחירות המזון
                          </Typography>
                        </Box>
                        <Typography
                          variant="body1"
                          paragraph
                          sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                        >
                          כדי לשנות את התמונה ולהנחות את ילדינו לעבר הרגלי אכילה
                          בריאים, יש להכיר ולהתמודד עם ההפניית הקשב הסלקטיבית
                          שלנו כהורים:
                        </Typography>
                        <Box sx={{ mt: 3 }}>
                          {[
                            {
                              title: "התבוננות כוללת במזון",
                              content:
                                "במקום להתמקד רק ברכיב הבודד החיובי, כדאי לבחון את המנה בכללותה – האם היא מכילה גם רכיבים מזינים נוספים? האם היא מתוכננת כך שתיתן מענה תזונתי מאוזן?",
                            },
                            {
                              title: "יצירת ארוחות מאוזנות",
                              content:
                                "יש להקפיד לשלב בתפריט מגוון רחב של רכיבים – חלבונים, שומנים בריאים, פחמימות מלאות, ויטמינים ומינרלים – כך שהארוחה תהיה מאוזנת ומספקת.",
                            },
                            {
                              title: "שיתוף פעולה בהכנת הארוחות",
                              content:
                                "כאשר ילדים משתתפים בהכנת הארוחה, הם לומדים להעריך את המזון ולהכיר את רכיביו השונים, מה שמגביר את הסיכוי שהם יפתחו טעמים מגוונים ולא יוגבלו לבחירה סלקטיבית.",
                            },
                          ].map((item, index) => (
                            <motion.div
                              key={index}
                              initial={{ opacity: 0, y: 20 }}
                              whileInView={{ opacity: 1, y: 0 }}
                              viewport={{ once: true }}
                              transition={{
                                duration: 0.5,
                                delay: 0.4 + index * 0.2,
                              }}
                            >
                              <Box sx={{ mb: index < 2 ? 4 : 0 }}>
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  color="primary"
                                  sx={{
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      width: 8,
                                      height: 8,
                                      borderRadius: "50%",
                                      backgroundColor:
                                        theme.palette.primary.main,
                                    }}
                                  />
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                                >
                                  {item.content}
                                </Typography>
                              </Box>
                            </motion.div>
                          ))}
                        </Box>
                      </StyledCard>
                    </motion.div>
                  </Grid>
                  <Grid size={{ xs: 12, md: 6 }}>
                    <Box
                      component="img"
                      src={IMAGES.healthy}
                      alt="Children eating healthy food"
                      sx={{
                        width: "100%",
                        height: 500,
                        objectFit: "cover",
                        borderRadius: 4,
                        boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            </Box>

            {/* Summary Section */}
            <Box sx={{ my: 8 }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                <StyledCard>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="primary"
                    sx={{ mb: 4, fontWeight: 700 }}
                  >
                    לסיכום
                  </Typography>
                  <Typography
                    variant="body1"
                    paragraph
                    sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                  >
                    כהורים, תפקידנו המרכזי הוא לנהל את הרכב הארוחות של ילדינו
                    וליצור אצלם הרגלי אכילה בריאים שמלווים אותם לאורך חייהם.
                    הפניית הקשב הסלקטיבית שלנו, שמתרחשת כאשר אנו מתמקדים רק
                    ברכיב בודד במזון, עלולה להוביל להטמעת הרגלי אכילה בררניים
                    ולעדיפות למזון מעובד ולא מאוזן. חשוב להכיר במנגנון זה ולפעול
                    לפי עקרונות תזונתיים מקיפים ומאוזנים. כך נוכל להבטיח כי
                    ילדינו יקבלו את כל הרכיבים התזונתיים הדרושים להם להתפתחות
                    תקינה ובריאה, והם ילמדו להעריך את המזון על פי ערכיו הכוללים,
                    ולא רק לפי המצאותו של רכיב בודד.
                  </Typography>
                </StyledCard>
              </motion.div>
            </Box>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
