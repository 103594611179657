"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductInfo = void 0;
const product_const_1 = require("./product.const");
exports.ProductInfo = {
    [product_const_1.Product.Lifestyle]: {
        title: "אורח חיים",
        description: "ליווי תזונתי אישי לאורח חיים בריא",
        theme: { main: "#4eccc6", gradient: "#7ce0db" },
    },
    [product_const_1.Product.Kids]: {
        title: "ילדים",
        description: "ליווי הורים לתזונת ילדים",
        theme: { main: "#ffca28", gradient: "#ffe082" },
    },
    [product_const_1.Product.Teens]: {
        title: "בני נוער",
        description: "ליווי תזונתי מותאם לגיל ההתבגרות",
        theme: { main: "#5b9bd5", gradient: "#7cb9e8" },
    },
    [product_const_1.Product.Vegan]: {
        title: "צמחונות וטבעונות",
        shortTitle: "צמחונות",
        description: "תזונה מאוזנת לצמחונים וטבעונים",
        theme: { main: "#70d170", gradient: "#98ff98" },
    },
    [product_const_1.Product.Sport]: {
        title: "ספורט",
        description: "ליווי תזונתי מקצועי לפעילות גופנית ואימונים",
        theme: { main: "#ff8c1a", gradient: "#ffb347" },
    },
    [product_const_1.Product.Maternity]: {
        title: "היריון והנקה",
        description: "ליווי תזונתי מיטבי להריון, הנקה ואחרי הלידה",
        theme: { main: "#b784a7", gradient: "#d4a5c7" },
    },
    [product_const_1.Product.Midlife]: {
        title: "גיל המעבר",
        description: "תזונה מותאמת לשינויים בגיל המעבר",
        theme: { main: "#7d6b91", gradient: "#9b89b3" },
    },
};
