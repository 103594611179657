import { Box, Container, Typography, useTheme } from "@mui/material";
import { motion, useScroll, useTransform } from "framer-motion";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ContactButtons } from "modules/common";
import { useRef } from "react";

gsap.registerPlugin(ScrollTrigger);

export const ContactSection = () => {
  const theme = useTheme();
  const sectionRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["start end", "end start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [0, 1, 1, 0]);
  const y = useTransform(scrollYProgress, [0, 0.2, 0.8, 1], [50, 0, 0, 50]);

  return (
    <Box
      ref={sectionRef}
      component={motion.div}
      style={{ opacity }}
      sx={{
        py: 8,
        background: theme.palette.background.paper,
        position: "relative",
        overflow: "hidden",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: "4px",
          background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        },
        "&::after": {
          content: '""',
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "140%",
          height: "140%",
          background: `radial-gradient(circle, ${theme.palette.primary.main}10, transparent 60%)`,
          transform: "translate(-50%, -50%)",
          zIndex: 0,
          animation: "pulse 15s ease-in-out infinite",
        },
        "@keyframes pulse": {
          "0%": {
            transform: "translate(-50%, -50%) scale(1)",
          },
          "50%": {
            transform: "translate(-50%, -50%) scale(1.2)",
          },
          "100%": {
            transform: "translate(-50%, -50%) scale(1)",
          },
        },
      }}
    >
      <Container maxWidth="md" sx={{ position: "relative", zIndex: 1 }}>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
          style={{ y }}
        >
          <Typography
            variant="h6"
            align="center"
            sx={{
              mb: 2,
              color: theme.palette.text.secondary,
              maxWidth: 600,
              mx: "auto",
              fontSize: { xs: "1.1rem", md: "1.2rem" },
              lineHeight: 1.8,
            }}
          >
            בשיחה אישית נוכל יחד למצוא את הדרך שנכונה בדיוק לך.
          </Typography>

          <Box
            sx={{
              transform: "scale(1.2)",
              "& > *": {
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                },
              },
            }}
          >
            <ContactButtons />
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};
