import {
  faCarrot,
  faEarth,
  faHeart,
  faLeaf,
  faSeedling,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Container, Grid2 as Grid, Typography } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { withWrapper } from "app/hooks";
import { ArticlesButton, ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider,
} from "react-scroll-parallax";
import "./Home.scss";

export const Home = withWrapper(() => {
  useMeta();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box className="vegan-landing">
        <ParallaxBanner
          layers={[
            {
              image:
                "https://images.unsplash.com/photo-1512621776951-a57141f2eefd?ixlib=rb-1.2.1&auto=format&fit=crop&w=1260&q=80",
              speed: -20,
            },
          ]}
          className="parallax-banner"
        >
          <div className="banner-content">
            <Typography variant="h1" className="banner-title">
              חיים בריאים, טבעיים ומקיימים
            </Typography>
            <Typography variant="h2" className="banner-subtitle">
              הצטרפו למהפכה הטבעונית
            </Typography>

            <ArticlesButton />
          </div>
        </ParallaxBanner>

        <Container>
          <Box className="benefits-section" my={8}>
            <Grid container spacing={4} justifyContent="center">
              <Grid data-aos="fade-up" size={{ xs: 12, md: 4 }}>
                <Box className="benefit-card">
                  <FontAwesomeIcon icon={faHeart} className="benefit-icon" />
                  <Typography variant="h5">בריאות מיטבית</Typography>
                  <Typography>
                    תזונה טבעונית מפחיתה סיכון למחלות לב, סוכרת ומחלות כרוניות
                    אחרות
                  </Typography>
                </Box>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-delay="200"
                size={{ xs: 12, md: 4 }}
              >
                <Box className="benefit-card">
                  <FontAwesomeIcon icon={faEarth} className="benefit-icon" />
                  <Typography variant="h5">אחריות סביבתית</Typography>
                  <Typography>
                    הפחתת טביעת הרגל האקולוגית ותרומה לשמירה על כדור הארץ
                  </Typography>
                </Box>
              </Grid>
              <Grid
                data-aos="fade-up"
                data-aos-delay="400"
                size={{ xs: 12, md: 4 }}
              >
                <Box className="benefit-card">
                  <FontAwesomeIcon icon={faCarrot} className="benefit-icon" />
                  <Typography variant="h5">תזונה עשירה</Typography>
                  <Typography>
                    מגוון עשיר של ויטמינים, מינרלים וחלבונים מהצומח
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box className="nutrition-section" my={8}>
            <Parallax speed={-10}>
              <Typography
                variant="h3"
                className="section-title"
                data-aos="fade-up"
              >
                תזונה טבעונית מאוזנת
              </Typography>
            </Parallax>
            <Grid container spacing={6} alignItems="center">
              <Grid data-aos="fade-right" size={{ xs: 12, md: 6 }}>
                <Box className="nutrition-content">
                  <Typography variant="h4" gutterBottom>
                    מקורות חלבון מהצומח
                  </Typography>
                  <Typography paragraph>
                    קטניות, טופו, סייטן, קינואה ואגוזים הם מקורות מעולים לחלבון
                  </Typography>
                  <Typography variant="h4" gutterBottom>
                    ויטמינים ומינרלים חיוניים
                  </Typography>
                  <Typography paragraph>
                    ירקות ירוקים, פירות, דגנים מלאים וזרעים מספקים את כל הרכיבים
                    התזונתיים הנחוצים
                  </Typography>
                </Box>
              </Grid>
              <Grid data-aos="fade-left" size={{ xs: 12, md: 6 }}>
                <img
                  src="https://images.unsplash.com/photo-1540420773420-3366772f4999?ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80"
                  alt="מזון טבעוני מגוון"
                  className="nutrition-image"
                />
              </Grid>
            </Grid>
          </Box>

          <Box className="lifestyle-section" my={8}>
            <Parallax speed={5}>
              <Typography
                variant="h3"
                className="section-title"
                data-aos="fade-up"
              >
                אורח חיים מודע ובריא
              </Typography>
              <Grid container spacing={4}>
                <Grid data-aos="zoom-in" size={{ xs: 12, md: 6 }}>
                  <Box className="lifestyle-card">
                    <FontAwesomeIcon icon={faLeaf} className="lifestyle-icon" />
                    <Typography variant="h5">מתכונים טעימים</Typography>
                    <Typography>
                      גלו עולם שלם של טעמים חדשים ומרגשים בתזונה הטבעונית
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  data-aos="zoom-in"
                  data-aos-delay="200"
                  size={{ xs: 12, md: 6 }}
                >
                  <Box className="lifestyle-card">
                    <FontAwesomeIcon
                      icon={faSeedling}
                      className="lifestyle-icon"
                    />
                    <Typography variant="h5">קהילה תומכת</Typography>
                    <Typography>
                      הצטרפו לקהילה של אנשים שחולקים את אותם ערכים ותמיכה הדדית
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Parallax>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
});
