"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultTags = exports.LetsTalkTags = exports.ArticlesTags = exports.baseCanonicalUrl = exports.baseTitle = exports.baseShortTitle = void 0;
exports.baseShortTitle = "עדי יאנה";
exports.baseTitle = "עדי יאנה - תזונאית קלינית";
exports.baseCanonicalUrl = "https://www.adiyana.co.il";
exports.ArticlesTags = {
    title: "תכנים",
    description: "תכנים מקצועיים בנושאי תזונה, בריאות ואורח חיים בריא מאת עדי יאנה - תזונאית קלינית מוסמכת",
    keywords: "מאמרי תזונה, תזונה נכונה, אורח חיים בריא, דיאטה נכונה, תזונה מאוזנת, הרגלי אכילה, תזונה בריאה, ייעוץ תזונתי",
};
exports.LetsTalkTags = {
    title: "צור קשר",
    description: "צרו איתי קשר לייעוץ תזונתי מקצועי ומותאם אישית. זמינה בווטסאפ, טלפון ורשתות חברתיות לכל שאלה או התייעצות",
    keywords: "צור קשר, ייעוץ תזונתי, תזונה מקצועית, תזונה מותאמת, תזונה בריאה, תזונה נכונה, תזונה מאוזנת",
};
exports.DefaultTags = {
    // Basic meta tags
    routeTitle: "",
    title: "",
    description: "עדי יאנה - תזונאית קלינית מוסמכת. מומחית בטיפול תזונתי מותאם אישית לילדים, מתבגרים ומבוגרים.",
    keywords: "תזונאית קלינית, ייעוץ תזונתי, תזונה נכונה, תזונה בריאה, דיאטה, השמנת ילדים, תזונת מתבגרים",
    canonicalUrl: "",
    author: "עדי יאנה",
    robots: "index, follow",
    themeColor: "#ffffff",
    mobileWebAppCapable: "yes",
    appleMobileWebAppCapable: "yes",
    formatDetection: "telephone=yes",
    google: "notranslate",
    // Open Graph / Facebook tags
    ogType: "website",
    ogImage: "https://www.adiyana.co.il/ogImage.jpeg",
    ogImageType: "image/jpeg",
    ogImageAlt: "עדי יאנה - תזונאית קלינית",
    ogImageWidth: "1280",
    ogImageHeight: "720",
    ogSiteName: "עדי יאנה - תזונאית קלינית",
    ogLocale: "he_IL",
    // JSON‑LD Structured Data
    jsonLdData: {
        organizationJsonLd: {
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "עדי יאנה - תזונאית קלינית",
            url: "https://www.adiyana.co.il",
            logo: "https://www.adiyana.co.il/logo.png",
            sameAs: [
                "https://www.facebook.com/adi.oberman",
                "https://www.instagram.com/adiyana.nutritionist",
                "https://www.linkedin.com/in/adiyana",
            ],
            contactPoint: {
                "@type": "ContactPoint",
                telephone: "+972-54-7363669",
                contactType: "customer service",
                areaServed: "IL",
                availableLanguage: ["Hebrew", "English"],
            },
        },
        websiteJsonLd: {
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "עדי יאנה - תזונאית קלינית",
            url: "https://www.adiyana.co.il",
        },
        localBusinessJsonLd: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "@id": "https://www.adiyana.co.il/#localBusiness",
            name: "עדי יאנה - תזונאית קלינית",
            image: "https://www.adiyana.co.il/logo.png",
            url: "https://www.adiyana.co.il",
            telephone: "+972-54-7363669",
            address: {
                "@type": "PostalAddress",
                addressCountry: "IL",
                addressLocality: "Israel",
            },
            openingHoursSpecification: [
                {
                    "@type": "OpeningHoursSpecification",
                    dayOfWeek: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday"],
                },
            ],
        },
    },
};
