import {
  faCarrot,
  faChild,
  faHeartPulse,
  faLeaf,
  faPersonPregnant,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HERO_IMAGE =
  "https://images.pexels.com/photos/5529587/pexels-photo-5529587.jpeg?w=1260&auto=compress";
const VISION_IMAGE =
  "https://images.pexels.com/photos/3626439/pexels-photo-3626439.jpeg?w=1260&auto=compress";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const pregnancyNutrients = [
  {
    title: "חלבון",
    description: "קטניות, טופו וסייטן",
    icon: faUtensils,
  },
  {
    title: "ברזל",
    description: "עדשים ותרד עם ויטמין C",
    icon: faHeartPulse,
  },
  {
    title: "סידן",
    description: "טופו מועשר, שקדים וברוקולי",
    icon: faLeaf,
  },
];

export const LifeStages = () => {
  useMeta();

  const theme = useTheme();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                טבעונות בשלבי החיים השונים
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                תזונה טבעונית מאוזנת מציעה יתרונות בריאותיים ייחודיים בכל שלבי
                החיים. לצד סיפוק כל אבות המזון הדרושים, היא תורמת לשמירה על משקל
                תקין, מפחיתה את הסיכון למחלות כרוניות ומשפרת את תפקוד מערכות
                הגוף ביחס לאוכלי כל. במאמר זה אבחן כיצד תזונה טבעונית אפשרית, מה
                הדגשים אליהם יש לשם לב וכיצד היא תומכת בבריאות ובהתפתחות בשלושה
                שלבי חיים עיקריים: הריון והנקה, גיל ההתבגרות וגיל המבוגר.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Pregnancy and Nursing Section */}
          <Box sx={{ my: 6 }}>
            <Grid container spacing={4}>
              <Grid size={{ xs: 12 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ gap: 2, mb: 3 }}
                >
                  <FontAwesomeIcon
                    icon={faPersonPregnant}
                    style={{ fontSize: "2rem", color: "primary.main" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    טבעונות בהריון והנקה – יתרונות בריאותיים לאם ולתינוק{" "}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  תקופת ההריון וההנקה מחייבת הקפדה על תזונה מאוזנת כדי לתמוך
                  בבריאות האם ובהתפתחות התקינה של התינוק. מחקרים מראים כי תזונה
                  טבעונית עשירה בסיבים תזונתיים, נוגדי חמצון ושומנים בלתי רוויים
                  תורמת לאיזון רמות הסוכר והכולסטרול ומפחיתה את הסיכון לסוכרת
                  הריונית וליתר לחץ דם בהשוואה לאוכלי כל. בנוסף, נשים טבעוניות
                  נוטות לשמור על משקל תקין במהלך ההריון.
                  <br />
                  איכות חלב האם אצל נשים טבעוניות דומה לזו של נשים שאוכלות כל,
                  כל עוד התזונה מאוזנת וכוללת מקורות מספקים של חלבון, ויטמין B12
                  ואומגה 3. מחקרים מראים כי תכולת החומרים המזינים בחלב האם אינה
                  מושפעת באופן משמעותי מהתזונה, אך יש להקפיד על צריכה מספקת של
                  ויטמינים ומינרלים כדי להבטיח הרכב מיטבי לתינוק. בנוסף, נשים
                  טבעוניות מדווחות לעיתים על תחושת קלילות ועייפות פחותה, שכן
                  מזונות צמחיים מעוכלים ביתר קלות ומפנים לגוף יותר אנרגיה לתפקוד
                  יומיומי. עם זאת, תזונה טבעונית עלולה להוביל לחוסרים תזונתיים
                  אם אין מודעות מספקת לצורך בצריכת אבות מזון חיוניים. חוסרים
                  בברזל או ב-B12 עלולים לגרום לעייפות ויש להקפיד על צריכתם כדי
                  למנוע פגיעה ברווחת האם והתינוק.
                  <br />
                  לכן יש להקפיד על צריכת חלבון ממקורות כמו קטניות, טופו וסייטן.
                  ברזל חיוני למניעת אנמיה ונמצא בעדשים ותרד, בשילוב עם ויטמין C
                  לשיפור הספיגה. סידן לבריאות העצמות ניתן לקבל מטופו מועשר,
                  שקדים וברוקולי, וצריכת ויטמין B12 ו-D נדרשת כתוסף תזונה.
                  מחקרים מדגישים כי תזונה מאוזנת תומכת בהתפתחות מערכת העצבים של
                  התינוק ושומרת על בריאות האם לאורך ההריון וההנקה.
                </Typography>

                {/* Pregnancy Nutrients Grid */}
                <Grid container spacing={3} sx={{ mt: 2 }}>
                  {pregnancyNutrients.map((nutrient, index) => (
                    <Grid key={index} size={{ xs: 12, sm: 4 }}>
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.2 }}
                      >
                        <InfoCard>
                          <FontAwesomeIcon
                            icon={nutrient.icon}
                            style={{
                              fontSize: "2rem",
                              color: theme.palette.primary.main,
                              marginBottom: "1rem",
                            }}
                          />
                          <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ fontWeight: 600 }}
                          >
                            {nutrient.title}
                          </Typography>
                          <Typography variant="body2">
                            {nutrient.description}
                          </Typography>
                        </InfoCard>
                      </motion.div>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* Teens Section */}
          <Box sx={{ my: 4 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 2, mb: 3 }}
            >
              <FontAwesomeIcon
                icon={faChild}
                style={{ fontSize: "2rem", color: "primary.main" }}
              />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                תזונה טבעונית בגיל ההתבגרות – צמיחה בריאה ויתרונות בהשוואה
                לאוכלי כל
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              גיל ההתבגרות מאופיין בצמיחה מואצת ובשינויים הורמונליים הדורשים
              תזונה עשירה באנרגיה, חלבון, ברזל וסידן. מחקרים שפורסמו ב-Journal
              of Adolescent Health מצאו כי מתבגרים טבעונים אשר מקפידים על תזונה
              עשירה, מאוזנת ומגוונת נהנים ממשקל גוף נמוך יותר ומהפחתת הסיכון
              להשמנה בהשוואה לאוכלי כל, ללא פגיעה בגדילה ובבריאות העצם.
              <br />
              חלבון ממקורות כמו טופו וקטניות תורם לבניית רקמות השריר והעצם.
              ברזל, החיוני למניעת עייפות ולתפקוד הקוגניטיבי, נמצא בקינואה
              ועדשים. צריכת סידן מטופו מועשר ושומשום מסייעת למניעת בריחת סידן
              בעתיד. תזונה עשירה בירקות ופירות צבעוניים תומכת במערכת החיסון
              ומשפרת את מראה העור. יתרון נוסף הוא ההפחתה בצריכת שומן רווי
              ומזונות מעובדים, אשר תורמת לשמירה על לחץ דם תקין ומפחיתה את הסיכון
              למחלות לב בגיל מאוחר.
              <br />
              יחד עם זאת, תזונה טבעונית לא מאוזנת עלולה להוביל לחוסרים תזונתיים
              בשל חוסר ידע או צריכה לא מספקת של אבות מזון חיוניים. מתבגרים ללא
              ידע וליווי מתאים נוטים להסתמך על תחליפי מזון מעובדים ולהרבות
              בצריכת פחמימות, דבר שעלול לפגוע בהרכב הגוף שלהם ולפגוע בגדילה
              התקינה. בנוסף, הרגלי אכילה אלו עלולים להוביל להשמנה ולפגיעה
              בבריאות הכללית. לכן חשוב להקפיד על תפריט מגוון ומאוזן, תוך שילוב
              מקורות חלבון מלא, ירקות עליים עשירים בברזל וסידן, ושימוש בתוספי
              תזונה לפי הצורך, כדי להבטיח גדילה בריאה ושמירה על מסת עצם ושריר.
            </Typography>
          </Box>

          {/* Elderly Section */}
          <Box sx={{ my: 6 }}>
            <ParallaxBanner
              style={{
                height: "40vh",
                borderRadius: "20px",
                overflow: "hidden",
                marginBottom: "2rem",
              }}
            >
              <ParallaxBannerLayer
                image={VISION_IMAGE}
                speed={-15}
                opacity={[0.7, 1]}
                scale={[1.1, 1]}
              />
            </ParallaxBanner>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 2, mb: 3 }}
            >
              <FontAwesomeIcon
                icon={faCarrot}
                style={{ fontSize: "2rem", color: "primary.main" }}
              />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                צמחונות וטבעונות בגיל המבוגר – שמירה על חיוניות ובריאות מיטבית
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              בגיל המבוגר ישנה חשיבות מיוחדת לשמירה על מסת שריר, בריאות העצמות
              ומניעת מחלות כרוניות. מחקרים מראים כי מבוגרים טבעונים נהנים מלחץ
              דם נמוך יותר, רמות סוכר מאוזנות וסיכון מופחת למחלות לב בהשוואה
              לאוכלי כל. בנוסף, תזונה טבעונית עשירה בסיבים תומכת במערכת העיכול
              ומפחיתה את הסיכון לעצירות.
              <br />
              שמירה על צריכת חלבון מטופו וסייטן מסייעת למניעת סרקופניה ושימור
              כוח השרירים. סידן וויטמין D תורמים לשמירה על צפיפות העצם, והברזל
              ממקורות צמחיים מסייע במניעת אנמיה. יתרון משמעותי נוסף הוא תחושת
              הקלילות והאנרגיה המוגברת שמעניקה תזונה טבעונית, בשל העיכול הקל
              יותר של מזונות צמחיים בהשוואה למזון מן החי.
            </Typography>
          </Box>

          {/* Summary */}
          <Box>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 3 }}
            >
              סיכום
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              תזונה טבעונית מבוססת מזון מלא ובריא מספקת את כל הצרכים התזונתיים
              בכל שלבי החיים – מהריון והנקה, דרך גיל ההתבגרות ועד הגיל המבוגר.
              יתרונותיה באים לידי ביטוי בשמירה על משקל תקין, הפחתת הסיכון למחלות
              כרוניות ושיפור תפקוד מערכות הגוף ביחס לאוכלי כל. עם זאת, יש להקפיד
              על תכנון תפריט מאוזן הכולל חלבון, ברזל, סידן וויטמין B12, ולשקול
              ליווי מקצועי של דיאטנית קלינית המתמחה בתזונה טבעונית להבטחת מענה
              מלא לכל הצרכים התזונתיים.
            </Typography>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
