import {
  faClock,
  faMobileScreen,
  faScaleBalanced,
  faUtensils,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const ParallaxHeader = styled(Box)(({ theme }) => ({
  position: "relative",
  height: "50vh",
  width: "100%",
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(2),
  overflow: "hidden",
}));

const ContentSection = styled(Box)(({ theme }) => ({
  position: "relative",
  zIndex: 1,
  "& > *": {
    marginBottom: theme.spacing(2),
  },
}));

const InfoCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  borderRadius: 15,
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const hormonalData = [
  { hour: "22:00", ghrelin: 30, leptin: 70 },
  { hour: "23:00", ghrelin: 45, leptin: 60 },
  { hour: "00:00", ghrelin: 60, leptin: 50 },
  { hour: "01:00", ghrelin: 75, leptin: 40 },
  { hour: "02:00", ghrelin: 85, leptin: 30 },
  { hour: "03:00", ghrelin: 90, leptin: 25 },
];

const sleepTips = [
  {
    id: "screens",
    title: "הגבלת שימוש במסכים",
    description:
      "הימנעו משימוש במסכים לפחות שעה לפני השינה - הפחתת חשיפה לאור כחול משפרת את ייצור המלטונין ומסייעת להירדמות קלה יותר",
    icon: faMobileScreen,
    color: "#FF6B6B",
  },
  {
    id: "schedule",
    title: "שגרת שינה קבועה",
    description:
      "שמירה על זמני שינה והתעוררות קבועים והימנעות משינויים דרסטיים בסופי שבוע וחופשות",
    icon: faClock,
    color: "#4ECDC4",
  },
  {
    id: "nutrition",
    title: "תזונה תומכת שינה",
    description:
      "אכילת מזונות עשירים בטריפטופן כגון שקדים, טופו ושיבולת שועל לשיפור איכות השינה",
    icon: faUtensils,
    color: "#45B7D1",
  },
  {
    id: "balance",
    title: "איזון הורמונלי",
    description:
      "שינה מספקת חיונית לאיזון הורמוני הרעב והשובע ולהפרשת הורמון הגדילה",
    icon: faScaleBalanced,
    color: "#96CEB4",
  },
];

const HERO_IMAGE =
  "https://images.pexels.com/photos/3771069/pexels-photo-3771069.jpeg?w=1260&auto=compress";

export const SleepScreenObesity = () => {
  useMeta();

  const theme = useTheme();

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxHeader>
          <ParallaxBanner style={{ height: "100%" }}>
            <ParallaxBannerLayer
              image={HERO_IMAGE}
              speed={-20}
              scale={[1.2, 1]}
              opacity={[0.9, 1]}
              translateY={[0, 50]}
            />
            <ParallaxBannerLayer
              speed={-15}
              opacity={[1, 0.9]}
              translateY={[0, 30]}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background:
                    "linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.6))",
                  padding: { xs: 2, md: 4 },
                }}
              >
                <Typography
                  variant="h1"
                  align="center"
                  sx={{
                    fontWeight: 800,
                    color: "white",
                    textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                    maxWidth: "1000px",
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    lineHeight: 1.2,
                  }}
                >
                  מתבגרים עייפים אוכלים יותר
                </Typography>
                <Typography
                  variant="h4"
                  align="center"
                  sx={{
                    color: "white",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
                    maxWidth: "800px",
                    mt: 2,
                    fontSize: { xs: "1.5rem", md: "2rem" },
                  }}
                >
                  הקשר בין שעות שינה, מסכים והשמנה
                </Typography>
              </Box>
            </ParallaxBannerLayer>
          </ParallaxBanner>
        </ParallaxHeader>

        <Container maxWidth="lg" sx={{ py: 2 }}>
          <ContentSection>
            {/* Introduction */}
            <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  הקדמה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.2rem", lineHeight: 1.8 }}
                >
                  השמנה בקרב מתבגרים היא סוגיה גלובלית המושפעת מגורמים רבים,
                  כולל תזונה, פעילות גופנית, גנטיקה ואורח חיים. אחד הגורמים
                  המרכזיים שנמצאו קשורים ישירות להשמנה הוא מחסור בשינה, אשר נפוץ
                  במיוחד בקרב מתבגרים. בנוסף, השימוש הנרחב במסכים – פלאפונים,
                  מחשבים, וטלוויזיות – מוביל לשיבוש משמעותי של מחזורי השינה
                  ולפגיעה באיכותה. יציאות מהשגרה, כגון חופשות וחוסר סדר בין יום
                  ולילה, מחמירות את ההשפעות השליליות הללו.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Hormonal Balance Graph */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  הקשר הביולוגי בין שינה לתיאבון ולגדילה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 4 }}
                >
                  שינה חיונית לאיזון הורמונלי תקין. היא משפיעה על ההורמונים
                  גרלין ולפטין, המווסתים את תחושות הרעב והשובע. גרלין, המיוצר
                  בקיבה, מגביר את תחושת הרעב, בעוד לפטין, המיוצר בתאי השומן,
                  מאותת על תחושת שובע. מחקרים מצביעים על כך שמתבגרים הסובלים
                  ממחסור בשינה חווים עלייה ברמות הגרלין וירידה ברמות הלפטין, מה
                  שגורם לרעב מוגבר ולצריכת יתר של מזונות עתירי קלוריות, בעיקר
                  פחמימות ושומנים.
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 4 }}
                >
                  מעבר לכך, שינה מספקת היא קריטית להפרשת הורמון הגדילה (GH),
                  החיוני להתפתחות תקינה של רקמות הגוף, העצמות והשרירים. מתבגרים
                  אשר אינם ישנים מספיק עלולים לסבול מהאטה בגדילה ופגיעה בהתפתחות
                  גופנית תקינה.
                </Typography>
                <Box sx={{ height: 300, width: "100%" }}>
                  <ResponsiveContainer>
                    <AreaChart data={hormonalData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="hour" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="ghrelin"
                        stackId="1"
                        stroke="#FF6B6B"
                        fill="#FF6B6B"
                        name="גרלין (הורמון הרעב)"
                      />
                      <Area
                        type="monotone"
                        dataKey="leptin"
                        stackId="2"
                        stroke="#4ECDC4"
                        fill="#4ECDC4"
                        name="לפטין (הורמון השובע)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>
              </StyledPaper>
            </Parallax>

            {/* Sleep Tips Grid */}
            <Grid container spacing={3}>
              {sleepTips.map((tip) => (
                <Grid key={tip.id} size={{ xs: 12, sm: 6, md: 3 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ height: "100%" }}
                  >
                    <InfoCard>
                      <FontAwesomeIcon
                        icon={tip.icon}
                        style={{
                          fontSize: "2.5rem",
                          color: tip.color,
                          marginBottom: "1rem",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        {tip.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {tip.description}
                      </Typography>
                    </InfoCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>

            {/* Screen Impact Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  השפעת המסכים על השינה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  השימוש במסכים לפני השינה הוא אחד הגורמים המרכזיים לפגיעה
                  באיכות השינה של מתבגרים. המסכים פולטים אור כחול (אורך גל קצר),
                  המעכב את ייצור המלטונין, הורמון האחראי על ויסות מחזורי השינה.
                  רמות נמוכות של מלטונין גורמות לקשיים בהירדמות ולשינה מקוטעת.
                  בנוסף, החשיפה לתכנים מעוררים (כגון משחקי מחשב ורשתות חברתיות)
                  מעלה את רמת העירנות ומקשה על ההירגעות הדרושה לשינה איכותית.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Schedule Disruption Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  יציאות מהשגרה והשפעתן על דפוסי השינה
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  במהלך חופשות, חגים וסופי שבוע, מתבגרים רבים נוטים לשנות את
                  זמני השינה שלהם – ערות עד שעות מאוחרות והתעוררות מאוחרת,
                  לעיתים אף מעבר לשעות הצהריים. שינוי זה משבש את מחזור השינה
                  הפנימי ויוצר ג&rsquo;ט לג חברתי, מצב בו הגוף מתקשה לחזור לשגרת
                  יום-לילה תקינה, במיוחד כאשר החופשה מסתיימת. המעבר בין חופשה
                  ללימודים הופך למאתגר וגורר עייפות כרונית, חוסר ריכוז והגברת
                  הצורך במזונות מעוררים ועתירי קלוריות.
                </Typography>
              </StyledPaper>
            </Parallax>

            {/* Nutritional Behavior Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  השפעת חוסר שינה על התנהגות תזונתית
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 2 }}
                >
                  חוסר שינה בקרב מתבגרים מוביל לשינויים התנהגותיים המשפיעים
                  ישירות על הרגלי האכילה:
                </Typography>
                <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    צריכת יתר של חטיפים ומזון מעובד – עייפות גורמת למוח לחפש
                    מקורות אנרגיה מהירים, מה שמוביל לצריכת יתר של סוכרים
                    ושומנים.
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    אכילה בשעות מאוחרות – שעות שינה לא סדירות גורמות לאכילה
                    בשעות חריגות, מה שמוביל לעיכול לקוי ולעלייה במשקל.
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    דילוג על ארוחות – מתבגרים עייפים מדלגים לעיתים קרובות על
                    ארוחות חשובות כמו ארוחת הבוקר, מה שמוביל לאכילה לא מאוזנת
                    במהלך היום.
                  </Typography>
                </Box>
              </StyledPaper>
            </Parallax>

            {/* Improvement Tips Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  כיצד ניתן לשפר את הרגלי השינה של מתבגרים?
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8, mb: 2 }}
                >
                  כדי לשפר את איכות השינה ולמנוע השפעות שליליות על הבריאות
                  והמשקל, מומלץ:
                </Typography>
                <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    ליצור הרגלים סביב ניהול השינה והמסכים
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    להימנע משימוש במסכים לפחות שעה לפני השינה – הפחתת חשיפה לאור
                    כחול משפרת את ייצור המלטונין ומסייעת להירדמות קלה יותר
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    להפחית צריכת קפאין ומשקאות ממותקים בערב – קפאין עלול לעכב את
                    ההירדמות ולגרום לשינה שטחית
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    לעודד פעילות גופנית במהלך היום – ספורט מסייע לשינה עמוקה
                    ואיכותית יותר
                  </Typography>
                  <Typography component="li" sx={{ fontSize: "1.1rem", mb: 1 }}>
                    לאכול מזונות תומכי שינה – שילוב מזונות עשירים בטריפטופן
                    (כגון שקדים, טופו ושיבולת שועל) יכול לתרום להפרשת מלטונין
                    ולשיפור איכות השינה
                  </Typography>
                </Box>
              </StyledPaper>
            </Parallax>

            {/* Summary Section */}
            <Parallax translateY={[10, -10]} opacity={[0.7, 1]}>
              <StyledPaper elevation={3}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 600, color: theme.palette.primary.main }}
                >
                  סיכום
                </Typography>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  מתבגרים רבים סובלים ממחסור בשינה בשל שימוש מוגבר במסכים,
                  יציאות מהשגרה ושיבוש של מחזורי יום-לילה, דבר המשפיע ישירות על
                  בריאותם, על תהליך הגדילה שלהם ועל משקל גופם. מחקרים מצביעים על
                  כך ששינה בלתי מספקת מובילה לשינויים הורמונליים, התנהגותיים
                  ותזונתיים, אשר עלולים לגרום להשמנה ולבעיות בריאותיות נוספות.
                  על כן, חיוני להעלות את המודעות לנושא וליישם הרגלים נכונים
                  לשמירה על בריאות מתבגרים ושינה איכותית.
                </Typography>
              </StyledPaper>
            </Parallax>

            <ContactButtons />
          </ContentSection>
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
