import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBaby,
  faChild,
  faGrid,
  faHandsHoldingChild,
  faHourglassHalf,
  faLeaf,
  faRunning,
  faUser,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Product, ProductInfo } from "@monorepo/constants";
import { Box, Button, ButtonBase, Popover, Typography } from "@mui/material";
import { withWrapper } from "app/hooks";
import { useProduct } from "modules/core";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

interface ProductLinkProps {
  to: string;
  isActive: boolean;
  onClick: () => void;
  icon: IconProp;
  children: React.ReactNode;
}

export const ProductSwitcher = withWrapper(() => {
  const { product, productTitle } = useProduct();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    setAnchorEl(buttonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const ProductLink = ({
    to,
    isActive,
    onClick,
    icon,
    children,
  }: ProductLinkProps) => {
    const productInfo = ProductInfo[to.replace("/", "") as Product];

    return (
      <ButtonBase
        component={Link}
        to={to}
        onClick={onClick}
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "12px 16px",
          color: productInfo.theme.main,
          gap: 2,
          width: "100%",
          borderRadius: 2,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            transform: "translateX(-2px)",
          },
          ...(isActive && {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
          }),
        }}
      >
        <Box sx={{ width: 32, display: "flex", justifyContent: "center" }}>
          <FontAwesomeIcon icon={icon} size="2xl" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
            {children}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            {productInfo.description}
          </Typography>
        </Box>
      </ButtonBase>
    );
  };

  if (!product) {
    return null;
  }

  return (
    <>
      <Button
        ref={buttonRef}
        onClick={handleClick}
        variant="text"
        sx={{
          fontSize: "20px",
          color: "white",
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.08)",
          },
          display: "flex",
          alignItems: "center",
          gap: 1,
          minWidth: 140,
          padding: "6px 16px",
        }}
      >
        <FontAwesomeIcon icon={faGrid} />
        {productTitle}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            mt: 1,
            borderRadius: 2,
            boxShadow: "0 4px 20px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "800px",
            width: "100%",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <ProductLink
              to="/lifestyle"
              isActive={product === Product.Lifestyle}
              onClick={handleClose}
              icon={faUser}
            >
              {ProductInfo[Product.Lifestyle].title}
            </ProductLink>
            <ProductLink
              to="/kids"
              isActive={product === Product.Kids}
              onClick={handleClose}
              icon={faHandsHoldingChild}
            >
              {ProductInfo[Product.Kids].title}
            </ProductLink>
            <ProductLink
              to="/teens"
              isActive={product === Product.Teens}
              onClick={handleClose}
              icon={faChild}
            >
              {ProductInfo[Product.Teens].title}
            </ProductLink>
            <ProductLink
              to="/vegan"
              isActive={product === Product.Vegan}
              onClick={handleClose}
              icon={faLeaf}
            >
              {ProductInfo[Product.Vegan].title}
            </ProductLink>
            <ProductLink
              to="/sport"
              isActive={product === Product.Sport}
              onClick={handleClose}
              icon={faRunning}
            >
              {ProductInfo[Product.Sport].title}
            </ProductLink>
            <ProductLink
              to="/maternity"
              isActive={product === Product.Maternity}
              onClick={handleClose}
              icon={faBaby}
            >
              {ProductInfo[Product.Maternity].title}
            </ProductLink>
            <ProductLink
              to="/midlife"
              isActive={product === Product.Midlife}
              onClick={handleClose}
              icon={faHourglassHalf}
            >
              {ProductInfo[Product.Midlife].title}
            </ProductLink>
          </Box>
        </Box>
      </Popover>
    </>
  );
});
