import MetaTags from "@monorepo/metatags";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  removeElementById,
  updateOrCreateLink,
  updateOrCreateMeta,
  updateOrCreateScript,
} from "../services";

const DEFAULT_TITLE = "עדי יאנה - תזונאית קלינית";

export function useMeta() {
  const location = useLocation();
  const { pathname } = location;
  const metaTags = useMemo(() => MetaTags(pathname), [pathname]);

  useEffect(() => {
    // Basic meta tags
    document.title = metaTags.title || DEFAULT_TITLE;
    updateOrCreateMeta("description", metaTags.description);
    updateOrCreateMeta("keywords", metaTags.keywords);
    updateOrCreateLink("canonical", metaTags.canonicalUrl);
    updateOrCreateMeta("author", metaTags.author);
    updateOrCreateMeta("robots", metaTags.robots);
    updateOrCreateMeta("theme-color", metaTags.themeColor);
    updateOrCreateMeta("mobile-web-app-capable", metaTags.mobileWebAppCapable);
    updateOrCreateMeta(
      "apple-mobile-web-app-capable",
      metaTags.appleMobileWebAppCapable
    );
    updateOrCreateMeta("format-detection", metaTags.formatDetection);
    updateOrCreateMeta("google", metaTags.google);

    // Open Graph / Facebook tags
    updateOrCreateMeta("og:type", metaTags.ogType, "property");
    updateOrCreateMeta("og:title", metaTags.title, "property");
    updateOrCreateMeta("og:description", metaTags.description, "property");
    updateOrCreateMeta("og:url", metaTags.canonicalUrl, "property");
    updateOrCreateMeta("og:image", metaTags.ogImage, "property");
    updateOrCreateMeta("og:image:type", metaTags.ogImageType, "property");
    updateOrCreateMeta("og:image:alt", metaTags.ogImageAlt, "property");
    updateOrCreateMeta("og:image:width", metaTags.ogImageWidth, "property");
    updateOrCreateMeta("og:image:height", metaTags.ogImageHeight, "property");
    updateOrCreateMeta("og:site_name", metaTags.ogSiteName, "property");
    updateOrCreateMeta("og:locale", metaTags.ogLocale, "property");

    // WhatsApp
    updateOrCreateMeta("og:whatsapp:message", metaTags.description);

    // Pinterest
    updateOrCreateMeta("pinterest-rich-pin", "true");

    // Instagram
    updateOrCreateMeta("instapp:hashtags", metaTags.keywords, "property");

    // LinkedIn
    updateOrCreateMeta("linkedin:title", metaTags.title, "property");
    updateOrCreateMeta(
      "linkedin:description",
      metaTags.description,
      "property"
    );
    updateOrCreateMeta("linkedin:image", metaTags.ogImage, "property");

    // JSON‑LD Structured Data
    updateOrCreateScript(
      "jsonld-organization",
      metaTags.jsonLdData.organizationJsonLd
    );
    updateOrCreateScript("jsonld-website", metaTags.jsonLdData.websiteJsonLd);
    updateOrCreateScript(
      "jsonld-local-business",
      metaTags.jsonLdData.localBusinessJsonLd
    );

    if (metaTags.jsonLdData.articleJsonLd) {
      updateOrCreateScript("jsonld-article", metaTags.jsonLdData.articleJsonLd);
    } else {
      removeElementById("jsonld-article");
    }

    // Cleanup function
    return () => {
      removeElementById("jsonld-organization");
      removeElementById("jsonld-website");
      removeElementById("jsonld-local-business");
      removeElementById("jsonld-article");
    };
  }, [metaTags]);
}
