import {
  faHeartPulse,
  faMoon,
  faPersonCircleCheck,
  faUsersRays,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Container,
  Grid2 as Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion } from "framer-motion";
import { ContactButtons, useMeta } from "modules/common";
import { useEffect } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  ParallaxProvider,
} from "react-scroll-parallax";

const HERO_IMAGE =
  "https://images.pexels.com/photos/3768894/pexels-photo-3768894.jpeg?w=1260&auto=compress";
const NUTRITION_IMAGE =
  "https://images.pexels.com/photos/1640770/pexels-photo-1640770.jpeg?w=1260&auto=compress";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2),
  borderRadius: 20,
  backgroundColor: theme.palette.background.paper,
  boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
}));

const InfoCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  height: "100%",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 30px rgba(0,0,0,0.12)",
  },
}));

const nutritionGuidelines = [
  {
    title: "חלבונים",
    description: "תמיכה בתהליכי הגדילה ושמירה על בריאות השרירים",
    icon: "🥩",
  },
  {
    title: "סיבים תזונתיים",
    description: "ויסות תחושת השובע ומניעת נשנושים עודפים",
    icon: "🥬",
  },
  {
    title: "פחמימות ושומנים בריאים",
    description: "אספקת אנרגיה נאותה ומניעת חסרים תזונתיים",
    icon: "🥑",
  },
  {
    title: "הפחתת מזון מעובד",
    description: "הפחתת הסיכון להשמנה ולבעיות מטבוליות",
    icon: "🚫",
  },
];

export const DietWeightBodyImage = () => {
  useMeta();

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
  }, []);

  return (
    <ParallaxProvider>
      <Box sx={{ overflow: "hidden" }}>
        {/* Hero Section */}
        <ParallaxBanner
          style={{
            height: "50vh",
            borderRadius: "0 0 20px 20px",
            overflow: "hidden",
          }}
        >
          <ParallaxBannerLayer
            image={HERO_IMAGE}
            speed={-20}
            opacity={[0.9, 1]}
            scale={[1.2, 1]}
          />
          <ParallaxBannerLayer
            speed={-15}
            opacity={[1, 0.9]}
            translateY={[0, 30]}
          >
            <Box
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.7))",
                padding: 4,
                textAlign: "center",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  color: "white",
                  fontWeight: 800,
                  fontSize: { xs: "2rem", md: "3.5rem" },
                  textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
                  maxWidth: "1200px",
                  mb: 2,
                }}
              >
                הקשר בין תזונת מתבגרים, משקל ודימוי גוף
              </Typography>
            </Box>
          </ParallaxBannerLayer>
        </ParallaxBanner>

        <Container maxWidth="lg" sx={{ py: 6 }}>
          {/* Introduction */}
          <Parallax translateY={[10, -10]} opacity={[0.5, 1]}>
            <StyledPaper elevation={3}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{ fontWeight: 600, mb: 3 }}
              >
                הקדמה
              </Typography>
              <Typography
                variant="body1"
                paragraph
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                תקופת ההתבגרות מאופיינת בשינויים פיזיולוגיים, קוגניטיביים
                ורגשיים משמעותיים, אשר משפיעים גם על הרגלי האכילה, המשקל ודימוי
                הגוף של בני הנוער. אחד הגורמים המרכזיים לכך הוא הסערה ההורמונלית
                המתרחשת בגיל ההתבגרות, אשר גורמת לשינויים בגוף, בהתנהגות ובמצב
                הרגשי. ההורמונים משפיעים על קצב חילוף החומרים, חלוקת השומן בגוף,
                והתפתחות השרירים, ויכולים להשפיע באופן ישיר על תחושת הביטחון
                ודימוי הגוף של המתבגרים.
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
              >
                השינויים הללו עלולים לגרום לחוסר יציבות רגשית ולתחושת זרות כלפי
                הגוף המשתנה. מחקרים מצביעים על כך שתהליכי שינוי אלו משפיעים
                באופן ישיר על תפיסת הגוף, הערכת המשקל והיחס לתזונה. דימוי גוף
                שלילי בקרב מתבגרים עשוי להוביל להפרעות אכילה, בעוד שתזונה לקויה
                עלולה להשפיע על הבריאות הפיזית והנפשית בטווח הארוך.
              </Typography>
            </StyledPaper>
          </Parallax>

          {/* Physiological Changes Section */}
          <Box sx={{ my: 6 }}>
            <ParallaxBanner
              style={{
                height: "50vh",
                borderRadius: "20px",
                overflow: "hidden",
                marginBottom: "2rem",
              }}
            >
              <ParallaxBannerLayer
                image={NUTRITION_IMAGE}
                speed={-15}
                opacity={[0.7, 1]}
                scale={[1.1, 1]}
              />
            </ParallaxBanner>
            <Grid container spacing={4}>
              <Grid size={{ xs: 12 }}>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ gap: 2, mb: 3 }}
                >
                  <FontAwesomeIcon
                    icon={faHeartPulse}
                    style={{ fontSize: "2rem", color: "primary.main" }}
                  />
                  <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                  >
                    שינויים פיזיולוגיים ותזונתיים בגיל ההתבגרות
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  paragraph
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  בגיל ההתבגרות חלים שינויים מטבוליים משמעותיים, לרבות עלייה
                  בקצב חילוף החומרים, גדילה מואצת ושינויים הורמונליים שמשפיעים
                  על הרכב הגוף. מחקרים מצביעים על כך שמתבגרים זקוקים לכמות
                  אנרגיה וחומרי תזונה מספקים כדי לתמוך בתהליך הגדילה.
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
                >
                  עם זאת, הרגלי התזונה בגיל זה עלולים להיות לא מאוזנים. תזונה
                  דלה בחלבונים, ויטמינים ומינרלים או לחלופין צריכה מוגברת של
                  מזון מעובד, עתיר שומן וסוכר נמצאו קשורים להשפעות שליליות על
                  הבריאות, ביניהן עלייה במשקל, השמנה, וחסרים תזונתיים.
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Sleep Section */}
          <Box sx={{ my: 6 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 2, mb: 3 }}
            >
              <FontAwesomeIcon
                icon={faMoon}
                style={{ fontSize: "2rem", color: "primary.main" }}
              />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                חשיבות השינה בגיל ההתבגרות
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              שינה מסודרת ומספקת היא גורם מכריע בתהליך ההתבגרות הפיזיולוגית
              והנפשית. במהלך השינה, הגוף משחרר הורמוני גדילה חיוניים אשר תומכים
              בהתפתחות השרירים, העצמות והתפקודים הקוגניטיביים. מחקרים מצביעים על
              כך שמתבגרים שישנים פחות מהנדרש עלולים לסבול מחוסר איזון הורמונלי,
              אשר עלול להשפיע על הרכב הגוף, להגביר את הסיכון לעלייה במשקל
              ולהחמיר תחושות של עייפות כרונית. בנוסף, חוסר שינה נמצא קשור
              לשינויים במצב הרוח ולהתנהגויות אכילה לא מבוקרות, מה שעלול להשפיע
              על דימוי הגוף של המתבגרים.
            </Typography>
          </Box>

          {/* Body Image Section */}
          <Box sx={{ my: 6 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 2, mb: 3 }}
            >
              <FontAwesomeIcon
                icon={faPersonCircleCheck}
                style={{ fontSize: "2rem", color: "primary.main" }}
              />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                דימוי גוף והשפעותיו על בריאות המתבגרים
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              דימוי הגוף בקרב מתבגרים מושפע מגורמים רבים, בהם תקשורת חברתית,
              השפעות מדיה ותפיסות תרבותיות לגבי אידאל היופי. מחקרים מראים כי
              חשיפה לתכנים המעודדים רזון קיצוני או מבנה גוף לא מציאותי יכולה
              לפגוע בהערכה העצמית ולהוביל להתנהגויות אכילה מסוכנות, כגון הגבלת
              קלוריות קיצונית, דיאטות קיצוניות, אכילה מפרעת - מנודות בין צימצום
              ובין אכילה בולמוסית והפרעות אכילה שונות.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              על פי מחקר שפורסם ב-Journal of Adolescent Health, בני נוער
              המרגישים לחץ חברתי לרזון עלולים להיות בסיכון גבוה יותר לפתח חוסר
              שביעות רצון ממשקלם ולנקוט בהרגלי אכילה לא בריאים. מאידך, משקל עודף
              עלול להוביל לתחושות בושה והימנעות מפעילות גופנית, מה שמחמיר את
              הבעיה.
            </Typography>
          </Box>

          {/* Nutrition Guidelines */}
          <Box sx={{ my: 6 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 4 }}
            >
              תזונה מאוזנת ככלי לשיפור בריאות ודימוי גוף
            </Typography>
            <Grid container spacing={3}>
              {nutritionGuidelines.map((guideline, index) => (
                <Grid key={index} size={{ xs: 12, sm: 6, md: 3 }}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.2 }}
                  >
                    <InfoCard>
                      <Typography
                        variant="h1"
                        sx={{ fontSize: "3rem", mb: 2, textAlign: "center" }}
                      >
                        {guideline.icon}
                      </Typography>
                      <Typography
                        variant="h6"
                        gutterBottom
                        sx={{ textAlign: "center", fontWeight: 600 }}
                      >
                        {guideline.title}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "center" }}>
                        {guideline.description}
                      </Typography>
                    </InfoCard>
                  </motion.div>
                </Grid>
              ))}
            </Grid>
          </Box>

          {/* Professional Role Section */}
          <Box sx={{ my: 6 }}>
            <Stack
              direction="row"
              spacing={2}
              alignItems="center"
              sx={{ gap: 2, mb: 3 }}
            >
              <FontAwesomeIcon
                icon={faUsersRays}
                style={{ fontSize: "2rem", color: "primary.main" }}
              />
              <Typography variant="h4" gutterBottom sx={{ fontWeight: 600 }}>
                תפקיד ההורים ואנשי המקצוע בקידום הרגלים בריאים
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              חינוך תזונתי נכון מהווה מרכיב משמעותי בעיצוב הרגלי האכילה של
              מתבגרים. הורים ואנשי מקצוע, כגון דיאטנים קליניים ואנשי חינוך,
              יכולים להוות מודל לחיקוי ולספק תמיכה מתאימה ליצירת מערכת יחסים
              בריאה עם אוכל.
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              מחקרים מדגישים כי שיח חיובי על הגוף והימנעות מהערות ביקורתיות
              בנוגע למשקל תורמים להפחתת הסיכון להפרעות אכילה. כמו כן, שילוב של
              פעילות גופנית סדירה ושיפור הגישה הרגשית לאכילה מסייעים בשיפור
              דימוי הגוף ובשמירה על אורח חיים בריא.
            </Typography>
          </Box>

          {/* Summary */}
          <Box sx={{ my: 6 }}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{ fontWeight: 600, mb: 3 }}
            >
              סיכום
            </Typography>
            <Typography
              variant="body1"
              paragraph
              sx={{ fontSize: "1.1rem", lineHeight: 1.8 }}
            >
              הקשר בין תזונה, משקל ודימוי גוף בקרב בני נוער הוא נושא משמעותי
              הדורש התייחסות רב-מערכתית. הרגלי תזונה לא מאוזנים יכולים להשפיע הן
              על הבריאות הפיזית והן על ההיבט הנפשי של מתבגרים. על כן, ישנה
              חשיבות רבה להקניית הרגלי תזונה מאוזנים וליווי מקצועי מתאים, אשר
              יסייעו למנוע בעיות בריאותיות ולחזק את הדימוי העצמי החיובי של בני
              הנוער.
            </Typography>
          </Box>

          <ContactButtons />
        </Container>
      </Box>
    </ParallaxProvider>
  );
};
