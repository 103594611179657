import { Product, ProductInfo } from "@monorepo/constants";
import { createTheme, Theme } from "@mui/material/styles";

const baseTheme = createTheme({
  direction: "rtl",
  typography: { fontFamily: "Alef, Arial, sans-serif" },
  palette: {
    primary: { main: "#00A9FF" },
  },
});

// Create themes using the centralized colors from ProductInfo
const themeColors = Object.values(Product).reduce<Record<Product, Theme>>(
  (acc, product) => ({
    ...acc,
    [product]: createTheme({
      ...baseTheme,
      palette: { primary: { main: ProductInfo[product].theme.main } },
    }),
  }),
  {} as Record<Product, Theme>
);

export const getTheme = (product?: Product) => {
  return product ? themeColors[product] : baseTheme;
};
