import { Article } from "modules/core";

export const kidsArticles: Article[] = [
  {
    path: "/kids/articles/parental-attention-trap",
    image:
      "https://images.unsplash.com/photo-1556909114-f6e7ad7d3136?q=80&w=350",
  },
  {
    path: "/kids/articles/sleep-obesity-link",
    image:
      "https://images.pexels.com/photos/3771663/pexels-photo-3771663.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/kids/articles/parenting-overeating",
    image:
      "https://images.pexels.com/photos/4262010/pexels-photo-4262010.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/kids/articles/screen-sedentary-obesity",
    image:
      "https://images.pexels.com/photos/4740529/pexels-photo-4740529.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
  {
    path: "/kids/articles/food-marketing-eating-habits",
    image:
      "https://images.pexels.com/photos/3985062/pexels-photo-3985062.jpeg?auto=compress&cs=tinysrgb&w=350",
  },
];
