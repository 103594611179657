"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = void 0;
var Product;
(function (Product) {
    Product["Lifestyle"] = "lifestyle";
    Product["Kids"] = "kids";
    Product["Teens"] = "teens";
    Product["Vegan"] = "vegan";
    Product["Sport"] = "sport";
    Product["Maternity"] = "maternity";
    Product["Midlife"] = "midlife";
})(Product || (exports.Product = Product = {}));
